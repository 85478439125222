/*
    Old Filter Dropdown.
*/

.filter-dropdown {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 0;
	z-index: 2;

	&.is-open {
		height: auto;
		border-bottom: 6px solid $c_secondary;
	}

	&__item {
		color: $c_chicago;
		padding: px2rem(15) px2rem(30);

		&:first-child {
			padding-top: px2rem(30);
		}

		&:last-child {
			padding-bottom: px2rem(30);
		}
	}

	&__header {
		display: none;
	}

	&__footer {
		display: none;
	}
}









@include at(sm) {
	.filter-dropdown {
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		bottom: 1.5rem;
		width: 90vw;
		z-index: 10;

		max-height: 90vh;
		overflow: scroll;

		&.is-open {
			border-bottom: none;
		}

		&__header {
			display: block;
			padding: 1rem;
			background: $c_wild-sand;
		}

		&__footer {
			display: block;
		}

		&__back {
			.icon {
				transform: scaleX(-1);
				width: 1rem;
				height: 1rem;
			}
		}
	}

	.filter-is-open {
		overflow-y: hidden;

		.filter__header {
			opacity: 0;
		}

		.filter__item {
			opacity: 0;
		}

		.filter__footer {
			opacity: 0;
		}

		.filter-dropdown__footer {
			.filter__item {
				opacity: 1;
			}
		}
	}

}
