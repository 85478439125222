body {
    &.is-open,
    &.quote-is-open,
    &.is-single-close-open {
        overflow: hidden;
    }

    scroll-behavior: smooth;
}

/*
    Header Hero Banner
 */

.header__image .header__body {
    top: 215px;
    position: absolute;
    z-index: 1;
    padding: 0 15px;
}

.header__image .header__body h1,
.header__image .header__body p,
.header__image .header__body button {
    position: relative;
    top: 0;
    z-index: 1;
}

.header__image .header__body p {
    line-height: 1.7rem;
    margin-bottom: 36px;
}

@media (max-width: 767px) {
    .header__image .header__body {
        top: 175px;
    }
}

@media (max-width: 600px) {
    .header__image .header__body h1,
    .header__image .header__body p {
        width: 80%;
    }
}

.modal-video {
    /*font-size: 13px;*/
    /*padding: 0 15px;*/
    /*padding-bottom: 1.365rem;*/
}

.banner_button {
    font-size: 13px;
    padding: 0 15px 1.365rem 15px;
}

.modal-video span {
    position: relative;
    top: 5px;
}

.modal-video svg {
    width: 22.18px;
    height: 22.18px;
    top: 6px;
    position: relative;
    margin-left: 29.3px;
}

@media (max-width: 47.9375em) {
    .modal-video {
        width: auto;
    }
}

.header__image .header__body .link-button a {
    height: 44px;
    display: initial;
    padding: 0 37px 1.365rem 37px;
}

.header__image .header__body .link-button a span {
    position: relative;
    top: 11px;
}

.pimcore_area_herobanner .button {
    width: auto;
}

.header__image .full-bleed-image picture {
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    background-position: center;
}

/*
    Industry Solutions page css.
 */
.industry-teaser:before {
    content: "";
    position: absolute;
    height: 5px;
    border-top: 1px solid #ecebea;
    width: 100%;
}

.industry-container:last-child:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid #ecebea;
    width: 100%;
}

.industry-teaser h2 {
    font-size: 1em;
    font-weight: bold;
    flex: 8;
    padding: 25px 0;
    color: #5c5c5b;
}

/*
  Content page for Services.
 */
.content-page .article__inner h1 {
    line-height: 1.25;
}

.content-pagination {
    margin-top: 40px;
}

.content-page .content-pagination h2 {
    font-size: 1.5em;
    font-weight: bold;
    color: #5c5c5b;
    display: inline-block;
}

@media (max-width: 767px) {
    .content-page .content-pagination .prev {
        margin-bottom: 20px;
    }

    .content-page .content-pagination h2 {
        font-size: 1em;
    }
}

.content-page .content-pagination a.previous-arrow:hover:before {
    right: 0.1875em;
}

.content-page .content-pagination a.previous-arrow:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" style="fill:rgba(0, 158, 226, 1);" transform="rotate(180)" viewBox="0 0 27.1 8.8"><path d="M22.7 0v3.8H0v1.3h22.7v3.8l4.4-4.4L22.7 0z"/></svg>');
    transition: all 0.1s ease-in-out;
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    width: 2.30769em;
    height: 1em;
    margin-right: 1.5em;
    position: relative;
}

.content-page .content-pagination .next a {
    float: right;
}

.wysiwyg--article h2 {
    line-height: 2.25rem;
}

/*
    Individual Application
 */
.individual-application-teaser {
    margin-bottom: 40px;
}

/*
    Content Pages Main Container
 */
.content-page .article__split-header,
.content-page .article {
    width: 100%;
}

.content-page .article header p {
    line-height: 1.6;
    color: #009ee2;
    font-size: 1.1rem;
}

@media (max-width: 767px) {
    .content-page .container {
        max-width: 100%;
    }

    .content-page .u_has-shadow {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

/*
    Content Pages CTA Editable
 */

.box-cta-editable-container {
    background: #009ee2;
}

.box-cta-editable-container .inner {
    text-align: center;
    padding: 40px;
}

.box-cta-editable-container p {
    color: #fff;
    line-height: 1.3;
    margin-bottom: 40px;
    font-size: 1.05rem;
}

.box-cta-editable-container .button {
    background-color: #07b4ff;
    font-size: 0.77em;
    font-weight: bold;
    -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    padding: 1.8rem 4.5625rem;
    line-height: initial;
    height: 57px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .box-cta-editable-container {
        margin-bottom: 40px;
    }
}

@media (max-width: 450px) {
    .box-cta-editable-container .inner {
        padding: 20px;
    }

    .box-cta-editable-container .button {
        padding: 1.8rem 0.9rem;
    }
}

/*
    About us
 */

.about-page .hero-text {
    color: #009ee2;
    line-height: 1.7;
    font-size: 1.5rem;
}

.brands-page .brand-container {
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 450px) {
    .brands-page .brand-container {
        max-width: 100%;
        flex: 100%;
    }
}

.brands-page .hero-text {
    color: #009ee2;
    font-size: 1.1rem;
    line-height: 1.6;
}

.brands-page .brand-container .brand-text {
    font-style: italic;
}

.brands-page .brand-container .brand-img {
    min-height: 75px;
    max-height: 75px;
}

.brands-page .brand-container img {
    margin: 0 auto;
}

/*
    Tractel Values
 */
.value-container .individual-container {
    margin-bottom: 100px;
}

.value-container .value-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.value-container label {
    font-size: 1.9rem;
}

.value-container p.hero-text {
    color: #009ee2;
    font-size: 1.2rem;
}

.value-container p.content {
    line-height: 2rem;
    font-size: 0.98rem;
}

@media (max-width: 767px) {
    .value-container .individual-container {
        margin-bottom: 40px;
    }

    .value-container .value-text {
        order: 1;
    }

    .value-container .value-image {
        order: 2;
    }
}

/*
    Area Blocks
 */
.content-box a:hover {
    color: #000;
}

.content-box a:after {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" style="fill:rgba(255, 255, 255, 1);" viewBox="0 0 27.1 8.8"><path d="M22.7 0v3.8H0v1.3h22.7v3.8l4.4-4.4L22.7 0z"/></svg>');
    transition: all 0.1s ease-in-out;
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    width: 2.30769em;
    height: 1em;
    margin-right: 1.5em;
    position: relative;
}

/*
    Hero Banner
 */
.banner-video header {
    position: relative;
    height: auto;
}

.banner-video .pimcore_area_herobanner {
    width: 100%;
    height: 100%;
}

.banner-video .video-full,
.banner-video .video-full .pimcore_tag_video,
.banner-video .video-full video {
    height: 100%;
}

.banner-video .nav-container {
    position: absolute;
    width: 100%;
    top: 0;
}

@media (max-width: 767px) {
    .banner-video header {
        padding-top: 5rem;
    }

    .banner-video .video-full {
        display: block;
    }
}

/*
    Product Page
 */
.inline-video:before {
    content: none;
}

.iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
}

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
    padding-top: 75%;
}

/*
    Sort Style
 */
.sort-container {
    color: #009ee2;
    text-align: right;
    white-space: nowrap;
}

.sort-container .sort-by {
    font-weight: bold;
}

.sort-container svg {
    width: 9px;
    height: 10px;
}

.sort-container ul {
    position: absolute;
    right: 0;
    overflow: hidden;
    height: auto;
    z-index: 2;
    background-color: #fff;
    border-bottom: 6px solid #9ace49;
    text-align: right;
    margin-top: 10px;
    min-width: 250px;
    width: 200%;
}

.sort-container ul:hover {
    cursor: pointer;
}

.sort-container ul li span {
    padding-right: 20px;
}

.sort-container .compare-unselect-all {
    display: none !important;
}

@media only screen and (max-width: 991.5px) {
    .sort-container .compare-unselect-all {
        display: inline-block !important;
    }

    .sort-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.sort-by-zindex {
    z-index: 12342344;
}

.sort-by-zindex-zero {
    z-index: 0 !important;
}

/*
    Social Icons
 */
.footer__social svg {
    opacity: 0.3;
}

.footer__social svg:hover {
    opacity: 0.5;
}

/*
    Tractel Main Logo
 */
#tractel_logo .cls-1 {
    opacity: 1;
}

.js_close-quote:hover {
    cursor: pointer;
}

.quote_form_industry_container {
    flex-direction: column;
}

.quote_form_industry_container #quote-industry {
    padding: 1em 0.5em 1em 0.5em;
}

/*
    Login
*/

.login-slider,
.register-slider {
    .login-slider__inner {
        height: 100%;
    }

    .form-container {
        flex: 1;
    }

    .login-form,
    .register-form {
        flex-grow: 1;
        flex-direction: column;
        display: flex;
        position: relative;
    }

    h2 {
        font-weight: bold;
        font-size: 2rem;
    }

    form {
        margin-top: 36px;
    }

    form .field label {
        left: 0;
    }

    .footer {
        padding: 0px;
    }

    .footer .inner {
        padding: 30px 0;
    }

    .footer .reset-password {
        color: #009ee2;
        font-weight: bold;
    }

    .footer .reset-password:hover {
        cursor: pointer;
    }
}

/*
    Form Errors
 */

.error-speech-bubble {
    position: relative;
    background: #fff;
    top: 20px;
    padding: 10px 15px;
    -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    z-index: 100000;
}

#checkout-modal-login-form .error-speech-bubble {
    top: 0px;
}

.error-speech-bubble span {
    color: red;
}

.error-speech-bubble .bold {
    font-weight: bold;
}

.error-speech-bubble:after {
    content: "";
    position: absolute;
    top: 0;
    left: 12%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    margin-left: -8px;
    margin-top: -8px;
}

/*
    News Page Styles
 */
.news-teaser__image {
    max-width: 400px;
}

@media (max-width: 1200px) {
    .news-teaser__image {
        max-width: 325px;
    }
}

/*
    Form Success Styling
 */

.form-success h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #009ee2;
}

.form-success label {
    font-size: 1em;
    display: block;
}

/*
    Generic css
 */

.center {
    text-align: center;
}

.white-text {
    color: #fff !important;
}

.js_download-asset,
.js_download-asset * {
    cursor: pointer;
}

.video-modal .tingle-modal-box {
    width: 80%;
}

.video-modal .tingle-modal-box__content {
    overflow-y: auto;
}

@media (max-width: 575px) {
    .video-modal .tingle-modal-box,
    .video-modal .iframe-container {
        width: 100%;
    }
}

.mt-60 {
    margin-top: 60px;
}

.button:hover {
    background-color: #9ace49;
}

.t_color--white {
    color: #ffffff;
}

/*
    Configure Solution styles
 */

@media (min-width: 1200px) {
    .configSolution-container.big-width .container {
        max-width: 80%;
    }
}

.breadcrumb {
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
}

/**
    Video Carousel
 */
.swiper-container.js_video-carousel {
    overflow: hidden;
}

.swiper-container.js_video-carousel .swiper-wrapper {
    margin-top: 15px;
}

/*
    Customer Portal
 */

.success-text {
    padding-top: 5px;
    margin-left: 30px;
    font-weight: bold;
}

/*
    Reset Password
 */

.reset-password .container {
    height: 100vh;
}

.reset-password .inner {
    text-align: center;
    align-items: center;
    height: 100%;
}

@media (min-height: 625px) {
    .reset-password .inner {
        top: -15%;
        position: relative;
    }
}

.reset-password .inner-box {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 40px 50px 50px 50px;
}

@media (max-width: 992px) {
    .reset-password .inner-box {
        width: 75%;
    }
}

@media (max-width: 640px) {
    .reset-password .inner-box {
        width: 90%;
    }
}

.reset-password .inner-box .form-container {
    width: 100%;
}

.reset-password h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 58px;
}

.reset-password label {
    color: #cecece;
}

.reset-password .js_reset-submit {
    margin: 0 auto;
}

.reset-password .success p {
    padding: 0 60px;
}

@media (max-width: 500px) {
    .reset-password .success h2 {
        font-size: 1.3rem;
    }

    .reset-password .success p {
        padding: 0;
    }
}

@media (max-width: 400px) {
    .reset-password .inner-box {
        padding: 20px;
    }

    .reset-password .success h2 {
        font-size: 1.3rem;
    }
}

.reset-password .success .login-btn {
    margin-top: 40px;
    justify-content: center;
}

.change-password {
    text-align: right;
}

/**
    Product Teasers
 */
.product-teaser__add {
    z-index: 1;
}

.product-teaser__add:hover {
    cursor: pointer;
}

/**
    Footer
 */
.footer {
    padding-bottom: 0px;
}

.footer-base {
    background-color: #ffffff;
    height: 55px;
    padding: 20px 98px;
    width: 100%;
    font-size: 13px;
}

.footer-base .fa-copyright {
    color: #b2b2b2;
}

.footer-base .left {
    float: left;
}

.footer-base .right {
    float: right;
}

.footer-base .padding-right {
    padding-right: 50px;
}

.footer-base .right span a {
    font-weight: normal;
}

@media (max-width: 767px) {
    .footer-base {
        padding: 10px 0 0 0;
        text-align: center;
    }

    .footer-base .left,
    .footer-base .right {
        float: none;
        margin-bottom: 10px;
    }
}

.about-menu-section {
    display: block !important;
    background-color: white;
    z-index: 15;
}

.contact-close-button {
    opacity: 1;
    top: 0;
}

.overflow--hidden {
    overflow: hidden;
}

.contact-slider {
    background-color: white;
    width: 50%;
}

@media (max-width: 576px) {
    .slide-nav__item-inner.form {
        margin: 0 20px;
    }
}

@media (max-width: 992px) {
    .slide-nav__item-inner h2 {
        text-align: center;
        margin-top: 15px;
    }
}

button:focus,
a:focus,
.swiper-button-next:focus,
.swiper-button-prev {
    outline: none;
}

/* Select Your Language */
.sl-language__item {
    margin-bottom: 50px;
}

.home-page-logo {
    max-width: 134px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 577px) {
    .sl-language__item {
        text-align: center;
    }
    .sl-language__map {
        margin: 0 auto;
    }
    .home-page-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (min-width: 767px) {
    .sl-language__item {
        margin-bottom: 100px;
    }
}

.sl-language__main-title {
    margin-bottom: 75px;
}
.sl-region__main-title {
    margin-bottom: 60px;
    font-size: px2rem(30);
}
@media only screen and (min-width: 767px) {
    .sl-language__main-title {
        margin-top: 122px;
    }
    .sl-region__main-title {
        margin-top: 50px;
    }
}
@media only screen and (max-width: 577px) {
    .sl-language__main-title,
    .sl-region__main-title {
        text-align: center;
    }
}

.sl-language__title {
    color: #4e4d5a;
    font-size: 18px;
    font-weight: bold;
    font-family: "Arial", "Helvetica", "sans-serif";
    margin-bottom: 11px;
    margin-top: 20px;
}

.sl-language__list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sl-language__list li a {
    font-size: 16px;
    font-weight: normal;
    color: #009ee2;
    font-family: "Arial", "Helvetica", "sans-serif";
    text-decoration: underline;
    padding: 9px 0;
    display: block;
}

.sl-language__list li a:hover {
    color: #00a5ec;
}

.bold {
    font-weight: bold;
}

.login-slider .reset-password-form .field {
    margin-bottom: 2.875rem;
}

.login-slider .reset-submission-successful p {
    margin-top: 36px;
    margin-bottom: 2.875rem;
}

.font-weight-normal {
    font-weight: normal;
}

.featured-article-teaser__date span {
    color: #ffffff;
    opacity: 0.7;
}

input[disabled="disabled"]:hover,
.button:disabled {
    background-color: #c4c4c4 !important;
    cursor: not-allowed;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-hidden-relative {
    overflow-x: hidden;
    position: relative;
}

.overflow-x-auto-scroll {
    overflow-x: auto;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid #d6d6d6; /* should match background, can't be transparent */
        background-color: #d6d6d6;
    }
}

.overflow-hidden-relative {
    overflow: hidden;
    position: relative;
}

.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

span[fraction] {
    display: none;
}

.show-bottom-arrows {
    #mainHeaderBlock::after {
        content: "";
        background-image: url(/static/bundles/img/svg/scroll-down-white.svg);
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 27px;
        display: block;
        position: absolute;
        bottom: 45px;
    }

    .content-blocks-type-two::after {
        content: "";
        background-image: url(/static/bundles/img/svg/scroll-down-blue.svg);
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 27px;
        display: block;
        position: absolute;
        bottom: 45px;
    }
}
