/*
Block Link.
*/

.block-links {

	@include at(sm) {
		margin-top: px2rem(80);
		margin-left: - $grid-gutter-width / 2;
		margin-right: - $grid-gutter-width / 2;
	}

	span {
		word-break: break-all;
	}
}

.block-link {
	padding: px2rem(30);
	background: $c_white;
}

@include at(md) {

	.block-link {

		.link {
			@include type(sans, seedling);
		}
	}
}