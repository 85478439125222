/*
Icons.
*/

$icons: (
	'search', 'menu','icon-product-grid','icon-product-list', 'login', 'catalogue', 'location', 'calendar', 'arrow-right', 'arrow-right-blue', 'play', 'big-play-btn', 'facebook', 'pinterest', 'twitter', 'linkedin', 'linkedin-blue', 'basket', 'basket-white', 'menu-arrow', 'distributor', 'information', 'articles', 'download', 'download-blue', 'optional-extra', 'video', 'csv', 'pdf', 'plus', 'minus', 'arrow-dropdown', 'arrow-down', 'chevron-down-white', 'chevron-down-blue', 'chevron-right-blue', 'close', 'close-white', 'circle-arrow-up', 'share', 'bin', 'zoom', 'tag', 'preview', 'company-profile_icon', 'products_icon', 'menu-open', 'menu-close', 'arrow-down-blue', 'shopping-cart', 'cart-blue'
);

[class^="i_"],
[class*=" i_"] {
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
}

@each $icon in $icons {
	.i_#{$icon} {
		background-image: url(/static/bundles/img/svg/#{$icon}.svg);
	}
}

.i_arrow-right {
	width: px2rem(27);
}
