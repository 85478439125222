// ==========================================================================
// # Login Slider
// ==========================================================================

$transitionMultiplier: 2;

.login-slider, .register-slider, .right-panel-slider {
    @include transition(transform, $transitionMultiplier);
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 1999999946;
    display: flex;
    transform: translateX(100%);

    &.step-1 {
        transform: translateX(50%);
    }

    &.step-2 {
        transform: translateX(0);

        .login-slider__continue-button {
            opacity: 0;
        }
    }

    &.step-3 {
        transform: translateX(-50%);
    }

    .content-container {
        width: 50%;
        height: 100%;
    }

    &__inner {
        .form-container {
            padding: 0 0 0 7.98389%;
            width: 32.5rem;

            @media (max-width: 1200px) {
                margin-right: 5.2rem;
                width: auto;
            }
        }
    }

    @include at(xs) {
        &__inner {
            padding: px2rem(90) px2rem(20);
        }

        &__back-link {
            margin-left: px2rem(20);
        }
    }

    @include at(sm) {
        width: 400vw;

        &__slide {
            width: 100vw;
        }

        &__double-slide {
            width: 100vw;
            flex-wrap: wrap;

            .login-slider__slide {
                width: 100vw;
                box-shadow: none;
                overflow-y: hidden;
            }

            &.step-1 {
                transform: translateX(0);
            }

            &.step-2 {
                transform: translateX(-25%);
            }

            &.step-3 {
                transform: translateX(-50%);
            }
        }

        &__inner {
            width: 100%
        }
    }

    @include at(md) {
        width: 300vw;

        .content-container {
            width: 100%;
        }

        &__slide {
            width: 100vw;
        }

        &__double-slide {
            width: 100vw;

            .login-slider__slide {
                width: 50%;
            }
        }

        &.step-1 {
            transform: translateX(0);
        }
        &.step-2 {
            transform: translateX(-33.34%);
        }
        &.step-3 {
            transform: translateX(-66.67%);
        }

        &__inner {
            width: 100%
        }

    }

    @include at(lg) {

        &__back-link {
            left: 100vw;
            margin-left: px2rem(50);
            top: px2rem(34);
        }
    }

    &__continue-button {
        @include transition(opacity);
        opacity: 1
    }

    &__slide {
        width: 25%;
        background: $c_white;
        box-shadow: 0px .1875rem 1.25rem rgba(0, 0, 0, 0.05);
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;

        &:nth-child(1) {
            z-index: 4;
        }

        &:nth-child(2) {
            z-index: 3;
        }

        &:nth-child(3) {
            z-index: 2;
        }
    }

    &__double-slide {
        width: 50%;
        max-height: 100vh;
        overflow-y: auto;

        .login-slider__slide {
            width: 50%;
        }

        :nth-child(1) {
            z-index: 2;
        }

        :nth-child(2) {
            z-index: 1;
        }
    }

    &__button {
        justify-content: center;
    }

    &__close {
        @include transition((opacity, transform), $transitionMultiplier);
        float: right;

        .icon {
            width: px2rem(10);
            height: px2rem(10);
        }

        [class*="step-"] ~ &,
        [class^="step-"] ~ & {
            opacity: 1;
            transform: none;
        }
    }

    &__back-link {
        @include transition(opacity, $transitionMultiplier);

        position: absolute;
        top: px2rem(60);
        opacity: 0;

        .step-2 & {
            opacity: 1;
        }
    }

    @include at(md) {
        &__close {
            position: absolute;
            top: 35px;
            right: 50px;
            opacity: 1;
        }
    }
}

.login-slider-item {
    padding: 1.5rem 0;
    border-top: 1px solid $c_border;

    &:last-child {
        border-bottom: 1px solid $c_border;
    }

    @include at(xs) {

        .login-slider-item {
            position: relative;

            &__product-area {
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            &__image {
                width: px2rem(86);
                margin-right: px2rem(10);
            }

            &__title {
                width: auto;
            }

            &__preview-button {
                position: absolute;
                top: 0;
                right: 2.5rem;
            }

            &__remove-button {
                position: absolute;
                top: 0;
                right: 0;
            }

            &__quantity {
                align-self: flex-end;
                width: 100%;
            }
        }
    }

    &__image {
        width: px2rem(70);
        padding: px2rem(10);
    }

    &__title {
        width: 100%;
    }

    &__quantity {
        align-self: flex-start;
    }

    &__quantity-button {
        width: px2rem(15);
        height: px2rem(15);
    }

    &__quantity-inner {
        width: 100%;
    }

    &__quantity-title {
        margin-right: px2rem(35);
    }

    &__quantity-input {
        width: px2rem(60);
        height: px2rem(60);
        margin: 0 px2rem(20);
        text-align: center;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
    }

    &__preview-button,
    &__remove-button {
        .icon {
            width: px2rem(25);
            height: px2rem(15);
            margin-right: 0.5rem;
        }
    }
}

.login-slider-summary {
    border-bottom: 1px solid $c_border;

    @include at(xs) {
        &__item {
            flex-wrap: wrap;
        }

        &__label {
            @include font-size(13);
            width: 100%;
        }

        &__value {
            @include font-size(18);
            width: 100%;
        }
    }

    &__item {
        position: relative;
        display: flex;
    }

    &__label {
        width: px2rem(100);
        display: block;
        flex-grow: 1;
    }

    &__value {
        width: calc(100% - #{px2rem(100)});
    }
}

.login-form, .register-form {
    width: 100%;

    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @include at(md) {
            width: 100%;
        }

        .inner {
            padding: 30px 0;
            transform: translateY(6rem);

            .reset-password {
                color: #009EE2;
                font-weight: bold;
            }
        }
    }
}

.slide-nav__item-inner.form .footer {
    width: 100%;
}

.form-slider {
    width: 100%;

    input[type='submit'] {
        margin-bottom: 40px;
    }
}

@media (max-width: 992px) {
    .login-slider__inner {
        text-align: center;
        width: 100%;
    }

    .login-form, .register-form {
        .footer {
            background: none;
        }
    }

    .form-slider {
        width: 100%;
    }
}

.password-form {
    width: 100%;
}

.close-container {
    width: 30.625rem;
    padding-top: 3.375rem;
    padding-bottom: 5rem;
    margin-left: 7.1875rem;

    @media (max-width: 1200px) {
        margin-left: 1.5rem;
        width: auto;
    }

    @include at(md) {
        padding: 27px 30px;
    }

    .login-slider__close, .add-to-quote-slider__close {
        width: 5rem;
        margin-right: 3.2rem;
        float: right;
        cursor: pointer;

        @include at(md) {
            margin-right: 0px;
        }

        .icon {
            margin: 0 auto;
            align-self: center;

            @include at(md) {
                width: 1.125rem;
                height: 1.125rem;
            }
        }
    }
}

.account-form .login-slider__button input {
    margin-bottom: 20px;
}

.mobile-login {
    @include at(sm) {
        .form-container {
            padding: 0px;
            margin: 0px;
        }
    }

    @media (max-width: 400px) {
        .reset-password-pre-submission .js_form-submit {
            padding: 1.875rem 1.5625rem;
        }
    }

    @media (max-height: 780px) {
        .footer {
            position: relative;
            background-color: white;
        }
    }
}

@media (max-width: 500px) {
    .login-slider__slide .password-form .login-slider__button input {
        padding: 1.875rem 1.5625rem;
    }
}

.activate-account-message {
    padding: 0px 80px;
}