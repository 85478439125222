.product-card {
  background-color: $c_white;
  box-shadow: 0px 0.1875rem 1.25rem rgba(0,0,0,0.09) !important;

  &__price {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;

    small {
      display: block;
      font-weight: 300;
      margin-top: 5px;
    }
  }

  &__title {
    font-size: 1.2rem;

    &-small {
      font-size: 1rem !important;
    }
  }

  &__subtitle {
    font-weight: lighter;
    color: $c_gray;

    &-small {
      font-size: 0.9rem !important;
    }
  }

  &__no-shadow {
    box-shadow: none !important;
  }

  &--long {
    padding: 0 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    border: solid 2px transparent;
    transition: all 0.3s;
    height: 100px;

    &:hover {
      transform: scale(1.01);
      border: solid 2px $c_cerulean;
    }

    .product-card__thumbnail {
      width: 60px;
      padding: 3px;
    }

    .product-card__title-container {
      flex-grow: 1;
      margin-left: 1rem;
    }

    .product-card__title {
      margin: 0;
      margin-bottom: 0.4rem;
    }

    .product-card__subtitle {
      font-size: 1rem;
    }
  }

  &--slide {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0.5rem 1rem 2rem 1rem;
    width: 25%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-right: 1.5rem;
    border: solid 2px transparent;
    transition: all 0.3s;
    height: auto !important;

    &:hover {
      transform: scale(1.02);
      border: solid 2px $c_cerulean;
    }

    .product-card__thumbnail {
      margin-bottom: 5px;
      width: 100%;
      height: 250px;

      picture {
        display: block;
        height: 250px;
        width: 100%;
        position: relative;
      }
      
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 220px;
        padding: 5px;
      }
    }

    .product-card__thumbnail-container {
      width: 100%;
      height: 250px;
    }

    .product-card__title-container {
      margin-bottom: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .product-card__title {
      margin: 0;
      margin-bottom: 0.5rem;
    }

    .product-card__subtitle {
      font-size: 1rem;
    }

    .product-card__price {
      margin-top: 1.5rem;
      margin-bottom: 0;

      small {
        font-size: 64%;
      }
    }
  }
}

@include below-ipad-breakpoint {
  .product-card {
    padding: 1rem;
    position: relative;
    margin-bottom: 1rem;
    display: block;
    min-height: 100px;
    height: auto;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
  }

  .product-card--slide {
    max-width: 100% !important;
    width: 100% !important;
  }

  .product-card__price {
    font-size: 1rem;
    margin-bottom: 0 !important;
    margin-left: 70px;
    text-align: right;

    small {
      font-size: 80% !important;
    }
  }

  .view-product {
    font-weight: 300;
  }

  .product-card--long {
    &:hover {
      transform: scale(1) !important;
      border: solid 2px $c_cerulean;
    }
  }

  .product-card__subtitle {
    display: none;
  }

  .product-card__title-container {
    margin: 0 !important;
    flex-grow: 1 !important;
    margin-left: 67px !important;
  }

  .product-card__title {
    margin-bottom: 0 !important;
    margin-left: 70px;
    text-align: left;
  }

  .product-card__thumbnail-container {
    position: absolute;
    left: 8px;
    margin: 0;
    top: 50%;
    transform: translate(0%, -50%);
    height: 100% !important;
    width: 80px !important;
  }

  .product-card__thumbnail {
    width: 50px !important;
    margin-bottom: 0 !important;
    margin-right: 1rem;
    height: 100% !important;
    width: auto !important;

    picture {
      width: auto !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;
    }

    img {
      position: relative !important;
      top: 0% !important;
      left: 0% !important;
      transform: none !important;
      max-height: 70px !important;
      padding: 0px !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .product-card--slide {
    box-shadow: 1px 8px 62px -38px rgba(0,0,0,0.75);
    flex-direction: row !important;
    align-items: center;
    width: 100% !important;
    text-align: left;
    margin-right: 0;

    &:hover {
      transform: scale(1) !important;
      border: solid 2px $c_cerulean;
    }
  }
}

.spare-parts-container {

  .product-card {
    margin-left: 15px;
    margin-right: 15px;
  }
  
  .product-card__cta-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
    text-align: right;
    font-size: 0.9rem;

    .add-quote {
      position: relative;
      padding-left: px2rem(22);
    }

    .add-quote::before {
      content: " ";
      background-image: url(/static/bundles/img/svg/enquiry-blue.svg);
      background-repeat: no-repeat;
      display: inline-block;
      width: 17px;
      height: 18px;
      position: absolute;
      left: -3px;
      top: 0px;
    }
  }

  .product-card__stockdivider::after {
    content: "|";
    display: inline-block;
    padding: 0px 15px 0px 12px;
  }

  @media (max-width: 767px) {
    .product-card__title-container {
      margin-left: 0px !important;
    }

    .product-card__title {
      margin-bottom: 0.3rem !important;
    }
    .product-card__subtitle {
      display: block;
      text-align: left;
      margin-bottom: 0.6rem;
    }

    .product-card__cta-container {
      margin-right: 0rem;
    }
  }

  @media (max-width: 575px) {
    .product-card__title-container {
      display: flex;
      flex-direction: column;
    }
    .product-card__cta-container {
      order: 1;
      margin-right: -13px;
    }
  }
}

.accessories__container .product-card__thumbnail img {
  max-height: 70px !important;
  padding: 0px !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}