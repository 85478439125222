.order-details {
  &__summary {
    margin-bottom: 4rem;
    
    &-text--left, &-text--right {
      line-height: 0;
    }

    &-text--right {
      @include phone {
        transform: translateY(-0.4rem);
      }
    }
  }

  &__top-duplicate-btn {
    @include below-ipad-breakpoint {
      display: none;
    }
  }

  &__summary-middle-content {
    margin: 1rem 0;
  }

  &__text-content {
    margin-top: 2rem;
  }

  &__summary-text {
    line-height: 1;
    margin: 0;
    @include below-ipad-breakpoint {
      line-height: 1.5;
      margin: 0;
    }
  }

  &__btn-container {
    margin-top: 2rem;
  }

  &__order-items {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $c_border-light;
    padding-bottom: 1rem;
    padding-top: 1rem;
    @include below-ipad-breakpoint {
      position: relative;
      display: block;
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
  }

  &__summary-lead {
    width: 80%;
    line-height: 1.5;

    @include below-ipad-breakpoint {
      width: 100%;
    }
  }

  &__thumbnail-container {
    display: flex;
  }

  &__thumbail-title {
    line-height: 0.5;
    margin-left: 1rem;
    @include below-ipad-breakpoint {
      margin-left: 0;
      line-height: 1;
    }
    @include smallPhone {
      font-size: 0.8rem;
    }
  }

  &__product-code {
    font-size: 0.8rem;

    @include smallPhone {
      font-size: 0.65rem;
    }
  }

  &__thumbail {
    width: 60px;
    border: solid 1px $c_mercury;

    @include below-ipad-breakpoint {
      position: absolute;
      right: 0;
      width: 90px;
      margin-top: -0.5rem;
    }

    @include smallPhone {
      width: 80px;
    }
  }

  &__thumbail-title, &__price, &__qty, &__total {
    margin-top: 2rem;

    @include below-ipad-breakpoint {
      margin-top: -0.4rem;
    }
  }

  &__price, &__qty, &__total {
    margin-top: 2rem;

    @include smallPhone {
      font-size: 0.8rem;
    }
  }

  &__qty {
    @include below-ipad-breakpoint {
      margin: 0;
      transform: translateY(-0.5rem)
    }

    @include smallPhone {
      margin: 0;
      transform: translateY(-0.8rem)
    }
  }

  &__total {
    @include below-ipad-breakpoint {
      margin: 0;
      transform: translateY(-0.8rem)
    }
    @include smallPhone {
      margin: 0;
      transform: translateY(-1.4rem)
    }
  }

  &__thumbnail-title-link {
    color: $c_pop;
    font-weight: lighter;
    text-decoration: underline;
    transition: all 0.2s;

    &:hover, &:active {
      color: $c_pop-light;
    }
  }

  &__price, &__qty, &__total {
    @include below-ipad-breakpoint {
      line-height: 0;
      margin-bottom: 0;
      margin-top: 2rem;
    }
  }

  &__delivery {
    margin-top: 2rem;
    line-height: 0;
  }

  &__delivery-left--has-margin-bottom {
    margin-bottom: 7rem;

    @include below-ipad-breakpoint {
      margin-bottom: 0;
    }
  }

  &__delivery-details {
    margin-bottom: 2rem;
    line-height: 0.4;
  }

  &__delivery-text {
    @include below-ipad-breakpoint {
      margin: 0;
      line-height: 1.5;
    }
  }

  &__delivery-block {
    @include below-ipad-breakpoint {
      margin-bottom: 1rem;
    }
  }

  &__payment-details {
    display: flex;
  }

  &__payment-logo {
    width: 90px;
    margin-top: 1rem;
  }

  &__payment-text {
    margin-top: 3rem;
    margin-left: 3rem;
  }

  &__order-total {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    &__right {
      text-align: right;
    }

    &__text--has-top-margin {
      margin-top: 3rem;
    }

    &__text--is-bigger {
      font-size: 1.3rem;
      margin-top: 2.7rem;
    }
  }

  &__pdf-link {
    padding: 1rem 0;

    a {
      text-decoration: none;
      display: flex;
    }

    .icon {
      display: inline-block;
      width: 17px;
      height: 17px;
      margin-left: 10px;
    }
  }
}

.help-section {
  margin-top: 2rem;

  &__content {
    margin-top: 2.5rem;
  }

  &__text--top {
    margin-bottom: 1rem;
    width: 80%;
    line-height: 1.7;
  }
}