/*
Article
*/

.in-edit-mode {
    .article {
        top: 0 !important;
        margin-top: 0 !important;
    }
}

.article {
    display: inline-block;
    top: px2rem(-60); // island padding.
    position: relative;
    padding-bottom: 0;
    margin-top: -55%;

    header {

        h1 {
            line-height: 1.25;
        }

        p {
            line-height: 1.5;
        }
    }

    .video-teaser {
        margin: px2rem(50) 0;

        &:before {
            content: none;
        }

        @include at(sm) {
            margin-left: - $grid-gutter-width / 2;
            margin-right: - $grid-gutter-width / 2;
        }
    }

    &--flexible-top-zero {
        top: 0px;
        margin-top: 0%;
    }

    &--flexible-top-25 {
        top: 0px;
        margin-top: -25%;
    }

    &--flexible-top-50 {
        top: 0px;
        margin-top: -50%;
    }

    &--small {
        > p {
            font-size: 14px;
        }
    }

    &--info {

        > div:first-of-type {
            padding-bottom: 18px;
            margin-bottom: 18px;
            border-bottom: solid 1px $c_gallery;
        }

        .icon {
            width: 15px;
            height: 15px;

            &.i_share {
                margin-right: 5px;
            }

            &.i_calendar {
                width: px2rem(20);
                height: px2rem(22);
                margin-right: px2rem(10);
                margin-top: px2rem(-2);
            }
        }
    }

    &__split-header {
     /*   position: absolute;
        bottom: 100%;
        left: 0;*/
        .article__inner {
            padding: 0;
        }
    }

    &__inner {
        padding: px2rem(50);
        background-color: $c_white;
    }


}

@include at(md) {
    .article {
        box-shadow: none;
    }
}

@include at(sm) {
    .article {
        top: auto;
        margin-bottom: px2rem(60);
        margin-top: 0;

        &__split-header {
            position: static;
        }

        &__inner {
            padding: 0;
        }

        &--info {
            margin-top: 3rem;
        }
    }
}

.article-feed-left-panel {
    z-index: 1;
}

.resource-page {
    background-color: #F4F4F4;
    z-index: 2;
    position: relative;

    > .container {
        padding-bottom: 7rem;
    }

    .resource-page-content-header {
        display: flex;
        justify-content: space-between;

        .share-link {
            font-weight: normal;
            color: #8E8E8E;
        }

        .content-author {
            color: #009EE2;
            font-weight: 600;
        }
    }

    .resource-page-content {
        background-color: #ffffff;
        padding: 3rem;

        hr {
            border: none;
            border-bottom: 1px solid #cccccc;
            margin: 1rem 0 3rem 0;
        }
    }

    .resource-page-content,
    .newsletter {
        margin-top: -5rem;
        margin-bottom: 5rem;

        input[disabled="disabled"]:hover, .button:disabled {
            background-color: #0078ab !important;
        }
    }

    @media only screen and (max-width: 991.5px) {
        .newsletter {
            margin-top: 0;
        }
    }

    @include at(sm) {
        .newsletter {
            margin-left: 0;
            margin-right: 0;
        }
    }

}
@media only screen and (max-width : 767.5px) {
    .resource-page {
        .resource-page-content,
        .newsletter {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
    }
}

body.resource-page {
    .header--slim .header-info {
        padding-top: 3rem;
        padding-bottom: 7rem;
    }
}

.similar-resources {
    &-blocks a {
        display: block;
        max-width: 350px;
        height: 200px;
        margin-bottom: 1rem;
        color: #ffffff;
        padding: 2rem;
    }

    &-blocks .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    &-blocks h3 {
        font-size: px2rem(18);
        font-weight: bold;
    }

    &-blocks .link {
        font-size: px2rem(14);
        color: #ffffff;
    }

    &-blocks .link--pop:after {
        filter: brightness(0) invert(1);
    }

    &-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        gap: 5px;

        * {
            font-size: px2rem(13);
            font-weight: bold;
        }
    }
}


.product-resources.article-feed {
    .swiper-slide {
        width: 345px !important;
        height: 334px !important;
    }

    .content {
        padding: 10rem 2rem 0 2rem;
    }

    .extract {
        height: 98px;
    }

    .link--pop {
        font-size: px2rem(14);
    }

    .link--pop:after {
        filter: brightness(0) invert(1);
    }

    .swiper-slide:hover .link {
        display: none;
    }
}

.resource-page-category {
    &-block {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .link {
            font-size: px2rem(14);
        }
    }

    &.spread-blocks {
        @media only screen and (max-width: 991.5px) {
            .container {
                max-width: initial !important;
            }
        }



        @media (min-width: 612px) {
            .resource-page-category-block {
                display: grid !important;
                grid-template-columns: 1fr 1fr;
            }
        }

        @media (min-width: 922px) {
            .resource-page-category-block {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        @media (min-width: 1200.5px) {
            .resource-page-category-block {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        a.swiper-slide {
            width: 100% !important;
        }
    }
}



.resource-page-pagination {
    display: flex;
    justify-content: space-between;
    margin: 4rem 0;
    font-size: px2rem(14);
    gap: 1rem;
    flex-wrap: wrap;

    a {
        color: #8E8E8E;
        font-weight: normal;
    }

    > a.next::after,
    > a.previous::before {
        @include arrow(#009EE2);
        content: "";
        display: inline-block;
        width: 27px;
        height: 9px;
        background-repeat: no-repeat;
    }

    > a.previous::before {
        transform: scaleX(-1);
        margin-right: 1rem;
    }

    > a.next::after {
        margin-left: 1rem;
    }
}

@include at(md) {
    .resource-page-pagination {
        justify-content: center;
    }
}

.resource-page-main {
    h2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
            font-size: px2rem(14);
            display: inline-block;
            margin-left: 5rem;
            position: relative;
        }

        span::before {
            content: "";
            background-image: url(/static/bundles/img/svg/menu-right-blue.svg);
            background-repeat: no-repeat;
            background-position: center;
            display: block;
            width: 5px;
            height: 8px;
            position: absolute;
            left: -10px;
            top: calc(50% - 4px);
        }
    }

    > section:nth-child(even) {
        background-color: #fff !important;
    }
}

body.news-grid {
    .article-feed {
        background-color: #F3F3F3;
    }

    .header {
        padding-top: 3rem;
    }
}

@include at(md) {
	body.news-grid {
        .header {
            padding-top: 3rem;
        }
    }

    .resource-page-category-block {
        justify-content: center;
    }
}

.pimcore_area_website-article-feed {
    .article-nav {
        p {
            padding-right: 7rem;
            display: block;
        }
        @media only screen and (max-width : 767.5px) {
            p {
                padding-right: 0;
            }
        }
    }
}