/*
Cta.
*/

.cta {
	padding: $grid-gutter-width;

	h3 {
		line-height: px2rem(36);
	}

	&__contact {
		border: solid 1px $c_cerulean;
	}

	&__search {
		padding-top: $grid-gutter-width * 2;
		padding-bottom: $grid-gutter-width * 2;
		background-color: $c_cerulean;

		input {
			color: $c_white;
			border-radius: 100px;
			border: none;
			padding: px2rem(12);
			background-color: rgba(255, 255, 255, 0.20);
			display: block;
			width: 100%;
			background-image: url(/static/bundles/img/svg/search-white.svg);
			background-repeat: no-repeat;
			background-size: 17px;
			background-position: center right 15px;

			&::placeholder {
				color: $c_white;
			}
		}
	}
}


@include at(sm) {

	.cta {

		&__search {
			margin-bottom: px2rem(60);
		}
	}
}