/*
    New eCommerce Filters
*/

.filters {

    ul {
        padding-left: 0px;
        margin-top: 0px;
        list-style: none;
        overflow: hidden;
    }

    .heading {
        margin: 0px 0px 9px 0px;
        font-size: px2rem(14);
        color: #000000;
    }

    .counter {
        padding: 0px 2px;
        border-radius: 2px;
        color: $c_silver_chalice;
        align-self: flex-start;

        &::before {
            content: "(";
        }

        &::after {
            content: ")";
        }
    }

    .category-link,
    .field-link {
        display: flex;
        justify-content: space-between;
        font-weight: 100;
        margin: 0.8rem 0;

        .dash {
            margin-right: 5px;
        }

        label, a, div {
            flex-grow: 1;
            margin-right: 0.3rem;
            transform: translateX(0.3rem) translateY(-0.1rem);
        }

        label {
            margin-left: 5px;
            cursor: pointer;
        }

        a {
            font-weight: 400 !important;
        }
    }

    .category-current,
    .category-siblings {
        color: #000000;

        .counter {
            color: $c_silver_chalice;
        }
    }

    .filter-container {
        padding: 0px 2px 0px 7px;
        margin-top: -5px;
    }
}

@media (max-width: 768px) {
    .product-filter-container {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .left-filter-box-hide {
        display: none;
    }
}

@media only screen and (max-width : 991.5px) {
    .left-filter-box-hide {
        display: none;
    }
}

@media (max-width: 990px) {
    .left-filter-box-hide {
        display: block;
    }
    .left-filter-box-hide {
        .product-filter__trigger.js_product-filter-trigger-btn {
            display: none;
        }
    }
}