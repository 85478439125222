/*
Section Title.
*/

.section-title {
	padding-top: px2rem(10);
	padding-bottom: px2rem(20);
	margin-bottom: px2rem(25);
	border-bottom: solid 1px $c_border;

	&--dark {
		border-bottom-color: $c_gallery;		
	}

	&--accessories {
		margin-bottom: 3rem;
	}

	&--related {
		margin-bottom: 2.5rem;
		padding-bottom: 1rem;
	}
}