.is-bold {
  font-weight: 600 !important;
}

.is-thin {
  font-weight: lighter;
}

.is-light {
  color: $c_text-light;
}

.is-blue {
  color: $c_pop;
}

.is-underlined {
  text-decoration: underline;
}

.is-borderless {
  border: 0;
}

.is-transparent {
  background-color: transparent;
}

.is-narrow {
  line-height: 0.7;
}

.standard-line-height-on-mobile {
  @include below-ipad-breakpoint {
    line-height: 1;
  }
}

.is-narrow-on-mobile {
  @include below-ipad-breakpoint {
    line-height: 0;
  }
}

.is-a-link {
  color: $c_pop !important;
  font-weight: lighter;
  text-decoration: underline;
  transition: all 0.2s;
  cursor: pointer;

  &:hover, &:active {
    color: $c_pop_light !important;
  }

  &--not-underlined {
    text-decoration: none;
  }
}

.has-light-bg {
  border: 0;
  border-top: solid 1px $c_mercury;
}

.align-center {
  text-align: center;
}

.center-horizontal {
  margin-left: auto;
  margin-right: auto;
}