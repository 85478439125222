/*
Pagination.
*/

.pagination {
	margin: 40px 0;

	&--has-padding {
		padding: 40px 0;
		margin: 0;
	}

	&__number {
		@include transition((background-color, color));

		width: px2rem(30);
		height: px2rem(30);
		border-radius: 50%;

		&--active,
		&:hover {
			background-color: $c_pop;
			color: $c_white;
		}
	}

	&__number-break {
		align-self: center;
	}

	&__main {

		.before {
			display: inline-block;
			background-repeat: no-repeat;
			background-position: center center;
			width: px2em(30, 13);
			height: 1em;
			margin-right: 3em;
			margin-top: .5em;
			transform: scaleX(-1);
		}

		.after {
			display: inline-block;
			background-repeat: no-repeat;
			background-position: center center;
			width: px2em(30, 13);
			height: 1em;
			margin-top: .5em;
			margin-left: 3em;
		}
	}
}
