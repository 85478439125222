/*
Features
*/


.features {


}

.feature {
	flex-direction: row;
	margin-bottom: px2rem(80);

	&__body {
		@include make-col(4);
		order: 1;
	}

	&__image {
		@include make-col-offset(1);
		@include make-col(7);
		order: 2;

	}

	&:nth-child(2n+2) {
		.feature__body {
			order: 2;
			@include make-col-offset(1);
			@include make-col(4);
		}

		.feature__image {
			order: 1;
			@include make-col-offset(0);
			@include make-col(7);
		}
	}
}









@include at(sm) {
	.feature {
		&:nth-child(n) {
			flex-wrap: wrap;

			.feature {
				&__body {
					flex-basis: 100%;
					max-width: 100%;
					order: 2;
					margin-left: 0;
				}

				&__image {
					flex-basis: 100%;
					max-width: 100%;
					order: 1;
					margin-left: 0;
					margin-bottom: 2rem;
				}
			}

		}
	}
}









@include at(xs) {
	.feature {
		&:nth-child(n) {
			.feature {
				&__image {
					flex-basis: 100vw;
					max-width: 100vw;
					margin-left: -15px;
				}
			}
		}
	}
}
