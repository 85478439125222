/*
Page Teasers.
*/

.page-teasers {
	padding: px2rem(70) 0;

	&__page-teaser {
		padding: px2rem(30);
		background-size: cover;
		min-height:  px2rem(295);

		&:before {
			// @include transition(all);
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(rgba(22,22,54,.5),rgba(22,22,54,.5));
		}

		.link {
			// @include transition(all);
			position: absolute;
			bottom: px2rem(30);
		}

		&:hover {
			
			.link {
				color: $c_pop;
			}

			&:before {
				background: linear-gradient(rgba(22,22,54,.65),rgba(22,22,54,.75));
			}
		}
	}
}