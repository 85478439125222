.customer-portal-base {
  margin-top: 1rem;

  .error-speech-bubble {
      top: -1rem;
  }
  
  .radio-button {
      + .error-speech-bubble {
          top: 0rem;
      }
  }

  .form-check-label {
    + .error-speech-bubble {
        top: 1rem;
    }
  }
}