/*
Category Teaser.
*/

.category-teaser {
	position: relative;
	min-height: px2rem(250);
	margin-bottom: px2rem(80);

	&__image {
		position: absolute;
		top: 0;
		right: 0;
	}

	&__body {
		position: absolute;
		bottom: px2rem(30);
		flex-direction: column;
		padding: px2rem(30);
		background-color: $c_white;
		min-height: px2rem(130);
		width: px2rem(470);

		a {
			margin-top: 10px;
		}
	}
}

@media only screen and (max-width : 991.5px) {

	.category-teaser {
		min-height: px2rem(286);
		margin-bottom: px2rem(40);

		&__image {
			width: 50%;
			margin-right: - $grid-gutter-width / 2;
		}

		&__body {
			top: 70px;
			min-height: fit-content;
			width: 90%;

			h2 {
				margin-bottom: px2rem(10);
			}

			.link {
				@include type(sans, seedling);
			}
		}
	}
}

@include at(sm) {

	.category-teaser {
		min-height: px2rem(230);


		&__body {
			bottom: auto;
			padding: px2rem(20);
		}
	}
}

@include at(xs) {

	.category-teaser {
		min-height: px2rem(260);
	}
}
