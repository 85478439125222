/*
Contact Module.
*/

.contact-module {
	padding: px2rem(44) px2rem(38);
	background-color: $c_pop;
}

@include at(sm) {
	.contact-module {
	display: none;
		flex-wrap: wrap;

		&__body {
			margin-bottom: 1rem;
		}
	}
}

.js_contact-submit,
.quote-bottom-buttons,
.js_tracked-resources-submit {
	margin-top: 3rem;
}