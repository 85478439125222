.img-thumbnail {
  border: 1px solid transparent;
  cursor: pointer;

  &__padding-thin {
    padding: 10px;
    margin-right: 15px;
  }

  &:hover {
    border-color: #009EE2;
  }

  &__active {
    border-color: #009EE2;
  }
}

.img-center {
  &img {
    margin-left: auto;
    margin-right: auto;
  }
  picture img {
    margin-left: auto;
    margin-right: auto;
  }
}

.img-max-height-100px {
  img {
    max-height: 100px;
  }
}

.img-max-height-200px {
  img {
    max-height: 200px;
  }
}

picture.image-contain {
  margin: 0;
  position: relative;
}

picture.image-contain img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}