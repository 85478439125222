/*
Disributors.
*/

.disributors {

	&__footer {

		.button {
			margin-right: px2rem(80);
		}
	}
}