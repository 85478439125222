.product-filter {
  background-color: $c_white;
  padding: 0rem 1rem 1rem 1rem;
  

  @include lg-desktop-breakpoint {
    margin-bottom: 2rem;
  }

  @media only screen and (max-width : 991.5px) {
    position: fixed;
    top: 0;
    left: -80rem;
    transition: all 0.5s;
    transition-delay: 0.2s;
    min-width: 104%;
    z-index: 20;
    height: 100%;
    overflow-y:scroll !important;
    overflow-x:hidden;
  }

  &--is-visible {
    @include lg-desktop-breakpoint {
      left: 50%;
      top: 0 !important;
      transform: translateX(-50%);
      z-index: 900;
      min-width: 102%;
      -webkit-box-shadow: 0px 10px 19px -13px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 10px 19px -13px rgba(0,0,0,0.75);
      box-shadow: 0px 10px 19px -13px rgba(0,0,0,0.75);
    }
    @media only screen and (max-width : 991.5px) {
      left: 50%;
      top: 0 !important;
      transform: translateX(-50%);
      z-index: 900;
      min-width: 100%;
    }
  }

  &--shadows {
    
    @media only screen and (max-width : 991.5px) {
      border-top: solid 1px $c_mercury;
    }
  }
  

  // ---- Section header and general styles.

  &__section {
    border-bottom: solid 1px $c_mercury;
    font-size: 0.875rem;
    padding-bottom: 1rem;
    max-height: 4rem;
    overflow: hidden;
    transition: max-height 0.3s;

    &:last-child {
      border-bottom: 0;
    }

    &--is-expanded {
      max-height: initial;
    }

    &-divider {
      width: calc(100% + 32px);
      height: 23px;
      background-color: #f4f4f4;
      margin-left: -16px;
    }
    
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__dropdown-icon {
    width: 11px;

    &--is-toggled {
      transform: rotate(180deg);
    }
  }

  &__section-title {
    margin: 0;
    text-transform: uppercase;
    font-size: 0.875rem;
    padding: 1.5rem 0;
  }

  &__category-dropdown {
    cursor: pointer;
  }

  // ---- product filter list styles

  &__prodcut-quantity {
    color: $c_silver-chalice;
    font-size: 0.7rem;
    margin-left: 0.5rem;
  }

  &__unselect {
    margin-left: 23px;
    color: $c_cerulean;
    cursor: pointer;

    &:hover {
      color: $c_dodger-blue;
    }
  }

  // top mobile menu trigger

  &__mobile-menu-trigger {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    margin-top: 1rem;
    display: none;
    @media only screen and (max-width : 991.5px) {
      display: flex;
    }
  }

  &__trigger {
    color: $c_pop;
    border: solid 2px $c_pop;
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }

  &__mobile-pagination {
    display: flex;
    justify-content: space-between;
    background-color: $c_white;
    margin-bottom: 1.5rem;
    align-items: center;
    display: none;

    @media only screen and (max-width : 991.5px) {
      display: flex;
    }

    &--left {
      padding: 1.3rem 1.5rem;
      border-right: solid 1px $c_mercury;
      cursor: pointer;
    }

    &--right {
      padding: 1.3rem 1.5rem;
      border-left: solid 1px $c_mercury;
      cursor: pointer;
    }

    &--bottom {
      margin-bottom: 0;
    }
  }

  &__mobile-pagination-icon {
    width:10px;

    &--left {
      transform: rotate(90deg);
    }
    &--right {
      transform: rotate(-90deg);
    }
  }

  &__mobile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0; 
    display: none;

    @media only screen and (max-width : 991.5px) {
      display: flex;
    }
  }
}

.mobile-filter-pagination-container {
  display: none;
  @include lg-desktop-breakpoint {
    display: block;
    padding-bottom: 2rem;
  }
}