/*
Nav.
*/

.nav {
	position: relative !important;
	background-color: $c_white;
	height: 3rem;
	z-index: 50;
    padding-top: 4px;

    &__main {
        height: 3rem;

        ul.nav__list {
            height: 3rem;
			align-items: center;

            li.nav__item > a {
				display: inline-flex;
				align-items: center;
				height: 100%;
				text-align: center;
				font-size: px2rem(12);
				color: #8E8E8D !important;
            }

			li.nav__item > a::after {
				content: "";
				background-image: url(/static/bundles/img/svg/chevron-down-nav.svg);
				display: inline-block;
				width: 8px;
				height: 8px;
				background-repeat: no-repeat;
				margin-bottom: 5px;
				margin-left: 4px;
            }

            li.nav__item--active > a::before {
				content: "";
                width: 100%;
				display: block;
				height: 6px;
				background-color: #009EE2;
				position: absolute;
				bottom: 1px;
            }

        }

    }

	&__link {
		display: inline-flex;
		align-items: center;
		height: 100%;
		text-align: center;
		font-size: px2rem(12);
		color: #8E8E8D !important;
		padding-right: 0 !important;
    	padding-left: 2rem;
	}

	&__icon::before {
		content: "";
		display: inline-block;
		background-repeat: no-repeat;
		transform: translateY(2px);
		margin-top: -5px;
		margin-right: 5px;
	}

	&__icon-phone::before {
		background-image: url(/static/bundles/img/svg/phone-nav.svg);
		width: 16px;
		height: 15px;
	}

	&__icon-contact::before {
		background-image: url(/static/bundles/img/svg/contact-nav.svg);
		width: 14px;
		height: 13px;
	}

	&__inner {
		width: 100%;
		height: 100%;
	}

	&__logo {
		margin-left: px2rem(30);
		width: px2rem(127);

		a object {
			pointer-events: none;
		}

		object {
			max-width: 127px;
			max-height: 27px;
		}

		svg {
			@include transition(all);

			max-width: 100%;
			max-height: 100%;
		}

		&:hover {
			svg {
				filter: brightness(.9);
			}
		}
	}

	&__item {
		position: relative;
		white-space: nowrap;
		height: 100%;
	}

	&__buttons {
		height: 100%;
		align-items: center;
	}

	&__button {
		width: 3rem;
		height: 100%;
		cursor: pointer;

		.icon {
			@include transition(all);

			width: px2rem(18);
			height: px2rem(18);
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {

				.icon {
					filter: brightness(.75);
				}
			}
		}

	}

	&__search {
		width: 100%;
		background-color: $c_white;
		position: relative;

		&__dropdown-row {
			border-bottom: solid 1px black !important;
		}

		&__dropdown {
			width: 100%;
			box-shadow: 0px 9px 29px -14px rgba(0,0,0,0.75);
		}

		&__dropdown-thumbnail {
			max-width: 45px;
			max-height: 45px;
			border: solid 1px $c_gallery;
		}

		&__table {
			width: 100%;
			border-collapse: collapse;
		}

		&__table-cell {
			position: relative;
			padding-top: 0.3rem;
			padding-bottom: 0.3rem;
			padding-left: 1.6rem;
			&:last-child {
				padding-right: 1rem;
			}

			.arrow-right {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 2rem;
				opacity: 0;
				visibility: hidden;
				transition: all 0.4s;

				&:hover {
					opacity: 0.5 !important;
					transform: translateY(-50%) translateX(0.2rem);
				}

				&::before {
					content: url(/static/bundles/img/svg/menu-arrow.svg);
					height: 10px;
					width: 10px;
					display: inline-block;
				}
			}

			.is-a-link {
				display: inline-flex;
				align-items: center;
				min-height: 43px;
			}

			b {
				color: #00618b;
			}
		}

		&__dropdown-row {
			border-bottom: solid 1px $c_gallery !important;
		}

		.search-is-open & {
			transform: none;
			opacity: 1;
			display: block;
		}
	}

	&-search {
		&__input {
			flex-grow: 1;
			padding: 1.5rem;
			border: none;
		}
	}
}

.search-is-open {
	.search-button {
		background-image: url(/static/bundles/img/svg/close.svg);
	}
}

.mobile-nav-open-trigger {
	display: none;
}

@include at(md) {
	.nav {
		&__main {
			display: none;
		}

		&__search {
			z-index: 1;
		}
	}

	.mobile-nav-open-trigger {
		display: flex;
	}

	.nav__main ul.nav__list li.nav__item--active>a::before {
		display: none;
	}
}

.mobile-nav-shopping-cart {
	display: none;
}

@include below-ipad-breakpoint {
	.nav__search__table-cell {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		.autocomplete-code {
			display: none;
		}
	}

}

@media (max-width: 768px) {
	.mobile-nav-shopping-cart {
		display: flex;
	}
}

@media (max-width: 475px) {
	.nav__button {
		width: 2.5rem;
		border-left-width: 0px;
	}
	.mobile-nav-open-trigger {
		width: 3rem;
		margin-right: 10px;
	}
	#gather-search-button {
		width: auto;
	}
}

.nav__search__dropdown-row:hover {
	background-color: #f9f9f9;
	cursor: pointer;
}

.nav__search__dropdown-row:hover > .nav__search__table-cell > .arrow-right {
	opacity: 1;
	visibility: visible;
}

#gather-search-form {
	border: 1px solid #E8E7E7;
	height: 38px;
	border-radius: 38px;
	padding: 0 0.5rem 0 1rem;
	max-width: 558px;
	margin-left: 1rem;
    margin-right: 1rem;
}

#gather-search-button {
	width: 2rem;
	border: none !important;
}

.nav-search__input {
	outline: none !important;
	border: none !important;
	padding: 0;
	margin: 0;
	height: auto;
	width: 100%;
	font-size: px2rem(13);

	&::-webkit-input-placeholder {
		color: #A4A4A3;
		font-size: px2rem(13);
	}
	
	&:-ms-input-placeholder {
		color: #A4A4A3;
		font-size: px2rem(13);
	}
	
	&::placeholder {
		color: #A4A4A3;
		font-size: px2rem(13);
	}
}

#search-autocomplete {
	position: absolute;
    z-index: 1;
    background-color: #ffffff;
    max-width: 558px;
}

.nav-container {
	background-color: #ffffff;
}

.nav-container::after {
	content: "";
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);
	width: 100%;
    display: block;
    height: 1px;
    z-index: 41;
    position: relative;
}

.js_overflow-nav-open-trigger {
	border-left: none !important;
	width: 29px;
    padding-left: 1rem;
}