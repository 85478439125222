.mega-menu__product-dropdown {
  transform: translateY(-1rem);
  min-height: 90px !important;
}

.mega-menu--product-dropdown-active {
  position: relative;
}

.mega-menu--product-dropdown-active::after {
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background: $c_gray;
  position: absolute;
  top: 20px;  
}

.mega-menu__product-dropdown:not(.initial) {
  display: none;
}

.mega-menu__product-dropdown-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.mega-menu__product-dropdown-list {
  list-style: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.mega-menu__product-dropdown-list-item {
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.mega-menu__product-dropdown-list-item-link {
  font-weight: 100 !important;
  margin: 0;
}