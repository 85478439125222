@mixin smallPhone {
  @media (max-width: 375px) { @content; }
}
@mixin phone {
  @media (max-width: 420px) { @content; }
}
@mixin tablet {
  @media (max-width: 768px) { @content; }
}
@mixin largeTablet {
  @media (max-width: 1024px) { @content; }
}
@mixin desktop {
  @media (max-width: 1200px) { @content; }
}
@mixin largeDesktop {
  @media (max-width: 1800px) { @content; }
}

@mixin custom1-767 {
  @media (max-width: 767px) { @content; }
}

@mixin below-ipad-breakpoint {
  @media (max-width: 767px) { @content; }
}

@mixin sidebar-breakpoint {
  @media only screen and (max-width : 991.5px) { @content; }
  @media (max-width: 991px) { @content; }
}

@mixin ipad-column-breakpoint {
  @media (max-width: 991px) { @content; }
}

@mixin sidebar-arrow-breakpoint {
  @media (max-width: 1199px) { @content; }
}

@mixin sidebar-backgroundColor-breakpoint {
  @media (min-width: 1199px) { @content; }
}

@mixin order-summary-breakpoint {
  @media (max-width: 1199px) { @content; }
}

@mixin order-summary-collapse-breakpoint {
  @media (max-width: 394px) { @content; }
}

@mixin lg-desktop-breakpoint {
  @media (max-width: 1199px) { @content; }
}

@mixin browser-mobile-breakpoint {
  @media (max-width: 510px) { @content; }
}

@mixin customer-portal-breakpoint {
  @media (max-width: 1199px) { @content; }
}