/*
	Pager
	*/

	.pager {
		background-color: $c_wild_sand;
		

		&--footer {
			padding-top: px2rem(80);
		}

		&__next {

			&:after {
				@include arrow($c_cerulean);

				content: '';
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center center;
				width: px2em(30, 13);
				height: 1em;
				margin-left: 1.5em;
				position: relative;
				left: 0;
			}
		}

		&__prev {

			&:before {
				@include arrow($c_cerulean);

				content: '';
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center center;
				width: px2em(30, 13);
				height: 1em;
				margin-left: 0;
				margin-right: 1.5em;
				position: relative;
				left: 0;
				transform: scaleX(-1);
			}
		}

		&__prev,
		&__next {

			&:hover {
				color: $c_scarpa-flow;
			}
		}

		&--single {
			.pager__inner {
				justify-content: flex-end;

				@include at(sm) {
					justify-content: center;
				}
			}
		}

		&--multiple {
			padding-top: px2rem(80);
			padding-bottom: px2rem(40);

			.pager__inner {
				justify-content: space-between;
			}
		}

		@include at(sm) {
			padding-bottom: px2rem(80);
		}
	}
