// ==========================================================================
// #Equipment Box
// ==========================================================================

.md-equipment-box {
  background-color: #666;
  position: fixed;
  overflow: auto;
  z-index: 400;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;

  &__outer {
    padding: 77px 6%;
    position: absolute;
    z-index: 1;
  }

  &__inner {
    position: relative;
  }
}