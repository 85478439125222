// 
// # Footer
// 

.footer {

  background-color: $c_gallery;
  padding-bottom: px2rem(15);
  text-align: center;
  border-top: 6px solid #9ACE49;

  &__footer-nav {

    margin: px2rem(60) 0;

    a {
      color: $c_chicago;
      margin: 0 px2rem(12);
      text-decoration: none;

      @include transition(all);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: darken($c_chicago,20);
        text-decoration: underline;
      }
    }
  }

  &__footer-logo {
    margin: auto;
    width: px2rem(136);
  }

  &__social {
    margin-top: px2rem(45);
    margin-bottom: px2rem(35);

    .icon {
      @include transition(all);
      display: inline-block;
      margin: 0 px2rem(18);
      opacity: 0.6;
      width: 3rem;
      height: px2rem(20);
      @media (max-width: 767px) {
          margin: 0px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        filter: brightness(.7);
      }
    }
  }

  p,
  small {
    color: $c_silver_chalice;
  }
}

@include at(sm) {
  .footer {
    &__footer-nav {
      margin: px2rem(70) 0 px2rem(70) 0;

      a {
        width: 100%;
        margin: 0 0 1rem 0;
        display: block;
      }
    }
  }
}
