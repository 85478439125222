.card-section-title {
  font-weight: 600;
  border-bottom: solid 1px $c_border-light;
  padding-bottom: 1.3rem;
  margin-bottom: 2rem;
  font-size: 1.3rem;

  &--has-no-border {
    border: 0;
  }

  &--has-no-bottom-margin {
    margin-bottom: 0rem;
  }

  &--has-top-margin {
    margin-top: 3rem;
  }

  @include phone {
    font-size: 1.2rem;
  }
}