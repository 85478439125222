// ==========================================================================
// #CT Table
// ==========================================================================

.ct-table-wrap {
    margin:0 auto;
}

.ct-table {
    width: 100%;
    font-size: px2rem(16);
    text-align: left;
    margin: px2rem(15) 0;
    border-spacing: 0;
    border-collapse: separate;

    .text-right {
        text-align:right;
    }

    .text-left {
        text-align:left;
    }

    &__text-black {
        color: #71707e;
    }

    &__table-model {
        @extend .t_color--highlight;
        font-weight: 600;
    }

    &__table-price {
        color:  #4f4e5b;
        font-weight: 600;
    }

    &--inverse {
        background-color: white;
        .ct-table__header {
            @extend .t_background--pop;
            color: white;
        }
    }

    &--stacked {
        table {
            border-bottom: none;
        }

        table + table {
            border-bottom: px2rem(3) solid #e5e5e5;
        }
    }

    &--to-continue {
        border-bottom: px2rem(1) solid #e5e5e5;
    }

    &--special-row {
        border: px2rem(1) solid #e5e5e5;
        font-weight: 700
    }

    &--no-wrap {
        td {
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    tr {
        height: px2rem(32);
    }

    th {
        height: px2rem(30);
        white-space: nowrap;
    }

    th, td {
        padding: 0 px2rem(7);
    }

    td {
        border-bottom: px2rem(1) solid #e5e5e5;
        background: #fff;
        &:empty {
            border-bottom: none;
        }
    }

    &__header {
        font-size: px2rem(16);
        @extend .t_background--pop;
        color: white;

        th {
            font-weight: 600;
        }

        &--mix {
            .ct-table__header {
                border: px2rem(3) solid #2aabe1;
                border-left: none;
                border-right: none;
            }
        }

        &--inverse {
            font-size: px2rem(16);
            border: px2rem(3) solid $c_cerulean;
            border-left: none;
            border-right: none;
            color: $c_cerulean;
            background-color: white;
        }
    }

    .ct-action-column {
        width: px2rem(160);
    }


}

.codetable {
    .selected-child,
    .selected-child * {
        background-color: #eeeeee;
    }
}

.ct-table-website td:empty {
    border-bottom: px2rem(1) solid #e5e5e5;
}

.ct-table-wrap {
    *:not(.d-none) + p,
    *:not(.d-none) + nav,
    *:not(.d-none) + .field__select-container {
        margin-top: 3rem;
    }
};

@media only screen and (max-width : 767.5px) {
    .ct-table-wrap {
        *:not(.d-none) + p:not(.d-none) + div {
            margin-top: 3rem;
        }
    };
}