.my-details {

  &__title {
    font-weight: 600;
    border-bottom: solid 1px $c_border-light;
    padding-bottom: 1.3rem;
    margin-bottom: 2rem;
  }
 
  &__btn-container {
    margin-top: 3rem;
  }

  &__address {
    margin-top: 4rem;
    line-height: 0.6;
  }

  &__personal {
    line-height: 0;
  }

  &__address-btn-container {
    margin: 1.5rem 0 3rem 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__personal-right {
    @include below-ipad-breakpoint {
      transform: translateY(-0.5rem);
    }
  }
}