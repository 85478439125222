/*
Configure.
*/

.configure {
	position: relative;
	padding: px2rem(70) px2rem(90);
	background-color: $c_white;
	background-repeat: no-repeat;

	&__configure-body {
		max-width: px2rem(370);

		p {
			margin-bottom: px2rem(60);
		}
	}

	&__narrow {
		padding: px2rem(30);
		background-image: url(/static/bundles/img/m_configure-feature_img.png);

		.configure__configure-body {

			p {
				max-width: px2rem(220);
				margin-bottom: px2rem(90);
			}
		}
	}

	&--z-index {
		z-index: 1;
	}
}

@include at(lg) {

	.configure {
		background-position: center right -150px;

		&__narrow {
			background-position: center right -10px;

			.configure__configure-body {
				max-width: px2rem(180);

				.link {
					@include type(sans, seedling);
				}
			}
		}
	}
}

@include at(md) {

	.configure {
		background-position: center right -300px;
		background-image: none!important;
	}
}

@include at(sm) {

	.configure {
		padding: px2rem(20);
		background-image: none!important;
		background-position: center right;
		margin-top: px2rem(20);

		&__configure-body {
			p {
				max-width: px2rem(220);
				margin-bottom: px2rem(90);
			}
		}

		&__narrow {
			background-image: none;
		}
	}
}
