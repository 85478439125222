/*
Tabs.
*/

.product-page-container {
    padding-left: 0;
    padding-right: 0;
}

.product-page-tabs-container {
    padding: 4rem 0;

    .swiper-button-prev,
    .swiper-button-next {
        z-index: 200;
        top: 42px;
    }

    .swiper-button-prev {
        left: -0.5rem;
    }

    .swiper-button-next {
        right: -0.5rem;
    }

    .swiper-button-disabled {
        display: none;
    }

    .swiper-wrapper {
        padding-top: 1rem;
    }

    .swiper-slide {
        margin-right: 0 !important;
        width: auto !important;
        padding-right: 14px;
        padding-left: 0px;
    }

    .swiper-container {
        overflow: visible !important;
        z-index: 11;
    }
}

.tabs {
    padding: 2rem 0;
    position: relative;
    z-index: 10;
}

.tabs__table-container {
    display: block;
    overflow-x: auto;

    .tabs__table {
        margin: 0 !important;
    }

    &.tabs__table-wrap-header th {
        white-space: normal !important;
        vertical-align: top;
    }
}

.tabs__table-fix-right-column {
    thead tr,
    tbody tr {
        position: relative;
    }

    thead th:last-child,
    tbody td:last-child {
        position: sticky;
        right: 0px;
        box-shadow: -2px 0px 7px 0px rgba(0, 0, 0, 0.16);
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    tbody td:last-child::before {
        content: "";
        display: block;
        background-color: white;
        height: 10px;
        position: absolute;
        top: -10px;
        left: 0px;
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
    }

    thead th:last-child {
        z-index: 1;
        background-color: #009ee2;
    }
}

.tabs__table {
    th {
        padding-top: 0.8rem !important;
        padding-bottom: 0.8rem !important;
        padding-left: 1rem !important;
    }

    td {
        padding-top: 1.2rem !important;
        padding-bottom: 1.2rem !important;
        padding-left: 1rem !important;
    }
}

.tabs__pricing {
    display: inline-block;
    text-align: right;

    small {
        display: block;
    }
}

.tabs__table-download {
    vertical-align: baseline !important;
    &::after {
        content: url(/static/bundles/img/svg/download.svg) !important;
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: baseline !important;
        margin-left: 1rem;
    }
}

.js_trackedResources {
    height: 48px;
    display: inline-block;
    margin-top: 20px;

    &::after {
        content: none !important;
    }

    div::after {
        content: url(/static/bundles/img/svg/download.svg) !important;
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: baseline !important;
        margin-left: 1rem;
    }

    small {
        color: #5C5C5B;
        text-decoration: none;
        display: inline-block;
        margin-top: 9px;
    }
}

div[data-model="tracked-resources-model"] {
    .popup-model__left {
        width: 320px;

        h3 {
            font-weight: 600;
            margin-bottom: 28px;
            font-size: 24px;
        }
    }

    .popup-model__right {
        h2 {
            color: #009ee2;
            font-weight: 600;
            margin-top: 33px;
            margin-bottom: 28px;
            font-size: 24px;
        }
    }
}

.tabs__table-download-icon img {
    width: 20px;
    height: 20px;
    vertical-align: baseline !important;
    display: none;
}

.tabs__add-to-cart {
    vertical-align: baseline !important;
    white-space: nowrap;
    text-decoration: none;

    &::before {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: text-top !important;
        padding-right: 1.5rem;
    }

    &[data-carttype="eCommerce"] {
        &::before {
            content: url(/static/bundles/img/svg/cart-blue.svg) !important;
        }
    }

    &[data-carttype="quote"] {
        &::before {
            content: url(/static/bundles/img/svg/enquiry-blue.svg) !important;
        }
    }
}

.tabs__table-thumbnail-container {
    padding-left: 0 !important;

    img {
        width: 60px;
    }
}

.tabs__table-row td:last-child {
    padding-right: 1rem;
}

.tabs__table-row td {
    white-space: nowrap;
}

// video styles

.tabs__videos {
    overflow-x: auto !important;
    padding: 0 1rem 1rem 1rem;
}

.tabs__videos-thumbnail-container {
    position: relative;
    display: flex;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    scroll-behavior: smooth;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.tabs__videos-thumbnail-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.tabs__videos-thumbnail-container::-webkit-scrollbar:horizontal {
    height: 5px;
}

.tabs__videos-thumbnail-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #d6d6d6; /* should match background, can't be transparent */
    background-color: #d6d6d6;
}

.tabs__table-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.tabs__table-container::-webkit-scrollbar:horizontal {
    height: 5px;
}

.tabs__table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #d6d6d6; /* should match background, can't be transparent */
    background-color: #d6d6d6;
}

.tabs__video-thumbnail {
    margin-right: 1rem;
    padding: 0;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;
    cursor: grab;
    overflow: auto;
}

.product-page__video-player {
    position: fixed;
    padding-bottom: 45rem;
    height: 0;
    width: 80%;
    z-index: 201;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    z-index: 100000010;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80%;
        z-index: 100000010;
    }

    &--is-visible {
        opacity: 1;
        visibility: visible;
    }
}

.product-page__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.8);
    z-index: 200;
    opacity: 0;
    visibility: hidden;
    z-index: 100000009;

    &--is-visible {
        opacity: 1;
        visibility: visible;
    }
}

.product-page__video-close {
    position: fixed;
    top: -4rem;
    right: 0;
    font-size: 3rem;
    color: $c_gallery;
    z-index: 300;
    font-weight: 100;
    cursor: pointer;
    z-index: 100000010;
}

// video player styles end

.tabs__nav {
    margin-bottom: px2rem(70);
    position: relative;
    z-index: 1;

    &__container {
        &:first-child {
            margin-left: 0;
            padding-left: 0;
        }

        &:last-child {
            margin-right: 0;
            padding-right: 0;
        }
    }

    &__item {
        @include transition((background-color));

        padding: px2rem(20);
        background-color: $c_wild_sand;
        box-shadow: 0px 0.1875rem 1.25rem rgba(0, 0, 0, 0.07);
        margin-bottom: 2rem;

        &:hover {
            background-color: $c_white;
            cursor: pointer;
        }

        &--active {
            z-index: 20;
            background-color: $c_white;
        }

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px !important;
        }

        &-inner {
            display: none;
        }
    }
}

@include ipad-column-breakpoint {
    .product-page-tabs-container {
        .swiper-slide {
            padding-left: 0;
            padding-right: 0;
            margin-right: 1rem !important;
            max-width: initial !important;
            width: auto !important;
            white-space: nowrap;
        }

        .swiper-button-prev {
            left: 0rem;
        }

        .swiper-button-next {
            right: 0rem;
        }
    }

    .product-page__video-player {
        width: 90%;
    }
}

@include below-ipad-breakpoint {
    .product-page-tabs-container {
        padding: 0rem;
        
        .swiper-slide {
            padding-left: 0;
            padding-right: 0;
        }
        .swiper-container {
            width: 100% !important;
        }
    }

    .tabs__table-subtitle {
        display: none;
    }

    .tabs__nav__container {
        &:first-child {
            padding-left: 15px;
        }

        &:last-child {
            padding-right: 15px;
        }
    }

    .tabs__table-thumbnail-header {
        display: none;
    }

    .tabs__table-thumbnail-container {
        display: none;
    }

    .tabs__table {
        &--downloads {
            overflow: hidden;
            white-space: normal;
        }
    }

    .tabs__table-download::after {
        display: none;
    }

    .tabs__table-download-icon img {
        display: inline-block;
        transform: translateY(-1rem);
    }

    .tabs-main-container {
        .swiper-button-prev,
        .swiper-button-next {
            display: none !important;
        }

        .swiper-wrapper {
            flex-direction: column;
            box-sizing: border-box;
            transform: translate3d(0px, 0px, 0px) !important;
        }

        .swiper-slide {
            min-width: 100% !important;
            transform: translate3d(0px, 0px, 0px) !important;
        }
    }
}

@include phone {
    .tabs__table-download-icon-container {
        padding: 0 !important;
    }

    .tabs__table-download-icon img {
        display: inline-block;
        transform: translateY(-1rem) translateX(-1rem) !important;
    }
}

@include at(sm) {
    .tabs {
        display: none;
    }

    .tabs-nav {
        padding-bottom: 2rem;
    }

    .tabs__nav {
        &__item {
            justify-content: left;
            padding: px2rem(40) px2rem(20);
            background-image: url(/static/bundles/img/svg/arrow-down.svg);
            background-color: #fff;
            background-repeat: no-repeat;
            background-position: center right $grid-gutter-width;
            background-size: 13px;
            margin: 0;
            box-shadow: none !important;

            &--active {
                z-index: auto;

                &:after {
                    content: none;
                }
            }

            &-inner {
                background-color: $c_white;
                position: relative;
                z-index: 1;
                display: none;
                height: 0;
                overflow: hidden;
                border-bottom: solid 1px $c_gallery;
            }

            &.is-open {
                & ~ .tabs__nav__item-inner {
                    height: auto;
                    display: block;
                }
            }
        }
    }

    .tabs__nav__item.is-open {
        background-image: url(/static/bundles/img/svg/arrow-up.svg);
    }

    .tabs-compact {
        padding-top: 0rem !important;
    }
}

.multi-table-carousel {
    position: relative;
    background-color: #ffffff;

    .swiper-slide {
        font-size: px2rem(13);
        white-space: nowrap;
        max-width: initial !important;
        width: auto !important;
        background-color: #e1e1e1;
        font-weight: bold;
        color: #5c5c5b;
        padding: 0.9rem 1.5rem 0.6rem 1.5rem;
        margin-right: 0.5rem !important;
        cursor: pointer;
    }

    .swiper-slide.active {
        background-color: #009ee2;
        color: #ffffff;
    }

    > div {
        margin: 0px !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        top: 37px;
    }

    .swiper-button-prev {
        left: -25px;
    }

    .swiper-button-next {
        right: -25px;
    }

    .swiper-button-disabled {
        display: none;
    }
}

.tabs__table-fix-right-column tbody tr,
.tabs__table-four tbody tr {
    td:first-child {
        border-left: 1px solid #e5e5e5;
    }
    td:last-child {
        border-right: 1px solid #e5e5e5;
    }
}

@media only screen and (max-width: 767.5px) {
    .tabs__table-fix-right-column {
        thead th:last-child,
        tbody td:last-child {
            position: relative;
            box-shadow: none;
        }
    }
    .multi-table-carousel {
        display: none;
    }
    .multi-table-select {
        display: block !important;
    }
    .tabs__table-container {
        position: relative;
        margin-top: -44px;
    }
    .tabs__table-container::before {
        content: "";
        background-image: url(/static/bundles/img/svg/table-chevron-right.svg),
            linear-gradient(to right, rgba(255, 0, 0, 0), rgba(0, 158, 226, 1));
        background-repeat: no-repeat;
        background-position: center right;
        position: sticky;
        left: calc(100% - 100px);
        top: 47px;
        height: 44px;
        width: 100px;
        display: inline-block;
        z-index: 2;
    }
}

.price-column,
.price-row {
    padding-right: 3rem !important;
}

.tabs-compact {
    padding-top: 3rem;

    .tabs__nav__item {
        color: #969696;
    }

    .tabs__nav__item.tabs__nav__item--active,
    .tabs__nav__item:hover {
        color: #5C5C5B;
    }

    .tab-selected-content {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .tabs__nav__item .icon {
        background-size: contain;
    }

    .content-blocks,
    .download-doc {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        background-color: #ffffff;
    }

    .download-doc .download-list {
        padding-bottom: 0;
        border-bottom: none;
    }

    .download-doc > div {
        padding-bottom: 0;
    }

    .download-doc .t_page-title-primary,
    .section-video .t_page-title-primary,
    .article-feed .t_page-title-primary {
        font-size: 21px;
        font-weight: normal;
    }

    .download-doc .container-fluid,
    .section-video .container-fluid {
        padding: 0;
    }

    .article-feed {
        background-color: #ffffff;
    }

    .section-video p {
        color: #8e8e8e;
    }

    .section-video .video-teaser__inner {
        position: relative;
    }

    .section-video .video-teaser:before {
        display: none !important;
    }

    .section-video .video-teaser__inner::before {
        content: "";
        background: #000000;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        opacity: 0.2;
    }

    .section-video .video-teaser__inner:hover {
        outline: 6px solid #009ee2;
        @include transition(all);
    }

    .section-video .video-teaser__inner {
        width: 100%;
        height: 200px;
        background-repeat: repeat;
        background-position: center;
        background-size: cover;
        background-color: black;
    }
}

@media only screen and (min-width: 767.5px) {
    .tabs-compact {
        .tabs__nav__item {
            background-color: #fafafa;
            box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.05) !important;
            margin-bottom: 0 !important;
            padding: 16px 20px;
        }

        .tabs__nav__item:hover {
            background-color: #ffffff !important;
        }

        .tabs__nav__item--active {
            background-color: #ffffff;
            position: relative;
        }

        .tabs__nav__item--active::after {
            content: "";
            position: absolute;
            background-color: white;
            width: 100%;
            height: 8px;
            bottom: -4px;
        }
    }
}
@media only screen and (max-width: 767.5px) {
    .tabs-compact {
        .download-doc .t_page-title-primary,
        .section-video .t_page-title-primary,
        .article-feed .t_page-title-primary {
            font-size: 16px !important;
            line-height: 24px !important;
            font-weight: normal;
            margin-bottom: 0px !important;
        }

        .article-feed .t_page-title-primary {
            margin-bottom: 0.5rem !important;
        }

        .download-doc h3 {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0rem;
        }

        .download-doc .info,
        .download-doc .download-msg {
            font-size: 12px;
            line-height: 21px;
        }

        .download-doc .download-msg::after {
            width: 22px;
            height: 22px;
            margin-left: 17px;
        }

        .download-doc a {
            padding: 1.5rem 0;
        }

        .video-teaser__body {
            display: none;
        }

        .video-teaser__title {
            font-size: 20px;
            line-height: 30px;
            top: 1.1rem;
            left: 1.1rem;
        }

        .video-teaser__controls {
            bottom: 1.1rem;
            left: 1.1rem;
        }

        div[data-contentid="articles"] {
            padding-bottom: 2rem;
        }
    }
}

.tabs__nav__item-inner,
.tabs {
    p {
        font-size: 16px;
        line-height: 26px;
        font-weight: normal;
    }

    .call-out-text {
        font-size: 21px;
        line-height: 36px;
        font-weight: normal !important;
    }
}

.tabs__nav__item-inner section > * {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

@include at(sm) {
    .tabs__nav__item-inner,
    .tabs {
        p {
            font-size: 14px;
            line-height: 21px;
        }

        .call-out-text {
            font-size: 16px;
            line-height: 24px;
        }
    }
    .tabs__nav__item.is-open {
        padding: 2.5rem 1.25rem 1.5rem 1.25rem;
    }
}

#product-info .pimcore_area_content {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 1rem;
}

.section-faqs {
    h2 {
        font-size: px2rem(21);
        font-weight: 500;
    }

    h3 {
        font-weight: 600;
        color: #5C5C5B;
        margin-bottom: 1rem;
    }

    .faqs-list > div > div {
        margin-bottom: 2rem !important;
        border-bottom: 1px solid #ECEBEA;
        padding-bottom: 2rem;
    }
}