
.atomic-section-title { /* generic section title for the atomic components html page */
  color: $c_cerulean;
  font-weight: bold;
  font-size: 2.4rem;
}

.atomic-section-title-medium {
  color: $c_cerulean;
  font-weight: bold;
  font-size: 2rem;
}

/* titles */

.section-header {
  margin-bottom: 2rem;
}

.t_page-title-primary {
  font-size: 1.8rem;
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: $c_cerulean;

  @include below-ipad-breakpoint {
    font-size: 1.5rem;
  }

  &--page {
    @include below-ipad-breakpoint {
      padding-left: 10px;
      margin: 0 0 2rem 0;
    }
  }

}

.t_page-title-secondary {
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  font-weight: 700;
  color: $c_text-dark;

  &--light {
    font-weight: 400;
  }

  &--blue {
    color: $c_cerulean !important;
  }

  &--centered {
    text-align: center;
  }
}

.t_page-title-secondary--light {
  font-size: 1.5rem;
  font-family: Arial, sans-serif;
  font-weight: 400;
  color: $c_text-dark;
}

/* generic paragraphs/text */

.t_generic-text {
  font-size: 1rem;
  color: $c_text-dark;
  line-height: 1.5;
  font-family: Arial, sans-serif;
}

.t_generic-text--light {
  font-size: 1rem;
  color: $c_gray;
  line-height: 1.5;
  font-family: Arial, sans-serif;
}

.t_generic-text--tiny {
  font-size: 0.9rem;
  color: $c_text-dark;
  line-height: 1.5;
  font-family: Arial, sans-serif;
}

.t_generic-text--wysiwyg,
.t_generic-text--wysiwyg-normal {
  font-size: 1rem;
  line-height: px2rem(19);
  font-family: Arial, sans-serif;

  ul {
    padding-left: px2rem(13);
  }

  ul li {
    margin-bottom: px2rem(6);
  }

  p {
    margin-top: 0px;
  }
}

.t_generic-text--wysiwyg {
  color: $c_gray;
}

.bullet-blue {
  ul {
    padding-left: px2rem(13);
    list-style: none;
  }

  ul li::before {
    content: "\2022";
    color: #009EE2;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}


@include below-ipad-breakpoint {
  .t_page-title-primary {
    font-size: 1.5rem !important;
  }
  .t_page-title-secondary {
    font-size: 1.5rem !important;
  }
  .t_page-title-secondary--light {
    font-size: 1.5rem !important;
  }
}

.font-size-20 {
  font-size: px2rem(20);
}

.text-left {
    text-align: left;
}

.text-right {
  text-align: right;
}

.font-size-24 {
  font-size: px2rem(24);
  line-height: px2rem(30);
}