// ==========================================================================
// # Thank You Page
// ==========================================================================


.thank-you-page {

    h1 {
        line-height: px2rem(48) !important;
    }

    p {
        font-size: px2rem(14);
        font-weight: bold;
        line-height: px2rem(21);
    }

    a {
        font-size: px2rem(14) !important;
        font-weight: bold !important;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .header__body {
        padding: 0 !important;
    }
}

@include at(sm) {
    .thank-you-page {
        .header__body {
            padding-right: 15px !important;
        }
    }
}

@media only screen and (max-width : 415.5px) {
    .thank-you-page {
        a {
            width: 100%;
        }
    }
}