/*
Product Compare page
*/


.compare-page {
    .filter.filter-background,
    .product-filter__trigger,
    .product-pagination__select,
    .product-filter__mobile-menu__left,
    .product-filter__mobile-menu__right,
    .js_product-filter {
        display: none !important;
    }

    // &.col-lg-3 {
    //     display: none !important;
    // }

    .sort-for-quote {
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .product-filter__mobile-menu-trigger {
        margin-top: -48px;
    }


}