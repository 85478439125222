/*
Inbox.
Unsure of where something lives? Leave it here for a lead FE dev to sort
*/

.which-breakpoint:before {
	// content: '';
	display: block;
	position: fixed;
	bottom: 0;
	right: 0;
	padding: 1.5rem;
	background: red;
	z-index: 100;
}

@include at(sm) {
	.u_hide--sm {
		display: none;
	}
}

@include at(md) {
	.u_hide--md {
		display: none;
	}
}

.u_show--sm {
	display: none;

	@include at(sm) {
		display: initial;
	}
}

.u_show--md {
	display: none;

	@include at(md) {
		display: initial;
	}
}

@include at(xs) {
	.u_hide--xs {
		display: none;
	}
}

// .u_show--xs {
// 	display: none;
//
// 	@include at(xs) {
// 		display: initial;
// 	}
// }

.page-search {

	@include at(sm) {
		text-align: center;
	}
}

.search-result-count {
	padding-top: px2rem(80);
	padding-bottom: px2rem(30);
}


.no-results {
	margin-top: 188.5px;

	&.has-search-query {
		margin-top: 43px;
	}

	&.center {
		text-align: center;
	}

	h2 {
		font-size: 28px;
		font-weight: bold;
	}

	p {
		font-size: 18px;
	}
}

@include at(sm) {

	.results-block {

		&__heading {
			text-align: left;
			padding-bottom: px2rem(15);
			margin-bottom: px2rem(40);
			border-bottom: solid 1px rgba(0,0,0,0.1);

			small {
				margin: 0;
			}
		}
	}
}

.other-results {

	@include at(sm) {
		text-align: left;
	}
}

.back-to-top {

	display: none;

	@include at(sm) {

		display: flex;
		margin-top: px2rem(40);

		.icon {
			width: px2rem(60);
			height: px2rem(60);
		}
	}
}

.delete-link {
	@include at(xs) {
		margin-top: 2rem;
	}
}

.margin-top-80 {
	margin-top: 80px;
}