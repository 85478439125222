@charset "UTF-8";
/*!*
 *	Tractel
 *	tractel.com
 */

@import 'vendor/swiper';
@import 'vendor/plyr';
@import 'vendor/tingle';

@import '_core/core';
@import 'base/__base';
@import 'components/__components';
@import './atomic-components/__atomic-components';
@import 'pages/__pages';
@import 'specifics/__specifics';
@import 'misc';
@import 'inbox';
@import 'hacks';

@import 'mindmap/__index-mindmap';

@import 'custom';