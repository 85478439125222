/*
Tooltips.
*/

.tippy-tooltip.light-border-theme {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e4e4;
  color: #26323d;
  filter: drop-shadow(0px 2px 4px rgba(0,0,0,0.15));
  border-radius: 2px;
  padding: 10px 20px;

  .tippy-content {
    padding: 0px;
    line-height: 25px;
    text-align: left;
    a {
      color: #009fe3;
      display: block;
    }
  }
  .tippy-backdrop {
    background-color: #fff;
  }

  .tippy-arrow {
    &:after, &:before {
      content: "";
      position: absolute;
      z-index: -1;
    }
  }

  &[x-placement^=top] {
    .tippy-arrow {
      border-top-color: #fff;
      &:after {
        border-top: 7px solid #fff;
        top: -7px;
      }
      &:before {
        border-top: 7px solid #e4e4e4;
        bottom: -1px;
      }
    }
  }

  &[x-placement^=bottom] {
    .tippy-arrow {
      border-bottom-color: #fff;
      &:after {
        border-bottom: 7px solid #fff;
        bottom: -7px;
      }
      &:before {
        border-bottom: 7px solid #e4e4e4;
        bottom: -6px;
      }
    }
  }

  &[x-placement^=left] {
    .tippy-arrow {
      border-left-color: #fff;
      &:after {
        border-left: 7px solid #fff;
        left: -7px;
      }
      &:before {
        border-left: 7px solid #e4e4e4;
        left: -6px;
      }
    }
  }

  &[x-placement^=right] {
    .tippy-arrow {
      border-right-color: #fff;
      &:after {
        border-right: 7px solid #fff;
        right: -7px;
      }
      &:before {
        border-right: 7px solid #e4e4e4;
        right: -6px;
      }
    }
  }

  &[x-placement^=bottom] .tippy-arrow {
    &:after, &:before {
      left: -7px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }

  &[x-placement^=top] .tippy-arrow {
    bottom: -4px;
    &:after, &:before {
      left: -7px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }
  }

  &[x-placement^=left] .tippy-arrow {
    &:after, &:before {
      top: -7px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
    }
  }

  &[x-placement^=right] .tippy-arrow {
    &:after, &:before {
      top: -7px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
    }
  }
}

@media (max-width: 510px) {
  .tippy-tooltip.light-border-theme {
    max-width: 300px !important;
  }
}

.tippy-popper {
  z-index: 120000012 !important;
}