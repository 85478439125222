// ==========================================================================
// #Mindmap Item
// ==========================================================================

%md-mindmap-item {
    position: absolute;
    height: auto;
}

.md-mindmap {
    position: relative;
    background-color: $c_gallery;

    &__main-img {
        width: 100%;
        height: auto;
        display: block;
    }

    &__home-button {
        font-size: 12px;
        text-decoration: none;
        font-weight: bold;
        position: fixed;
        top: 30px;
        left: 30px;
        color: white;
    }

    &__item {
        @extend %md-mindmap-item;
        &-16 {
            @extend %md-mindmap-item;
            top: 42%;
            left: 46%;
        }
        &-17 {
            @extend %md-mindmap-item;
            top: 74%;
            left: 82%;
        }
        &-18 {
            @extend %md-mindmap-item;
            top: 10%;
            left: 10%;
        }
        &-19 {
            @extend %md-mindmap-item;
            top: 19%;
            left: 32%;
        }
        &-20 {
            @extend %md-mindmap-item;
            top: 52%;
            left: 16.5%;
        }
    }

    .md-close {
        position: absolute;
        right: 10px;
        top: 10px;
        height: 20px;
        width: 20px;
        border: none;
    }

}
