.c_card-basic {
  width: 100%;
  background-color: $c_white;
  padding: 50px;
  margin-bottom: 1rem;

  &--bordered {
    border-bottom: solid 4px $c_green;
    margin-bottom: 3rem;
  }

  &__header {
    border-bottom: solid 1px $c_gallery;
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    &--no-border {
      border: solid 1px transparent;
    }

    &--extra-mb {
      margin-bottom: 3rem !important;
    }
  }

  &__padding-small {
    padding: 40px 30px;
  }

  &__returns-list {
    margin-left: 1.2rem !important;
    padding: 0;
    li {
      line-height: 2;
    }
  }

  &__btn {
    margin-top: 3.5rem;
    margin-bottom: 2rem;

    @include below-ipad-breakpoint {
      text-align: center;

      .b_button-secondary {
        display: block;
      }

      .b_button-primary {
        display: block;
      }
    }
  }

  .t_page-title-secondary {
    margin-bottom: 1rem;
  }

  .f_checkbox-container {
    margin-bottom: 3rem;
  }

  .t_generic-text--tiny {
    margin-bottom: 2rem;
  }

  &__order-summary {
    &__section {

      &--bordered {
        border-top: solid 1px $c_gallery;
        border-bottom: solid 1px $c_gallery;
        padding: 0.5rem 0;
      }

      &--border-bottom {
        border-bottom: solid 1px $c_gallery;
        padding: 0.5rem 0;
      }
    }
  }

  &__payment-image {
    width: 228px;
  }

  &__btn-group-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    > * {
      display: block;
      margin-bottom: 1rem;
      min-width: 200px;
    }

    @media (max-width: 767px) {
      > * {
        width: 100%;
      }
    }
  }
}

.c_card-btn-container-left {
  margin-top: 2rem;
}

@include below-ipad-breakpoint {
  .c_card-basic {
    padding: 40px 30px 50px 30px;
  }
}

@include browser-mobile-breakpoint {
  .c_card-basic__padding-small {
    padding: 30px 25px 30px 25px;
  }
}

.c_card__link-icon {
  display: inline-block;
  
  margin-left: 0.4rem;
  width: 17px;
}

.c_card__wysiwyg {

  p, li {
    font-size: 1rem;
    color: #5c5c5b;
    line-height: 1.5;
    font-family: Arial, sans-serif;
  }

  ul {
    margin-left: 1.2rem !important;
    padding: 0;
  }

  li {
    line-height: 2;
  }

  .returns-address {

    ul {
      list-style-type: none;
    }

    li {
      line-height: 23px;
    }
  }
}

.image-thumbnails-220 {

  height: 220px;

  picture {
    width: 100%;
    height: 100%;
    position: relative;
  }

  img {
    display: block;
    max-width: 100%;
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 220px;
    padding: 1rem;
  }
}

.product-list-thumbnail {
  width: 135px;

  img {
    max-height: 150px;
    margin-left: auto;
    margin-right: auto;
  }
}

.c_card-product-list-view {
  td {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (max-width : 991.5px) {
  .c_card-product-list-view {
    table, tr, tbody, td {
      display: block;
    }
    td {
      margin-bottom: 2rem;

      &[colspan] {
        margin-bottom: 0;
      }
    }
  }

  .product-list-thumbnail {
    display: block;
    margin-left: auto;
    margin-right: auto;

    img {
      max-height: 180px;
    }
  }
}

.shadow {
  &-light {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
  }
}