.cta-banner {
    background-color: #F7F7F7;
    padding: 5.75rem 0 2rem 0;

    @include below-ipad-breakpoint {
        padding: 4.25rem 0 2.375rem 0;
    }

    &__image {
        width: 100%;
        height: 100%;
        min-height: 380px;
        position: relative;

        @include below-ipad-breakpoint {
            min-height: 200px;
        }

        picture, img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        background-color: $c_cerulean;
        color: $c_white;
        padding: 3.5rem 3rem;

        @include below-ipad-breakpoint {
            padding: 2rem;
        }

        p {
            max-width: 375px;
            font-size: 0.875rem;
            line-height: 1.4;
            margin: 1.5rem 0;
        }
    }

    .h2:before {
        background-color: $c_white !important;
    }

    .link--arrow {
        font-size: 1.25rem;
        line-height: 1;
    }
}
