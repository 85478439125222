.checkout-modal {
  position: absolute;
  background-color: $c_white;
  width: px2rem(767);
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;

  @include largeTablet {
    width: 80%;
  }

  @include tablet {
    width: 100%;
  }

  &--is-visible {
    opacity: 1;
    visibility: visible;
    z-index: 10000005;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include desktop {
      width: px2rem(720);
      top: 10%;
    }

    @include tablet {
      width: 100%;
      top: 30%;
    }

    @include below-ipad-breakpoint {
      top: 25%;
    }
  }

  @include tablet {
    width: 100%;
  }

  @include below-ipad-breakpoint {
    width: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: 1.6rem;
    @include phone {
      font-size: 1.3rem;
    }
  }

  &__title-container {
    width: 100%;
    background-color: $c_pop;
    padding: 1.5rem 1.5rem;
    color: $c_white;
    display: flex;
    justify-content: space-between;
  }

  &__content {
    padding: 2rem 2rem 5rem 2rem;
  }

  &__close {
    cursor: pointer;
    font-size: 1.5rem;
  }

  &__title-left {
    font-weight: 600;
    margin-bottom: 2rem;
  }

  &__title-right {
    font-weight: 600;
    margin-bottom: 3rem;
    @include custom1-767 {
      margin-bottom: 1rem;
    }
  }

  &__form {
    width: 90%;
  }

  &__text-left {
    font-size: 0.8rem;
    line-height: 1.6;
    width: 90%;
  }

  &__text-right {
    font-size: 0.8rem;
    line-height: 1.8;
    width: 91%;
    margin-bottom: 3rem;
  }

  &__create-account-btn {
    width: 100%;
  }

  &__forgot-btn {
    margin-bottom: 2rem;
  }

  &__go-back-btn {
    display: block;
  }
}

.modal-visible {
  opacity: 1;
  visibility: visible;
}

.overlay {
  opacity: 0.5; 
  background: #000; 
  width: 100%;
  height: 100%; 
  z-index: 10000004;
  top: 0; 
  left: 0; 
  position: fixed;
  visibility: hidden; 

  &--is-visible {
    visibility: visible; 
  }
}

@media (max-width: 840px) {
  .checkout-modal__form {
    width: 100%;
  }
}