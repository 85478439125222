/* margin bottom */

.u_mb-05 {
  margin-bottom: 0.5rem !important;
}

.u_mb-075 {
  margin-bottom: 0.75rem !important;
}

@for $i from 0 through 7 {
  .u_mb-#{$i} {
    margin-bottom: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_mb-md-#{$i} {
      margin-bottom: ($i)+rem !important;
    }
  }
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

/* margin right */

@for $i from 0 through 7 {
  .u_mr-#{$i} {
    margin-right: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_mr-md-#{$i} {
      margin-right: ($i)+rem !important;
    }
  }
}

/* margin top */

.u_mt-075 {
  margin-top: 0.75rem !important;
}

@for $i from 0 through 7 {
  .u_mt-#{$i} {
    margin-top: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_mt-md-#{$i} {
      margin-top: ($i)+rem !important;
    }
  }
}

@media only screen and (max-width : 991px) {
  @for $i from 0 through 7 {
    .u_mt-lg-#{$i} {
      margin-top: ($i)+rem !important;
    }
  }
}

/* margin left */

@for $i from 0 through 7 {
  .u_ml-#{$i} {
    margin-left: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_ml-md-#{$i} {
      margin-left: ($i)+rem !important;
    }
  }
}

/* padding bottom */

@for $i from 0 through 7 {
  .u_pb-#{$i} {
    padding-bottom: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_pb-md-#{$i} {
      padding-bottom: ($i)+rem !important;
    }
  }
}

/* padding top */

@for $i from 0 through 7 {
  .u_pt-#{$i} {
    padding-top: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_pt-md-#{$i} {
      padding-top: ($i)+rem !important;
    }
  }
}


/* padding right */

.u_pr-05 {
  padding-right: 0.5rem;
}

@for $i from 0 through 7 {
  .u_pr-#{$i} {
    padding-right: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_pr-md-#{$i} {
      padding-right: ($i)+rem !important;
    }
  }
}

/* padding left */

.u_pl-05 {
  padding-left: 0.5rem;
}

@for $i from 0 through 7 {
  .u_pl-#{$i} {
    padding-left: ($i)+rem !important;
  }
}

@media (max-width: 767px) {
  @for $i from 0 through 7 {
    .u_pl-md-#{$i} {
      padding-left: ($i)+rem !important;
    }
  }
}

.position-relative {
  position: relative !important;
}

.wrapper + .atomic-components {
  margin-top: -20px;
}

.word-break-md {
  @media (max-width: 768px) {
    word-break: break-word;
  }
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.font-light {
  font-weight: 400 !important;
  font-size: 0.9rem !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-bolder {
  font-weight: bolder !important;
}

.w-100 {
  width: 100%;
}

@include browser-mobile-breakpoint {
  .w-sm-100 {
    width: 100%;
  }
}

@include below-ipad-breakpoint {
  .w-md-100 {
    width: 100%;
  }
}

.h-100 {
  height: 100%;
}

.max-width-100-per {
  max-width: 100% !important;
}

.max-width-1200 {
  max-width: 1200px;
}

.max-width-400 {
  max-width: 400px;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-250 {
  max-width: 250px;
}

.max-width-150 {
  max-width: 150px;
}

.min-height-1 {
  min-height: 1px;
}

.line-height-24 {
  line-height: 24px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.v-align {
  &-top {
    vertical-align: top;
  }
  &-middle {
    vertical-align: middle;
  }
  &-bottom {
    vertical-align: bottom;
  }
}

.border-bottom-grey {
  border-bottom: solid 1px #e4e4e4;
}

.border-bottom-none {
  border-bottom-width: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;

  input:invalid,
  select:invalid,
  textarea:invalid {
    box-shadow: none;
  }
}

/* 
  Add this to hide element, but allow validation
*/
.opacity-zero,
.opacity-zero * {
  opacity: 0;
  pointer-events: none;
  height: 2px;
  padding: 0 !important;
  margin: 0 !important;

  input:invalid,
  select:invalid,
  textarea:invalid {
    box-shadow: none;
  }
}

.hide-firefox-red-border {
  input:invalid,
  select:invalid,
  textarea:invalid {
    box-shadow: none;
  }
}

.back-icon::before {
  content: " ";
  display: inline-block;
  background: url(/static/bundles/img/blue-chevron.png);
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
  transform: rotate(90deg) translate(1px, 0px);
  background-repeat: no-repeat;
}

.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}