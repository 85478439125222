/*
Form.
*/

.form {
	margin: 0 auto;
}

.field {

	&__input {
		width: 100%;
		border: 1px solid $c_black;
		margin: 0;
		padding: px2em(16) 0.5em px2em(16) 0.5em;
		border-radius: px2em(3);

		&--message {
			padding: 0.5em;
		}
	}

	&__label {
		margin-bottom: 0.5em;
	}

	&--checkbox {
		display: flex;
		align-items: center;

		.field {
			&__input {
				width: auto;
				position: relative;
			}

			&__label {
				margin-bottom: 0;
				margin-left: 0.5em;
			}
		}
	}

}

fieldset {
	@include transition(opacity);

	padding: 0;
	border: none;

	&[disabled] {
		opacity: 0.1;
	}
}

.contact-form-feedback {
	@include transition((opacty, min-height));

	height: 0;
	min-height: 0;
	overflow: hidden;
	opacity: 0;

	.contact-form--success & {
		min-height: 100px;
		opacity: 1;
	}
}
