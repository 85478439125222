.cart {
  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__edge-link {
    font-size: 0.8rem;
    margin-top: 0.3rem;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    tbody, tr {
      width: 100%;
    }
  }

  &__order-row {
    td {
      border-bottom: solid 1px $c_mercury;
    }

    &--top td{
      border-top: solid 1px $c_mercury;
    }
  }

  &__order-row-details td {
    padding: 1.4rem 0.8rem;
  }

  &__order-thumbail-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 60px;
  }

  &__order-thumbnail {
    border: solid 1px $c_silver;
    left: 0;
    width: 100%;
  }

  &__product-code {
    display: block;
    font-size: 0.7rem;
    margin-top: 0.4rem;
  }

  &__order-price {
    white-space: nowrap;
  }

  &__order-total {
    white-space: nowrap;
  }

  &__order-quantity {
    white-space: nowrap;
    &--is-bordered {
      line-height: 0;
      margin: 0;
    }

    &-input {
      border: 0;
      width: 55px;
      height: 35px;
      padding-left: 0.7rem;
      border: solid 1px $c_mercury;
      font-weight: lighter;
      text-align: center;

      &:focus {
        outline: none;
        border-color: $c_pop;
      }
    }
  }

  &__order-update {
    font-size: 0.75rem;
  }

  &__clear-cart {
    text-align: right;
    margin-top: 2rem;
  }

  &__order-remove {
    padding-right: 0 !important;
  }

  &__order-remove-btn {
    cursor: pointer;
  }

  &__order-remove-btn:link,
  &__order-remove-btn:visited {
    color: $c_pop;
  }

  &__order-remove-btn:hover,
  &__order-remove-btn:active {
    color: $c_pop_light;
  }
  
}

.cart-wrapper {
  position: relative;
}

@include at(md) {
  .cart {

    &__order-total {
      display: none;
    }

    &__order-thumbail-container {
      padding-left: 0 !important;
    }

    &__info {
      padding-left: 1rem !important;
      padding-right: 0 !important;
    }
  }
}

@include at(sm) {

  $cart_image_width: 90px;
  $cart_remove_btn_width: 30px;

  .cart {
    &__table {
      display: block;
    }

    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      border-bottom: solid 1px $c_mercury;
      padding-top: 30px;
      padding-bottom: 30px;

      &.cart__order-row--top {
        border-top: solid 1px $c_mercury;
        margin-top: 14px;
      }
    }


    td {
      border-width: 0px !important;
    }

    &__order-thumbail-container {
      position: absolute;
      left: 0px;
      width: $cart_image_width;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    &__order-price {
      order: -1;
    }

    &__order-remove {
      position: absolute;
      right: 0px;
      width: $cart_remove_btn_width;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    &__info {
      margin-left: $cart_image_width;
      margin-right: $cart_remove_btn_width;
      padding: 0px 0px 20px 25px !important;
    }

    &__order-quantity {
      padding-bottom: 0px !important;
    }
    
  }
}

@media (max-width: 400px) {
  $cart_image_xs_width: 50px;

  .cart {

    &__order-thumbail-container {
      width: $cart_image_xs_width;
    }

    &__info {
      margin-left: $cart_image_xs_width;
    }
    
  }
}

@media (max-width: 360px) {
  $cart_image_xss_width: 0px;

  .cart {

    &__order-thumbail-container {
      width: $cart_image_xss_width;
      display: none !important;
    }

    &__info {
      margin-left: $cart_image_xss_width !important;
      padding-left: $cart_image_xss_width !important;
    }
    
  }
}