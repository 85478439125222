/*
Brands.
*/

.brands {
  position: relative;
  background-color: $c_white;
  padding-top: px2rem(40);

  @include at(sm) {
    padding-top: px2rem(150);
  }

  &__brands {
    padding-top: px2rem(80);
    padding-bottom: px2rem(80);
    text-align: center;
  }

  &__brand-logo {
    display: inline-block;
    padding: 10px;

    img {
      max-height: 70px;
    }
  }

  &--has-overlap {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: px2rem(150);
      background-color: $c_white;
      border-top: 6px solid $c_secondary;
      position: absolute;
      top: px2rem(-150);
      z-index: 0;
    }
  }

  &__carousel {
    .swiper-button-prev,
    .swiper-button-next {
      top: auto;
    }
  }
}

.brand-container {
    a .block-link {
        .brand-text {
            font-weight: normal;
        }
    }
}

@include at(xs) {
  .brands {
    .brands__brand-logo {
      width: 100%;
      max-width: calc(100% - 70px);
    }

    .brands__brand-logo img {
      margin-left: auto;
      margin-right: auto;
      max-height: 100px;
    }
  }
}
