/*
	WYSIWYG.
	Custom type styles applied to wysiwyg content from the CMS.
*/
.wysiwyg {

	&--article {

		@include type(sans, bonzai);
		line-height: 1.9;
		color: $c_gray;

		h2 {
			@include type(sans, scotspine);

			font-weight: bold;
			color: $c_cerulean;
		}

		h3 {
			@include type(sans, cactus);

			font-weight: bold;
			color: $c_scarpa-flow;
		}

		a {
			font-weight: bold;
			color: $c_cerulean;
			text-decoration: underline;
		}

		blockquote {
			@include type(sans, cactus);

			color: $c_cerulean;
			line-height: 1.5;
			border-left: solid 4px $c_cerulean;
			padding: 0 0 0 px2rem(30);
			margin: px2rem(40) 0;
		}

		picture {
			margin: px2rem(50) 0;
		}

	}

}

.pimcore_area_snippet.pimcore_area_content > .container > .wysiwyg-container {
	padding-left: 15px;
	padding-right: 15px;
}
