.t_table-basic {
  width: 100%;

  tr:first-child td {
    width: 30%;
  }

  &__edit {
    display: block;
  }

  &__col {
    vertical-align: top;
    padding-bottom: 1rem;
    &--right {
      text-align: right;
    }

    &--link {
      @include below-ipad-breakpoint {
        display: inline-block;
        margin-top: 1rem;
      }
    }

    &--extra-b-space {
      padding-bottom: 2rem;

      @include below-ipad-breakpoint {
        padding-bottom: 1rem;
      }
    }

    &--b-border {
      border-bottom: solid 1px $c_gallery;

      @include below-ipad-breakpoint {
        border: 0;
      }
    }

    &--extra-t-space {
      padding-top: 2rem;
      @include below-ipad-breakpoint {
        padding-top: 0.3rem;
      }
    }

  }

  &__col--bold {
    font-weight: bold;
  }
  
}

.t_table-price {
  width: 100%;

  &__col {
    padding-bottom: 0.5rem;

    &--right {
      text-align: right;
    }

    &--bold {
      font-weight: bold;
    }

    &--lg {
      font-size: 1.5rem !important;

      @include below-ipad-breakpoint {
        font-size: 1rem !important;
      }
    }
  }
  
}

.t_table-product {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  &__thumb[data-ll-status="loaded"] {
    width: 70px;
    border: solid 1px $c_gallery;
    padding: 5px;
  }

  &__title {
    color: $c_pop;
    font-size: 1.15rem;
    margin-top: 2rem;

    @include below-ipad-breakpoint {
      margin-top: 1rem;
    }
  }

  &__col {
    padding: 5px;
    border-bottom: solid 1px $c_gallery;

    &--hide-mobile {
      @include below-ipad-breakpoint {
        display: none;
      }
    }

    &--ur-col {
      padding: 2rem 0;

      img {
        min-width: 20px;
      }

      &--show-on-mobile {
        display: none;
        @include below-ipad-breakpoint {
         display: table-cell;
        }
      }

      @include below-ipad-breakpoint {
        padding-right: 1rem;
      }
    }

    &--right {
      text-align: right;
    }
    &--center {
      text-align: center;
    }

    &--stack-qty {
      white-space: nowrap;
      input {
        width: 50px;
        padding: 5px;
        text-align: center;
        border: 0;
        color: $c_text-dark;
        border: solid 1px $c_gallery;
      }
    }

    &--stack-remove {
      font-size: 1.6rem;
      color: $c_pop;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: $c_gray;
      }
    }
  }

  &__code {
    color: $c_gray;
  }
}

.t_table-product__col--stack-remove-item {
  display: none !important;
  margin-bottom: 1rem !important;
  padding-bottom: 0rem;
  border-bottom: solid 1px $c_gallery !important;
  margin-top: 2rem;
}

.t_table-product__col--stack-border-bottom {
  display: none !important;
  margin-bottom: 1rem !important;
  padding-bottom: 0rem;
  border-bottom: solid 1px $c_gallery !important;
  margin-top: 0rem;
}

@include below-ipad-breakpoint {
  .t_table-product__row {
    position: relative !important;
    .t_table-product__col--stack {
      display: block;
      text-align: left !important;
      margin-bottom: 0.5rem;
      border-width: 0px;

      .t_table-product__title {
        width: 99%;
        display: block;
        margin-top: 1rem;
        margin-bottom: 0.5rem
      }

      .t_table-product__code {
        margin-top: 0rem;
        margin-bottom: 1rem;
      }
    }
  }


  .t_table-product__row--checkout {
    position: relative !important;
    .t_table-product__col--stack-thumb {
      float: right;
    }
    .t_table-product__col--stack {
      display: block;
      text-align: left !important;
      margin-bottom: 0.5rem;

      .t_table-product__title {
        width: 99%;
        display: block;
      }

      &-remove {
        display: none;
      }


      &-remove-item, &-border-bottom {
        display: block !important;
      }
    }
  }


  .t_table-product__code {
    color: $c_gray;
  }

  .t_table-product__col--stack-thumb {
    float: right;
  }


  .t_table-basic__col--stack {
    display: block !important;
  }

  .t_table-basic {
    tr:first-child td {
      width: auto;
    }
  }  

  .t_table-basic__col--stack-end {
    padding-bottom: 2rem;
  }

}

.t_table-basic--b-border {
  border-bottom: solid 1px $c_gallery;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}


.t_table-product__col--stack-thumb {
  width: 100px;
}

.t_table-product__border-none {
  border-width: 0px !important;
}

.t_table-product__row-info {
  padding: 1rem 0.3rem 1rem 0rem;
}