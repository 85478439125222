/*
Product Teaser - Without Listview feature (legacy sites)
*/

.filter__item {
    &.disable {
        opacity: 0.8;
        cursor: not-allowed;
        user-select: none;
    }

    &.disable * {
        user-select: none;
    }

    &.disable + * {
        display: none;
    }
}

.product-teaser-container__without-listview {
    .product-teaser {
        min-height: 382px;

        @include transition(all);

        overflow: hidden;
        background-color: $c_white;
        position: relative;
        margin-bottom: px2rem(30);
        cursor: pointer;

        &__add {
            @include transition(all, 3);
            position: absolute;
            top: 0.5rem;

            .icon {
                width: 15px;
                height: 15px;
                display: inline-block;
            }

            span.add-quote {
                opacity: 0;
            }

            span + span {
                @include transition(all);
                opacity: 0;
                margin-left: 30px;
            }
        }

        &__image {
            height: 250px;

            picture {
                width: 100%;
                height: 100%;
                position: relative;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
                padding: px2rem(15);
            }
        }

        &__body {
            padding: px2rem(35);

            h2 {
                font-weight: 600;
                margin-bottom: 7px;
            }
        }

        &__view-product {
            padding: px2rem(14);
            border-top: solid 1px $c_gallery;

            a {
                color: $c_cerulean;
            }
        }

        &.grid-view {
            .product-teaser__add {
                right: -74px;
            }

            .long-marketing-text {
                display: none;
            }
        }

        &-slide {
            width: px2rem(270);
            margin: 1rem auto;
        }
    }

    &.product-teaser-has-shadow {
        .product-teaser {
            box-shadow: 0px .1875rem 1.25rem rgba(0,0,0,0.05);
        }
    }

    @media (min-width: 768px) {
        .product-teaser {
            &:hover {
                outline: px2rem(4) $c_cerulean solid;

                .product-teaser__add {
                    right: px2rem(20);
                }

                span + span {
                    margin-left: 0px;
                    opacity: 1;
                }

                .product-teaser__view-product {
                    background-color: $c_cerulean;

                    a {
                        color: $c_white;
                    }
                }

                .product-teaser__image {
                    display: none;
                }

                .image-display {
                    display: block !important;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .product-teaser {
            min-height: 170px !important;
            max-height: 200px !important;
            display: block !important;
            margin-bottom: $grid-gutter-width / 2;

            &__add {
                display: none !important;
            }

            &__image {
                float: left !important;
                height: 180px !important;
                width: 45% !important;
                display: block !important;
            }

            &__body {
                padding: 10px 10px 10px 5px !important;
                margin-top: 33px !important;
                width: 55% !important;
                float: right !important;
                text-align: left !important;
            }

            &__view-product {
                bottom: 27px !important;
                width: 55% !important;
                right: 0px !important;
                text-align: left !important;
                border-top-width: 0px !important;
                padding: 10px 10px 10px 5px !important;
            }

            .row {
                margin-right: -$grid-gutter-width / 4;
                margin-left: -$grid-gutter-width / 4;

                > .col,
                > [class*="col-"] {
                    padding-right: $grid-gutter-width / 4;
                    padding-left: $grid-gutter-width / 4;
                }
            }
        }
    }
}

.product-teaser__checkbox-label {
    font-size: 0.9rem;
}