.section-checkout {
  background-color: $c_white;
  padding: 2rem 1.5rem 6rem 1.5rem;

  &.checkout-success {
    padding-bottom: 2rem !important;
    margin-bottom: 2rem;
  }
}

#checkout-review-container {
  .section-checkout {
    padding-bottom: 1.5rem;
  }

  .section-checkout::after {
    content: " ";
    border-bottom: 1px solid #e5e5e5;
    height: 1px;
    display: block;
    padding-top: 4rem;
  }

  .section-checkout:first-child::after {
    display: none;
  }

  .section-checkout:last-child::after {
    border-bottom-width: 0px;
  }
}