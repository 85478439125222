// ==========================================================================
// # Product application
// ==========================================================================

.product-application {
    a {
        position: relative;
        display: block;
        height: 278px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: #009ee2;
        box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.14);
        padding: 2rem;
        margin-bottom: 2rem;
    }

    a::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.45);
        z-index: 1;
        left: 0px;
        top: 0px;
    }

    a::after {
        content: "";
        background: url("/static/bundles/img/svg/arrow-right-small-white.svg")
            no-repeat right;
        width: 25px;
        height: 12px;
        display: inline-block;
        position: absolute;
        z-index: 2;
        bottom: 2rem;
        right: 32px;
    }

    a:hover {
        * {
            opacity: 0.8;
            @include transition(all);
        }

        &::after {
            right: 22px;
            @include transition(all);
        }
    }

    * {
        color: #ffffff;
        z-index: 2;
        position: relative;
    }

    h2 {
        font-size: 11px;
        margin-bottom: 4px;
    }

    h3 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    p {
        font-size: 16px;
        margin: 0px;
        font-weight: normal;
    }
}

@media only screen and (max-width: 767.5px) {
    .product-application {
        a {
            height: 300px;
        }

        h2 {
            font-size: 14px;
            margin-bottom: 1rem;
        }

        h3 {
            font-size: 20px;
            margin-bottom: 1rem;
        }

        p {
            font-size: 14px;
            line-height: 16px;
        }

        a::after {
            background: url("/static/bundles/img/svg/arrow-right-white.svg")
                no-repeat right;
            width: 40px;
            height: 15px;
        }
    }
}
