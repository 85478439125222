.order-history {

  &:not(:first-child) {
    margin-top: 2rem;

    @include phone {
      margin-top: 1rem;
    }
  }
  
  &__title {
    font-size: 1.3rem;
    font-weight: 600;
    border-bottom: solid 1px $c_border-light;
    padding-bottom: 1.3rem;

    &--is-lighter {
      font-weight: lighter;
    }

    @include phone {
      font-size: 1.1rem;
    }
    @include smallPhone {
      font-size: 1rem;
    }
  }

  &__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__text {
    line-height: 0;

    @include phone {
      font-size: 0.9rem;
    }

    &--right {
      @include below-ipad-breakpoint {
        transform: translateY(-0.5rem);
      }
    }
  }
  
}