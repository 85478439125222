// ==========================================================================
// # Product Landing Page
// ==========================================================================

// Header

.header {
    &__on-page-header,
    &__with-news {
        p {
            font-size: px2rem(14);
            font-weight: bold;
            line-height: px2rem(21);
        }

        a {
            font-size: px2rem(14) !important;
            font-weight: bold !important;
        }
    }

    &__with-news {
        h1 {
            font-weight: bold;
            margin-bottom: 2rem;
            max-width: 40vw;
            font-size: 3.2vw;
            line-height: 3.5vw;
        }
    }

    &__on-page-nav {
        position: absolute;
        z-index: 2;
        bottom: 0px;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        letter-spacing: 1px;

        nav {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            overflow-x: hidden;
            overflow-y: hidden;
            margin-top: -3px;
            max-height: 54px;
        }

        div[data-target] {
            display: inline-block;
            position: relative;
            padding: px2rem(21) 1rem;
            color: #fff;
            font-size: px2rem(13);
            font-weight: 600;
            cursor: pointer;
            text-transform: uppercase;
        }

        div[data-target] .index,
        div[data-target] .close,
        div[data-target] .goto {
            display: none;
        }

        .text {
            position: relative;
            padding: 0 px2rem(8);
        }

        .text::before {
            content: " ";
            display: block;
            position: absolute;
            top: -21px;
            left: 0px;
            width: 100%;
            height: 4px;
            background-color: transparent;
        }

        div[data-target]:hover {
            .text::before {
                background-color: #009ee2 !important;
            }
        }

        div[data-target].first {
            .text::before {
                background-color: #009ee2;
            }
        }

        &:hover div[data-target].first {
            .text::before {
                background-color: transparent;
            }
        }

        div[data-target].nav-title {
            display: none;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .header {
        &__on-page-nav {
            border-top-width: 0px;
            padding: 0px;

            nav {
                display: block;
                overflow-x: initial;
                max-height: 56px;
                padding: 0;
                padding-top: 1px;
            }

            div[data-target] {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1.3rem 2rem;
                border: 1px solid rgba(255, 255, 255, 0.4);
                border-bottom-width: 0px;
            }

            div[data-target]:hover {
                border-top: 1px solid rgba(255, 255, 255, 0.4);
            }

            div[data-target] .index {
                opacity: 0.8;
            }

            div[data-target] .index,
            div[data-target] .close,
            div[data-target] .goto {
                display: inline-block;
            }

            .text {
                padding: 0 px2rem(8);
            }

            .text::before {
                top: -23px;
            }

            .i_chevron-down-white {
                opacity: 1;
            }

            .i_close-white {
                width: 21px;
                height: 21px;
                background-repeat: no-repeat;
            }

            .i_chevron-down-white {
                width: 16px;
                height: 11px;
                background-repeat: no-repeat;
                background-position: 0px -5px;
            }

            nav.is-open {
                max-height: 1000px;
                background-image: url("/static/bundles/img/blue-overlay.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position-y: 1px;

                div[data-target]:hover {
                    background-color: rgba(101, 209, 255, 0.24);
                }

                .i_chevron-down-white,
                .i_close-white {
                    opacity: 0.5;
                }

                div[data-target]:hover .i_chevron-down-white,
                div[data-target]:hover .i_close-white {
                    opacity: 1;
                }

                .nav-title {
                    display: flex;
                    padding: 1.1rem 2rem;
                }

                .text::before {
                    display: none;
                }
            }
        }
    }
}

@include at(sm) {
    .header {
        &__on-page-header,
        &__with-news {
            .header__body {
                padding-left: 0 !important;
            }
        }
    }
}

// Article/Cards

.article-container {
    padding-top: 7.5rem;
    margin-bottom: 7.5rem;
}

.article-cards {
    .article-card {
        margin-left: auto;
        margin-right: auto;
    }

    .article-card > div {
        padding: 0 20px;
        margin-bottom: 40px;

        &:first-child,
        &:first-child > div {
            margin-left: auto;
        }

        &:last-child,
        &:last-child > div {
            margin-right: auto;
        }
    }

    &.two .article-card,
    &.three .article-card {
        max-width: 960px;
    }
}

@media (max-width: 1200px) {
    .article-cards {
        &.four {
            max-width: 100%;

            .article-card > div {
                &:nth-child(3),
                &:nth-child(3) > div {
                    margin-left: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 991.5px) {
    .article-cards {
        &.two,
        &.three {
            .article-card > div,
            .article-card > div > div {
                margin-left: auto !important;
                margin-right: auto !important;
            }
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .article-block {
        margin-top: 3.5rem;
    }

    .article-cards .article-card > div,
    .article-cards .article-card > div > div {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

@include at(sm) {
    .article-cards {
        .c_card-basic {
            max-width: initial !important;
        }
    }

    .article-container {
        padding-top: 0 !important;
    }

    .article-cards .article-card > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .article-card > div > div {
        padding: 2rem !important;
    }
}

// Tiles

.tiles {
    background-color: #fcfcfc;
    overflow-x: hidden;
    position: relative;

    .tiles_content {
        padding-left: 0;
        padding-right: 0;
    }

    p {
        margin-bottom: 2rem;
    }

    &_left-content,
    &_right-content {
        padding-left: 0;
        padding-right: 0;
        padding-top: px2rem(80);
        padding-bottom: px2rem(80);
    }

    &_left-content {
        margin-left: px2rem(100);
        margin-right: px2rem(80);
    }

    &_right-content {
        margin-right: px2rem(100);
        margin-left: px2rem(80);
    }

    &_image {
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 600px;
    }
}

@media (max-width: 1200px) {
    .tiles {
        &_image {
            min-height: 565px;
        }
    }
}

@media (max-width: 992px) {
    .tiles {
        &_left-content,
        &_right-content {
            padding-top: px2rem(80) * 0.75;
            padding-bottom: px2rem(80) * 0.75;
        }

        &_left-content {
            margin-left: px2rem(100) * 0.75;
            margin-right: px2rem(80) * 0.75;
        }

        &_right-content {
            margin-right: px2rem(100) * 0.75;
            margin-left: px2rem(80) * 0.75;
        }

        &_image {
            min-height: 437px;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .tiles {
        .tiles_content {
            padding-left: 15px;
            padding-right: 15px;
        }

        &_left-content,
        &_right-content {
            height: auto !important;
            margin: 3rem auto 5rem auto;
            padding: 0rem 1rem;
        }

        &_image {
            min-height: 376px;
        }
    }
}

@include at(sm) {
    .tiles {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .t_page-title-primary {
            margin-bottom: 1.5rem !important;
        }
    }
}

// Tiles (multi-link)

.tiles-multi {
    background-color: $c_grey-2;
    padding-top: 5rem !important;
    padding-bottom: 4rem;

    .tiles-multi_content {
        padding: 4rem 7rem 4rem 5rem;
    }

    .tiles-multi_content .t_page-title-primary {
        margin-bottom: 2rem;
    }

    .tiles-multi_content .t_generic-text--wysiwyg {
        margin-bottom: 4rem;
    }

    .tiles-multi_content .link--has-arrow:after {
        margin-left: 0;
        left: 10px;
    }

    &_images,
    &_images * {
        padding: 0;
        margin: 0;
        border: none;

        table,
        table * {
            width: 100%;
            border-spacing: 0px;
            border-collapse: collapse;
        }
    }

    &_image {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $c_white;
        font-weight: bold;
        font-size: px2rem(20);
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }

    &_image span {
        text-align: center;
        z-index: 1;
    }

    &_image:before {
        content: "";
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        opacity: 1;
        @include transition(all);
    }

    &_image[href]:hover:before {
        opacity: 0.5;
        @include transition(all);
    }

    &_image[href]:hover:after {
        right: 15px;
        @include transition(all);
    }

    &_image[href]:after {
        @include arrow($c_cerulean);
        @include transition(all);
        content: "";
        display: block;
        background-repeat: no-repeat;
        width: 2.30769em;
        height: 1em;
        margin-left: 1.5em;
        position: absolute;
        right: 22px;
        bottom: 15px;
    }

    &.two {
        > div {
            padding-left: 0;
            padding-right: 0;
        }

        .tiles-multi_images td {
            width: 100% !important;
        }

        .tiles-multi_image {
            height: 296px;
        }

        &.one {
            .tiles-multi_image {
                height: 565px;
            }
        }
    }

    &.two.tiles-right {
        .tiles-multi_images {
            padding: 0 0 0 15px;
        }
    }

    &.two.tiles-left {
        .tiles-multi_images {
            padding: 0 15px 0 0;
        }
    }

    &.four {
        .tiles-multi_images td {
            width: 50% !important;
        }

        .tiles-multi_image {
            height: 296px;
        }
    }

    &.six {
        .tiles-multi_images td {
            width: 33% !important;
        }

        .tiles-multi_image {
            height: 197px;
        }
    }
}

.tiles-header-copy {
    background-color: #F3F3F3;
    padding-top: 5rem;
    text-align: center;

    > * {
        max-width: 772px;
    }
}

@media only screen and (max-width: 991.5px) {
    .tiles-multi {
        padding: 2rem;
    }

    .tiles-multi > div {
        max-width: 100%;
    }

    .tiles-multi_content {
        padding: 4rem 2rem !important;
    }
}

@media only screen and (max-width: 767.5px) {
    .two .tiles-multi_images {
        padding: 0 15px;
    }

    .col-12 + .tiles-multi_images {
        order: -1;
    }

    &.two.tiles-left,
    &.two.tiles-right {
        .tiles-multi_images {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
}

@include at(sm) {
    .tiles-multi {
        padding: 1rem 1rem 3rem 1rem !important;

        .tiles-multi_content {
            padding: 3rem 1rem 4rem 1rem !important;
        }

        .tiles-multi_content .t_page-title-primary {
            margin-bottom: 1.5rem;
        }

        .tiles-multi_content .t_generic-text--wysiwyg {
            margin-bottom: 1.5rem;
        }

        .tiles-multi_image {
            font-size: px2rem(13);
        }

        &.two {
            .tiles-multi_image {
                height: 140px;
            }
        }

        &.four {
            .tiles-multi_image {
                height: 133px;
            }
        }

        &.six {
            .tiles-multi_image {
                height: 112px;
            }
        }
    }
}

// Content Blocks

.content-blocks {
    padding-top: 7rem;
    padding-bottom: 7rem;
    background-color: #f9f9f9;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 1.8rem;
        line-height: px2rem(38);
        font-family: Arial, sans-serif;
        font-weight: 700;
        color: #009ee2;
        margin-bottom: 2rem;
    }

    .call-out-text {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 1rem;
        }
    }
}

.call-out-text {
    color: #009ee2 !important;
    font-weight: bold !important;
}

@include at(sm) {
    .content-blocks {
        padding-top: 3rem !important;
        padding-bottom: 5rem !important;
    }
}

.content-blocks-type-two {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;

    .t_generic-text--wysiwyg {
        font-size: 14px;
        line-height: 24px;
    }

    .t_generic-text--wysiwyg.call-out-text {
        font-size: 24px;
        line-height: 36px;
    }
}

@media (max-width: 767.5px) {
    .content-blocks-type-two {
        .t_generic-text--wysiwyg {
            font-size: 16px;
            line-height: 24px;
            color: #4e4d5a;
        }

        .t_generic-text--wysiwyg.call-out-text {
            font-size: 16px;
            line-height: 21px;
        }
    }
}

// Article feed

.article-feed {
    background-color: #f9f9f9;

    table,
    table * {
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        vertical-align: top;
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-button-disabled {
        filter: grayscale(100%);
    }

    .swiper-slide {
        width: 281px !important;
        height: 410px !important;
        margin-right: 20px !important;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.22);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &.resource-page-category .swiper-slide {
        margin-right: 0 !important;
        width: 272px !important;
    }

    .swiper-slide:before {
        content: "";
        display: block;
        background-color: rgba(0, 0, 0, 0.45);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        opacity: 1;
        @include transition(all);
    }

    .swiper-slide * {
        color: $c_white;
        position: relative;
        z-index: 2;
    }

    .content {
        padding: 11rem 2rem 0 2rem;
    }

    .small {
        font-size: px2rem(12);
        margin-bottom: 1rem;
    }

    h3 {
        font-size: px2rem(18);
        font-weight: bold;
        line-height: px2rem(24);
    }

    .extract {
        height: 121px;
        overflow-y: hidden;
        margin-bottom: 1rem;
    }
    &.page-feed .extract {
        height: 160px;
    }

    .wysiwyg.extract {
        display: none;
    }
    &.page-feed .wysiwyg.extract {
        display: block;
        font-size: px2rem(15);
        line-height: px2rem(24);
        font-weight: normal;
        margin-top: px2rem(20);
    }

    .link {
        font-size: px2rem(16);
    }
    &.page-feed .link {
        font-size: px2rem(14);
        color: #009EE2;
    }

    .swiper-slide:hover {
        @include transition(all);
        background-image: none;

        &:before {
            background-color: $c_white;
            @include transition(all);
        }

        .content {
            padding: 2rem 2rem 0 2rem;
        }

        .small {
            color: $c_cerulean;
        }

        .extract {
            height: 265px;
        }

        h3 {
            color: $c_cerulean;
        }

        .wysiwyg.extract {
            color: $c_gray;
            display: block;
            font-weight: normal;
            font-size: 15px;
            line-height: px2rem(21);
            margin-top: 1rem;

            p {
                color: $c_gray;
            }
        }

        .link {
            color: $c_cerulean;
        }
    }

    .article-feed-dynamic-left::before {
        content: "";
        display: block;
        width: 1000000px;
        position: absolute;
        background-color: #f9f9f9;
        height: 100%;
        top: -25px;
        z-index: -1;
        right: 0;
    }
}

@media (min-width: 767.5px) {
    .article-feed {
        .swiper-button-prev {
            background-image: url(/static/bundles/img/svg/arrow-right-blue.svg) !important;
            transform: rotate(180deg) !important;
        }
        .swiper-button-next {
            background-image: url(/static/bundles/img/svg/arrow-right-blue.svg) !important;
            transform: rotate(0deg) !important;
        }

        .swiper-buttons {
            margin-left: auto;
            width: 200px;
        }

        .swiper-buttons > div {
            height: 20px;
            position: relative;

            * {
                width: 50px;
                height: 25px;
            }
        }

        &.article-feed-dynamic {
            padding-top: 5rem;
        }

        &.article-feed-dynamic .swiper-slide {
            width: 363px !important;
            height: 463px !important;
        }

        &.article-feed-dynamic .content {
            padding: 15rem 2rem 0 2rem;
        }

        &.article-feed-dynamic .swiper-slide:hover .extract {
            height: 329px;
        }

        .article-feed-dynamic-left {
            z-index: 2;
            background-color: #f9f9f9;
        }
    }

    .theme_dark {
        .article-feed {
            .swiper-button-prev {
                background-image: url(/static/bundles/img/svg/arrow-right-blue_dark.svg) !important;
            }
            .swiper-button-next {
                background-image: url(/static/bundles/img/svg/arrow-right-blue_dark.svg) !important;
            }
        }
    }
}

.page-feed {

    .swiper-slide:hover .extract {
        height: 304px;
    }
}

@media only screen and (max-width: 991.5px) {
    .article-feed-dynamic {
        &-left::before {
            display: none !important;
        }

        &-left {
            background-color: transparent !important;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .article-feed:not(.article-feed-dynamic) {
        .swiper-buttons > div {
            width: 50px;
            height: 25px;
            position: relative;
            transform: translateY(0px);

            * {
                width: 20px;
                height: 25px;
            }
        }

        .link--has-arrow:after {
            margin-left: 0.5em;
        }
    }

    .article-feed.article-feed-dynamic {
        padding-top: 3rem;

        .swiper-button-prev {
            background-image: url(/static/bundles/img/svg/arrow-right-blue.svg) !important;
            transform: rotate(180deg) !important;
        }
        .swiper-button-next {
            background-image: url(/static/bundles/img/svg/arrow-right-blue.svg) !important;
            transform: rotate(0deg) !important;
        }

        .swiper-buttons {
            margin-left: auto;
            width: 200px;
        }

        .swiper-buttons > div {
            height: 20px;
            position: relative;

            * {
                width: 50px;
                height: 25px;
            }
        }
    }
}

// Application feed

.application-feed {
    padding-top: 5rem !important;
    background-color: #009EE2;

    * {
        color: #fff !important;
    }

    &-left {
        z-index: 2;
        background-color: #009ee2;
    }

    &-left::before {
        content: "";
        display: block;
        width: 1000000px;
        position: absolute;
        background-color: #009ee2;
        height: 100%;
        top: -25px;
        z-index: -1;
        right: 0;
    }

    table,
    table * {
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        vertical-align: top;
    }

    .swiper-container {
        overflow: visible;
    }

    .swiper-button-disabled {
        filter: grayscale(100%);
    }

    .swiper-slide {
        width: 570px !important;
        height: 270px !important;
        margin-right: 30px !important;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
    }

    .swiper-slide a {
        width: 270px !important;
        height: 270px !important;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.22);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-end;
        background-color: #00405c;
    }

    .swiper-slide a:before {
        content: "";
        display: block;
        background-color: rgba(0, 16, 23, 0.45);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        opacity: 1;
    }

    .swiper-slide * {
        color: $c_white;
        position: relative;
        z-index: 2;
    }

    h3 {
        font-size: px2rem(18);
        font-weight: bold;
        line-height: px2rem(24);
        padding: 2rem 5rem 2rem 2rem;
        position: relative;
    }

    h3:after {
        content: "";
        background-image: url(/static/bundles/img/svg/white-chevron-right.svg);
        display: block;
        width: 15px;
        height: 15px;
        position: absolute;
        right: 27px;
        bottom: 36px;
    }

    .swiper-slide a:hover h3:after {
        @include transition(all);
        right: 20px;
    }

    .link {
        font-size: px2rem(16);
    }
}

@media (min-width : 991.5px) {
    .application-feed {
        h2 {
            padding-top: 5rem;
        }
    }
}

@media only screen and (max-width: 991.5px) {
    .application-feed {
        &-left {
            background-color: transparent !important;
        }

        &-left::before {
            display: none !important;
        }
    }
}

@media (min-width: 767.5px) {
    .application-feed {
        .swiper-button-prev {
            background-image: url(/static/bundles/img/svg/arrow-right-white.svg) !important;
            transform: rotate(180deg) !important;
        }
        .swiper-button-next {
            background-image: url(/static/bundles/img/svg/arrow-right-white.svg) !important;
            transform: rotate(0deg) !important;
        }

        .swiper-buttons {
            margin-left: auto;
            width: 200px;
        }

        .swiper-buttons > div {
            height: 20px;
            position: relative;

            * {
                width: 50px;
                height: 25px;
            }
        }

        .swiper-slide {
            width: 270px !important;
            height: 570px !important;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .application-feed {
        padding-top: 3rem !important;

        .swiper-button-prev {
            background-image: url(/static/bundles/img/svg/arrow-right-white.svg) !important;
            transform: rotate(180deg) !important;
        }
        .swiper-button-next {
            background-image: url(/static/bundles/img/svg/arrow-right-white.svg) !important;
            transform: rotate(0deg) !important;
        }

        .swiper-buttons {
            margin-left: auto;
            width: 200px;
        }

        .swiper-buttons > div {
            height: 20px;
            position: relative;

            * {
                width: 50px;
                height: 25px;
            }
        }
    }
}

// Product feed

.product-feed {
    margin-top: 7.5rem;
    padding-bottom: 1rem;

    .js_product-feed-primary-carousel .swiper-slide {
        margin-left: 15px !important;

        picture {
            width: 203px;
            height: 233px;
        }
    }

    .js_related-products-carousel .swiper-slide {
        margin-left: 5px !important;

        picture {
            width: 152px;
            height: 175px;
        }
    }

    .js_product-feed-primary-carousel .swiper-slide,
    .js_related-products-carousel .swiper-slide {
        width: 204px !important;
        height: 363px !important;
        padding: 2rem 1.5rem 1.5rem 1.5rem;
        margin-top: 15px !important;
        margin-right: 20px !important;

        picture {
            margin: auto;
        }

        .price {
            font-size: px2rem(18);
        }
    }

    .product-feed-intro {
        align-items: baseline;
    }

    .swiper-slide {
        @include transition(outline);
        background-color: $c_white;
        border: 1px solid #f6f6f6;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

        .content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }

        h3 {
            font-weight: bold;
            font-size: px2rem(18);
            text-align: center;
        }

        .key-facts {
            font-weight: normal;
            font-size: px2rem(14);
            line-height: px2rem(21);
            text-align: left;
            display: none;
            height: 230px;
            overflow: hidden;
        }

        .key-facts ul {
            padding-left: 15px;
        }

        .b_button-primary {
            padding: 0.5rem 2.5rem;
            margin-left: auto;
            margin-right: auto;
            font-weight: bold !important;
        }
    }

    .swiper-slide:hover {
        outline: px2rem(4) $c_cerulean solid;
        box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.29);

        picture {
            display: none;
        }

        .key-facts {
            display: block;
        }
    }

    .swiper-pagination {
        position: relative;
        cursor: pointer !important;
    }

    .swiper-pagination-bullet-active {
        background-color: $c_cerulean;
        border: 1px solid $c_cerulean;
    }

    .js_product-feed-secondary-carousel .swiper-slide {
        width: 174px !important;
        height: 256px !important;
        padding: 2rem 1.5rem 1.5rem 1.5rem;
        margin-top: 10px !important;
        margin-left: 15px !important;
        margin-right: 0px !important;

        picture {
            width: 170px;
            height: 123px;
        }

        .price {
            font-size: px2rem(16);
        }

        .key-facts {
            height: 129px;
        }
    }

    .product-feed-secondary {
        background-color: #f9f9f9;
        padding: 1rem 2rem 0 0;
        margin-bottom: px2rem(100);

        .link--has-arrow:after {
            margin-left: 15px;
        }

        .product-feed-secondary-intro > div {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            height: 100%;
            padding: 1rem 2rem;

            p {
                line-height: px2rem(21);
            }
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
            opacity: 0;
        }

        .swiper-button-prev {
            left: -15px;
        }

        .swiper-button-next {
            right: -15px;
        }
    }

    .swiper-button-disabled {
        filter: grayscale(100%);
    }

    .technical-info {
        color: #009ee2;
        margin-top: 1.5rem;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-size: px2rem(13);
    }

    .technical-info:hover {
        opacity: 0.7;
    }

    .technical-info::before {
        content: "";
        background-image: url("/static/bundles/img/svg/info.svg");
        display: inline-block;
        width: 13px;
        height: 17px;
        margin-right: 4px;
        margin-top: -2px;
    }

    .technical-info-empty {
        height: 39px;
    }
}

@media only screen and (max-width: 1199.5px) {
    .product-feed {
        .product-feed-secondary-intro {
            padding: 1rem 0rem 2rem 1rem !important;
        }

        .product-feed-secondary-intro > div {
            padding: 1rem !important;
        }
    }

}

@media (min-width: 1200.5px) {
    .page-feed .swiper-wrapper {
        display: grid;
        gap: 1rem
    }
    .page-feed .swiper-slide {
        width: 100% !important;
    }
}

@media only screen and (max-width: 991.5px) {
    .product-feed {
        .product-feed-secondary {
            padding: 2rem 0rem 1rem 0rem !important;
        }

        .product-feed-intro {
            align-items: initial;
        }

        .product-feed-secondary-intro {
            padding: 0 1rem !important;
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
            opacity: 0.35 !important;
        }

        .t_generic-text--wysiwyg {
            margin-bottom: 1rem !important;
        }

        .link {
            margin-bottom: 2rem;
        }

        .swiper-buttons {
            position: relative;
            width: 73px;
            margin-left: auto;
            margin-right: 0rem;
            margin-bottom: 3.5rem;
            margin-top: 2.5rem;
        }

        .product-feed-secondary .swiper-buttons {
            width: 23px;
            margin-bottom: 1.5rem;
            margin-top: 0.5rem;
            margin-right: 1.5rem;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .product-feed {
        margin-top: 3.5rem;

        .js_related-products-carousel .swiper-slide {
            margin-left: 5px !important;
            margin-right: 4px !important;
        }

        .product-feed-secondary {
            margin-bottom: 0;
        }

        .product-feed-intro .text-right {
            text-align: left !important;
        }

        .t_generic-text--wysiwyg {
            margin-bottom: 2rem !important;
        }
    }
}

@media only screen and (max-width: 575.5px) {
    .product-feed {
        .js_related-products-carousel .swiper-slide {
            margin-left: 15px !important;
            // width: calc(100vw - 113px) !important;
        }

        .swiper-container {
            overflow: visible;
        }

        .product-feed-secondary {
            overflow-x: hidden;
        }
    }
}

@include at(sm) {
    .product-feed {
        padding-bottom: 2rem !important;
    }

    .js_product-feed-primary-carousel,
    .js_related-products-carousel {
        margin-left: -15px !important;
    }

    .product-feed-secondary {
        margin-left: 0;
        margin-right: 0;
    }
}

// Download Docs

.download-doc {
    background-color: #fcfcfc;
    padding-top: px2rem(100);

    a {
        @include transition(all);
        display: flex;
        border-bottom: solid 1px $c_border-light;
        padding: 2.5rem 0;
        height: 100%;
    }

    a:hover {
        border-bottom-color: $c_cerulean;

        img {
            box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
        }
    }

    .download-list > div:nth-child(1) a,
    .download-list > div:nth-child(2) a {
        border-top: solid 1px $c_border-light;
    }

    .download-list {
        padding-bottom: 5rem;
        border-bottom: 1px solid #c3c3c3;
    }

    > div {
        padding-bottom: 5rem;
    }

    .left-col {
        margin-right: 2rem;
    }

    picture {
        width: 70px;
        height: 100px;
    }

    img {
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
        border: 1px solid #f6f6f6;
    }

    .right-col {
        overflow-x: hidden;
        position: relative;
    }

    h3 {
        font-size: px2rem(18);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .info,
    .download-msg {
        font-size: px2rem(13);
        font-weight: normal;
        color: $c_silver;
    }

    h3 {
        margin-bottom: 0.5rem;
    }

    .info {
        margin-bottom: 1.5rem;
    }

    .download-msg {
        position: relative;
    }

    .download-msg::after {
        content: "";
        background-image: url("/static/bundles/img/svg/pdf-blue.svg");
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        display: inline-block;
        margin-left: 38px;
    }
}

@media only screen and (max-width: 767.5px) {
    .download-doc {
        padding-top: 3rem !important;

        > div {
            padding-bottom: 0;
        }

        a {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .download-list {
            padding-bottom: 3rem;
            border-bottom-width: 0px;
        }

        .download-list > div:nth-child(2) a {
            border-top-width: 0px !important;
        }
    }
}

@include at(sm) {
    .download-doc {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

// FAQ

.faq-block {
    margin-top: 2rem;

    .faq-accordian__header {
        background-color: $c_white;
        color: $c_cerulean;
        font-weight: bold;
        padding: 2rem;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
        margin-bottom: 1rem;
        position: relative;
        z-index: 2;
    }

    .faq-accordian__header .icon {
        width: 28px;
        height: 9px;
    }

    .faq-accordian__body {
        background-color: #fcfcfc;
        margin-bottom: 1.5rem;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06);
        position: relative;
        z-index: 1;
        margin-top: -1rem;
    }

    .faq-accordian__body-inner {
        padding: 1rem;
        line-height: px2rem(21);
        padding: 1.5rem 2rem;
    }

    .faq-accordian__header.is-open .icon {
        background-image: url(/static/bundles/img/svg/close-thin.svg) !important;
        width: 21px !important;
        height: 21px !important;
    }

    .js_faq-load-more-button {
        font-size: px2rem(14);
        font-weight: bold;

        .icon {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0.5rem;
            width: 24px;
            height: 12px;
        }
    }

    .faq-hidden {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }

    .faq-show {
        transition: all 0.4s ease-in-out;
        max-height: initial !important;
        overflow: visible !important;
        opacity: 1;
    }
}

@media only screen and (max-width: 991.5px) {
    .faq-block {
        .col-lg-6.text-right {
            text-align: left !important;
        }

        .faq-intro {
            margin-bottom: 2rem !important;
        }

        .faq-middle-caption,
        .faq-middle-caption p {
            margin-bottom: 0 !important;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .faq-block {
        margin-top: 0;
    }
}

@include at(sm) {
    .faq-block {
        .faq-accordian {
            &:last-child {
                margin-bottom: px2rem(20);
            }
        }

        .faq-intro {
            margin-bottom: 1rem !important;
        }

        .faq-intro .t_page-title-primary {
            margin-bottom: 0 !important;
        }
    }
}

// Videos

.video-container {
    padding: 0;
    background-color: #000000;

    .video-container-overlay {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute !important;
        z-index: 0 !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.videos {
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
    display: flex;
    align-items: stretch;

    &.initialize {
        .video-preview > * {
            opacity: 0;
            visibility: hidden;
        }

        .video-preview .video-intro,
        .video-preview .video-intro * {
            opacity: 1;
            visibility: visible;
        }
    }

    .video-preview {
        flex: 1;
        position: relative;
        height: 80vh;
        max-height: px2rem(750);
    }

    &:not(.initialize) {
        .video-preview {
            background: black;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .video-preview-overlay {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute !important;
        z-index: 0 !important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .video-preview-overlay {
        display: none;
    }

    .video-preview > div {
        position: relative;
        z-index: 2;
    }

    .video-intro {
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    .i_big-play-btn {
        background-size: contain !important;
    }

    .video-intro:hover .i_big-play-btn {
        background-image: url(/static/bundles/img/svg/big-play-btn-green.svg) !important;
    }

    h2 {
        font-weight: bold !important;
        line-height: 30px !important;
        color: $c_white !important;
        max-width: 290px;
    }

    .copy {
        position: absolute;
        font-size: px2rem(24);
        left: 15px;
        top: 100px;
    }

    .start-video {
        position: absolute;
        bottom: 80px;
        left: 15px;
        display: flex;
    }

    .start-video .i_big-play-btn {
        width: 100px;
        height: 100px;
    }

    .start-video .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .start-video .title,
    .start-video .description {
        color: $c_white;
        font-size: px2rem(16);
        padding: 0 px2rem(130) 0 2rem;
    }

    .start-video .title {
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    .video-list-panel {
        width: 260px;
        background-color: rgba(134, 134, 134, 0.32);
        backdrop-filter: blur(3px);
        z-index: 2;
        position: relative;
    }

    .video-list-panel::after {
        content: "";
        background-color: rgba(134, 134, 134, 0.32);
        backdrop-filter: blur(3px);
        display: block;
        width: 1000000px;
        height: 100%;
        top: 0;
        position: absolute;
        right: -1000000px;
    }

    .video-list-panel .background-overlay {
        display: none;
    }

    .video-list-title {
        color: #ffffff;
        font-size: px2rem(18);
        font-weight: bold;
        text-align: center;
        height: 120px;
        padding-top: 60px;
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .swiper-container-fix {
        position: absolute;
        width: 100%;
        top: 120px;
        bottom: 85px;
        overflow: hidden;
    }

    .swiper-slide {
        width: 166px;
        height: auto !important;
        padding: 0 47px;
        margin-bottom: 20px !important;
        margin-top: 3px;
    }

    .swiper-slide img {
        box-shadow: 0px -1px 6px rgba(255, 255, 255, 0.11);
        cursor: pointer;
    }

    .video-teaser picture span {
        content: "";
        background-image: url(/static/bundles/img/svg/circle-play-white.svg);
        width: 23px;
        height: 23px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }

    .video-teaser picture {
        position: relative;
    }

    .video-teaser:hover picture span,
    .video-teaser.active picture span {
        background-image: url(/static/bundles/img/svg/circle-play-green.svg);
    }

    img[src="/static/bundles/img/svg/image-load.svg"]:not([data-ll-status="loaded"]) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: none;

        & + span {
            background-image: none;
            position: relative;
            background-color: #151515;
            height: 97px;
            width: 100%;
            transform: translate(0%, 0%);
            z-index: -1;
            left: 0;
            top: 0;
        }
    }

    .video-teaser.active picture {
        outline: 1px solid rgba(255, 255, 255, 0.37);
        outline-offset: 2px;
    }

    .video-info {
        display: none;
    }

    .swiper-buttons {
        position: absolute;
        bottom: 0;
        width: 260px;
        height: 85px;
    }

    .swiper-buttons > div {
        position: relative;
        width: 260px;
        height: 85px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        background-image: url(/static/bundles/img/svg/chevron-down-white-thin.svg) !important;
        position: absolute;
        width: 23px;
        height: 14px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .swiper-button-prev {
        // display: none;
        transform: rotate(180deg) translate(50%, 170%);
    }

    .swiper-button-disabled {
        display: none;
    }

    .video-teaser:before,
    .video-teaser.active:before {
        display: none;
    }

    .dummy {
        height: 50px !important;
    }
}

@media only screen and (max-width: 1100.5px) {
    .copy {
        padding-right: 135px;
    }
}

@media only screen and (max-width: 1080.5px) {
    .videos .video-intro {
        padding: 5rem 0;

        &:not(.initialize) {
            .video-preview {
                padding-bottom: 10rem;
            }
        }
    }
}

@media only screen and (max-width: 970.5px) {
    .videos {
        .video-intro {
            padding: 6rem 0;

            &:not(.initialize) {
                .video-preview {
                    padding-bottom: 12rem;
                }
            }

            .copy {
                top: 50px;
            }

            .start-video {
                bottom: 50px;
            }
        }

        .video-list-title {
            height: 90px;
            padding-top: 40px;
        }

        .swiper-container-fix {
            top: 90px;
            bottom: 80px;
        }

        .swiper-buttons {
            height: 80px;
        }
    }
}

@media only screen and (max-width: 900.5px) {
    .videos {
        .copy {
            top: 35px;
        }

        .start-video {
            flex-direction: column;
        }

        .start-video .title,
        .start-video .description {
            padding: 0 3rem 0 0;
        }

        .start-video .i_big-play-btn {
            width: 64px;
            height: 64px;
            margin-bottom: 1rem;
        }
    }
}

@media (min-width: 790.5px) {
    .videos {
        .video-preview {
            background-image: none !important;
        }
    }
}

@media only screen and (max-width: 790.5px) {

    .video-container {
        .video-container-overlay {
            display: none !important;
        }
    }

    .videos {
        display: block !important;
        max-width: initial;

        &.initialize {
            .plyr {
                display: none;
            }
        }

        &:not(.initialize) {
            .video-preview,
            .video-intro {
                transition: min-height 1s ease;
                min-height: 0 !important;
            }
        }

        .copy {
            left: 0 !important;
            top: 0 !important;
            margin: 3rem 1rem !important;
            padding: 0 !important;
        }

        .start-video {
            display: block;
            left: 0 !important;
            bottom: 0 !important;
        }

        .video-intro {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-evenly;
            padding: 0 !important;
        }

        .video-preview,
        .video-intro {
            min-height: 75vh !important;
        }

        .video-preview {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            box-shadow: 0px 10px 13px -7px #000000,
                9px 10px 11px 10px rgba(0, 0, 0, 0);
        }

        .video-preview-overlay {
            display: block;
        }

        &:not(.initialize) {
            .video-preview {
                padding-bottom: 0;
                background-image: none !important;
                background-color: #000000 !important;
            }
        }

        .start-video .i_big-play-btn {
            width: 65px !important;
            height: 65px !important;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }

        .start-video .title {
            margin-top: 0 !important;
        }

        .start-video .description {
            margin-bottom: 3rem;
        }

        .video-list-panel {
            width: 100% !important;
        }

        .video-list-title {
            position: relative;
            height: auto !important;
            text-align: left !important;
            display: block !important;
            padding: 2.5rem 1rem 1.5rem 1rem !important;
        }

        .start-video .title,
        .start-video .description {
            padding: 0 1rem !important;
        }

        .swiper-container-fix {
            position: relative !important;
            height: auto !important;
            max-height: 400px !important;
            top: initial !important;
            bottom: initial !important;
            width: calc(100% - 1.5rem) !important;
        }

        .swiper-slide {
            padding: 0 1rem !important;
            width: 100% !important;
        }

        .video-list-panel {
            position: relative;
        }

        .video-list-panel .background-overlay {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.9;
            background-color: #636375;
        }

        .js_video-teaser {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 15px;
        }

        h3 {
            color: $c_white;
            font-size: px2rem(16);
            font-weight: bold;
            line-height: 24px;
            margin: 0 0 0 1rem;
        }

        h3 span {
            font-weight: normal;
        }

        .video-teaser picture {
            width: 133px !important;
        }

        .video-info {
            display: block !important;
            max-height: 74px;
            overflow: hidden;
        }

        .swiper-buttons,
        .swiper-buttons > div {
            position: relative !important;
            width: 100% !important;
        }

        .swiper-buttons {
            height: 62px !important;
        }

        .swiper-buttons::before {
            content: "";
            display: block;
            height: 1px;
            width: calc(100% - 30px);
            background-color: #ffffff;
            position: absolute;
            top: 1px;
            left: 15px;
            opacity: 0.4;
        }

        .swiper-buttons > div {
            height: 100% !important;
        }

        .swiper-button-prev:not(.swiper-button-disabled),
        .swiper-button-prev:not(.swiper-button-disabled) + .swiper-button-next {
            top: 40px !important;
        }
    }
}

/* 
    Testimonials
*/

.testimonials {
    background-color: #009ee2;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 6rem;

    .testimonial {
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .testimonial-container {
        max-width: 662px;
        margin-left: auto;
        margin-right: auto;
    }

    .testimonial-text {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 2rem;
    }

    .testimonial-client {
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 1px;
    }

    .swiper-pagination-bullets {
        bottom: 60px;
    }

    .swiper-pagination-bullet {
        border: 1px solid #ffffff;
        margin: 0 0.65rem !important;
    }

    .swiper-pagination-bullet-active {
        background-color: #ffffff;
    }
}

@media (max-width: 767.5px) {
    .testimonials {
        .testimonial-container {
            padding: 0 1rem;
        }

        .testimonial-text {
            font-size: 18px;
        }

        .swiper-pagination-bullets {
            bottom: 45px;
        }
    }
}

.pimcore_area_website-snippet > section > div > div > div > .u_mb-4 {
    margin-bottom: 0px !important;
}

.pimcore_area_website-snippet > section,
.pimcore_area_website-multi-cta > section {
    padding-top: 7rem;
    padding-bottom: 7rem;
}

@media (max-width: 767.5px) {
    .pimcore_area_website-snippet > section,
    .pimcore_area_website-multi-cta > section {
        padding-top: 4rem;
        padding-bottom: 3rem;
    }
}

/* 
    Map Banner
*/

.map-banner {
    padding-top: 5rem;
    background-color: #009EE2;
    overflow-x: hidden;

    * {
        color: #fff !important;
    }

    .map-banner-image {
        padding-bottom: 1rem;
        padding-right: 0;
    }

    .map-banner-image a {
        display: block;
        background-image: url(/static/bundles/img/svg/map.svg);
        background-size: cover;
        background-repeat: no-repeat;
    }
}

@media (max-width: 991.5px) {
    .map-banner {
        .map-banner-image a {
            margin-left: auto;
            margin-right: auto;
            background-size: contain;
            width: 100% !important;
        }
    }
}

@media (max-width: 512.5px) {
    .map-banner {
        .map-banner-image a {
            background-size: cover;
            background-position: left;
            height: 340px !important;
            width: auto !important;
        }
    }

    .pimcore_area_website-map-banner .container {
        padding-right: 0;
    }
}

/* 
    Vertical Tab Box
*/

.vertical-tab-box {
    padding-top: 5rem;
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;

    &-container {
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.40);
        width: 100%;
        height: 100%;
    }

    h2 {
        margin-top: 2rem;
        padding-right: 3rem !important;
    }

    &-left, &-left a, &-left > div {
        width: 100%;
        height: 100%;
    }

    &-left a, &-left > div {
        display: block;
        position: relative;
        background-color: #000000;
    }

    &-left picture {
        position: absolute;
        top: 0; 
        background-size: cover;
        z-index: 0;
        width:100%;
        height:100%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .desktop-show {
        display: block !important;
    }

    .mobile-show {
        display: none !important;
    }

    &-title {
        position: absolute;
        width: 100%;
        z-index: 1;
        bottom: 0;
        padding: 0 0 2.7rem 3rem;
        font-size: px2rem(38);
        color: #fff;
        font-weight: bold;
    }

    &.vertical-tab-box-type-two &-title {
        bottom: initial;
        padding: 5rem 0 2.7rem 3rem;
    }

    &-title > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding-bottom: 0.3rem;
        position: relative;
    }
    &.vertical-tab-box-type-two &-title > div {
        white-space: initial;
        overflow: initial;
        width: 60%;
    }
    &.vertical-tab-box-type-two &-title > div:nth-child(4) {
        position: absolute;
        top: 81px;
        margin-left: 3rem;

        &::before {
            display: none;
        }

        span {
            display: block;
        }

        span:nth-child(2) {
            font-size: px2rem(16);
            font-weight: normal;
            line-height: px2rem(20);
            margin-top: 2rem;
        }
    }

    &-title > div::before {
        content: "";
        display: inline-block;
        height: 28px;
        width: 3px;
        background-color: #ffffff;
        opacity: 0.6;
        margin-right: 2.5rem;
    }

    &-title > div.active::before {
        background-color: #009EE2;
        opacity: 1;
    }

    &-title > div:last-child:after {
        content: "";
        display: inline-block;
        background-image: url(/static/bundles/img/svg/menu-right-white.svg) !important;
        width: 13px;
        height: 22px;
        background-repeat: no-repeat;
        background-size: contain;
        padding-left: 4rem;
        background-position: right;
    }
    &.vertical-tab-box-type-two &-title > div:last-child:after {
        display: none;
    }

    &-right {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: stretch;
        justify-content: space-between;
        min-width: 215px;
        width: 22%;
    }

    &-right > div:not(.vertical-tab-box-select-bar){
        width: 100%;
        height: 33.3333%;
        min-height: 214px;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        justify-content: space-between;
    }

    &-right .js_tab-click {
        cursor: pointer;
    }

    &-select-bar {
        position: absolute;
        background-color: #ffffff;
        width: 11px;
        padding: 0;
        height: 33.3333%;
        min-height: 214px;
        top: 0px;
        left: -11px;
        border: 1px solid #cccccc;
        @include transition(all);
    }

    &-tab1 {
        background-color: #4E4D5A;
        color: #ffffff !important;
    }

    &-tab1 a {
        color: #ffffff !important;
    }

    &-tab2 {
        background-color: #009EE2;
        color: #ffffff !important;
    }

    &-tab2 a {
        color: #ffffff !important;
    }

    &-tab3 {
        background-color: #ECEBEA;
        color: #4E4D5A !important;
    }

    &-tab3 a {
        color: #4E4D5A !important;
    }

    &-tab1, &-tab2, &-tab3 {
        font-size: px2rem(18);
        font-weight: bold;
    }

    &-tab1 a, &-tab2 a, &-tab3 a {
        font-size: px2rem(13);
        font-weight: bold;
    }

    &-text > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        margin-bottom: 0.4rem;
    }
    &.vertical-tab-box-type-two &-text > div:nth-child(2) {
        font-size: px2rem(14) !important;
        white-space: initial;
        font-weight: normal;
        line-height: px2rem(20);
    }

    &-right .link {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        position: relative;
        padding-right: 10px;
    }

    &-tab3 .link--pop:after {
        background-image: url(/static/bundles/img/svg/menu-right-blue.svg) !important;
    }

    &-tab1 .link--pop:after, &-tab2 .link--pop:after {
        background-image: url(/static/bundles/img/svg/menu-right-white.svg) !important;
    }

    &-right .link--pop:after {
        position: absolute;
        width: 7px;
        height: 11px;
        background-size: cover;
        position: absolute;
        left: calc(100% - 30px);
        top: 1px;
    }

    &-footer {
        width: 100%;
        height: 5rem;
        margin-top: -4rem;
    }

    &.vertical-tab-box-type-two &-footer {
        margin-top: -7rem;
        height: 13rem;
    }

    &.vertical-tab-box-type-two .info img {
        max-width: 189px;
        margin-top: 6rem;
    }
}

@media only screen and (max-width: 991.5px) {
    .vertical-tab-box {
        &.vertical-tab-box-type-two .info img {
            margin-top: 0 !important;
            margin-bottom: 5rem;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .vertical-tab-box {
        &.vertical-tab-box-type-two .info img {
            margin-top: 4rem !important;
            margin-bottom: 5rem;
        }

        &.vertical-tab-box-type-two .vertical-tab-box-title {
            padding: initial;
            bottom: 1rem;
            left: 2rem;

            span {
                display: none !important;
            }

            & > div::before {
                width: 32px;
                margin-right: 0;
            }
        }

        &.vertical-tab-box-type-two {
            .vertical-tab-box-right > div {
                position: relative;
            }
            .vertical-tab-box-right > div.js_tab-click:after {
                content: "";
                background-image: url(/static/bundles/img/svg/menu-right-white.svg) !important;
                width: 7px;
                height: 11px;
                background-size: cover;
                position: absolute;
                bottom: 2rem;
                right: 2rem;
            }
        }
    }
}

@media (min-width: 767.5px) {
    .vertical-tab-box {
        .container {
            padding-right: 0;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .vertical-tab-box {
        padding-top: 1rem;

        h2 {
            font-size: px2rem(18) !important;
            line-height: px2rem(27);
        }

        &-container {
            flex-direction: column;
            width: 100% !important;
            height: 100% !important;
            box-shadow: none;
        }

        &-container-parent {
            width: 926px;
            overflow-x: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            padding-right: 0;
        }

        &-container-parent::-webkit-scrollbar {
            display: none;
        }

        &-left {
            display: flex;
            width: 926px;
            gap: 10px;
        }

        &-left a[data-tabindex], &-left > div[data-tabindex] {
            display: block !important;
            width: 302px;
            height: 243px;
        }

        .desktop-show {
            display: none !important;
        }

        .mobile-show {
            display: block !important;
        }

        &-title {
            display: flex;
            width: 181px;
            padding: 0 0 1.7rem 2rem;
        }

        &-title span {
            display: none;
        }

        &-title > div:last-child:after {
            display: none;
        }

        &-title >div::before {
            height: 3px;
            width: 28px;
        }

        &-right {
            flex-direction: row;
            width: 926px;
            gap: 10px;
        }

        &-right > div:not(.vertical-tab-box-select-bar){
            width: 302px;
            height: 232px;
        }

        &-select-bar {
            display: none !important;
        }

        &-text > div {
            width: 238px;
        }

        &-right .link {
            width: 238px;
        }

        &-right .link--pop:after {
            left: 211px;
        }
    }
}

.vertical-tab-box-type-two .vertical-tab-box-footer {
    background-color: #009ee2;
}

.page-feed {
    background-color: #009ee2;

    .swiper-buttons > div {
        filter: brightness(0) invert(1);
    }

    .page-header-copy {
        text-align: center;
        max-width: 772px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5rem;

        p {
            color: #fff;
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .page-feed {
        .swiper-button-prev, .swiper-button-next {
            background-image: url(/static/bundles/img/svg/arrow-right-blue.svg) !important;
        }

        .swiper-button-prev {
            transform: rotate(180deg) !important;
            left: -27px;
        }
    }
}

.invert-cta {
    max-width: 100%;
    background-color: #009ee2;
    h2, p {
        color: #fff;
    }
}

body:not(.theme_dark).invert-cta {
    .b_button-primary {
        background-color: #8BC34A !important;

        &:hover {
            opacity: 0.8;
        }
    }
}

.theme_dark {
    .vertical-tab-box-type-two .vertical-tab-box-tab1 {
        background-color: #0065A1;
        color: #ffffff !important;
    }
    .vertical-tab-box-type-two .vertical-tab-box-tab2 {
        background-color: #122632;
        color: #ffffff !important;
    }
    .vertical-tab-box-type-two .vertical-tab-box-tab3 {
        background-color: #009EE2;
        color: #ffffff !important;
    }
    .vertical-tab-box-type-two .vertical-tab-box-tab3 a {
        color: #ffffff !important;
    }
    .vertical-tab-box-type-two .vertical-tab-box-footer {
        background-color: #003A60;
    }
    .page-feed {
        background-color: #003A60;
    }
    .page-feed .swiper-slide:before {
        background-color: rgba(0, 0, 0, 0.67);
        @include transition(all);
    }
    .page-feed .swiper-slide:hover:before {
        background-color: #ffffff;
        @include transition(all);
    }
    .invert-cta {
        background-color: #003A60;
    }
    .tiles-multi.invert-tiles {
        background-color: #122632;
        .tiles-multi, .bg-white {
            background-color: #122632;
        }
        h3, .wysiwyg {
            color: #ffffff;
        }
        .tiles-multi_images td {
            padding: 9px;
        }
        .tiles-multi_image[href]:after {
            filter: brightness(0) invert(1);
        }
    }

    .article-card {
        .c_card-basic {
            background-color: #F3F3F3;
            text-align: center;

            h3 {
                font-size: px2rem(18);
                margin: 2rem 0;
            }

            .wysiwyg {
                font-size: px2rem(14);
            }

            p {
                text-align: center !important;
            }
        }
    }

    .pimcore_area_website-multi-tiles .tiles-multi:not(.invert-tiles) section:nth-child(even) {
        .container {
            background-color: #F3F3F3 !important;
        }
    }
    .pimcore_area_website-multi-tiles section:not(.invert-tiles):nth-child(odd) {
        background-color: #fff;
    }

    .pimcore_area_website-cta + .pimcore_area_website-multi-tiles > section:not(.invert-tiles) {
        background-color: #F3F3F3 !important;
    }

    .article-card .c_card-basic {
        box-shadow: none;
    }

    .header-info.show-drop-arrow {
        .header__on-page-nav div[data-target] {
            display: none;
        }
    }

    .b_button-primary,
    .header__on-page-nav div[data-target]:hover .text::before,
    .header__on-page-nav div[data-target].first .text::before {
        background-color: #0065A1 !important;
    }

    .b_button-primary:hover {
        background-color: #005d94 !important;
    } 

    .call-out-text, 
    .atomic-section-title-medium, 
    .t_color--highlight, 
    .t_page-title-primary,
    .article-feed .swiper-slide:hover .link,
    .faq-block .faq-accordian__header {
        color: #0065A1 !important;
    }

    a.t_color--highligh:hover,
    .article-feed .swiper-slide:hover .link:hover {
        color: #005d94 !important;
    }

    .link--pop:after, .link--pop:before {
        @include arrow(#0065A1);
    }

    .product-feed .swiper-slide:hover {
        outline: 0.25rem #0065A1 solid;
    }

    .i_big-play-btn {
        background-image: url(/static/bundles/img/svg/big-play-btn_dark.svg);
    }

    .faq-accordian__header:hover {
        outline: 0.25rem #0065A1 solid;
    }

    .i_arrow-right-blue {
        background-image: url(/static/bundles/img/svg/arrow-right-blue_dark.svg);
    }

    .faq-block .faq-accordian__header.is-open .icon {
        background-image: url(/static/bundles/img/svg/close-thin_dark.svg) !important;
    }

    .product-feed .swiper-pagination-bullet-active {
        background-color: #0065A1;
        border: 1px solid #0065A1;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%230065A1'%2F%3E%3C%2Fsvg%3E");
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%230065A1'%2F%3E%3C%2Fsvg%3E");
    }

    .website-cta.invert-cta h2,
    .tiles-multi.invert-tiles .t_page-title-primary,
    .page-feed .atomic-section-title-medium {
        color: #ffffff !important;
    }

    .article-feed .swiper-slide:hover h3 {
        color: #0065A1;
    }

    .header--slim .header-info p, .header--slim .header-info-slim p {
        font-size: px2rem(15);
        font-weight: 400;
    }
}

@media (max-width: 767.5px) {
    .article-feed {
        .article-nav {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .theme_dark {
        .article-feed {

            .swiper-button-prev, .swiper-button-next {
                background-image: url(/static/bundles/img/svg/arrow-right-blue_dark.svg) !important;
            }
    
            .swiper-button-prev {
                transform: rotate(180deg) !important;
                left: -27px;
            }
        }
    }
}

@include at(sm) {
    .theme_dark {
        #mainHeaderBlock {
            height: calc(100vh - 77px);

            .b_button-primary {
                width: auto;
                padding-left: 3rem !important;
                padding-right: 3rem !important;
            }
        }
    }

    .vertical-tab-box-type-two {
        margin-top: 0.5rem;
    }

    .vertical-tab-box-type-two .mobile-show {
        margin-top: 0 !important;
    }

    .vertical-tab-box-type-two .desktop-slideshow {
        display: none;
    }
}

@include at(sm) {
    .header__with-news,
    .pimcore_area_website-videos,
    .vertical-tab-box,
    .application-feed,
    .article-feed-dynamic,
    .map-banner {
        .container {
            max-width: initial;
        }
    }

    .header__with-news {
        h1 {
            max-width: 362px;
        }
    }
}

.a2a_menu.a2a_full.a2a_localize {
    z-index: 1999999999;
}