/*
Header.
*/

.header {
	position: relative;
	height: px2rem(726);


	&__image,
    &__map {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&__body {
		color: $c_white;
	}

	&__nav {
		position: relative;
	}

	&--simple {
		height: auto;
	}

	&--slim {
		height: auto;

		.container {
			z-index: 1;
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background-color: #000000;
			z-index: 0;
			position: absolute;
			top: 0px;
		}

		&.add-blue-backdrop::before {
			background-color: #007eb5;
		}

		.header-info {
			padding-top: 7rem;
			padding-bottom: 11rem;
		}
		.header-info.show-drop-arrow:after {
			content: "";
			background-image: url(/static/bundles/img/svg/chevron-down-white-thin.svg) !important;
			position: absolute;
			width: 23px;
			height: 14px;
			left: 50%;
			transform: translate(-50%, -50%);
			bottom: 15px;
			background-repeat: no-repeat;
		}

		.header-info-slim {
			padding-top: 2rem;
			padding-bottom: 7rem;
		}

		.header-info p,
		.header-info-slim p {
			line-height: 1.7rem;
			margin-bottom: 36px;
		}

		.header-info p a {
			color: #009EE2;
		}

		@media only screen and (max-width : 991.5px) {
			.header-info {
				padding-top: 2rem;
				padding-bottom: 10rem;
			}

			.header-info-slim {
				padding-top: 2rem;
				padding-bottom: 7rem;
			}
		}
	}

	&--home {

		.header__body {
			margin-top: 13.5rem;
		}
	}

	&__carousel {
		z-index: 0;
	}

	&-carousel {
		&__slide {
			overflow: hidden;
		}

		&__pagination,
		.swiper-container-horizontal > &__pagination {
			bottom: px2rem(90);
		}
	}
}

.header--slim .breadcrumb-item,
.header--slim .breadcrumb-item * {
	color: #ffffff !important;
}

.banner-map .header {
    height: 85vh;
    min-height: 400px;
}

.banner-map .point-of-contact-map {
	height: calc(100vh - 177px);
	min-height: initial !important;
}

@media only screen and (max-width : 991.5px) {
	.banner-map .point-of-contact-map {
		height: calc(100vh - 265px);
	}
}

@media only screen and (max-width: 767.5px) {
	.banner-map .point-of-contact-map {
		height: calc(100vh - 186px);
	}
}


@include at(md) {
	.header {
		&__nav {
			padding-bottom: px2rem(75);
		}
	}
}



@include at(sm) {
	.header--slim p {
		line-height: 21px !important;
	}
}


@include at(sm) {
	.header-nav-container {
		position: static;
	}

	.pimcore_area_website-header-with-news {
		margin-top: -5rem;

		h1 {
			padding-right: 2rem;
		}
	}

	.header {
		padding-top: 8rem;

		&__nav {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding-top: 0;
			z-index: 10;
			padding-bottom: 0;
		}

		&--home {
			.header__body {
				margin-top: 10.5rem;
			}
		}

		&--has-featured-article {
			margin-bottom: px2rem(220);
		}

		&--news {
			height: px2rem(400);
		}
	}
}

/* 
	Header Type Three
*/

.header-type-three  {
	padding-bottom: 11rem !important;

	h1 {
		font-size: 38px !important;
		line-height: 48px !important;
		margin-top: 5rem;
	}

	p {
		font-size: 14px !important;
		line-height: 21px !important;
		font-weight: normal !important;
	}
}


@media (max-width : 767.5px) {
	.header-type-three  {
		h1 {
			font-size: 32px !important;
			line-height: 48px !important;
			margin-top: 0rem;
		}
	}
}

.pimcore_area_website-header-type-two .header__body p a {
	color: #ffffff;
	text-decoration: underline;
}

.header__with-news.header-show-no-news {
	padding-bottom: 3rem;
}

.header__with-news.header-show-news {
	padding-bottom: 0;
}

.header-scroll-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	color: #ffffff !important;

	.header-scroll {
		display: flex;
		align-items: center;
    	gap: 1rem;
		font-size: px2rem(16);
		font-weight: bold;
	}

	.header-scroll::after {
		content: "";
		background-image: url(/static/bundles/img/svg/scroll.svg) !important;
		background-repeat: no-repeat;
		width: 26px;
		height: 38px;
		display: block;
	}
} 

@media (max-width : 767.5px) {
	.header-scroll span {
		display: none;
	}
}

.header__with-news .header-news-carousel {
	position: relative;
	z-index: 1;
	padding-left: 0;
    padding-right: 0;

	+ .header-scroll-global {
		display: none;
	}

	.header-news-carousel-container {
		background-color: rgba(0, 158, 226, 0.19);
		opacity: 1;
		backdrop-filter: blur(6px);
		padding-top: 2rem;
		padding-bottom: 1.5rem;
	}

	* {
		color: #ffffff !important;
	}

	.swiper-slide {
		opacity: 0.8;
		height: 141px;
		position: relative;
	}

	.swiper-slide::before {
		content: "";
		background-image: url(/static/bundles/img/svg/arrow-right-white.svg) !important;
		background-repeat: no-repeat;
		width: 41px;
		height: 13px;
		display: block;
		position: absolute;
		bottom: -14px;
	}

	.swiper-slide::after {
		content: "";
		border-top: 1px solid rgba(255, 255, 255, 0.44);
		position: absolute;
		display: block;
		bottom: -31px;
		width: 100%;
		height: 1px;
	}

	.link--light {
		font-size: px2rem(16) !important;
	}

	h3 {
		font-size: px2rem(12);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: 1rem;
		font-weight: bold;
	}

	.extract {
		font-size: px2rem(16);
		line-height: px2rem(24);
	}

	.swiper-button-next {
		background-image: url(/static/bundles/img/svg/white-chevron-right.svg);
		width: 100px;
		height: 100%;
		background-size: 20px 20px;
		right: -85px;
		background-position-x: left;
	}

	.swiper-button-prev {
		background-image: url(/static/bundles/img/svg/white-chevron-right.svg);
		transform: translateY(-50%) rotate(180deg);
		width: 100px;
		height: 100%;
		background-size: 20px 20px;
		background-position-x: left;
		left: -85px;
	}
}

@media (max-width : 766.5px) {
	.header__with-news .header-info-slim {
		h1 {
			font-size: px2rem(28);
			line-height: px2rem(34);
		}
	}

	.header__with-news .header-news-carousel {
		display: none;

		+ .header-scroll-global {
			display: flex;
			padding-bottom: 2rem;
		}
	}
}

.header__with-news {
	display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(100vh - 11rem);
    justify-content: space-between;

	.container.header-info-slim {
		margin-top: auto;
		margin-bottom: auto;
		padding-top: 7rem;
    	padding-bottom: 7rem;
	}
}

@include at(sm) {
	.header__with-news {
		.container.header-info-slim {
			padding-top: 2rem;
			padding-bottom: 1rem;
		}
	}
}