// ==========================================================================
// # Path tiles
// ==========================================================================

.path-tiles {
    position: relative;
    z-index: 0;
    
    a,
    div[href] {
        position: relative;
        display: block;
        height: 335px;
        overflow: hidden;

        background-color: #009ee2;
        box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.14);
        @include transition(all);
    }

    a .image-bg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 0;
        transform: scale(1);
        transition: all 2.5s ease-out;
    }

    a::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    a:hover {
        box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.24);
        @include transition(all);

        .image-bg {
            transform: scale(1.1);
            transition: all 2.5s ease-out;
        }
    }

    .container {
        padding: 0 22px;
    }

    .col-12 {
        padding: 8px;
    }

    h2,
    p {
        color: #ffffff;
        font-weight: bold;
        z-index: 2;
    }

    h2 {
        font-size: 21px;
        line-height: 28px;
        padding: 2rem 7rem 2rem 2rem;
        position: relative;
    }

    h2::after {
        content: "";
        background: url("/static/bundles/img/svg/arrow-right-white.svg");
        background-repeat: no-repeat !important;
        background-position: right !important;
        width: 60px;
        height: 23px;
        display: inline-block;
        position: absolute;
        right: 32px;
        top: 36px;
    }

    p {
        font-size: 14px;
        position: absolute;
        top: 269px;
        padding: 0 2rem;
        overflow: hidden;
        height: 44px;
        line-height: 21px;
    }
}

@media only screen and (max-width : 991.5px) {
    .path-tiles {
        a,
        div[href] {
            height: 300px;
        }
    
        h2 {
            font-size: 20px;
            padding: 2rem 5rem 2rem 1.5rem
        }

        h2::after {
            background: url("/static/bundles/img/svg/arrow-right-small-white-2.svg");
            width: 40px;
            height: 14px;
            right: 22px;
        }

        p {
            height: auto;
            top: 228px;
            padding: 0 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
        }

        a:hover {
            h2::after {
                right: 10px;
            }
        }
    }
}

@media only screen and (min-width: 767.5px) {
    .path-tiles {
        padding-bottom: 1.5rem;
    }
}