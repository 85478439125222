/*
Add To Quote.
*/

$transitionMultiplier: 2;

$objName: '.add-to-quote-slider';

#{$objName} {
	@include transition(transform, $transitionMultiplier);

	position: fixed;
	top: 0;
	left: 0;
	width: 200vw;
	height: 100%;
	z-index: 100;
	display: flex;

	transform: translateX(50%);

	&.step-1 {
		transform: translateX(25%);
	}

	&.step-2 {
		transform: translateX(0);

		#{$objName}__continue-button {
			opacity: 0;
		}
	}

	&.step-3 {
		transform: translateX(-50%);
	}

	&__continue-button {
		@include transition(opacity);
		opacity: 1
	}

	&__slide {
		width: 25%;
		background: $c_white;
		box-shadow: 0px .1875rem 1.25rem rgba(0,0,0,0.05);
		max-height: 100vh;
		overflow-y: scroll;

		&:nth-child(1) {
			z-index: 4;
		}
		&:nth-child(2) {
			z-index: 3;
		}
		&:nth-child(3) {
			z-index: 2;
		}
	}

	&__double-slide {
		width: 50%;
		max-height: 100vh;
		overflow-y: scroll;

		#{$objName}__slide {
			width: 50%;
		}

		:nth-child(1) {
			z-index: 2;
		}
		:nth-child(2) {
			z-index: 1;
		}
	}

	&__inner {
		.form-container {
			padding: 0 0 0 7.98389%;
			width: 32.5rem;

			@media (max-width: 1200px) {
				margin-right: 5.2rem;
				width: auto;
			}

			@include at(sm) {
				padding: 0;
				margin-right: 0px;
			}
		}

      &.step-2 {
        width: 50%;
      }
	}

	&__button {
		margin-top: 36px;
		justify-content: center;
	}

	&__close {
		-webkit-transition: opacity .2s ease-in-out, -webkit-transform .2s ease-in-out;
		transition: opacity .2s ease-in-out, -webkit-transform .2s ease-in-out;
		-o-transition: opacity .2s ease-in-out, transform .2s ease-in-out;
		transition: opacity .2s ease-in-out, transform .2s ease-in-out;
		transition: opacity .2s ease-in-out, transform .2s ease-in-out, -webkit-transform .2s ease-in-out;

		.icon {
			width: px2rem(10);
			height: px2rem(10);
		}

		[class*="step-"] ~ &,
		[class^="step-"] ~ & {
			opacity: 1;
			transform: none;
		}
	}

	&__back-link {
		@include transition(opacity, $transitionMultiplier);

		position: absolute;
		top: px2rem(60);
		opacity: 0;

		.step-2 & {
			opacity: 1;
		}
	}
}

#{$objName}-item {
	padding: 1.5rem 0;
	border-top: 1px solid $c_border;

	&:last-child {
		border-bottom: 1px solid $c_border;
	}

	&__image {
		width: 300px;
		//width: px2rem(70);
		margin-right: px2rem(30);

		@include at(md) {
			margin: 0 auto;
			width: px2rem(70);
			margin-bottom: 20px;
		}
	}

	&__title {
		line-height: 1.5rem;
		margin-bottom: 15px;
		width: 100%;

		@include at(md) {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	&__quantity {
		align-self: flex-start;
	}

	&__quantity-button {
		width: px2rem(15);
		height: px2rem(15);
	}

	&__quantity-inner {
		width: 100%;
	}

	&__quantity-title {
		margin-right: px2rem(35);
	}

	&__quantity-input {
		width: px2rem(60);
		height: px2rem(60);
		margin: 0 px2rem(20);
		text-align: center;
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
	}

	&__preview-button,
	&__remove-button {
		.icon {
			width: px2rem(25);
			height: px2rem(15);
			margin-right: 0.5rem;
		}
	}
}

#{$objName}-summary {

	border-bottom: 1px solid $c_border;
	&__item {
		position: relative;
		display: flex;
	}

	&__label {
		width: px2rem(100);
		display: block;
		flex-grow: 1;
	}

	&__value {
		width: calc(100% - #{px2rem(100)});
	}
}

@include at(lg) {
	#{$objName} {

		&__back-link {
			left: 100vw;
			margin-left: px2rem(50);
            position: relative;
            top: 17px;
		}
	}
}

@include at(md) {
	#{$objName} {
		width: 300vw;

		&__slide {
			width: 100vw;
		}

		&__double-slide {
			width: 100vw;

			#{$objName}__slide {
				width: 50%;
			}
		}

		&.step-1 {
			transform: translateX(0);
		}
		&.step-2 {
			transform: translateX(-33.34%);
		}
		&.step-3 {
			transform: translateX(-66.67%);
		}

		&__inner {
			width: 100%;
		}
	}
}

@include at(sm) {
	#{$objName} {
		width: 400vw;

		&__inner {
			width: 100%;
			padding: 0 px2rem(20);

			.no-quotes {
				text-align: center;
			}
		}

		&__slide {
			width: 100vw;
		}
		&__double-slide {
			width: 100vw;
			flex-wrap: wrap;

			#{$objName}__slide {
				width: 100vw;
				box-shadow: none;
				overflow-y: hidden;
			}
		}

		&.step-1 {
			transform: translateX(0);
		}
		&.step-2 {
			transform: translateX(-25%);
		}
		&.step-3 {
			transform: translateX(-50%);
		}
	}
}

@include at(xs) {
	#{$objName} {
		&__close {
			right: px2rem(20);
		}

		&__inner {
			padding: 0 px2rem(20);
		}
	}

	#{$objName}-item {
		position: relative;

		&__product-area {
			flex-wrap: wrap;
			justify-content: flex-start;
		}

		&__image {
			width: px2rem(86);
			margin-right: px2rem(10);
		}

		&__title {
			width: auto;
		}

		&__preview-button {
			position: absolute;
			bottom: 2rem;
			left: 0;
		}

		&__remove-button {
			position: absolute;
			bottom: 2rem;
			left: 2rem;
		}

		&__quantity {
			align-self: flex-end;
			width: 100%;
		}
	}

	#{$objName}-summary {
		&__item {
			flex-wrap: wrap;
		}

		&__label {
			@include font-size(13);
			width: 100%;
		}

		&__value {
			@include font-size(18);
			width: 100%;
		}
	}
}

.add-to-quote-slider__close:not(.is-open) {
	right: 0;
}

.mobile-quote {
	margin: 0px!important;

	.add-to-quote-slider {
		position: initial;

		.add-to-quote-slider__inner {
			padding: 0 20px;
			width: 100%;
		}
	}

	@include at(md) {
		.slide-nav__item-inner {
			max-width: 100%;
		}

		.add-to-quote-slider__inner {
			width: 100%;

			&.step-3 .add-to-quote-slider__button {
				margin-bottom: 40px;
			}
		}

		.add-to-quote-slider__double-slide {
			flex-wrap: wrap;
			overflow-y: hidden;

			.add-to-quote-slider__slide {
				box-shadow: none;
				overflow-y: hidden;
				width: 100%;
			}
		}
	}

	&.overflow-x-hidden {
		max-width: 100%;

		.add-to-quote-slider__double-slide {
			overflow-y: scroll;
		}
	}
}

.desktop-quote {
	.quote-list {
		.add-to-quote-slider__button {
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
		}
	}

	&.step-2 {
		.close-container {
			margin-right: 0px;
            margin-left: 0px;
            width: 32.5rem;
            float: right;

			@include at(md) {
				.add-to-quote-slider__back-link {
					position: absolute;
					top: 3.75rem;
				}
			}
		}

		.form-container.quote-list, .form-container.left {
			padding: 0 7.98389% 0 0;
			width: 32.5rem;
			float: right;

			@include at(lg) {
				padding: 0 0 0 3rem;
				width: auto;
				float: none;
			}

			&.last {
				padding: 8.375rem 7.98389% 0 0;

				@include at(lg) {
					padding: 8.375rem 7.98389% 0 3rem;
				}
			}
		}

		.form-container.right {
			float: left;
			padding: 8.375rem 0 0 7.98389%;
			width: 32.5rem;

			@include at(lg) {
				padding: 8.375rem 3rem 0 3rem;
				width: auto;
				float: none;
			}
		}
	}

	.add-to-quote-slider__slide {
		overflow-x: hidden;
	}
}

.last-slide {
	.add-to-quote-slider-item__image {
		width: 70px;
		margin: 0 1.875rem 0 0;
	}
}

.js_add-to-quote-submit, .add-to-quote-slider__button button {
	margin-bottom: 20px;
}

.quote {
	&__title {
		display: flex;
		justify-content: space-between;
	}

	&__edge-link {
		font-size: 0.8rem;
		margin-top: 0.3rem;
	}

	&__table {
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;

		tbody, tr {
		width: 100%;
		}
	}

	&__order-row {
		td {
		border-bottom: solid 1px $c_mercury;
		}

		&--top td{
			border-top: solid 1px $c_mercury;
		}
	}

	&__order-row-details td {
		padding: 1.4rem 0.8rem;
	}

	&__order-thumbail-container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		width: 60px;
	}

	&__order-thumbnail {
		border: solid 1px $c_silver;
		left: 0;
		width: 100%;
	}

	&__order-total {
		white-space: nowrap;
	}


	&__clear-cart {
		text-align: right;
		margin-top: 2rem;
	}

	&__order-remove {
		padding-right: 0 !important;
	}

	&__order-remove-btn {
		cursor: pointer;
	}

	&__order-remove-btn:link,
	&__order-remove-btn:visited {
		color: $c_pop;
	}

	&__order-remove-btn:hover,
	&__order-remove-btn:active {
		color: $c_pop_light;
	}

	&__order-qty {
		font-size: px2rem(14);
		margin: 5px 0px;
	}

	&__order-product-total {
		font-size: px2rem(14);
	}
	
}

.quote__table tr:first-child td {
    border-top: solid 1px $c_mercury;
}

@media (max-width: 360px) {
	.quote {

		&__order-thumbail-container {
			display: none !important;
		}
	}
}