// so JS can know which breakpoint we're at.
// https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
@each $bp, $v in $breakpoints {
	@include at($bp) {
		.which-breakpoint {
			&:before {
				content: $bp;
			}
		}
	}
}

.backdrop {
	@include transition(opacity);

	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($c_black, 0.5);
	opacity: 0;
	z-index: -1;
}

@include at(md) {
	.nav__backdrop {
		.mega-menu-is-open & {
			opacity: 1;
			z-index: 1;
		}
	}
}

.hr {
	border: none;
	background-color: $c_border;
	height: 1px;
	margin: px2rem(40) 0;

	&--light {
		background-color: $c_border-light;
	}
}

.admin-links {
	margin-top: -3rem;
}

@include at(xs) {
	.u_has-bleed-line--top--xs {
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: -15px;
			width: 100vw;
			background-color: $c_mercury;
			height: 1px;
		}
	}

	.u_has-bleed-line--bottom--xs {
		position: relative;

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: -15px;
			width: 100vw;
			background-color: $c_mercury;
			height: 1px;
		}
	}
}
