// ==========================================================================
// #Circle
// ==========================================================================


.md-circle {
    border-radius: 50%;
    display: block;
    text-align: center;
    border-color: #86C543;
    border: 2px solid;
    background-color: #86C543;
    color: white;
    font-size: 16px;
    font-family: 'Arial', 'Helvetica Neue', 'Helvetica', 'sans-serif';

    &--inverse {
       background-color: white;
       color:  $c_green;
    }

    &--mid {
      height: 34px;
      width: 34px;
      line-height: 34px;
      border-color: $c_green;
    }


 }