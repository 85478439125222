/*
Side Nav.
*/

.side-nav {
	max-width: px2rem(300);

	position: sticky;
	top: 1rem;

	&__item {
		border-bottom: 1px solid $c_border-light;
		padding: 1.5rem;

		&:first-child {
			border-top: 1px solid $c_border-light;
		}

		a {
			&:hover {

				&:after {
					@include arrow($c_text-light);
				}

				color: $c_text-light;
				text-decoration: underline;
			}
		}
	}
}









@include at(md) {
	.side-nav {
		&__item {
			padding-left: 0;
			padding-right: 0;
		}
	}
}









@include at(sm) {
	.side-nav {
		max-width: none;
		margin-bottom: 2rem;
	}
}
