/*
Product Detail.
*/

.product-detail {

	&__header {

		padding-bottom: 0rem;
		margin-bottom: 2rem;
		border-bottom: solid 1px $c_silver;
	}

	&__product-title {
		margin-bottom: 0.7rem;
	}

	&__body {
        h2 {
            font-weight: bold;
            font-size: 1.3rem;
        }

        h3 {
            font-weight: bold;
            font-size: 1.15rem;
        }
	}

	input {
		padding: px2rem(15);
		background-color: transparent;
		border: solid 1px $c_silver;
		text-align: center;
		width: px2rem(80);
		margin-left: px2rem(20);
		margin-right: px2rem(30);
	}

	.product-range {
		margin-top: 10px;
	}

	/* NEW STYLES FOR TOP RIGHT PAGE  */

	&__ships {

		text-align: left;

		#in-stock-message {
			position: relative;
			padding-left: 32px;

			&::before {
				content: ' ';
				background-image: url(/static/bundles/img/svg/green-tick.svg);
				display: inline-block;
				width: 26px;
				height: 19px;
				background-repeat: no-repeat;
				position: absolute;
				top: 0px;
				left: 0px;			
			}

			&::after {
				content: ' | ';
				font-weight: 400;
			}
		}

		@media only screen and (min-width: 768px) and (max-width : 991.5px) {
			#in-stock-message {
				display: block;
				margin-bottom: 10px;

				&::after {
					content: none;
				}
			}
		}

		@media only screen and (max-width : 548px) {
			#in-stock-message {
				display: block;
				margin-bottom: 10px;

				&::after {
					content: none;
				}
			}
		}

	}

	&__info span {
		font-size: 1rem !important;
	}

	&__icons {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 3rem;
		border-top: solid 1px $c_silver;
		padding-top: 1rem;
		margin-top: 2rem;
	}

	&__icon-share {
		width: 15px;
		height: 15px;
		display: inline-block;
		margin-right: 0.4rem;
	}

	&__icon-text {
		font-size: 1rem;

		&--save {
			font-size: 1rem;
			margin-left: 0.3rem;
		}
	}

	&__icon-add {
		font-size: 1rem;
	}

	&__select {
		width: 100%;
    background: url('/static/bundles/img/svg/arrow-down-blue.svg') no-repeat right;
		-webkit-appearance: none;
		background-color: white;
		background-position-x: 95%;
		border-color: $c_mercury;
		padding: 1rem;
		border-width: 1px;
		border-style: solid;

		&:focus {
			outline: none;
		}
	}

	&__info {
		display: block;
		margin-top: 2rem;
		margin-bottom: 2rem;
		@include ipad-column-breakpoint {
			margin-top: 1rem;
			text-align: center;
		}
	}

	&__gap {
		display: inline-block;
		margin-left: 0.1rem;
		margin-right: 0.1rem;
	}

	&__cart-price {
		@include below-ipad-breakpoint {
			text-align: center;
		}
	}

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		> * {
			width: 100%;
		}
	}

	&__cta {
		display: flex;
		white-space: nowrap;
		justify-content: center;
	}

	&__qty {
		text-align: right;
		padding-right: 1rem;
	}

	&__price-number {
		display: block;
		font-size: 1.7rem;
		margin-top: 1rem;

		small {
			font-size: 12px;
			font-weight: 300;
		}

		@include ipad-column-breakpoint {
			display: inline-block;
			transform: translateY(0.3rem);
		}
	}

	&__quantity {
		display: inline-block;
		transform: translateX(-1rem);

		@include ipad-column-breakpoint {
			display: inline;
			margin-right: 0.5rem;
		}
	}

	&__input-qty {
		width: px2rem(70) !important;
		margin: 0 !important;
		margin-left: 1rem;
		background-color: white !important;

		@include smallPhone {
			transform: translateY(0.3rem);
		}
	}

	&__button {
		display: block;
		text-align: center;
		background: $c_pop;
		color: $c_white;
		padding: 1rem 1.5rem;
		border-radius: 40px;
		transition: all 0.2s;
		cursor: pointer;
		border-width: 0px;
		font-weight: bold;
		text-decoration: none;
		width: 100%;

		&:hover {
			background-color: $c_green;
		}

		&:disabled {
			background-color: $c_silver;
			cursor: auto;
		}

		@include below-ipad-breakpoint {
			display: inline-block;
		}
	}
}

@media only screen and (max-width : 991.5px) {

	.product-detail {

		&__container {
			flex-direction: column;
			text-align: center;
		}

		&__price {
			margin-bottom: 2rem;
		}
	}

	.product-price_label {
		display: none;
	}
}

@media (max-width : 374px) {

	.product-detail {
		&__button {
			margin-top: 3px;
		}
	}
}

.product-carousel {

	&__navigation {
		position: relative;
	}

	&__thumbs {
		width: px2rem(250);
	}
}

@include at(md) {

	.product-detail {

		&__footer {

			display: flex;
			justify-content: space-between;
			align-items: center;

			.button {
				text-align: center;
				width: 60%;
				white-space: nowrap;
				padding-left: px2rem(10);
				padding-right: px2rem(10);
			}
		}
	}

	.product-carousel {

		&__thumbs {
			display: none;
		}

		&__navigation {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 100%;
		}
	}

	.product-info {
		margin-top: px2rem(45);
	}
}

@media only screen and (max-width : 767.5px) {
	.product-info {
		margin-top: -15px !important;
	}

	.qty-lg {
		display: none !important;
	}

	.qty-sm {
		display: inline-block !important;
	}

	.product-detail input {
		width: 53px !important;
		height: 53px !important;
		padding: 10px;
	}
}


.view-more-arrow::after {
	background-image: url(/static/bundles/img/svg/arrow-right-blue.svg);
	content: "";
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center center;
	width: 1.7rem;
	height: 1rem;
	transform: translateY(0.2rem) translateX(0.2rem);
}