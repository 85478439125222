/*
Newsletter.
*/

.newsletter {
	padding: px2rem(30);
	margin-bottom: px2rem(50);

	h3 {
		margin-bottom: px2rem(40);
	}

	.field__label {
		left: 0;
		color: $c_white;
	}

	select {
		margin-left: px2rem(-3);
	}

    .field__select {
        background-image: url(/static/bundles/img/svg/arrow-down-white-select.svg);
        select {
            color: $c_white;
            outline: none;
            option {
                color: $c_chicago;
            }
        }
    }

	@include at(sm) {
		margin-top: 0;
		margin-left: - $grid-gutter-width;
		margin-right: - $grid-gutter-width;
	}
}

.newsletter-container {
	background-color: #009ee2;
	padding-top: 2rem;
	padding-bottom: 3rem;

	.newsletter {
		margin-bottom: 0;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;

		input[disabled="disabled"]:hover, .button:disabled {
				background-color: #0078ab !important;
		}

		.button {
			width: 100%;
		}

		h2 {
			text-align: center;
		}

		form {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			gap: 1rem;

			.field-50 {
				width: 45%;
			}

			.field-100 {
				width: 90%;
			}
		}
	}
}

@include at(md) {
	.newsletter-container .newsletter {
		form {
			display: block;
		}

		.field-50,
		.field-100 {
			width: 100% !important;
		}
	}
}
