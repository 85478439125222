/*
	Structure
*/

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
*, *:before, *:after {
	box-sizing: inherit;
}

.overflow-nav .js_overflow-nav.is-open body{
	overflow: hidden;
}