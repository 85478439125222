/*
    Typography.
*/

/*
Fonts.
Here's where you declare font families, weights etc.
*/
$font-sans: 'Arial', sans-serif;

/*
An object of type properties, for use with the type() mixin.
Here you can define a key for each font style, and a map of css properties such
as letter-spacing. Remember to define these in relative units so they scale with
the font size. You can also define variations of the font style, like bold,
which might have different properties.

eg.
$type-styles: (
    serif: (
        font-family: $font-serif,
        letter-spacing: 0.07em,
        line-height: 1.5
    _variants: (
      bold: (
        letter-spacing: 0.1em
      )
    )
    )
);
*/
$type-styles: (
  sans: (
    font-family: $font-sans,
  ),
  body: (
    line-height: (27/16)
  ),
  bold: (
    font-weight: bold
  ),
  caps: (
    text-transform: uppercase
  ),
  light: (
    font-weight: normal
  )
);

/*
An object of fallback type styles, for use with the type() mixin.
Here you can define different properies like letter-spacing to have the fallback
fonts look as similar to the real fonts as possible, to avoid a massive reflow
when the real fonts are loaded. As above, you can define variants here as well.

eg.
$fallback-type-styles: (
    serif: (
        letter-spacing: -0.03em,
    _variants: (
      letter-spacing: 0
    )
    )
);
*/
$fallback-type-styles: ();

/*
An object of type sizes, for use with the type() mixin.
Here you can define a key for each size, and an array of sizes according to how
they change at each breakpoint.

eg.
$type-sizes: (
    redwood: (36, 36, 32, 32, 32),
    scotspine: (32, 32, 28, 28, 28),
    smallpine: (28, 28, 24, 24, 24),
    oak: (24, 24, 20, 20, 20),
    cactus: (20, 20, 18, 18, 18),
    bonzai: (16, 16, 16, 16, 16),
    seedling: (12, 12, 12, 12, 12)
);
*/
$type-sizes: (
  redwood: (38,38,38,38,32),
  scotspine: (28,28,28,28,28),
  oak: (24),
  cactus: (18),
  bonzai: (16,16,16,16,16),
  seedling: (13)
);











// Loop through fonts to create font classes.
@each $key, $style in $type-styles {
  .f_#{$key} {
    @include type($key);
  }

  @if map-has-key($style, '_variants') {
    $variants: map-get($style, '_variants');

    @each $v_key, $v_value in $variants {
      .f_#{$key}--#{$v_key} {
        @include type($key, null, $v_key);
      }
    }
  }
}




// Loop through font sizes to create font size classes.
@each $key, $value in $type-sizes {
  .fs_#{$key} {
    @include type(null, $key);
    letter-spacing: px2em(-1, 80);
  }
}

// font size variants
.fs_scotspine {
  line-height: 1.4;
}


h1, h2, h3 {
  margin: 0;
  font-weight: normal;
  font-size: 1em;
}

body {
  @include type(sans, bonzai);

  -webkit-font-smoothing: antialiased;
  color: $c_text-dark;
}

a {
  @include transition(color);

  color: $c_text-dark;
  font-weight: bold;
  text-decoration: none;
}

address {
  font-style: normal;
}
