.f_form {
  width: 100%;

  .field {
    margin-bottom: 3.125rem;
  }

  .field__select select {
    padding: 1rem 0.5rem;
    border-bottom: solid 1px $c_mercury !important;
    outline: none !important;
  }

  .field__select.hide-dropdown-icon {
    background: none !important;
  }

  .field__select select[data-multiple] {
    color: #ffffff !important;
  }

  .field__select select[multiple] {
    overflow-y: hidden;
    padding: 0px !important;
    cursor: pointer;
  }

  .field__select select[multiple] option {
    padding: 6px 7px;
  }

  .field__select select[multiple] option:first-child {
    height: 16px;
    opacity: 0;
    visibility: hidden;
    padding: 0px !important;
  }

  .field__select select[multiple] option[selected] {
    color: #000000;
  }

  .field__select.field_type-select,
  .field__select.field_type-select-multi {
    background-position-x: calc(100% - 7px);
    background-position-y: calc(100% - 17px);
  }

}

/* checkboxes */

.f_checkbox {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.f_checkbox[type="checkbox"] {
  display: inline-block;
  position: absolute;
  opacity: 0;
}

.f_checkbox-label {
  position: relative;
  cursor: pointer;
  line-height: 2;
  display: flex;

  &::before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid $c_cerulean;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
    height: 22px;

    @include below-ipad-breakpoint {
      margin-right: 11px;
    }
  }
}

.f_checkbox:checked + .f_checkbox-label::after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid $c_cerulean;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* radio */




.f_radio:checked,
.f_radio:not(:checked) {
    position: absolute;
    opacity: 0;
    top: 14px;
    left: 7px;
}
.f_radio:checked + label,
.f_radio:not(:checked) + label
{
    position: relative;
    cursor: pointer;
    display: inline-block;
    color: #666;
    padding-left: 40px;
}
.f_radio:checked + label:before,
.f_radio:not(:checked) + label:before {
    content: '';
    width: 28px;
    height: 28px;
    border: 1px solid $c_pop;
    border-radius: 100%;
    background: transparent;
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: -3px;
}
.f_radio:checked + label:after,
.f_radio:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: $c_pop;
    position: absolute;
    top: 6px;
    left: 9px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.f_radio:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.f_radio:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.f_checkbox-container .f_checkbox-label{
  line-height: px2rem(23);
}