.product-3d-image {
	padding: px2rem(14);
	border-top: solid 1px $c_gallery;
	background: #ffffff;
	cursor: pointer;

	.arrow-right {
		float: right; 
		padding-right: 10px;

		.launch-text {
			display: none;
		}

		&::before {
			@include arrow($c_cerulean);
			@include transition(all);

			content: '';
			display: inline-block;
			background-repeat: no-repeat;
			background-position: center center;
			width: px2em(30, 13);
			height: 1em;
			margin-left: 1.5em;
			position: relative;
			left: 0;
		}
	}
	&:hover {
		background-color: $c_cerulean;
		color: $c_white !important;

		.arrow-right {
			.launch-text {
				display: inline-block;
			}

			&::before {
				@include arrow($c_white);
				margin-left: 0px;
				margin-right: 8px;
				top: 2px;
			}
		}
	}
}

@media (min-width:768px) and (max-width: 991px) {
  .product-3d-image {
    .arrow-right{
			.launch-text {
				display: none !important;
			}

			&::before {
        margin-right: 0px !important;
        top: 0px !important;
			}
    }
  }
}

.container-3d-images {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 80%; /* 5:4 Aspect Ratio */
}

.footer-3d-images {
  display: flex;
  margin: 20px 0px 0px 0px;
  justify-content: center;
  align-items: center;

  .dots {
    margin: 0px 30px;

    div {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #000000;
      margin: 0px 4px;
      cursor: pointer;
    }

    .selected{
      border: 1px solid #000000;
      background: #000;
    }
  }

  .toggle {
    @include arrow($c_cerulean);
    width: 38px;
    height: 14px;
    background-repeat: no-repeat;
    cursor: pointer;

    &-previous {
      transform: rotate(180deg);
    }

    &-next {
      margin-top: 3px;
    }
  }
}