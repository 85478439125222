/*
	Configurator Quote Forms
*/

#configurator-quote-slider {
  h3{
    margin: 0px 0px 35px 0px;
    font-size: px2rem(19);
    font-weight: bold;
  }

  h4{
    font-size: px2rem(18);
    margin-bottom: 0.5rem;
  }

  h4 + div{
    font-size: 0.9rem;
    color: #8e8e8e;
  }

  #configurator-details {

    .field {
      margin-bottom: 3rem;
    }

    .field__label {
      left: 0px;
    }
  }

  .dots {
    margin-left: auto;
    margin-right: auto;

    div {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      border: 1px solid #c4c4c4;
      margin: 0px 4px;
    }
    .selected{
      background: #c4c4c4;
    }
  }

  #configurator-back-btn{
    color: #009ee2;
    cursor: pointer;
    font-weight: 700;

    &.d-none + .dots {
      opacity: 0;
    }
  }

  table{
    border: 0;
    margin: 0 0 2rem;
    width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    font-size: 13px;

    thead th, thead td{
      border-bottom-width: 2px;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      white-space: nowrap;
    }

    td, th {
      border-bottom: 1px solid #dee2e6;
      padding: .75rem;
      vertical-align: top;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
      }
    }

    td {

      &::after {
        content: " ";
        display: block;
        height: 19px;
      }
    }

    th {
      text-align: inherit;
    }

    tbody > tr:last-child {
      font-size: 15px;
      font-weight: 700;
      border-bottom: none;

      td {
        padding: 19px 11px;
        border-bottom: none;

        &:first-child {
          padding-left: 0px;
        }
  
        &:last-child {
          padding-right: 0px;
        }
      }
    }

    #configuratorBodyCodeDesc {
      min-width: 171px;
    }

    .text-right {
      text-align: right;
    }

    .hide-cell {
      display: none !important;
    }
  }

  #configurator-thumbnails {
    margin-bottom: 40px;
  }

  @media (max-width: 992px) {
    .login-slider__inner {
        text-align: left !important;
    }
  }

  @include at(md) {
    .right-panel-slider__inner {
      padding-top: 10px;
    }
    .configurator-dots{
      position: relative;
    }
    .dots div {
      margin: 0px 2px;
    }
    #configuratorBodyCodeDesc {
      min-width: auto !important;
    }

  }

  @include at(sm) {
    .configurator-dots{
      width: 100% !important;
    }
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
    }
  }

}