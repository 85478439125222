/*
tingle.
*/

.tingle-modal-box {
	width: auto;

	&__content {
		padding: 0;
	}

	&__footer {
		padding: 0;

		padding: 18px 0px 0px 10px;
		text-align: right;
		background-color: transparent;

		button {
			background-color: transparent;
			border: 2px solid $c_pop;
			color: $c_pop;
			border-radius: 20px;
			padding: 7px 18px;
			margin-right: 10px;
			cursor: pointer;

			&:hover {
				background-color: $c_pop;
				color: white;
			}
		}
	}
}

.modal {
	&__close-button {
		width: px2rem(40);
		height: px2rem(40);
		background-size: 50%;
		color: transparent;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.tingle-modal__close {
	width: 60px;
	height: 60px;
}

@include at(xs) {
	.tingle-modal {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.tingle-modal-box {
		&__content {
			display: flex;
			justify-content: center;
		}
	}
}

@media (max-width : 540px) {
	.tingle-modal__closeIcon {
		font-size: 2rem;
	}
}

.popup-modal {
  
	.tingle-modal-box {
	  padding: 30px;
	  border-radius: 0px;
	}
  
	.tingle-modal__close {

	  &-show {
		color: $c_pop;
		top: 0px;
		right: 0px;
		font-size: 2rem;
	  }
	}
  }