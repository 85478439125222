/*
Product Teaser - With Listview feature (Newer sites)
*/

.compareBtn{
    position: relative;
    width: 0px;
    height: 0px;
    z-index: 2;

    > div {
        position: fixed;
        bottom: -85px;
        left: 0px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 3rem;
        color: #fff;
        background-color: #009EE2;
        box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.13);
        animation-name:slideupCompare;
        animation-duration:0.5s;
        animation-fill-mode:forwards;
        animation-timing-function:ease-out;
    }

    .compare-button {
        border-color: #fff !important;
        color: #fff !important;
    }

    .compare-button {
        &:hover, &:active {
            background-color: #0f89bd;
        }
    }

    @media (max-width: 767px) {
        .compare-text {
            display: none;
        }
        .compare-button {
            width: 100%;
            background-color: #009EE2;
            border-color: #009EE2;
        }
        > div {
            background-color: #fff;
        }
    }

}

@keyframes slideupCompare {
    0%{bottom:-85px}
    100%{bottom:0;}
}

#grid,
#list {
    &-view-btn {
        padding: 0px;
        width: 17px;
        height: 17px;
        border-width: 0px;
        margin-right: 9px;
        cursor: pointer;
        opacity: 0.4;
        background-color: transparent;

        &.active {
            opacity: 1;
        }

        &:hover {
            opacity: 1;
        }
    }

    &-view-msg {
        font-weight: 600;
    }
}

.product-teaser-container__with-listview {

    .product-teasers {
        @include at(sm) {
            .row {
                margin-right: -$grid-gutter-width / 4;
                margin-left: -$grid-gutter-width / 4;
    
                > .col,
                > [class*="col-"] {
                    padding-right: $grid-gutter-width / 4;
                    padding-left: $grid-gutter-width / 4;
                }
            }
        }
    }
    
    .product-teaser {
        @include transition(all);
    
        overflow: hidden;
        background-color: $c_white;
        position: relative;
        margin-bottom: px2rem(30);
        cursor: pointer;
    
        &__add {
            @include transition(all, 3);
            position: absolute;
            top: 0.5rem;
    
            .icon {
                width: 15px;
                height: 15px;
                display: inline-block;
            }
    
            span.add-quote {
                opacity: 0;
            }
    
            span + span {
                @include transition(all);
                opacity: 0;
                margin-left: 30px;
            }
        }
    
        &__checkbox-label {
            padding-left: 1.6rem !important;
            transform: translateY(-0.1rem);
        }
    
        &__grid-header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: 0 0.7rem;
            padding-top: 1rem;
            margin: 0;
        }
    
        &__header-icon {
            width: 12px;
        }
    
        &__grid-link {
            border: 0 !important;
            &::after {
                background-image: url(/static/bundles/img/svg/arrow-right-blue.svg);
                content: "";
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center center;
                width: 1.7rem;
                height: 1rem;
                transform: translateY(0.2rem) translateX(0.2rem);
            }
        }
    
        &__view-icon {
            display: inline-block;
            transform: scale(1.5) translateY(-0.05rem);
            margin-left: 0.7rem;
        }
    
        &__grid-title {
            font-size: 1.1rem;
            margin: -1rem auto;
    
            &::after {
                content: "";
                background-color: $c_mercury;
                display: block;
                margin-top: 1.5rem;
                height: 1px;
                width: 0;
                transform: translateX(-30%);
            }
        }
    
        &__field {
            margin-bottom: 0.6rem !important;
        }
    
        &__price {
            text-align: center;
            position: absolute;
            bottom: 1rem;
            font-size: 1.2rem;
            left: 0%;
            width: 100%;
            padding-bottom: 1.5rem;
            background-color: #ffffff;

            small {
                display: block;
                font-size: 50%;
            }
        }
    
        &__image {
            height: 220px;
    
            picture {
                width: 100%;
                height: 100%;
                position: relative;
            }
        }

        &.grid-view {
            .product-teaser__image {
                img {
                    position: absolute;
                    top: 30%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 100%;
                    max-height: 220px;
                    padding: 1rem;
                }
            }

            .product-teaser__view-product {
                background-color: #fff;
            }
        }

        &.list-view {
            .product-teaser__image {
                img {
                    max-width: 100%;
                    max-height: 190px;
                    padding: 1rem;
                    margin-left: auto;
                    margin-right: auto;

                    @media only screen and (max-width : 767.5px) {
                        position: absolute;
                        top: 30%;
                        left: 50%;
                        max-height: 220px;
                        transform: translate(-50%, -50%);
                        margin-top: initial;
                    }
                }
            }
        }
    
        &__body {
            padding: 2rem 1rem;
    
            h2 {
                font-weight: 600;
                margin-bottom: 7px;
            }
        }
    
        &__view-product {
            padding-top: 10px;
            padding-bottom: 20px;
    
            a {
                color: $c_cerulean;
            }
        }

        .field__checkbox [type=checkbox]:checked+.checkbox:before {
            content: '\2714';
            color: $c_cerulean;
            position: absolute;
            left: 2px;
        }

        .field__checkbox label .checkbox {
            left: 0px;
        }

        &.list-view {
            .product-teaser__add {
                right: -140px;
            }
            .list-view-product {
                display: block;
                margin-top: 0px;
                padding-bottom: 20px;
                font-size: 12px;
            }
            .product-teaser__add {
                margin-top: 7px;
            }
        }
    
        &.grid-view {
            .product-teaser__add {
                right: -74px;
            }
    
            .product-teaser__header-icon {
                width: 12px;
                position: absolute;
                top: 1rem;
                right: 0.6rem;
                transition: all 0.2s;
    
                &:hover {
                    filter: invert(89%) sepia(67%) saturate(300%) hue-rotate(60deg)
                        brightness(100%) contrast(84%);
                }
            }
    
            .key-facts {
                display: none;
                padding: 0px 30px;
                font-size: 14px;
                text-align: left;
                line-height: 25px;
                margin-top: -30px;
                overflow: hidden;
                overflow-y: hidden;
    
                ul {
                    padding-left: 0;
                    line-height: 25px;
                    margin-top: 0px;
                    width: 100%;
                }

                > div {
                    padding: 0px;
                    margin: 0px -10px;
                    line-height: 22px;
                }
            }
    
            .list-view-product {
                display: none;
            }
    
            &.added-to-compare {
                .key-facts {
                    display: block;
                }
    
                .product-teaser__image {
                    display: none;
                }
    
                .image-display {
                    display: block !important;
                }
    
                .checkbox-compare {
                    form {
                        display: block;
                    }
                }
            }
    
            &.added-to-compare,
            &:hover {
                .product-teaser__body {
                    .divider {
                        display: block;
                        border-top: 1px solid #e4e4e4;
                        margin: 20px auto 0px auto;
                        width: 80%;
                    }
                    .divider + div {
                        margin-top: 20px;
                    }
                }
            }
        }
    
        &-slide {
            width: px2rem(270);
            margin: 1rem auto;
        }
    
        .checkbox-compare {
            margin-top: 8px;
            margin-left: 8px;
    
            form {
                display: none;
            }
    
            &-text {
                padding-left: 1.2rem !important;
                padding-top: 0.2rem;
            }
        }
    }
    
    .key-facts__list {
        display: none;
    }
    
    @media (min-width: 768px) {
        .product-teaser {
            position: relative;
            &.list-view {
                display: flex;
                flex-direction: row;
                min-height: 300px;
    
                .product-teaser__body {
                    position: absolute;
                    width: 100%;
                    padding: 15px 10px 40px 15px;
                    text-align: left;
    
                    &::after {
                        content: " ";
                        display: block;
                        border-top: 1px solid #ececec;
                        margin: 10px auto 0px auto;
                        width: 100%;
                    }
    
                    h2 {
                        transform: translateY(1.2rem);
                    }
                }
    
                .product-teaser__grid-title {
                    &::after {
                        width: 0;
                    }
                }
    
                .product-teaser__image {
                    position: absolute;
                    left: 1rem;
                    top: 25%;
                    width: 18%;
                }
    
                .product-teaser__price {
                    position: absolute;
                    left: 0rem;
                    bottom: -34px;
                    z-index: 1;
                    line-height: 0;
                    margin-left: 22%;
                    text-align: center;
                    display: inline-block;
                    width: auto;
                    
                    small {
                        display: block;
                        text-align: center;
                        margin: 0 auto;
                        margin-top: 1rem;
                        font-size: 0.8rem;
                        font-weight: lighter;
                        line-height: 1.2;
                    }
    
                    @include lg-desktop-breakpoint {
                        bottom: -1.5rem;
                        margin-left: 23%;
                    }
                }
    
                .product-teaser__field {
                    position: absolute;
                    left: 2rem;
                    bottom: 16px;
                    z-index: 1;
    
                    @include lg-desktop-breakpoint {
                        bottom: 1.7rem;
                    }
                }
    
                .key-facts {
                    font-size: 14px;
                    line-height: 1.4;
                    margin-left: 19%;
                    margin-top: 84px;
                    margin-bottom: 114px;
                    padding-right: 2rem;
                    padding-bottom: 50px;
    
                    h3 {
                        font-size: 15px;
                        font-weight: 600;
                        margin: 0px 0px 6px 0px;
                    }
    
                    ul {
                        padding-left: 13px;
                        line-height: 25px;
                        list-style: none;
                        margin-top: 0px;
    
                        li::before {
                            content: "\2022";
                            font-weight: bold;
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }

                    > div {
                        padding: 0 1rem;
                        line-height: 22px;
                        br {
                            display: none;
                        }
                    }
    
                }
    
                .list-view-product {
                    font-size: 1rem;
                    position: absolute;
                    bottom: -5rem;
                    right: 3rem;
    
                    @include lg-desktop-breakpoint {
                        bottom: -4rem;
                        right: 1rem;
                    }
    
                    @include ipad-column-breakpoint {
                        bottom: -5rem;
                    }
                }
    
                .key-facts__list {
                    display: block;
                }
    
                .product-teaser__header-icon-container {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                }
    
                .product-teaser__view-product {
                    display: block !important;
                    position: absolute !important;
                    bottom: 11px !important;
    
                    a {
                        float: right;
                        padding-right: 3rem;
                    }

                    @include lg-desktop-breakpoint {
                        bottom: 1.3rem !important;
                    }
    
                    @include ipad-column-breakpoint {
                        bottom: 0;
                    }
                }
            }
        }
    }
    
    @media (min-width: 768px) {
        .product-teaser {
            &:hover {
                outline: px2rem(4) $c_cerulean solid;
    
                .product-teaser__add {
                    right: px2rem(20);
                }
    
                span + span {
                    margin-left: 0px;
                    opacity: 1;
                }
    
                .product-teaser__image {
                    display: none;
                }
    
                .image-display {
                    display: block !important;
                }
            }
        }
    
        .grid-view {
            &:hover {
                .key-facts {
                    display: block;
                }
    
                .checkbox-compare {
                    form {
                        display: block;
                    }
                }
            }
        }
    
        .list-view {
            &:hover {
                .product-teaser__header-icon {
                    filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(360deg)
                        brightness(206%) contrast(201%);
                }
                .product-teaser__image {
                    display: flex;
                }
    
                .product-teaser__body {
                    background-color: $c_cerulean;
                    color: $c_white !important;
                    height: 1rem;
    
                    &::after {
                        content: " ";
                        display: none;
                    }
    
                    * {
                        color: $c_white;
                    }
    
                    .product-teaser__add {
                        .i_basket {
                            background-image: url(/static/bundles/img/svg/basket-white.svg) !important;
                        }
    
                        .add-quote {
                            color: $c_white !important;
                        }
                    }
                }
            }
        }
    
        @media (max-width: 991px) and (min-width: 768px) {
            .list-view {
                min-height: 300px !important;
    
                .product-teaser__body {
                    padding-left: 10px !important;
                }
    
                .long-marketing-text {
                    display: none !important;
                }
            }
        }
    
        @media (max-width: 767px) {
            .product-teaser {
                min-height: 400px !important;
                max-height: 500px !important;
                display: block !important;
    
                &__add {
                    display: none !important;
                }
    
                &__image {
                    position: absolute;
                    width: 33%;
                    top: 60%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
    
                &__body {
                    padding: 10px 10px 10px 5px !important;
                    margin-top: 33px !important;
                    width: 55% !important;
                    float: right !important;
                    text-align: center !important;
                }
    
                &__view-product {
                    left: 50%;
                    transform: translateY(18.5rem) translateX(-22%);
                }
    
                .product-teaser__grid-title {
                    position: absolute;
                    top: 5rem;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
    
            #listGridBtns {
                display: none !important;
            }
        }
    
        @include at(sm) {
            .product-teaser {
                margin-bottom: $grid-gutter-width / 2;
    
                &__image {
                    height: 180px !important;
                }
            }
        }
    
        .key-facts__item {
            margin-bottom: 0.7rem;
            line-height: 1.4;
        }
    }
    
    .key-facts {
        @media only screen and (max-width : 767.5px) {
            display: none;
        }
    }
    
    .product-teaser__header-icon {
        @include below-ipad-breakpoint {
            width: 12px;
            position: absolute;
            top: 1rem;
            right: 0.6rem;
        }
    }
}

@media (max-width: 767px) {
    #listGridBtns {
        display: none !important;
    }
}


.search-results__price-divider {

    padding-top: 1rem;
    position: relative;
}

.search-results__price { 
    text-align: center;
    position: absolute;
    bottom: 34px;
    margin-top: 1rem;
    font-size: 1.2rem;
    width: 100%;
    background-color: #ffffff;

    small {
        display: block;
        font-size: 50%;
    }
    
}

body:not(.website_show_compare) {
    .field.field__checkbox.product-teaser__field,
    .compareBtn
    {
        display: none !important;
    }
}

.product-teaser.list-view .key-facts-image img {
    margin-top: -20px;
}

.product-teaser.list-view .keyfacts-as-bullets {
    max-height: calc(100% - 240px);
    padding-bottom: 13px !important;
}

.product-teaser-container__without-listview .product-teaser.grid-view .product-teaser__image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    max-height: 100%;
    padding: .9375rem;
}