/*
Structure
*/

.container {
	position: relative;
}

.product-view-top-padding {
	padding-top: 0;
}

.island {
	padding-top: px2rem(60);
	padding-bottom: px2rem(60);

	&--article {
		@include at(sm) {
			background-color: $c_white;
		}
	}

	&--pt0 {
		padding-top: 0;
	}
}

.aside {
	margin-top: 6rem;

	&--mt0 {
		margin-top: 0;
	}

	@include at(sm) {
		margin-top: 0;
	}
}

.white-area, .grey-area {
	.t_background--primary {
		background-color: transparent !important;
	}

	> div:last-child {
		> div {
			margin-bottom: 0px !important;
			padding-bottom: 4rem;
		}
	}
}

.overlap-white-area {
	> div:last-child {

		position: relative;
		overflow: hidden;

		&:before {
			content: '';
			display: block;
			width: 100%;
			background-color: #fff;
			border-top: 6px solid #9ace49;
			position: absolute;
			z-index: 0;
			top: 50%;
			height: 100%;
		  }
	}
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}