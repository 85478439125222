/*
Customer Portal.
*/

.customer-portal {
	padding-bottom: px2rem(200);
}









@include at(sm) {
	.customer-portal {
		padding-bottom: 3rem;

		&__header {
			flex-wrap: wrap;
			align-items: baseline;

			.admin-links {
				margin-top: 0;
			}
		}
	}
}









@include at(xs) {
	.customer-portal {

		&__heading {
			margin-top: 4rem;
			padding-bottom: 1rem;
		}

		&__button {
			width: 100%;
			text-align: center;
		}
	}
}
