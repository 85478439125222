// ==========================================================================
// #Bubble
// ==========================================================================

.md-bubble {
    font-family: 'Arial', 'Helvetica';
    font-size: 16px;
    font-weight: bold;
    
    position: relative;
    z-index: 1;

    display: inline-block;

    width: 54px;
    height: 54px;
    line-height: 54px;

    cursor: pointer;
    text-align: center;
    text-decoration: none;

    color: #fff;
    border: 1px solid white;
    border-radius: 50%;
   background: none;
   outline: none;
   &:hover .md-bubble__bg{
      width: 50px;
      height: 50px;
   }
   
   &:hover {
      color: white;
      border-color: #86C543;
      &:after {
         -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
          opacity: 1;
      }
   }
   
   &:after {
       pointer-events: none;
       position: absolute;
       width: 100%;
       height: 100%;
       border-radius: 50%;
       content:'';
       -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
       box-sizing: content-box;
   }
   
   &:before {
       speak: none;
       font-size: 48px;
       line-height: 90px;
       font-style: normal;
       font-weight: normal;
       font-variant: normal;
       text-transform: none;
       display: block;
       -webkit-font-smoothing: antialiased;
   }
      
   &__bg {
       position: absolute;
       top: 50%;
       right: 0;
       bottom: 0;
       left: 50%;

       width: 24px;
       height: 24px;
       line-height: 46px;
       padding: 4px;

       transition: all linear .2s;
       transform: translate(-50%, -50%);

       border-radius: 50%;
       background: rgba(214, 214, 214, .16);
   }
   
   &--signal-effect {
      -webkit-transition: background 0.2s, color 0.2s;
         -moz-transition: background 0.2s, color 0.2s;
            transition: background 0.2s, color 0.2s;
      
      &:after {
          top: -10px;
          left: -10px;
          padding: 10px;
          box-shadow: 0 0 0 1px #86C543;
          -webkit-transition: -webkit-transform .2s, opacity .2s;
             -moz-transition: -moz-transform .2s, opacity .2s;
                  transition: transform .2s, opacity .2s;
           -webkit-transform: scale(.8);
              -moz-transform: scale(.8);
               -ms-transform: scale(.8);
                   transform: scale(.8);  
            transition-delay: .15s;
          opacity: 0;
      }
   }
}