.atomic-page-section {
  padding: 2rem 0;
}

@import './utility/_atomic-thumbnails';
@import './utility/_atomic-utilities';
@import './typography/_atomic-typography';

@import './components/_atomic-form';
@import './components/_atomic-buttons';
@import './components/_atomic-table';
@import './components/_atmoic-separator';
@import './components/_atomic-card';
@import './components/_atomic-loaders';

@import './blocks/_c_cart';
@import './blocks/_returns';