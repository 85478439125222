/*
    Variables
*/

/*
Structure
*/
$width-page: 1440px;









/*
Breakpoints
*/
$breakpoints: (
  'xl': 2000px / 16px * 1em,
  'lg': 1200px / 16px * 1em,
  'md': 992px / 16px * 1em,
  'sm': 768px / 16px * 1em,
  'xs': 576px / 16px * 1em
);









/*
Misc
*/
$transition-duration: 0.1s;
$transition-easing: ease-in-out;
$base-font-size: 16;
