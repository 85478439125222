/* 
	Bluewater Quote Forms
*/
.bluewater-quote-form {

	.field-set {
		> * {
			display: none !important;

			&:first-child {
				display: flex !important;
			}
		}
	}

	&.field-set-selected {

		.main-cat-list {
			display: none;
		}

		.field-set {
			> * {
				display: flex !important;

				&:first-child {
					display: none !important;
				}
			}
		}
	}


	h5 {
		color: #009EE2;
		margin: 54px 0px 0px 0px;
		font-size: px2rem(22);
	}

	p {
		&.intro {
			margin: 0px 0px 29px 0px;
			font-size: px2rem(13);		
		}

		&.field-description {
			margin: 7px 0px 22px;
			font-size: px2rem(15);
			color: #a0a0a0;
		}
	}

	table:not(.table-fixed) {
		margin-bottom: 40px;
		display: block;
		text-align: left;
		
		tbody {
			display: block;
		}

		tr {
			display: flex;
			flex-wrap: wrap;
			align-items: baseline;
			font-size: px2rem(14);

			td {
				margin-right: 5px;
				display: block;

				&.break {
					width: 100%;
					height: 12px;
				}

				&:not(.input) {
					font-size: px2rem(15);
					color: #383838;
				}

				span {
					&.label {
						display: inline-block;
						font-size: px2rem(15);
						color: #a0a0a0;
						margin-right: 16px;

						&::after {
							content: ": ";
						}
					}

					&.Rail-Finish,
					&.formradiobuttons,
					&.SafetyStripcolors,
					&.Stanchion-Finish,
					&.Base-Finish {
						display: inline-flex;
						flex-wrap: wrap;

						> span {
							display: inline-block;
							margin: 0px 20px 7px 0px;
						}

						input {
							margin-right: 5px;
						}
					}

					&.field-block {
						display: block;

						> span {
							display: block;
							margin: 0px 20px 7px 0px;
						}

						input {
							margin-right: 5px;
						}
					}
				}


			}

			input[type="text"] {
				font-size: px2rem(17);
				max-width: 250px;
				padding: 5px;
                padding: 9px 5px;
                border: 2px solid #dadada;
			}
		}

		&.break-all {
			td {
				width: 100%;

				span.label {
					margin: 9px 0px 7px 0px;
					min-width: 116px;
				}

				&:first-child {
					span.label {
						margin-top: 0px;
					}
				}				
			}
		}

		&.table-flat {
			td {
				input {
					margin-bottom: 20px;
				}
			}
		}
	}

	.quote-section {
		position: relative;

		.add-to-quote-slider__back-link {
			opacity: 1 !important;
			top: px2rem(-82) !important;
		}
	}

	.personal-section {
		position: relative;

		.add-to-quote-slider__back-link {
			opacity: 1 !important;
			top: px2rem(-82) !important;
		}
	}

	.inputbox {
		width: 95px;

		input {
			width: 100%;
			text-align: center;
			padding: 9px 5px;
			border: 2px solid #dadada;
		}

		div {
			&.primary-bold {
				text-align: center;
			}
		}

	}

	.selectbox {
		width: 100%;

		select {
			width: 100%;
			padding: 10px;
			text-transform: uppercase;
		}
	}

	img {
		border: solid 1px #ebebeb;
		padding: px2rem(20);
	}

	hr {
		border-top: 1px solid #ebebeb;
	}

	.skylight-demarcation {
		margin-top: 0px;

		img {
			float: right;
			padding: 5px;
			margin: 0px 0px 0px 18px;
		}

		label {
			font-size: px2rem(14);
		}
	}

	.js_gaurdrail-summary-btns {
		button {
			padding: 26px 26px;
			white-space: nowrap;
			font-size: px2rem(14);
		}
	}

	#quotesum {
		table {

			border-spacing: 0px;
			border-top: 1px solid #e0e0e0;

			tr {

				td {
					border-bottom: 1px solid #e0e0e0;
					padding: 16px 0px;
				}

				.grq_summary {
					&--title {
						font-weight: 700;
						font-size: px2rem(20);
						width: 100%;
						text-align: left !important;
					}
					&--edit {
						font-size: px2rem(15);
						padding-left: 20px;
						padding-right: 20px;
					}
					&--remove {
						font-size: px2rem(15);
					}
				}
			}
		}
	}
}

/* 
	Mobile responsive
*/
@include at(md) {

	.bluewater-quote-form {
		p,
		h5,
		h4,
		h3,
		h2,
		span,
		small {
			text-align: left;
		}

		.Xtra-Base-Qty,
		small {
			display: block;
		}
	}

}