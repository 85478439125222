/*
    Hacks.
*/

// This we use in category page when there is five tabs in the row
@media (min-width: 768px) {
    .row.col-md-3__five-tabs .col-md-3 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.related-products-carousel {
    overflow: visible;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__pagination-item {
        position: static;
        transform: none;
        width: 10px;

        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.product-carousel__pagination-item {
    background-image: url(/static/bundles/img/svg/circle-arrow-right.svg);
    width: px2rem(30);
    height: px2rem(30);
}

.product-carousel__pagination-item.swiper-button-prev {
    transform: rotate(180deg) translateY(15px);
}

.product-carousel {
    z-index: 0;
    height: px2rem(445);

    .swiper-slide {
        background-color: $c_white;
    }
}

@include at(xs) {
    .homepage-video-carousel {
        margin-right: 0;
        margin-left: 0;

        .swiper-wrapper {
            flex-wrap: wrap;
        }

        .swiper-slide {
            height: auto;
            margin-bottom: 1rem;
        }
    }
}

//On some pages there's an overflow issue.
.overflow-fix {
    overflow: hidden;
}


// DUMP

.primary-link-underline {
    text-decoration: underline;
    color: #009EE2;
    font-weight: normal;
}

.quote-sub-title-menu {
    font-size: px2rem(24);
    font-family: 'Arial', 'Helvetica', 'sans-serif';
    margin-bottom: 32px;
}

.primary-bold {
    color: #009EE2;
    font-weight: bold;
}

.gd-login-button-wrap {
    margin-bottom: 60px;
}

.text-center {
    text-align: center;
}

.overflow-nav__language-change-wrap {
    display: inline-block;
}

.overflow-nav__flag-img {
    width: auto;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    font-weight: normal;
}

.overflow-nav__flag-text {
    display: inline-block;
    vertical-align: middle;
    color: #4E4D5A;
    font-family: 'Arial', 'Helvetica', 'sans-serif';
    color: #009ee2;
    text-decoration: underline;
}

.overflow-nav__flag-wrap-text {
    display: inline-block;
}

.change-language-text {
    color: #009EE2;
    font-weight: bold;
    font-size: 13px;
    font-family: 'Arial', 'Helvetica', 'sans-serif';
    display: inline-block;
}

.slide-nav-header-inner {
    display: inline-block;;
}

.slide-nav-logo {
    width: 7.9375rem;
}

.site-nav__header {
    position: relative;
}

.slide-nav__header {
    padding: 27px 30px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
    z-index: 21;
    position: relative;
}

.slide-nav__hide-scroll {
    overflow-y: hidden !important;
}

.js_slide-nav-trigger[data-navid="__home__"] {
    background-color: #F3F3F3;
    font-weight: 600 !important;
    margin-top: -1rem;
    margin-bottom: 1rem;
}

.slide-nav-btn {
    position: absolute;
    right: 0;
    top: 30px;
}

@media (max-width: 475px) {
    .slide-nav-btn {
        right: 29px;
    }
    .slide-nav-btn .js_slide-nav-open-trigger{
        width: 1.125rem;
        margin-right: 0px;
    }
}

.slide-nav__slide {
    padding-top: 1rem;
}

header, .home-slide, .slide-nav__slide {
    @include overflow-scrolling(touch);
}

/**
  Fix for the Russian site
 */
html.small-header {

    .fs_seedling:not(.js_mega-menu-trigger):not(.breadcrumb) {
        font-size: px2rem(10);
        line-height: px2rem(13);
        padding: px2rem(30) px2rem(15);
        margin: 0;

        .mega-menu__menu-item {
            margin-left: 30px;
        }

    }

    .fs_seedling {
        &.js_mega-menu-trigger,
        &.breadcrumb {
            font-size: px2rem(10);
        }
    }

}

@include below-ipad-breakpoint {
    .related-products-carousel-product-page {
        .swiper-wrapper {
            display: block;
            width: 100%;
        }
    }
    .related-products-carousel-snippet {
        padding-bottom: 0rem;

        .swiper-wrapper {
            display: block;
            width: 100%;
        }
    }

}
