.block-link {
    background: #fff;
     padding: 30px;
 }
 
.text-center {
    text-align: center;
 }
 
 .u_mb--0 {
    margin-bottom: 0;
 }

 .u_mt--0 {
    margin-top: 0;
 }
 
.block {
    display: block;
 }
 