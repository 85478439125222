.interactive-image_banner {
    &:before {
        background: none;
    }

    &:hover {
        &:before {
             background: none;
        }

        span {
            color: #ffffff;

            &:after {
                @include arrow($c_white);
                left: .1875em;
            }
        }
    }

    h2 {
        font-size: px2rem(24);
        width: 80%;
    }

    span {
        position: relative!important;
        top: px2rem(30);
        color: $c_cerulean;
    }

    @include at(md) {
        margin-bottom: 40px;
    }
}

.interactive-image_banner {
    background-color: #bcbcbc;
}

.big-image .interactive-image_banner {
    min-height: 22.4375rem;

    h2 {
        width: 40%;

        @include at(sm) {
            width: 80%;
        }
    }
}