/*
Breadcrumb.
*/

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  padding-top: px2rem(30);
  padding-bottom: px2rem(30);
  list-style: none;

  &__cart {
    padding-top: 30px;
    padding-left: 0 !important;
    margin-bottom: 20px !important;
  }
}

.breadcrumb-item {

	a {
		font-weight: normal;
	}

  + .breadcrumb-item {
    padding-left: px2rem(5);

    &::before {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: px2rem(5);
      content: '/';
      color: $c_silver;
      font-weight: normal;
    }
  }

  &.active {
    font-weight: bold;

    &.gray {
      color: #4E4D5A;
    }

    &:hover {
      text-decoration: none;
    } 
  }

  .t_color--highlight & {
    
    &.active {
      color: $c_scarpa-flow;
    }
  }
}

.breadcrumb-hover {
  &:hover {
    text-decoration: underline;
  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
}

@include at(md) {

  .breadcrumb {
    &__cart {
      margin-top: -76px !important;
    }
  }
}

@include at(sm) {

  .breadcrumb {
    display: none;

    &__cart {
      display: flex;
      margin-top: -48px !important;
    }
  }
}
