/*
Page Inner Nav.
*/

.page-nav {
	background-color: $c_white;
	margin-top: px2rem(-50);

	&__item {
		@include transition((background-color));

		height: px2rem(100);

		&:after {
			content: '';
			display: block;
			width: 1px;
			height: px2rem(34);
			background-color: $c_border;
			position: absolute;
			right: 0;
		}

		&--last {
			&:after {
				content: none;
				display: none;
			}
		}

		.icon {
			width: px2rem(25);
			height: px2rem(25);
		}

		&:hover {
			background-color: $c_mercury;
		}
	}
}

@include at(sm) {

	.page-nav {

		&__item {

			.icon {
				margin-right: 0;
			}

			span {
				display: none;
			}
		}
	}
}
