/*
News Teaser.
*/


.news-teaser {
	position: relative;
	min-height: px2rem(250);
	margin-bottom: px2rem(80);

	@include at(md) {
		margin-bottom: px2rem(40);
	}

	&__date {

		.icon {
			width: px2rem(24);
			height: px2rem(24);
			margin-right: px2rem(15);
		}

		span {
			font-size: 13px;
			text-transform: uppercase;
		}

		@include at(sm) {
			@include type(sans, seedling);
		}
		

		@include at(md) {
			padding-top: 2rem;
			padding-bottom: 1rem;
		}

	}

	&__image {
		position: absolute;
		top: 0;
		right: 0;

		@include at(lg) {
			width: 70%;
		}
		
		@include at(md) {
			width: 50%;
		}

		@include at(sm) {
			margin-right: - $grid-gutter-width / 2;			
		}
	}

	&__body {
		position: relative;
		flex-direction: column;
		padding: px2rem(30);
		background-color: rgba($c_cerulean, 0.8);
		min-height: px2rem(180);
		max-width: px2rem(430);

		@include at(md) {
			width: 90%;
		}

		.link {

			&:hover {
				color: $c_white;
			}
		}
	}

	&--small {
		min-height: auto;

		.news-teaser__date {
			@include type(sans, seedling);

			.icon {
				margin-right: px2rem(10);
			}
		}

		.news-teaser__image {
			visibility: hidden;

			@include at(sm) {
				visibility: visible;
			}
		}

		.news-teaser__body {
			background-color: $c_cerulean;
			width: 100%;

			@include at(sm) {
				background-color: rgba($c_cerulean, 0.8);
			}
		}

		h3 {
			line-height: 1.5;
		}

		.link {
			@include type(sans, seedling);
		}
	}
}