/*
Author
*/

.author {

	padding: px2rem(30) px2rem(35);
	margin-bottom: px2rem(50);

	@include at(md) {
		padding: px2rem(20);
		margin-bottom: px2rem(20);
	}

	@include at(sm) {
		padding: px2rem(20);
		margin-bottom: 0;
	}

	&__photo {
		border-radius: 100%;
		border: solid 2px $c_gray;
		margin-right: px2rem(50);
		width: px2rem(70);
		height: px2rem(70);

		@include at(md) {
			width: px2rem(66);
			height: px2rem(66);
			margin-right: px2rem(20);
		}

		@include at(sm) {
			margin-right: px2rem(10);
		}
	}

	&__role {
		margin-bottom: px2rem(10);
		line-height: 1.25;
	}

	&__name {
		color: $c_scarpa-flow;
		margin-bottom: px2rem(5);
	}

	&__social {

		.icon {
			width: 20px;
			height: 20px;
			margin: 0 px2rem(10) 0 px2rem(20);
		}
	}

	&--last {
		margin-bottom: 0;
	}
}
