.related-products {
  
  &__container {
    padding: 3rem 0.5rem 4rem 0.5rem;
  }

  &__title-container {
    position: relative;
  }

  &__arrows-container {
    position: absolute;
    right: 0;
    top: 0;

    span {
      cursor: pointer;
      display: inline-block;
      margin-left: 2rem;
    }
  }

  &__pagination-container {
    padding-top: 4rem;
  }

  &__prev {
    &::before {
      content: url(/static/bundles/img/svg/menu-arrow.svg);
      height: 10px;
      width: 10px;
      display: inline-block;
      transform: rotate(-180deg);
      transform-origin: bottom;
    }
  }

  &__next {
    &::before {
      content: url(/static/bundles/img/svg/menu-arrow.svg);
      height: 10px;
      width: 10px;
      display: inline-block;
    }
  }
}



