.useful-documents {

  &__title {
    display: inline-block;
    margin-left: 1rem;
    transform: translateY(0.2rem);

    @include below-ipad-breakpoint {
      width: 70%;
      line-height: 1.7;
    }

    @include smallPhone {
      font-size: 0.8rem;
      line-height: 1.1;
    }
  }

  &__text-content {
    padding: 1rem 0 1.5rem 0;
    margin-bottom: 0.7rem;
    border-bottom: solid 1px $c_border-light;
    position: relative;

    &--right {
      position: absolute;
      right: 0;

      @include below-ipad-breakpoint {
        top: 2rem;
      }
      @include smallPhone {
        top: 1.2rem;
      }
    }
  }

  &__link {
    color: $c_cerulean;
    text-decoration: underline;
    font-weight: lighter;
    margin-left: 5rem;
    font-size: 1rem;
    @include largeTablet {
      margin-left: 1rem;
    }

    @include below-ipad-breakpoint {
      display: none;
    }
  }

  &__icon {
    display: inline-block;
    width: auto;
    height: 30px;
    vertical-align: middle;
    margin-left: 0.5rem;
  }

  &__icon-download {
    width: auto;
    height: 25px;
    display: none;
    @include below-ipad-breakpoint {
      display: inline-block;
      margin-left: 1.7rem;
      transform: translateX(-0.7rem);
    }

    @include phone {
      margin-left: 1rem;
      transform: translateX(-0.3rem);
    }
  }
}