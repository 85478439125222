/*
Video.
*/

.video__carousel {
  overflow: visible;
}

.video-teaser {
  $padding: px2rem(28);

  &__inner {
    position: relative;
    cursor: pointer;
  }

  &__title {
    position: absolute;
    top: $padding;
    left: $padding;
    font-size: 21px;
  }

  &__controls {
    position: absolute;
    bottom: $padding;
    left: $padding;
    height: px2rem(30);

    .icon {
      width: px2rem(30);
      height: px2rem(30);
    }
  }

  &__image {
    img {
      width: 100%;
    }
  }

  &__time {
    position: absolute;
    bottom: $padding;
    right: $padding;
    color: $c_white;
    background-color: rgba(255,255,255,0.33);
    padding: px2rem(8) px2rem(22);
    border-radius: 100px;
  }

  &:before {
    @include transition(margin-top);

    content: '';
    display: block;
    width: px2rem(32);
    height: px2rem(32);
    position: absolute;
    top: px2rem(-5);
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    transform-origin: center center;
    background-color: rgba($c_text-dark, 0.6);

    margin-top: px2rem(12);

    @include at(sm) {
      content: none;
    }
  }

  &-controls {
    &__play-button {
      &.hide {
        display: none;

        & ~ .video-teaser-controls__playing-message {
          display: block;
        }
      }

      &.paused {
        display: initial;

        & ~ .video-teaser-controls__playing-message {
          display: none;
        }
      }
    }

    &__playing-message {
      display: none;
    }
  }

  &.active {
    &:before {
      margin-top: 0;
      border: solid 4px $c_cerulean;
      background-color: $c_cerulean;
    }

    .video-teaser__inner {
      border: solid 4px $c_cerulean;
    }
  }

  &.playing {
    .video-teaser-controls {
      &__play-button {
        display: none;
      }

      &__playing-message {
        display: initial;
      }
    }
  }

  &.paused {
    .video-teaser-controls {
      &__play-button {
        display: initial;
      }

      &__playing-message {
        display: none;
      }
    }
  }
}

.pimcore_area_sidebarvideo {
    .video-teaser:before {
        display: none;
    }
}

.video {

  // text-align: center;
  background-color: $c_wild-sand;
  border-bottom: solid 6px $c_atlantis;

  &__thumbs {
  }

  &__thumb {

  }

}

.video-carousel {
  &__navigation {
    position: relative;
    width: 100%;
    height: px2rem(50);
  }
}

  .section-video {
    .section-title {
      display: none;
    }
  }

@include at(sm) {

  .section-video {
    .section-title {
      display: flex;
    }
  }
}


.homepage-video-carousel {
  .swiper-button {
    &-prev, &-next {
      position: relative;
      margin-top: 10%;
    }

    &-prev {
      margin-right: 20px;
    }

    &-next {
      margin-left: 20px;
    }
  }
}

@media (max-width: 768px) {
  .homepage-video-carousel {
    .swiper-button {
      &-prev, &-next {
        margin-top: 15%;
      }
    }
  }
}

@media (max-width: 575px) {
  .homepage-video-carousel {
    .swiper-button {
      &-prev, &-next {
        display: none !important;
      }
    }
  }
}

/*
Video list
*/
.video-list {
  > div {
    margin-bottom: 30px;
  }
}


.video-thumbnail-link {
  width: 320px;
}