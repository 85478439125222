/*
Functions
*/

/*
Hover Color Picker
Asseses the darkness of a color & decides if the hover should be lighter or darker
*/
@function hover-picker($color) {

	@if lightness($color) > 60 {
		@return shade($color, 20);
	} @else {
		@return tint($color, 20);
	}

}









/*
Tint and Shade.
Alternative to lighten and darken.
*/
@function tint($color, $percent) {
	@return mix(white, $color, $percent);
}

@function shade($color, $percent) {
	@return mix(black, $color, $percent);
}









/*
Text Colour Picker.
*/
@function text-color-picker($color) {

	@if lightness($color) > 60 {
		@return $color-text-dark;
	} @else {
		@return $color-text-light;
	}

}









/*
First and last in list.
http://hugogiraudel.com/2013/08/08/advanced-sass-list-functions/
*/
@function first($list) {
	@return nth($list, 1);
}

@function last($list) {
	@return nth($list, length($list));
}









/*
Converts pixels to relative units.
*/
@function px2em($pixels, $base: $base-font-size) {
	@return ( $pixels / $base * 1em);
}

@function px2percent($pixels, $base) {
	@return ( $pixels / $base * 100%);
}









/*
Get Color
Returns a specific theme colour from the map $theme.
*/
@function get-color($id, $theme) {
  @return map-get(map-get($color-schemes, $id), $theme);
}
