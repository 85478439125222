// ==========================================================================
// #Slide Product
// ==========================================================================


.md-slide-product {

    &__row{
       &:after {
          content: "";
          clear: both;
          display: table;
       }
    }

    &__nav {
       width: auto;
       position: absolute;
       bottom: -19px;
       left: 50%;
    }

    &__nav-item {
       width: 5px;
       height: 5px;
       background-color: #E3E3E3;
       border: 1px solid;
       border-color: transparent;
       display: inline-block;
       border-radius: 50%;
       cursor: pointer;
       &.is-active {
          background-color: #009FE3;
          border-color: #008DC9;
       }
    }

    &__main-sidebar {
       float: left;
       width: 100px;
    }

    &__main-content {
       float: left;
       padding: 0 20px;
       width: calc(100% - 120px);
    }

    &__card {
        width: 100px;
        height: 100px;
        border: 1px solid #F0F0F0;
        position: relative;
        overflow: hidden;

       img {
          height: 100%;
          object-fit: cover;
       }
    }

    &__header {
       margin-bottom: 17px;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 8px;
      color: #8E8E8E;
      word-break: break-word;
      &b {
        font-weight: bold;
      }
    }

    &__sub-title {
       color: #8E8E8E;
       display: block;
       font-size: 13px;
    }

    &__link {
       color: #009EE2;
       text-decoration: none;
       font-weight: bold;
       font-size: 13px;
    }
 }

  .slider {
      position: relative;

    &__wrapper {
      display: flex;
      width: 10000px;
      margin-left: 0px;
      transition-duration: .6s;
      transition-timing-function: ease-out;
     }

    &__item {
       width: 400px;
       margin-right: 40px;
    }

 }


  .slider-toggles {
      display: flex;
      width: auto;
      position: absolute;
      bottom: -15px;
      left: 50%;
      @include transform(translateX(-50%));

     &__item {
       width: 5px;
       height: 5px;
       background-color: #e3e3e3;
       border: 1px solid;
       border-color: transparent;
       border-radius: 50%;
       cursor: pointer;
       margin-right: 6px;
       &.is-active {
          background-color: #009fe3;
          border-color: #008dc9;
       }
     }
 }


 .position-center {
     position: absolute;
     top: 50%;
     left: 50%;
     @include transform(translate(-50%, -50%));
 }