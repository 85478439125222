/*
Aside
*/


.aside-block {

	padding: px2rem(30);
	color: $c_white;
	background-color: $c_cerulean;
	margin-bottom: px2rem(30);

	h2 {
		color: $c_white;
		@include type(bold, scotspine);
	}

	a {
		font-weight: normal;
		color: inherit;
		text-decoration: underline;
	}

	hr {
		border: none;
		background-color: rgba($c_white, 0.3);
		height: 1px;
		margin: px2rem(40) 0;
	}
}
