/*
    Old Filter.
*/

.filter {
    margin-top: px2rem(-50);
    line-height: 1.4;

    &-background {
        background-color: $c_cerulean;
    }

    &__item {
        @include transition((background-color));

        background-color: $c_pop;
        color: $c_white;
        height: px2rem(100);
        padding: px2rem(18) px2rem(30);
        position: relative;
        z-index: 1;
        width: 100%;
        text-align: left;

        &:after {
            content: '';
            display: block;
            width: 1px;
            height: px2rem(80);
            background-color: rgba(255, 255, 255, .1);
            position: absolute;
            right: 0;
        }

        &--last {
            &:after {
                content: none;
                display: none;
            }
        }

        &:hover {
            background-color: $c_dodger-blue;
        }

        .icon {
            width: px2rem(25);
            height: px2rem(25);
            z-index: -1;
        }

        @include at(md) {
            height: px2rem(130);
        }
    }

    &-item {

        &__inner {
            width: 75%;
            z-index: -1;
        }

        &__preheading {
            margin-bottom: 0.5em;
        }
    }

    &__header {
        display: none;
    }

    &__footer {
        display: none;
    }

    &__reset-button {
        color: $c_pop;
        white-space: nowrap;
        margin-right: 0.5rem;

        .icon {
            width: px2rem(15);
            height: px2rem(15);
            margin-right: 0.5rem;
        }
    }

    &_category {
        background-color: $c_dodger-blue;
    }

}

@include at(sm) {

    .filter {
        margin-left: - $grid-gutter-width / 2;
        margin-right: - $grid-gutter-width / 2;

        &__header {
            display: block;
        }

        &__footer {
            display: block;
        }

        &__inner {
            display: none;
            position: absolute;
            top: px2rem(100);
            z-index: 2;

            &.is-open {
                display: block;
            }

            .filter__item {
                background-color: $c_white;
                color: $c_text-dark;
            }
        }

        &-item {
            &__inner {
                position: relative;
                width: 100%;
                border-bottom: 1px solid $c_pop;
                padding-bottom: px2rem(8);
                z-index: -1;

                &:after {
                    content: '';
                    display: block;
                    background-image: url(/static/bundles/img/svg/arrow-down.svg);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: px2rem(14);
                    height: px2rem(7);
                }
            }
        }
    }

    .filter-is-open .filter__backdrop {
        opacity: 1;
        z-index: 0;
    }
}


