.current-orders {

  &__pairs {
    line-height: 0;

    &:nth-child(2) {
      @include below-ipad-breakpoint {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  &__btn-container {
    margin-top: 2rem;
  }
}