/*
Buttons.
*/

.button {
    @include transition(background-color, 2);

    padding: px2rem(30) px2rem(73);
    background-color: $c_cerulean;
    color: $c_white;
    border-radius: px2rem(30);
    display: inline-block;
    cursor: pointer;
    line-height: 0;

    &:hover {
        background-color: $c_atlantis;
    }

    &--pop-light {
        background-color: $c_pop-light;
    }

    &--block {
        display: block;
        width: 100%;
    }

    &:disabled {
        background-color: $c_silver;
        cursor: auto;
    }

    &--add-to-quote {
        background-image: url(/static/bundles/img/svg/basket.svg);
        padding: px2rem(10);
        font-size: px2rem(14);
        background-repeat: no-repeat;
        background-size: px2rem(16);
        background-position: left center;
        padding-left: px2rem(20);
    }

}

@include at(sm) {

    .button {
        width: 100%;

        &--inline {
            width: auto;
        }
    }

}
