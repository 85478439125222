.checkout {
  background-color: $c_white;
  padding: 50px;
  transform: scaleY(0.999);
  transform-origin: top;
  margin-bottom: 1rem;

  .t_generic-text {
    margin-bottom: 2rem;
  }

  .t_generic-text--light {
    margin-bottom: 2rem;
  }

  .f_radio-container {
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 575px) {
      margin-bottom: 0rem;
    }
  }

  .b_button-container-left {
    margin-bottom: 2rem;
  }

  .f_checkbox-container {
    margin-bottom: 2rem;
  }

  @include below-ipad-breakpoint {
    padding: 40px 30px 50px 30px;
  }

  &__page-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--section-1 {
    max-height: 8rem;
    overflow: hidden;
    
    h2 {
      margin-bottom: 10rem;
    }

    &--is-visible {
      transform: scaleY(1);
      max-height: 1500px;
    }
  }

  &--section-2 {
    max-height: 8rem;
    overflow: hidden;

    h2 {
      margin-bottom: 10rem;
    }

    &--is-visible {
      transform: scaleY(1);
      max-height: 1500px;
    }
  }

  &--section-3 {
    max-height: 8rem;
    overflow: hidden;

    h2 {
      margin-bottom: 10rem;
    }

    &--is-visible {
      transform: scaleY(1);
      max-height: 1500px;
    }
  }

  &--section-1,
  &--section-2,
  &--section-3 {
    &--is-visible {
      max-height: initial;
      h2 {
        margin-bottom: 1rem !important;
      }
    }
  }
  
  &__title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  &__drop-icon {
    cursor: pointer;
    width: 15px;
    transition: all 0.2s;
    margin-top: 8px;
    
    &-1 {
      transform: rotate(0);

      &--is-pointing-down {
        transform: rotate(-180deg );
      }
    }

    &-2 {
      transform: rotate(-180deg);

      &--is-pointing-down {
        transform: rotate(0);
      }
    }

    &-3 {
      transform: rotate(-180deg);

      &--is-pointing-down {
        transform: rotate(0);
      }
    }
  }

  &__input-container {
    padding-top: 1rem;
    opacity: 1;
    height: auto;
    transition: opacity 0.4s ease-in;


    &.d-none-fade {
      opacity: 0;
      height: 0px;
    }
  }

  &__subtitle {
    margin-bottom: 3rem;
  }

  &__subtitle-billing {
    margin-bottom: 2rem;
  }

  &__generic-text {
    line-height: 1.5;

    &--terms {
      margin-left: 0.2rem;
      @include below-ipad-breakpoint {
        width: 60%;
      }
    }
  }

  &__contact-form {
    width: 90%;

    @include below-ipad-breakpoint {
      width: 100%;
    }
  }

  &__radio-button {
    margin-right: 1rem;
  }

  &__radio-button input {
    transform-origin: center;
    transform: scale(1.3);
    margin-right: 0.1rem;
  }
  
  &__contact-input {
    display: block;
    width: 75%;
    border: transparent;
    border-bottom: solid 1px $c_mercury;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    color: $c_pop;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__form-label {
    @include phone {
      font-size: 0.8rem;
    }
  }

  &__next-btn {
    padding-top: 2rem;
  }

  &__contact-text {
    margin-bottom: 1.5rem;
    margin-top: 2rem;
  }

  &__radio-buttons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    @media (max-width: 575px) {
      flex-direction: column;
    }

    &--b-margin {
      margin-bottom: 2rem;
    }

    &--individual {
      margin-right: 2rem;
    }

    .f_radio-container {
      margin-right: 2rem;
      position: relative;
    }

    .f_radio-container:first-child input[type="radio"] {
      left: 100%;
    }

    @media (max-width: 575px) {
      .f_radio-container:first-child input[type="radio"] {
        left: 43px;
        top: 69px;
      }
    }
  }

  &__radio-inline {
    flex-direction: column;

    > .f_radio-container {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  &__shipping-checkbox-container {
    position: relative;
    margin-bottom: 3rem;
  }

  &__contact-btn {
    font-size: 0.9rem;
  }

  &__special-instructions {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  &__checkbox-label {
    margin-left: 0.5rem;
  }

  &__checkbox-text {
    display: inline-block;
    margin-bottom: 2rem;
  }

  &__payments-title {
    font-weight: 600;
  }

  &__payments {
    margin-top: 1rem;
    margin-bottom: 3rem;

    @include phone {
      width: 100%;
    }
  }

  &__payments-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__payments-logo {
    width: 80px;
    display: inline-block;

    &--visa {
      display: inline-block;
      transform: translateY(-0.5rem) translateX(-1rem);
    }

    @include below-ipad-breakpoint {
      width: 40px;
      transform: translateY(-2rem);
    }

    @include phone {
      transform: translateY(-1rem);
     margin-left: 4rem;
    }
  
  }

  &__specific-date {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: all 0.5s;
    transform: scaleY(0);
    transform-origin: top;
  }

  &__form-fields {
    margin-bottom: 2rem;

    &--shipping-date {
      margin-bottom: 1rem;
    }
  }

  &__delivery-note-message {
    resize: none;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transition: all 0.5s;
    transform: scaleY(0);
    transform-origin: top;
    display: block;
    margin-top: 2rem;

    @include below-ipad-breakpoint {
      width: 100%;
    }
  }

  &__specific-date-menu {
    display: inline-block;
    background: url("/tractelfrontend/static/bundles/img/svg/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 100px;
  }

  &__specific-date-menu {
    border: 0;
    background: 0 0;
    color: $c_pop;
    border-radius: 0;
    padding: 0.3125rem 0.5rem;
  }

  &__payment-section-top {
    display: flex;
    justify-content: space-between;
  }

  &__globalP-logo {
    width: 200px;
    margin-right: 3rem;

    @include below-ipad-breakpoint {
      width: 110px;
      margin-top: 2rem;
      margin-right: 1rem;
    }
  }

  &__input-container--is-inline {
    position: relative;
  }

  &__billing-inline-form {
    position: relative;
  }

  &__card-logo-container {
    position: relative;
  }

  &__card-logos {
    position: absolute;
    top: -0.2rem;
    right: 0;
    width: 170px;

    &--top {
      display: none;

      @include below-ipad-breakpoint {
        display: block;
        width: 170px;
        margin: 1rem 0;
      }
    }

    @include below-ipad-breakpoint {
      width: 110px;
      display: none;
    }
  }

  &__tooltip-container {
    position: relative;
  }

  &__tooltip {
    position: absolute;
    display: inline-block;
    right: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
    outline: none;

    + .t_generic-text {
      width: calc(100% - 25px);
    }

    + .field__select:not(.field_type-select):not(.field_type-select-multi) {
      background-image: none !important;
    }

    @media (max-width: 520px) {
      transform: scale(0.8);
    }
  }

  &__tooltip-trigger {
    display: inline-block;
    background-color: $c_pop;
    color: $c_white;
    width: 4px;
    height: 4px;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  &__continue {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    @include ipad-column-breakpoint {
      display: none;
    }

    &-btn-container {
      margin-top: 1rem;

      @include ipad-column-breakpoint {
        margin-top: -0.9rem;
      }

      @include below-ipad-breakpoint {
        text-align: center;
        margin-top: 1rem;
      }
    }

    &-text {
      @include below-ipad-breakpoint {
        line-height: 1.7;
      }
    }
  }

  &__payments-content {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
  }
}

@media (max-width: 767px) {
  .checkout {
    padding: 22px;

    &--section-1,
    &--section-2,
    &--section-3 {
      max-height: 70px;

      &--is-visible {
        max-height: initial;
      }
    }
  }
}

#checkout-form-specific-date-check:checked ~ .checkout__specific-date {
  visibility: visible;
  opacity: 1;
  height: auto;
  transform: scaleY(1);
  padding-bottom: 1rem;
  margin-top: 2.5rem;
}

#checkout-form-delivery-note-check:checked ~ .checkout__delivery-note-message {
  visibility: visible;
  opacity: 1;
  height: 10rem;
  transform: scaleY(1);

  textarea {
    height: 7rem;
    margin-bottom: 0.2rem;
    margin-top: 1rem;
  }

  small {
    color: $c_gray;
    margin-bottom: 2rem;
  }
}

#checkout-main-container {
  input + .error-speech-bubble {
    top: -1rem;
  }

  .field--has-border + .error-speech-bubble {
    top: -2rem;
  }
}

.checkout__page-title-container {
  @include below-ipad-breakpoint {
    position: relative;
  }
}

.checkout__globalP-logo--top {
  opacity: 0;
  width: 150px;
  
  @include below-ipad-breakpoint {
    opacity: 1;
    display: inline-block;
    transform: translateY(-10px);
    position: absolute;
    right: 0;
  }
}

@include below-ipad-breakpoint {
  .checkout__payment-section-top--hidden {
    display: none;
  }
}

#checkout-form-confirm-email:required {
  box-shadow:none;
}

.green-tick {
  display: inline-block;
  width: 23px;
  height: 11px;
  position: relative;
  margin-left: 6px;

  &::before {
    content: ' ';
    background-image: url(/static/bundles/img/svg/green-tick.svg);
    display: inline-block;
    width: 15px;
    height: 12px;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    left: 0px;
    background-size: 15px;	
  }
}

.free-ship {
  &__checkout {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 90%;
  }

  &__cart {
    display: none;
  }
}

.free-ship__checkout {
  .green-tick {
    margin-left: 6px;
  }
}