/*
	Colours
*/

// https://davidwalsh.name/sass-color-variables-dont-suck
// http://chir.ag/projects/name-that-color

$c_white: #fff;
$c_black: #000;
$c_green: #86C543;

$c_cerulean: #009EE2;
$c_atlantis: #9ace49;
$c_wild_sand: #f4f4f4;
$c_mercury: #e5e5e5;
$c_silver: #c4c4c4;
$c_silver_chalice: #b2b2b2;
$c_chicago: #5c5c5b;
$c_gallery: #ecebea;
$c_gray: #8e8e8e;
$c_dodger-blue: #07B4FF;
$c_dodger-blue-faded: #07b5ff71;
$c_scarpa-flow: #4E4D5A;
$c_grey-2: #F3F3F3;
$c_border: $c_wild-sand;
$c_border-light: $c_gallery;

$c_text-dark: $c_chicago;
$c_text-light: $c_silver;
$c_pop: $c_cerulean;
$c_pop-light: $c_dodger-blue;
$c_secondary: $c_atlantis;

/*
Helpful colouring classes.
*/
.t_background--primary {
  background-color: $c_wild-sand;
}

.t_background--light {
  background-color: $c_white;
}

.t_background--pop {
  background-color: $c_cerulean;
}

.t_color--highlight {
  color: $c_cerulean;
}

.t_color--light {
  color: $c_white;
}

.t_color--mid {
  color: $c_silver;
}

.t_color--gray {
  color: $c_gray;
}

.t_color--dark {
  color: $c_text-dark;
}

.t_color--darkGray {
  color: $c_scarpa-flow;
}