/*
Images.
*/

img {
  display: block;
  max-width: 100%;

  &.center-block {
    @include center-block();
  }
}

picture {
  display: block;
}

.full-bleed-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    min-width: 100%;
    max-width: none;
    width: auto;

    min-height: 100%;
    max-height: none;
    height: auto;

    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header__image {
  .header__body h1 {
    top: 215px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.h-auto:not([data-ll-status="loading"]) {
  height: auto !important;
}

.w-auto:not([data-ll-status="loading"]) {
  width: auto !important;
}

.brands__brand-logo {
  .w-auto:not([data-ll-status="loading"]) {
    display: flex !important;
  }
}

.s-auto:not([data-ll-status="loading"]) {
  height: auto !important;
  width: auto !important;
}

img[src="/static/bundles/img/svg/image-load.svg"]:not([data-ll-status="loaded"]) {
  border-color: transparent !important;
}

.download__image {
	img[src="/static/bundles/img/svg/image-load.svg"]:not([data-ll-status="loaded"]) {
		margin-left: auto;
		margin-right: auto;
	}
}

.download-list {
	img[src="/static/bundles/img/svg/image-load.svg"]:not([data-ll-status="loaded"]) {
		box-shadow: none !important;
	}
}

.image-thumbnails-220 {
	img[src="/static/bundles/img/svg/image-load.svg"]:not([data-ll-status="loaded"]) {
		padding: 0 !important;
	}
}

img[src="/static/bundles/img/svg/image-load.svg"].w-100:not([data-ll-status="loaded"]) {
  width: auto !important;
}

.tabs__table-thumbnail-container {
  img[src="/static/bundles/img/svg/image-load.svg"]:not([data-ll-status="loaded"]) {
    width: auto !important;
  }
}

.video-teaser__image {
	img[src="/static/bundles/img/1x1-black.png"]:not([data-ll-status="loaded"]) {
    height: 175px !important;
	}
}

.product-carousel__image {
  width: 100%;
  height: 100%;

  picture, img {
    width: 100%;
    height: 100%;
  }

	img.img-loader {
    opacity: 1 !important;
    width: 60px !important;
    height: 60px !important;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
	}
}