.checkout-page-title {
  
  &--has-no-bg {
    display: block;
    font-size: 1.3rem;
    font-weight: 600;
    width: 100%;
    margin-bottom: 1.5rem;
    margin-left: 0px;
  }
}