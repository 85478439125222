.checkout-btn {
  background: $c_pop;
  color: $c_white;
  padding: 1rem 1.5rem;
  border-radius: 40px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: $c_secondary;
  }

  &--modal {
    font-size: 0.8rem;
  }

  &--order-summary-full-width {
    @include below-ipad-breakpoint {
      display: block;
    }
  }

  &--is-full-width {
    @include below-ipad-breakpoint {
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--is-full-width-on-col-xl {
    @include ipad-column-breakpoint {
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--small {
    padding: 0;
    background: transparent;
    color: $c_pop;
    text-decoration: underline;
    font-weight: lighter;
    font-size: 0.8rem;
    transition: all 0.2s;

    &:hover {
      background-color: transparent;
      color: $c_pop_light !important;
    }
  }

  &--shallow {
    padding: 0;
    background-color: transparent !important;
    color: $c_pop;
    text-decoration: underline;
    font-weight: lighter;
    transition: all 0.2s;
    font-size: 85%;
    
    &:hover {
      color: $c_pop_light;
    }
  }

  &--smaller-font {
    font-size: 0.8rem;
  }

  &--ghost {
    background-color: transparent;
    border: solid 2px $c_pop;
    color: $c_pop;
    padding: 1rem 1.5rem;

    &:hover {
      background-color: $c_pop;
      color: white;
    }

    &__accordion {
      @include below-ipad-breakpoint {
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }

  &--has-extra-padding-on-mobile {
    @include phone {
      display: block;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--is-button {
    border-width: 0px !important;

    &:disabled {
      background-color: $c_silver !important;
      cursor: auto !important;
    }
  }
}

#js_checkout-signin{
  padding: 1rem 2.5rem;
  margin-top: 1rem;
}