/*
Overflow Nav.
*/

.bg-white {
	background-color: white;
}

.overflow-nav {
	@include transition(transform, 6);
	@include fixed(top 0 left 0);
	@include box(100%);
	z-index: 100;
	display: flex;
	transform: translateX(100%);
    overflow: auto;

	&.is-open {
		transform: none;

		.overflow-nav__left {
			transform: none;
		}
	}

	@media only screen and (max-width: 992px) {
	    display: none;
	}

	&__logo {
		margin-left: 0;
		margin-bottom: 2rem;
	}

	&__outer {
		//padding: px2rem(46) 0;
		box-shadow: 10px 0 20px -14px #c3c3c3;
		@include at(lg) {
			padding-right: 1.5rem;
			padding-left: 1.5rem;
		}
	}

	&__inner {
		padding-top: 2.875rem;
		padding-bottom: 2.875rem;
		
	}

	&__inner-content {
		display: none;

		&.is-active {
			display: block;
		}
	}

	&__header {
		margin-bottom: px2rem(96);
	}

	&__left {
		@include transition(transform, 6);

		width: 50%;
		height: 100%;
		transform: translateX(50%);
		background-color: $c_white;
		z-index: 1;

		&-inner {
			width: px2rem(570);

			@include at(lg) {
				width: auto;
			}
		}

		&-item {
			display: flex;
			justify-content: flex-end;

			@include at(lg) {
				position: relative;
				justify-content: flex-start;
				margin-left: -1.5rem;
				margin-right: -1.5rem;
				padding-left: 1.5rem;
			}
		}

		&-shadow {
			// box-shadow: 10px 0 27px -14px #c3c3c3;
		}
	}

	&__right {
		width: 50%;
		height: 100%;
		background-color: $c_white;

		@media (min-width:992px) and (max-width: 1200px) {
			padding-left: 1.5rem;
		}

		&-inner {
			width: px2rem(570);
			max-width: 100%;
		}

		&-item {
			margin-left: px2rem(115);

			@include at(lg) {
				margin-left: 0;
			}
		}
	}

	&__primary {
		margin-bottom: px2rem(60);

		@media (min-width:992px) and (max-width: 1200px) {
			padding-left: 1.5rem;
		}
	}

	&__primary-item {
		@include transition((background-color, color));

		padding: px2rem(30) 0;
		border-top: 1px solid $c_border;

		&:last-child {
			border-bottom: 1px solid $c_border;
		}

		&:hover {
			background-color: $c_pop;
			color: $c_white;
		}
	}

	&__secondary {
		@media (min-width:992px) and (max-width: 1200px) {
			padding-left: 1.5rem;
		}
	}

	&__secondary-item {
		padding: px2rem(30) 0;
	}

	&__about {
		width: px2rem(370);
		@include at(lg) {
			&__about {
				width: auto;
			}
		}
	}

	&__button-wrap {
	    width: 30.625rem;
	    @include at(lg) {
	    	width: auto;
	    }
	}

	&__footer {
		margin-top: px2rem(212);
	}
}


 
 


 
