/*
Featured Article teaser.
*/

.featured-article-teaser {
	position: absolute;
	width: px2rem(635);
	bottom: calc(100% + 3rem);
	padding: px2rem(30);
	background-color: rgba($c_cerulean, 0.8);

	&__date {
		position: absolute;
		top: -4rem;

		.icon {
			width: 1.5rem;
			height: 1.5rem;
			margin-right: px2rem(15);
		}
	}

	.link {
		&:hover {
			color: $c_white;
		}
	}
}









@include at(lg) {
	.featured-article-teaser {
		width: px2rem(530);
	}
}









@include at(md) {
	.featured-article-teaser {
		width: px2rem(690); // Bootstrap container inner width.
	}
}









@include at(sm) {

	.featured-article-teaser {
		width: 100vw;
		left: 50%;
		transform: translateX(-50%);
		min-height: px2rem(220);
		background-color: $c_cerulean;

		&__date {
			position: static;
			margin-bottom: 1rem;
			color: $c_white;
		}
	}
}
