/*
Language select
*/

.language-select {

	&__dropdown {
		position: relative;
		height: 100% !important;
		min-height: 38px;
	}

	&__button {
		position: relative;
		font-size: px2rem(13);
		color: #A4A4A3;
		font-weight: normal;
		text-decoration: none !important;
	}

	&__empty {
		margin-right: 7px;
		padding-right: 4px;
	}

	&__empty::after {
		content: " ";
		background: url(/static/bundles/img/svg/arrow-down-blue.svg);
		background-repeat: no-repeat;
		display: block;
		position: absolute;
		width: 11px;
		height: 8px;
		top: 4px;
		right: -12px;
	}

	&__list {
		display: none;
		position: absolute;
		right: 0px;
		top: 26px;
		background-color: #ffffff;
		box-shadow: 0px 1px 11px -3px rgba(0,0,0,0.2);
		z-index: 1;
		padding: 5px 18px 5px 2px;
	}

	&__list a {
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		background-color: #ffffff;
		font-weight: lighter;
		text-decoration: none !important;
	}

	&__list a:hover {
		color: black;
	}

	&__dropdown:hover .language-select__list {
		display: block;
	}

}


/* 
	Mobile settings
*/
.slide-nav__item-inner .customer-portal-top__cart-icon {
    margin-top: -5px !important;
}

.mobile-language-link {
    padding-left: 0px;
    padding-right: 0px;
}
