/*
Mega Menu.
*/

.mega-menu {
    @include transition((transform, opacity), 2);
    visibility: hidden;
    position: absolute;
    width: 100%;
    background-color: $c_white;
    padding: px2rem(30);
    transform: translateY(px2rem(-80)); // transform up by height of nav.
    opacity: 0;
    z-index: -1;
    border-bottom: 6px solid $c_secondary;

    &--is-full {
        width: 100%;
        left: auto;
    }

    &--is-floating {
        min-width: px2rem(306);
        width: auto;
    }

    &--product-dropdown {
        width: 100% !important;
        left: 0 !important;
    }

    &--reset-dropdown-height {
        min-height: initial !important;
        padding-bottom: 0px;
    }

    &__menu-list--products {
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        justify-content: space-between;
        li {
            display: inline-block;
            margin-right: 1rem;
        }
    }

    &__menu-list--full {
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        justify-content: space-between;
        li {
            display: inline-block;
            margin-right: 1rem;
        }
    }

    &__container--full {
        left: 0px !important;
        width: 100% !important;
    }

    &__hide {
        display: none;

        &.activated {
            display: block;
            position: fixed;
            background-color: transparent;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: 40;
        }
    }

    &.was-open {
        z-index: 30;
    }

    &.is-open {
        transform: none;
        opacity: 1;
        z-index: 40;
        visibility: visible;
        margin-top: 1px;
    }

    &__menu-heading {
        margin-bottom: px2rem(40);
    }

    &__menu-list {
        &--single {
            column-count: 1;
            column-width: px2rem(250);
        }

        &--split {
            column-count: 2;
            column-width: px2rem(250);
            max-height: px2rem(410);
        }
    }

    &__menu-item {
        margin-bottom: px2rem(30);

        &:last-child {
            margin-bottom: px2rem(60);
        }
    }

    &__menu-inner {
        width: 100%;

        .icon:not(.menu-icon) {
            display: block;
            width: 11px;
            height: 11px;
            float: right;
            margin-top: 0x;
            cursor: pointer;
        }

        & + & {
            border-top: 1px solid $c_border;
            padding-top: px2rem(30);
        }
    }

    &__col {
        border-right: 1px solid $c_border;

        &--no-border {
            border-right: none;
        }
    }

    a {
        color: $c_text-dark;

        &:hover {
            color: $c_pop;
        }
    }

    .menu-indent {
        @for $i from 1 through 4 {
            &__#{$i} {
                padding-left: px2rem($i * 10);

                a {
                    color: $c_gray;
                }

                a:hover {
                    color: $c_pop;
                }

                &:first-child {
                    margin-top: px2rem(30);
                }

                &:last-child {
                    margin-bottom: 0px !important;
                }
            }
        }
    }
}

.navigation__link--arrow::after {
    background-image: url(/static/bundles/img/svg/menu-arrow.svg);
    transition: all 0.1s ease-in-out;
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    transform: scale(0.4) translateX(0.9rem);
    margin-left: 1.5em;
    position: relative;
    left: 0;
}

@include at(lg) {
    .mega-menu {
        width: auto;
        max-width: 100%;
        left: auto;
    }
}

@include at(md) {
    .mega-menu--product-dropdown {
        display: none;
    }
    .mega-menu {
        top: 99%;

        &__menu-list {
            &--split {
                max-height: none;
            }
        }
    }
}

@include at(sm) {
    .mega-menu {
        display: none;
    }
}

.mega-menu-mobile-nav-btn {
    display: inline-flex;
}

.mega-menu-desktop-nav-btn {
    display: none;
}

.mega-menu__nav-container {
    .nav__main {
        flex-grow: 1;
    }

    .nav__item {
        margin-right: 0px;
    }

    .nav__item a {
        padding-right: 2rem;
    }

    .mega-menu__our-brand {
        white-space: nowrap;
        width: auto;
        position: relative;
        overflow: hidden;
    }

    .mega-menu__our-brand .js_mega-menu-trigger {
        background-color: transparent;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 1;
    }

    .mega-menu__our-brand + div {
        border-left: 1px solid #dbdbdb;
    }

    .nav__button-search-mobile {
        display: none;
    }

    .nav__button-search-desktop {
        display: flex;
    }

    .js_mega-menu-mobile-nav,
    .mega-menu__our-brand-mobile {
        display: none;
        cursor: pointer;
    }
}

.mega-menu__mobile-back {
    display: none;
    width: 100%;
    margin-bottom: 2rem;
    padding: 1rem 2rem;
}

.mega-menu__mobile-back div {
    border-bottom: 1px solid #009ee2;
    color: #7d7d7d;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    padding-bottom: 1rem;
}

@media only screen and (max-width: 1200.5px) {
    .mega-menu__nav-container {
        .nav__item a {
            padding-right: 1rem;
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 991.5px) {
    .customer-portal-top__left > *:not(.nav-keep-mobile),
    .customer-portal-top__right > *:not(.nav-keep-mobile),
    .nav__search.js_search {
        display: none;
    }

    div[data-news-id] {
        display: none !important;
    }

    body.search-is-open {
        .nav__search.js_search {
            display: flex;
            position: absolute;
            width: calc(100% + 30px);
            height: 60px;
            top: 71px;
            left: -15px;
            right: 0px;
            border-bottom: 6px solid #9ace49;
            box-shadow: inset 0 3px 20px -7px rgba(0,0,0,0.29);
        }

        .nav-search__input {
            background-color: transparent;
        }

        #gather-search-form {
            width: 100%;
            height: 100%;
            border-radius: 0;
            border: none;
            max-width: initial;
        }

        #search-autocomplete {
            top: 60px;
            max-width: initial;
            box-shadow: none;
        }
    }

    body.mega-menu-mobile-nav-trigger {
        .customer-portal-top__container.container {
            padding-right: 15px;
        }

        .nav__button-search-mobile {
            display: none;
        }

        .nav__search.js_search {
            display: none !important;
        }
    }

    body.mega-menu-mobile-brand-trigger {
        .mega-menu__our-brand-mobile {
            position: absolute !important;
        }
    }

    .customer-portal-top__container.container {
        max-width: initial;
        padding-right: 0px;
    }

    .header-nav-container {
        .nav {
            box-shadow: none;
        }
    }

    .mega-menu__nav-container {
        .nav__button-search-mobile {
            display: flex;
        }

        .nav__button-search-desktop {
            display: none;
        }

        .mega-menu__our-brand {
            display: none;
        }

        .mega-menu__our-brand + div {
            border-left-color: transparent !important;
        }

        .nav__logo {
            margin-left: initial !important;
        }

        .nav__button {
            border: none !important;
        }

        .js_mega-menu-mobile-nav {
            display: flex;
            padding-right: 2rem;
            width: auto;
        }

        .js_search-open-trigger {
            padding-left: 2rem;
            width: auto;
        }

        .nav__list {
            display: block;
        }

        .nav__main {
            display: block;
            visibility: hidden;
            position: absolute;
            background-color: #fcfcfc;
            flex-direction: column;
            margin-left: 0px;
            width: 100%;
            min-height: calc(100vh - 5rem);
            height: auto;
            overflow-y: auto;
            top: -100vh;
            // top: 100%;
        }

        .mega-menu__our-brand-mobile {
            display: none;
            position: fixed;
            left: 0px;
            width: 100%;
            height: auto;
            bottom: 0px;
            padding: 1.5rem 0;
            text-align: center;
            background-color: #009ee2;
            color: #ffffff;
            font-size: 13px;
            font-weight: bold;
        }

        .nav__item {
            padding: 0 2rem;
        }

        .nav__item > a {
            width: 100%;
            color: #4e4d5a;
            display: block !important;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-right: 1px solid transparent;
            font-size: 13px !important;
            border-bottom: 1px solid #bababa !important;
            padding: 15px 27px 15px 0px !important;
            margin: 0px !important;
            text-align: left !important;
        }

        .nav__item[data-navid="products_nav"] > a {
            border-top: 1px solid #bababa !important;
        }

        .nav__item > a::after {
            content: "";
            background-image: url(/static/bundles/img/svg/menu-right-blue-mobile.svg) !important;
            width: 8px !important;
            height: 14px !important;
            top: calc(50% - 7px) !important;
            right: 13px !important;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            display: block;
        }
    }

    .mega-menu__container {
        height: 100vh !important;
        padding: 0 2rem !important;
        background-color: #fcfcfc;
        height: auto !important;
        min-height: initial;

        .row > div {
            display: none;
            max-width: 100%;
            flex: initial;
            border: none !important;
            margin: 0px !important;
        }

        .row > div:not(.mega-menu__blue) {
            padding: 0px !important;
        }

        .menu-mega__link,
        .menu-mega__header a,
        .menu-mega__header span {
            font-size: 13px !important;
            border-bottom: 1px solid #bababa;
            padding: 15px 27px 15px 0px !important;
            margin: 0px !important;
            border-right: 0px !important;
        }

        .menu-mega__link::after {
            background-image: url(/static/bundles/img/svg/menu-right-blue-mobile.svg) !important;
            width: 8px !important;
            height: 14px !important;
            top: calc(50% - 7px) !important;
            right: 13px !important;
        }

        .menu-mega__link.menu-expandable::after {
            background-image: url(/static/bundles/img/svg/menu-plus-blue-mobile.svg) !important;
            width: 18px !important;
            height: 18px !important;
            top: calc(50% - 9px) !important;
            right: 7px !important;
        }

        .nav-mobile-hidden {
            display: none !important;
        }

        .mega-menu__news-header {
            padding-left: 0px;
        }

        .mega-menu__contact-us {
            margin-top: 12rem;
            height: auto !important;
        }

        .mega-menu__about-us-policy-container .menu-mega__header {
            margin-top: 3rem !important;
            opacity: 0.9;
        }

        .mega-menu__about-us-policy a {
            color: #8e8e8e !important;
        }

        .mega-menu__about-us-policy a::after {
            background-image: url(/static/bundles/img/svg/menu-right-grey-mobile.svg) !important;
        }

        .menu-mega__header a,
        .menu-mega__header span {
            color: #7d7d7d !important;
        }

        .menu-mega__link {
            color: #4e4d5a !important;
        }

        .mega-menu__news-header {
            padding-left: 0px !important;
            margin-top: 2rem;
        }

        .mega-menu__news-header span {
            border-bottom: 1px solid transparent;
        }

        .mega-menu__news {
            padding-left: 0px !important;
            flex-direction: column;
        }

        .mega-menu__news > a {
            width: 100% !important;
            padding-bottom: 1.75rem !important;
            margin-bottom: -1rem;
        }

        .mega-menu__news-title {
            margin-bottom: 0.75rem !important;
        }

        .mega-menu__h-divider {
            border-bottom: none !important;
            margin-bottom: 0rem !important;
        }
    }
}

@media (min-width : 767.5px) {
	#megaMenuElement {
		top: -1px;
        z-index: 1999999946;
	}
}

#megaMenuBackground {
    display: none;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1999999945;
    top: 0px;
    background-color: transparent;
}

@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
    #megaMenuBackground {
        background-color: rgba(158,158,158,.2);
        filter: blur(10px);
        backdrop-filter: blur(10px);
    }
  }

@media (min-width : 991.5px) {

    body.mega-menu-is-open #megaMenuBackground {
        display: block;
    }

	#megaMenuElement {
		position: sticky;
	}

    .mega-menu-mobile-nav-btn {
        display: none;
    }

    .mega-menu-desktop-nav-btn {
        display: inline-flex;
    }

}

@media only screen and (max-width: 991.5px) {

    #megaMenuElement {
		top: 9px;
		z-index: 3;
	}

    #megaMenuElement {
        position: relative;
        height: 0px;
        overflow: hidden;
    }

    .nav-container::after {
        display: none;
    }

    body.mega-menu-mobile-nav-trigger .mega-menu__nav-container.nav {
        height: 5rem;
    }

    .nav__main {
        transition: all 0.4s ease-in-out;
    }

    body.mega-menu-mobile-nav-trigger {
        overflow-y: hidden;

        #megaMenuElement {
            position: relative;
            height: auto;
            overflow: initial;
        }
        
        .customer-portal-top {
            z-index: 34;
        }

        .nav__search {
            z-index: 33;
        }

        > header {
            height: calc(100vh - 5rem) !important;
            overflow-y: auto;
            margin-top: 5rem;
        }

        .header-nav-container {
            max-width: initial;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        
        .mega-menu__nav-container {
            .header__nav {
                position: relative !important;
            }

            &.nav {
                position: fixed !important;
                top: 0;
                left: 0;
                width: 100%;
                padding-top: 0;
                z-index: 31;
                padding-bottom: 0;
            }

            .nav__main {
                visibility: visible;
                top: 100%;
            }

            .nav__list {
                display: flex;
                flex-direction: column;
            }

            .mega-menu__our-brand-mobile {
                display: block;
            }

            .nav__search {
                z-index: 50;
            }

            .nav__item {
                width: 100%;
            }
        }

        .mega-menu__mobile-back:not(.menu-hidden) {
            display: block;
        }

        .nav__main {
            z-index: 33;
        }

        .mega-menu__container {
            visibility: visible;
            opacity: 1;
            top: calc(5rem - 9px) !important;
            min-height: calc(100vh - 5rem) !important;

            .row {
                display: flex;
                flex-direction: row;
            }

            .row > div:not(.mega-menu__blue) {
                display: block;
                flex: initial;
                max-width: 100%;
                height: auto;
            }

            .mega-menu__mobile-back {
                padding: 1rem 0rem;
                margin-bottom: 1rem;
                position: relative;
                margin-top: 1.2rem;
            }

            .mega-menu__mobile-back::before {
                content: "";
                background-image: url(/static/bundles/img/svg/menu-back.svg);
                background-repeat: no-repeat;
                background-position: center;
                display: block;
                width: 13px;
                height: 13px;
                position: absolute;
            }

            .mega-menu__mobile-back div {
                padding-left: 1rem;
            }
        }

        &.mega-menu-mobile-brand-trigger {
            .mega-menu__nav-container {
                height: calc(5rem - 9px);
                overflow: hidden;
            }

            > header {
                margin-top: 5rem;
                height: calc(100vh - 5rem) !important;
            }

            .mega-menu__container .mega-menu__blue.mega-menu__blue-timeout {
                top: 0px !important;
            }

            .mega-menu__container .mega-menu__blue{
                display: block;
                top: calc(100% + 4rem);
                transition: all 0.4s ease-in-out;
            }

            .mega-menu__container[data-navid="products_nav"] {
                display: block !important;

                .row > div:not(.mega-menu__blue) {
                    display: none !important;
                }
            }
        }
    }
}

.mega-menu__container {
    padding: 0px;
    border-bottom: 0px;
    height: auto;

    .mega-menu__menu-inner,
    .mega-menu__menu-body,
    .row {
        height: 100%;
    }

    .row {
        margin: 0px !important;
    }

    .row > div {
        padding: 0px 25px 50px 25px;
        margin-top: 50px;
    }

    .menu-hidden {
        display: none;
    }

    .menu-mega__link {
        color: #4e4d5a;
        display: block;
        padding: 10px 20px 10px 25px;
        margin: 0px -15px 0px -25px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-right: 1px solid transparent;
    }

    .menu-mega__link:hover {
        background-color: #fcfcfc;
        color: #009ee2;
        border-right: 1px solid #009ee2;
    }

    .menu-mega__link::after {
        content: "";
        background-image: url(/static/bundles/img/svg/menu-right-grey.svg);
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        width: 5px;
        height: 8px;
        position: absolute;
        right: 18px;
        top: calc(50% - 4px);
    }

    .menu-mega__link:not(.menu-expandable):after {
        transition: all 0.3s ease-in-out;
    }

    .menu-mega__link:hover:after {
        background-image: url(/static/bundles/img/svg/menu-right-blue.svg);
        right: 10px;
    }

    .menu-mega__link.menu-expandable {
        padding: 10px 40px 10px 25px;
        margin: 0px -25px;
        border-right: 1px solid transparent !important;
    }

    .menu-mega__link.menu-expandable::after {
        background-image: url(/static/bundles/img/svg/menu-plus-grey.svg);
        width: 11px;
        height: 11px;
        right: 25px;
        top: calc(50% - 6px);
    }

    .menu-mega__link.menu-expandable:hover {
        border-right: 1px solid transparent;
    }

    .menu-mega__link.menu-expandable:hover:after {
        background-image: url(/static/bundles/img/svg/menu-plus-blue.svg);
    }

    .menu-mega__link.menu-expandable.active-nav {
        background-color: #fcfcfc;
        color: #009ee2;
        border-right: 1px solid transparent;
    }

    .menu-mega__link.menu-expandable.active-nav:after {
        background-image: url(/static/bundles/img/svg/menu-plus-blue.svg);
    }

    .menu-level-active .menu-mega__link:not(.active-nav) {
        color: #c1c0c5;
    }

    .menu-level-active .menu-mega__link:not(.active-nav):hover {
        color: #009ee2;
    }

    .menu-level-active .menu-mega__header a.menu-mega__link {
        color: #8e8e8e;
    }

    .menu-mega__header a,
    .menu-mega__header span {
        color: #8e8e8e;
        background-color: transparent !important;
        display: block;
        font-size: 13px;
        line-height: 14px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0;
        margin: 0px 0px 14px 0px;
        border-right: 1px solid transparent !important;
    }

    .menu-mega__header a::after {
        display: none;
    }

    .mega-menu__h-divider {
        border-bottom: 1px solid #bababa;
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;
    }

    .mega-menu__v-divider {
        border-right: 1px solid #bababa;
    }

    .mega-menu__blue {
        background-color: #009ee2;
        padding: 50px 25px 50px 25px !important;
        margin-top: 0px !important;

        .menu-mega__link {
            color: #ffffff;
            background-color: #009ee2 !important;
        }

        .menu-mega__link:hover {
            background-color: #12aaec !important;
            border-right: 1px solid #ffffff;
        }

        .menu-mega__header .menu-mega__link:hover {
            background-color: transparent !important;
        }

        .menu-mega__header a {
            color: #b3e2fc;
        }

        .menu-mega__link:after {
            background-image: url(/static/bundles/img/svg/menu-right-white.svg) !important;
        }

        .menu-mega__header .menu-mega__link:after {
            background-image: url(/static/bundles/img/svg/chevron-down-nav-white.svg) !important;
        }

        .mega-menu__blue-mobile-hide {
            display: none;
        }
    }

    .mega-menu__brand {
        display: flex;
        flex-direction: row;
        height: 100%;
        padding: 0px 30px;
    }

    .mega-menu__brand-copy {
        padding-right: 3rem;
        flex: 1;
    }

    .mega-menu__title {
        font-size: 38px;
        line-height: 48px;
        color: #009ee2;
        font-weight: bold;
    }

    .mega-menu__paragraph {
        font-size: 14px;
        line-height: 21px;
        color: #8e8e8e;
        font-weight: normal;
    }

    .mega-menu__brand-img {
        width: 336px;
        height: 434px;
    }
    .mega-menu__brand-img a {
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        color: transparent;
    }

    .mega-menu__industry-solutions {
        width: 100%;
        height: 460px;
    }

    .mega-menu__industry-solutions a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #009fe3;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .mega-menu__industry-solutions a::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.47);
        z-index: 0;
    }

    .mega-menu__industry-solutions a::after {
        content: "";
        display: block;
        position: absolute;
        background-image: url(/static/bundles/img/svg/plus-expand.svg);
        z-index: 1;
        width: 81px;
        height: 81px;
        right: 0;
        background-repeat: no-repeat;
        background-size: 100%;
        transition: all 0.3s ease-in-out;
    }

    .mega-menu__industry-solutions a:hover:after {
        width: 117px;
        height: 117px;
    }

    .mega-menu__industry-solutions a div {
        font-size: 38px;
        line-height: 48px;
        color: #ffffff !important;
        position: absolute;
        bottom: 3rem;
        left: 3rem;
        width: 75%;
        z-index: 1;
    }

    .mega-menu__about-us,
    .mega-menu__contact-us {
        padding-left: 4rem !important;
        padding-right: 5rem !important;
    }

    .mega-menu__about-us-policy a {
        color: #c1c0c5;
    }

    .mega-menu__brand {
        padding-left: 4rem !important;
    }

    .mega-menu__contact-us {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .footer__social {
        margin-bottom: 1rem;
    }

    .mega-menu__news {
        display: flex;
        gap: 2rem;
        padding-left: 10px;
        margin-bottom: 2rem;
    }

    .mega-menu__news .icon {
        width: 15px;
        height: 15px;
        margin-right: 7px;
    }

    .mega-menu__news-date {
        font-size: 9px;
        line-height: 21px;
        display: inline-flex;
        font-weight: normal;
        color: #8e8e8e;
        text-transform: uppercase;
        width: 100%;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        margin-bottom: 2px;
    }

    .mega-menu__news-date span {
        transform: translateY(1px);
    }

    .mega-menu__news-title {
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        color: #009ee2;
        margin-bottom: 2px;
    }

    .mega-menu__news p {
        font-size: 14px;
        line-height: 21px;
        color: #8e8e8e;
        font-weight: normal;
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mega-menu__news > a {
        display: flex;
        flex-direction: column;
        width: 29%;
        border-bottom: 1px solid #bababa;
        padding-bottom: 2rem;
        justify-content: space-between;
        padding-right: 21px;
        transition: all 0.1s ease-in-out;
    }

    .mega-menu__news > a:hover {
        border-bottom: 1px solid #009ee2;
    }

    .mega-menu__news-header {
        padding-left: 10px;
    }
}

@media only screen and (max-width: 1200.5px) {
    .mega-menu__container {
        .mega-menu__about-us,
        .mega-menu__contact-us,
        .mega-menu__brand {
            padding-left: 2rem !important;
        }

        .menu-mega__link {
            font-size: 12px;
            padding-right: 27px !important;
        }

        .menu-mega__header a,
        .menu-mega__header span {
            padding-right: 0px !important;
        }

        .mega-menu__news {
            margin-bottom: 1rem;
        }

        .mega-menu__news > a {
            padding-bottom: 1.5rem;
        }
    }
}

@media only screen and (max-width: 991.5px) {
    .mega-menu__container {
        display: none;
        
        .mega-menu__contact-us {
            padding: 0px !important;
            width: 100%;
        }

        .footer__social {
            margin-top: 0px;
            margin-bottom: -0.5rem;
            margin-left: -0.5rem;
        }

        .footer__social svg {
            width: 36px;
        }

        .row {
            min-height: auto;
        }

        .mega-menu__blue {
            display: none;
            position: absolute;
            left: 0px;
            padding: 0 2rem !important;
            top: calc(100% + 4rem);
            min-height: calc(100vh - 71px);

            .menu-mega__link {
                color: #ffffff !important;
                border-color: #53bdeb !important;
                border-right: none !important;
            }

            .menu-mega__link:hover {
                background-color: transparent !important;
            }

            .menu-mega__header {
                margin-top: 2rem;
            }

            .menu-mega__header a {
                opacity: 0.5;
            }

            .menu-mega__link::after {
                background-image: url(/static/bundles/img/svg/menu-right-white-mobile.svg) !important;
                width: 8px !important;
                height: 14px !important;
                top: calc(50% - 7px) !important;
                right: 13px !important;
            }

            .mega-menu__blue-mobile-hide {
                display: block;
                padding: 3rem 0 2rem 0;
                width: 100%;
                cursor: pointer;
                position: relative;
                height: 155px;
            }

            .mega-menu__blue-mobile-hide::after {
                content: "";
                background-image: url(/static/bundles/img/svg/menu-bottom-white-mobile.svg) !important;
                height: 8px !important;
                display: block;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}

@media only screen and (max-width: 767.5px) {
    .mega-menu__container {
        .mega-menu__blue {
            height: calc(100vh - 59px);
            overflow-y: auto;
        }
    }
}

@media (min-width: 991.5px) {
    .menu-mega__header a::after,
    .mega-menu__blue .menu-mega__header a::after {
        content: "";
        background-image: url(/static/bundles/img/svg/chevron-down-nav.svg) !important;
        display: inline-block !important;
        width: 8px !important;
        height: 8px !important;
        background-repeat: no-repeat;
        position: relative !important;
        transform: rotate(-90deg) !important;
        top: 0 !important;
        right: 0 !important;
        margin-left: 0.5rem;
        margin-bottom: 1px !important;
    }
    
    .nav__item[data-navid="contact_nav"] {
        display: none;
    }
}