.generic-text {
  font-size: 1rem;
  
  &--has-line-height {
    line-height: 1.7;
  }

  &--no-line-height {
    line-height: 0;
  }

  &--stacked {
    @include below-ipad-breakpoint {
      line-height: 1.5;
      margin: 0;
    }
  }

  &--mobile-line-height {
    @include below-ipad-breakpoint {
      line-height: 1.5;
    }
  }
}