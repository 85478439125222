.radio-button {
  display: inline-block;
  margin-right: 2rem;
}

.radio-button__label-wrapper {
  border-radius: 50%;
  cursor: pointer;
  display: block;
  padding-left: 1.8rem;
  position: relative;
}

.radio-button__label-title {
  margin-bottom: 0.25rem;
}

.radio-button__custom-indicator {
  background-color: white;
  border: 1px solid #999;
  border-radius: 50%;
  height: 20px;
  left: 0;
  position: absolute;
  top: -1.1rem;
  width: 20px;

  &::after {
    background-color: $c_pop;
    border-radius: 50%;
    content: "";
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 13px;
    width: 13px;
  }
}

.radio-button__input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  
  &:focus ~ .radio-button__custom-indicator {
    border-color: black;
  }

  &:checked ~ .radio-button__custom-indicator {
    &::after {
      display: block;
    }
  }

  &:disabled ~ .radio-button__custom-indicator {
    opacity: 0.6;
    pointer-events: none;
  }
}
