.side-bar {
  padding: 0;
  background-color: transparent;
  list-style: none;
  margin-top: -10px;
  width: 97%;

  @include largeTablet {
    width: 100%;
  }

  @media only screen and (max-width : 991.5px) {
    margin-top: px2rem(0);
    border-top: solid 1px $c_wild_sand;
    margin-bottom: 3rem;
  }

  @media (max-width: 991px) {
    margin-top: px2rem(-75);
    border-top: solid 1px $c_wild_sand;
    margin-bottom: 3rem;
  }

  @include below-ipad-breakpoint {
    margin-top: 0;
    position: absolute;
    z-index: 7;
    left: 50%;
    top: px2rem(-48);
    transform: translateX(-50%);
    min-width: 97.5vw;
    border-top: solid 1px $c_wild_sand;
  }

  @include phone {
    min-width: 80vw !important;
  }
  
  &__collapsed-title {
    display: none;

    @include sidebar-breakpoint {
      display: flex;
      justify-content: space-between;
      background-color: $c_white;
      margin-bottom: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    @include below-ipad-breakpoint {
      
    }
  }

  &__collapsed-link {
    color: $c_pop;
    padding: 1.1rem 0 1.1rem 1.5rem;
    font-size: 1rem;
    @include below-ipad-breakpoint {
      font-size: 1rem;
    }
  }

  &__collapsed-dropdown {
    padding: 1rem;
    color: $c_pop;
    font-weight: bold !important;
    cursor: pointer;
  }

  &__dropdown-icon {
    width: 12px;
    margin-right: 1rem;
    margin-top: 0.4rem;
    transition: all 0.2s;
    filter: saturate(1.1);
    &--is-active {
      transform: rotate(180deg) scale(1.2);
    }
  }

  &__items {
    transition: max-height 0.5s ease-in-out;
    @include sidebar-breakpoint {
      max-height: 0;
      overflow: hidden;
      background-color: $c_mercury;
    }

    &--is-visible {
      max-height: 450px;
      background-color: $c_mercury;
      border-color: $c_silver;
    }
  }

  &__item {
    position: relative;
    transition: all 0.5s;
    transform-origin: top;
    
    &-link {
      display: block;
      padding: 1.1rem 0 1.1rem 0;
      font-weight: lighter !important;
      transition: all 0.5s;
      font-size: 0.95rem;

      @include sidebar-breakpoint {
        padding: 1.1rem 0 1.1rem 1.5rem;
      }

      &::after {
        background-image: url(/static/bundles/img/svg/arrow-right-blue.svg);
        content: "";
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        width: 1.8rem;
        height: 1rem;
        right: 3.5rem;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;

        @include sidebar-arrow-breakpoint {
          margin-left: 0.5rem;
        }

        @include sidebar-breakpoint {
          display: none;
        }
      }

      &--is-active {
        color: $c_pop;
        font-weight: 600 !important;

        &::after {
          right: 1.5rem;
          opacity: 1;
          visibility: visible;

          @include sidebar-arrow-breakpoint {
            margin-left: 0.5rem;
          }

          @include sidebar-breakpoint {
            display: none;
          }
        }
      }

      &:hover, &:active {
        color: $c_pop;

        &::after {
          opacity: 1;
          visibility: visible;
          right: 1.5rem;
        }
      }
    }
    cursor: pointer;
    border-bottom: solid 1px $c_mercury;
    font-size: 0.9rem;

    @include sidebar-breakpoint {
      border-bottom: solid 1px $c_silver-chalice;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &-container + * {
    @include below-ipad-breakpoint {
      margin-top: 4rem;
    }
  }
}