/*
Search.
*/

.search-teaser {
	&__image {
		flex-basis: px2rem(80);
	}

	&__body {
		flex-basis: calc(100% - #{ px2rem(80) } - 1rem);
	}
}


.results-block {

	&__heading {
		border-bottom: 1px solid $c_silver;
		margin-bottom: 3rem;
	}

	&__spitter {
		margin-bottom: 8rem;
	}

	&__spitter-narrow {
		margin-bottom: 4rem;
	}
}

.search-teaser{ 
	
	&__body {

		h2 {
			color: $c_chicago;
			margin-bottom: px2rem(5);
		}

		div {
			color: $c_chicago;
			margin-bottom: px2rem(19);
		}

	}

}

.search-results-title {
    font-size: 1.3rem !important;
    margin-top: 4rem;
}

.punctuation-separator {
    display: inline-block;
	margin-left: -5px;
}



@include at(sm) {
	.search-teaser {
		&__image {
			display: none;
		}

		&__body {
			flex-basis: 100%;
		}
	}
}
