.b_button-primary, .b_button-primary:link, .b_button-primary:visited {
  font-size: 1rem;
  padding: 1.3rem 2.2rem;
  color: $c_white;
  background-color: $c_cerulean;
  border-radius: 30px;
  border: solid 2px transparent;
  transition: background-color 0.3s;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  outline: none !important;
  cursor: pointer;
  letter-spacing: px2rem(0.65);
}

.b_button-primary {
  &:hover, &:active {
    background-color: $c_green;
  }
}

.b_button-invert, .b_button-invert:link, .b_button-invert:visited {
  font-size: 1rem;
  padding: 1.3rem 2.2rem;
  color: $c_white;
  background-color: $c_cerulean;
  border-radius: 30px;
  border: solid 1px #ffffff;
  transition: background-color 0.3s;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  outline: none !important;
  cursor: pointer;
  letter-spacing: px2rem(0.65);
}

.b_button-invert {
  &:hover, &:active {
    background-color: #0f89bd;
  }
}


.b_button-secondary, .b_button-secondary:link, .b_button-secondary:visited {
  font-size: 1rem;
  padding: 1.3rem 2.2rem;
  color: $c_cerulean;
  background-color: transparent;
  border-radius: 30px;
  border: solid 2px $c_cerulean;
  transition: all 0.3s;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  outline: none !important;
  cursor: pointer;
  letter-spacing: px2rem(0.65);

  &.b_button-secondary-white {
    color: $c_white;
    border-color: $c_white;
  }

  &.b_button-secondary-white-10 {
    color: $c_white;
    border-color: $c_white;
    background-color: rgba(0,0,0,0.1);
  }

  &.b_button-secondary-white-10:hover {
    background-color: rgba(0,0,0,0.4);
  }

  &.b_button-border-blue {
    border-color: $c_cerulean !important;
  }
}

.b_button-secondary {
  &:hover, &:active {
    color: $c_white;
    background-color: $c_cerulean;
  }
}

.b_button-primary:disabled, 
.b_button-primary:disabled:link,
.b_button-primary:disabled:visited,
.b_button-primary:disabled:hover,
.b_button-primary:disabled:active
{
  background-color: $c_silver_chalice;
}

.b_button-invert:disabled, 
.b_button-invert:disabled:link,
.b_button-invert:disabled:visited,
.b_button-invert:disabled:hover,
.b_button-invert:disabled:active
{
  background-color: #ffffff;
}

.b_button-secondary:disabled, 
.b_button-secondary:disabled:link,
.b_button-secondary:disabled:visited,
.b_button-secondary:disabled:hover,
.b_button-secondary:disabled:active
{
  color: $c_silver_chalice;
  background: transparent !important;
  border-radius: 30px;
  border: solid 2px $c_silver_chalice;
  outline: none !important;
  cursor: not-allowed;
}

.b_button-container-left {
  width: 100%;
  text-align: left;

  @include below-ipad-breakpoint {
    text-align: center;

    .b_button-secondary {
      display: block;
    }

    .b_button-primary {
      display: block;
    }

    .b_button-invert {
      display: block;
    }
  }
}

.b_button-wider {
  padding: 1.3rem 3.2rem !important;
}

.b_button-wide {
  padding: 1rem 3.2rem !important;
}

.b_button-wide-2 {
  padding: 1rem 4.2rem !important;
}

.b_button-narrow {
  padding: 1rem 2rem !important;
}

.b_button-short {
  padding: 0.5rem 2.5rem !important;
}

.b_button-secondary.chevron-right-blue {
  position: relative;

  &:after {
    content: '';
    background-image: url(/static/bundles/img/svg/chevron-right-blue.svg);
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    width: 6px;
    height: 10px;
    position: absolute;
    right: 21px;
    top: calc(50% - 4px);
  }

  &:hover {
    .chevron-right-blue:after {
      background-image: url(/static/bundles/img/svg/chevron-right-white.svg);
    }
  }
}

.b_button-secondary.chevron-right-blue:hover {
  &:after {
    background-image: url(/static/bundles/img/svg/chevron-right-white.svg);
  }
}

@media only screen and (max-width : 512px) {
  .b_button-group-mobile {
    display: inline-flex;
    flex-direction: column;

    > * {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.b_button-shadow {
  box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.12);
}