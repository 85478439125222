.checkout-review {

  &__delivery {
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    line-height: 0.7;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__link-container {
    margin-top: 2rem;

    @include below-ipad-breakpoint {
      margin-top: 0;
    }
  }

  &__delviery-texts {
    margin-top: 2rem;
  }

  &__payment {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &__visa-logo {
    width: 80px;
    margin-right: 1rem;
  }
}