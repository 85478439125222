/*
Faqs.
*/

.faqs {
	padding-top: px2rem(70);
	padding-bottom: px2rem(70);
}

.faq-search {
	position: relative;
	max-width: px2rem(635);

	&__input {
		background-color: $c_wild-sand;
		border-radius: 3em;
		padding-right: 5em;
	}

	&__label {
		position: absolute;
		right: 2rem;

		.icon {
			width: px2rem(18);
			height: px2rem(18);
		}
	}
}

.faq-accordian {
	&__body {
		@include transition(min-height);

		min-height: 0;
		height: 0;
		overflow: hidden;

		&-inner {
			padding: 1rem;
		}

		p {
			@include type(body, seedling);
			font-size: 14px; // ??
			color: $c_gray;
		}

	}

	&__header {
		@include transition(outline);

		padding: px2rem(20);
		cursor: pointer;

		.icon {
			width: 10px;
			height: 10px;
		}

		&:hover {
			outline: px2rem(4) $c_cerulean solid;
		}

		&.is-open {
			.icon {
				background-image: url(/static/bundles/img/svg/minus.svg);
			}
		}

		&-body {
			width: calc(100% - 10px);
		}
	}

}









@include at(lg) {
	.faq-search {
		max-width: px2rem(530);
	}
}









@include at(md) {
	.faq-search {
		max-width: none;
		width: 100%;
	}
}









@include at(sm) {

	.faq-accordian {
		margin-bottom: px2rem(20);

		&:last-child {
			margin-bottom: px2rem(60);
		}
	}

}
