.accounts-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  color: $c_chicago;
  background-color: $c_mercury;
  padding: 1.5rem;
  position: relative;

  @include phone {
    font-size: 1.4rem;
  }

  &--has-no-bg {
    background-color: transparent;

    @include smallPhone {
      line-height: 1.4;
    }
    
  }

  &--has-no-vertical-padding {
    padding: 0;
  }

  &--is-light {
    font-weight: lighter;
  }

  &--has-bottom-margin {
    margin-bottom: 2rem;
  }

  &--narrower {
    width: 60%;

    @include ipad-column-breakpoint {
      width: 100%;
    }
  }
}