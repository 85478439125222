.account-settings {
  line-height: 0;
  @include below-ipad-breakpoint {
    line-height: 1;
  }

  &__top {
    @include below-ipad-breakpoint {
      margin-bottom: 1rem;
    }
  }

  &__btn-container {
    @include below-ipad-breakpoint {
      margin-top: 0.5rem;
    }
  }
}