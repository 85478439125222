.checkout-success {


  @include phone {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &__title {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 2rem;

    &--has-less-margin {
      margin-bottom: 1.5rem;
    }
  }

  &__steps {
    text-align: left;
    margin: 0;
    padding-left: 1rem;

    @include tablet {
      line-height: 1.6;
    }

    @include phone {
      padding-left: 0;
      margin-left: 1rem;
    }
  }
  
  &__step {
    padding-left: 1rem;

    @include below-ipad-breakpoint {
      padding-left: 0;
    }
  }

  &__progress {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;

    &::after {
      display: block;
      width: 89%;
      height: 1px;
      content: "";
      background-color: transparent;
      position: absolute;
      border: dashed 1px $c_silver;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    @include ipad-column-breakpoint {
      margin-bottom: 6rem;
    }

    @include smallPhone {
      width: 100%;
    }
  }

  &__progress-ball {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: solid 1px $c_pop;
    padding: 1rem;
    position: relative;
    z-index: 2;

    &::after {
      content: "";
      display: block;
      background-color: $c_pop;
      height: 1.7rem;
      width: 1.7rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    &--grey {
      border: solid 1px $c_silver;

      &::after {
        background-color: $c_silver;
      }
    }
  }

  &__progress-title-text {
    text-align: center;
    position: absolute;
    top: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    width: 90px;
  }

  &__section-border--is-visible-on-phone {
    display: none;
    @include below-ipad-breakpoint {
      display: block;
    }
  }

  &__invoice {
    margin-bottom: 2rem;
    @include below-ipad-breakpoint {
      margin-top: 2rem;
    }
  }

  &__tracking {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include ipad-column-breakpoint {
      display: none;
    }
  }

  &__complete {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__section-border--is-hidden {
    @include ipad-column-breakpoint {
      display: none;
    }
  }

  &__complete-btn-container {
    text-align: left;
    margin-top: 3rem;
    @include ipad-column-breakpoint {
      display: block;
    }
  }

  &__create-account-btn {
    text-align: center;
    @include desktop {
      text-align: left !important;
    }
  }
}