/*
Swioer.
*/

.swiper-button-prev,
.swiper-button-next {
	@include transition(all);

	background-size: auto;
	width: px2rem(21);
	height: px2rem(21);
	margin-top: 0;
	transform: translateY(-50%);
	z-index: 2;

	&:hover {
		filter: brightness(.75);
	}
}

.swiper-button-prev {
	transform: translateY(-50%) scaleX(-1);
}

.swiper-pagination-bullet {
	border: 1px solid $c_chicago;
	opacity: 1;
	background-color: transparent;
}

.swiper-pagination-bullet-active {
	background-color: $c_chicago;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
	margin: 0 px2rem(6);
}

.related-products-carousel {
	.swiper-slide {
		max-width: 210px;
	}
}

.swiper-container {

	&--has-buttons {
		margin-left: px2rem(20);
		margin-right: px2rem(20);
	}
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23009ee2'%2F%3E%3C%2Fsvg%3E");
	left: 10px;
	right: auto;
	transform: rotate(0deg) translateY(-50%);
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23009ee2'%2F%3E%3C%2Fsvg%3E");
	right: 10px;
	left: auto;
}