.checkout-continue-section {
  
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    button:disabled,
    button:disabled:hover {
      border-color: $c_silver;
      color: $c_silver;
      cursor: auto;
      background-color: #e9e9e9;
    }
  }
}