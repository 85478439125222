/*
	Helpers.
*/

.u_flex {
	display: flex;

	&--column {
		flex-direction: column;
	}

	&--v-centre {
		align-items: center;
	}

	&--v-bottom {
		align-items: baseline;
	}

	&--h-centre {
		justify-content: center;
	}

	&--h-spread {
		justify-content: space-between;
	}

	&--h-end {
		justify-content: flex-end;
	}

	&--wrap {
		flex-wrap: wrap;
	}

	&--no-wrap {
		flex-wrap: nowrap;
	}

	&--align-items-stretch {
		align-items: stretch;
	}
}

// margin top
@each $i in (0,1,2,3,4,6) {
	.u_mt--#{$i} {
		margin-top: #{$i}rem;
	}
}

// margin right
@each $i in (0,1,2,3,4,6) {
	.u_mr--#{$i} {
		margin-right: #{$i}rem;
	}
}

// margin right
@each $i in (0,1,2,3,4,6) {
	.u_mri--#{$i} {
		margin-right: #{$i}rem !important;
	}
}

// margin bottom
@each $i in (1,2,3,4) {
	.u_mb--#{$i} {
		margin-bottom: #{$i}rem;
	}
}

// margin left
@each $i in (1) {
	.u_ml--#{$i} {
		margin-left: #{$i}rem;
	}
}

.u_pt--1 {
  padding-top: 1rem;
}

.u_pt--2 {
  padding-top: 2rem;
}

.u_pb--1 {
  padding-bottom: 1rem;
}

.u_p--0 {
	padding: 0;
}

.u_centred-text {
	text-align: center;
}

.u_right-text {
	text-align: right;
}

.u_rel {
	position: relative;
}

.u_attenuate {
  opacity: 0.8;
}

.u_has-shadow {
	box-shadow: 0px .1875rem 1.25rem rgba(0,0,0,0.05);
}

.u_ov-hid {
	overflow: hidden;
}