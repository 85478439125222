/*
Forms.
*/

.form {

	&__nav-contact {

		.field__label {
			top: 1.1em;
		}

	}

	.t_color--light {

		a {
			color: inherit;
		}
	}

	&__checkbox-set {
		.field__checkbox {
			margin-right: 3rem;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__text {
		max-width: px2rem(400);
	}

}

.fields-container {
	margin-right: -1rem;
	margin-left: -1rem;

	.field {
		width: 50%;
		margin-right: 1rem;
		margin-left: 1rem;

		&:last-child {
			padding-right: 0;
		}
	}
}

.field {
	position: relative;
	background: transparent;
	margin-bottom: px2rem(30);

	&__select {
		width: 100%;
		background-image: url(/static/bundles/img/svg/arrow-down.svg);
		background-repeat: no-repeat;
		background-position: center right;
		background-size: 10px;

		.field__label {
			transform: scale(0.6) translateY(-1.5em);
			top: 0.4em;
		}

		select {
			-moz-appearance: window;
			-webkit-appearance: none;
			border: none;
			width: 100%;
			background: transparent;
			color: $c_text-dark;
			border-radius: 0;
			padding: px2rem(5) 0.5em;
		}

		&--light {
			background-image: url(/static/bundles/img/svg/arrow-down-white.svg);

			select {
                color:$c_white;
				border-bottom-color: $c_dodger-blue;

                option {
                    color:$c_black;
                }
			}


		}

		&--lang {

			select {
				width: px2rem(50);
				font-weight: bold;
				color: $c_cerulean;
				border: none;
				padding: 0;
			}
		}
	}

	&__select-container {
		border: 1px solid #e5e5e5;
		padding: 0.5rem 1rem 0.5rem 0.5rem;
		margin-bottom: 2rem;

		.field__select {
			margin-bottom: 0px;
		}

		select {
			outline: none !important;
		}
	}

	&__input {
		border-radius: 0;
		border: none;
		border-bottom: 1px solid #ddd;
		background-color: transparent;
		position: relative;
		z-index: 1;
		// color: $c_pop;

		&--light {
			color: $c_white;
			border-bottom: none;
		}

		&--sm {
			padding: px2rem(5) 0;
		}

		&:focus {
			border-bottom: 2px solid $c_pop;

			outline: none;
		}
	}

	&__label {
		@include transition(transform);
		@include type(sans);

		transform-origin: top left;
		//color: $c_pop;
		position: absolute;
		top: 1em;
		left: 0.5rem;
		margin-bottom: 0;

		.field__input:focus + &,
		// .field__input:valid + &,
		.field__input[data-empty=false] + & {
			transform: scale(0.6) translateY(-1.5em);
		}
	}

	&__checkbox {
		display: inline-flex;

		label {
			display: flex;
			align-items: baseline;

			input {
				display: none;
			}

			.checkbox {
				position: absolute;
				margin-top: 1px;
				height: 18px;
				width: 18px;
				border: 1px solid $c_dodger-blue;
				display: inline-block;
				border-radius: 3px
			}

			.checkbox-text {
				display: block;
				padding-left: px2rem(30);

				&.wysiwyg {
					width: 100%;
				}
			}
		}

		/* style its checked state..with a ticked icon */
		[type=checkbox]:checked + .checkbox:before {
			content: '\2714';
			color: $c_white;
			position: absolute;
			// top: 2px;
			left: 3px;

			.form--white & {
				color: $c_pop;
			}

		}
	}

	&__extra {
		position: absolute;
		right: 0;
		bottom: px2rem(18);
		z-index: 1;
	}

	&--has-border {
		border-bottom: 1px solid rgba($c_white, 0.33);

		opacity: 1;
		height: auto;
		transition: opacity 0.4s ease-in;
	
	
		&.d-none-fade {
		  opacity: 0;
		  height: 0px;
		  margin-bottom: 0rem !important;
		}
	}

	@include at(sm) {
		&.field-textarea label {
			top: 0;
		}
	}
}

.field__label-force {
	transform: scale(0.6) translateY(-1.5em) !important;
}







@include at(xs) {
	.fields-container {
		flex-wrap: wrap;
		margin-right: 0;
		margin-left: 0;

		.field {
			margin-right: 0;
			margin-left: 0;
			width: 100%;
		}
	}
}


.hidden-checkbox {
	display: inline-block !important;
    opacity: 0;
    top: 22px;
    position: absolute;
}

h1 {
	&.large-bold {
		font-size: px2rem(24);
		font-weight: 700;
		margin-bottom: px2rem(6);
	}
}


/* 
	Webinar
*/

.webinar-form {
	padding-top: 7rem;
    padding-bottom: 7rem;

	h2 {
		text-align: center;
		font-size: 32px;
		line-height: 38px;
		font-weight: bold;
		color: #009EE2;
		margin-bottom: 3rem;
	}

	.field {
		margin-bottom: 2rem !important;
	}

	.b_button-primary {
		font-size: 14px;
		margin-left: auto;
		display: block;
	}
}

@media (max-width : 767.5px) {
    .webinar-form {
		padding-top: 4rem;
		padding-bottom: 5rem;

		h2 {
			font-size: 24px;
			line-height: 26px;
		}

		.b_button-primary {
			margin-left: auto;
			margin-right: auto;
		}
    }
}