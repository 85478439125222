.c_cart {

  &__review-btn {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 3rem;
  }

  &__btn-centered {
   display: flex;
   justify-content: center;
   align-items: center;
    margin-top: 2rem;
    .b_button-primary {
      text-align: center;
      display: block;
      width: 100%;
    }

    @include below-ipad-breakpoint {
      display: block;
    }

    
  }

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1rem;
    border-bottom: solid 1px $c_gallery;
    padding-bottom: 2rem;
  }

  &__end-link-container {
    text-align: left;
  }

  &__total-price {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__review-btn {
    margin: 3rem 0;
  }
}

.c_cart__review-btn {
  &-right {
    display: none;
  }
  
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media only screen and (max-width : 991.5px) {
    &-right {
      display: block;
    }
    
    &-bottom {
      display: none;
    }
  }
}