.product-pagination {

  &__content {
    @media only screen and (max-width : 991.5px) {
      display: none;
    }
  }

  &__select {
    width: calc(100% - 30px);
    background-color: white;
    border-color: transparent;
    padding: 1rem;
    position: absolute;
    left: 15px;
    top: -73px;
    
    font-size: 0.875rem;
    color: #5c5c5b;
    border-bottom: 3px solid #f4f4f4;
    z-index: 1;

    @media only screen and (max-width : 991.5px) {
      display: none;
    }
  }
  
  &__content-left {
    margin-top: 1.5rem;
  }
  
  &__content-title {
    align-self: center;

    h2 {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  &__content-right {
    margin-bottom: 0;
  }

  &__links {
    display: none;
  }

  &__links a:hover {
    color: #000000;
  }
}

.product-pagination__compare {
  display: flex;
  padding-bottom: 2rem;

  > * {
    width: 50%;
  }

  > .sort-for-quote {
    padding: 0px;
  }
  
  .sort-container ul {
    width: auto;
  }
}

.product-pagination__select:hover .product-pagination__links {
  display: block;
}

.bottom-pagination {
  @media only screen and (max-width : 991.5px) {
    display: none;
  }
}

.bottom-pagination__content-left {
  margin-top: 0.7rem;
  transform: translateX(-1.75rem);
}