.product-view {

  &__filters-section {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width : 991.5px) {
      display: none;
    }
  }

  &__filters-title {
    margin: 0;
    text-transform: uppercase;
    font-size: 0.875rem;
    display: inline-block;
    margin-right: 1rem;
  }

  &__filter-box {
    background-color: $c_pop;
    padding: 0.7rem 1rem;
    color: $c_white;
    border-radius: 40px;
    font-size: 0.8rem;
    margin-left: 0rem;
    margin-right: 0.5rem;
    display: inline-block;
    margin-top: 1rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: $c_green;
    }

    &--is-removed {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }

  &__filter-close {
    display: inline-block;
    margin-left: 0.7rem;
    transform: scale(2);
    cursor: pointer;
    opacity: 1;
  }

  &__filter-close:hover {
    opacity: 0.5;
    transition: opacity 0.2s ease-in;
  }

  &__filters-section--left {
    width: 90%;
  }

  &__filters-section--right {
    display: block;
    font-size: 0.875rem !important;
    
    &.filters-added {
      transform: translateY(px2rem(27));
    }
  }

  &__dropdown-icon {
    width: 11px;
    display: inline-block;
    margin-left: 0.2rem;
  }
}

.row--no-wrap {
  flex-wrap: nowrap;

  @include lg-desktop-breakpoint {
    flex-wrap: wrap;
  }
}