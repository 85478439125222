.notification {
  position: relative;
  background-color: $c_white;
  margin-bottom: 1rem;
  display: flex;
  min-height: 68px;

  &:last-child {
    margin-bottom: 3rem;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: px2rem(70);
    max-width: px2rem(70);

    &--success {
      background-color: $c_green;
    }

    &--warning {
      background-color: orange;
    }
  }

  &__icon {
    width: 3rem;
    height: auto;
  }

  &__text {
    display: inline-block;
    margin-top: 0.6rem;
    margin-left: 1.2rem;
    line-height: 1.5;
    margin-bottom: -0.4rem;
    margin-right: 5rem;

  }

  &__close-time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 5rem;
    padding: 6px 16px 12px 0px;
    position: absolute;
    right: 0px;
    height: 100%;
  }

  &__close, &__time {
    text-align: right;
    display: block;
  }

  &__close {
    color: $c_chicago;
    font-size: 1.8rem;
  }

  &__close-link {
    font-weight: 100;
    font-family: "Times New Roman", Times, serif;

    &:hover {
      opacity: 0.5;
    }
  }

  &__time {
    color: $c_silver;
    font-size: 0.8rem;
  }

  @media (max-width: 767px) {
    &__text {
      margin-right: 1rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }
}