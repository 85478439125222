/*
Tags
*/

.tags {

	.icon {
		width: 30px;
		height: 30px;
		margin-top: -5px;
		margin-left: -5px;
		margin-right: 6px;
	}

	&__tag {
		border-radius: 100px;
		padding: px2rem(10) px2rem(20);
		margin: 0 px2rem(10) px2rem(10) 0;
	}
}
