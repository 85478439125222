/*
	Helpers
*/

/*
List reset
The common task of zeroing out a list
*/
.u_list-reset {
	list-style: none;
	margin: 0;
	padding: 0;
}









/*
Visibility hidden
Accessiblity-friendlier way to hide text from h5b
*/
.u_visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}









.u_block {
	display: block;
}

.u_no-dec {
	text-decoration: none !important;
}

.u_ov-hid {
	overflow: hidden;
}

.u_button-reset {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
	cursor: pointer;
}
