// ==========================================================================
// #Product Box
// ==========================================================================


.md-product-box { 
   font-family: 'Arial', 'Helvetica Neue', 'Helvetica', 'sans-serif';
   background-color: white;
   width: 470px;
   border-radius: 2px;
   -webkit-box-shadow: 0px 6px 20px -10px rgba(0,0,41,1);
      -moz-box-shadow: 0px 6px 20px -10px rgba(0,0,41,1);
           box-shadow: 0px 6px 20px -10px rgba(0,0,41,1);

    h3 {
        font-size: 1.2rem;
    }
   
   ul, ol {
      margin: 0;
      padding: 0;
      list-style: none;
   }

   &__border-bottom {
      border-bottom: 2px solid #F5F5F5;
   }
   
   &__list {
      padding: 0 30px;
      overflow: hidden;
   }
   
   &__row {
      position: relative;
   }
   
   &__row-outer{
      padding: 30px 0;
      border-bottom: 2px solid #F5F5F5;
   }
   
   &__row-inner {
      height: 100%;
      position: relative;
   }
   
   &__item-number-wrap {
      position: absolute;
      right: 0;
      z-index: 100;
   }
   
}
