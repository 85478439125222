.accounts-btn {
  border: 0;
  background-color: transparent;
  color: $c_pop;
  border: solid 2px $c_pop;
  border-radius: 40px;
  padding: 1rem 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  transition: all 0.2s;
  cursor: pointer;

  @include phone {
    font-size: 0.8rem;
    padding: 0.8rem 1.3rem;
  }

  &--has-extra-padding {
    padding: 1.5rem;
  }

  

  &--has-extra-horizontal-padding {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }

  &:hover, &:active {
    background-color: $c_pop;
    color: $c_white;
  }

  &:focus {
    outline: none;
  }

  &--has-left-margin {
    position: absolute;
    right: 0.8rem;

    @include below-ipad-breakpoint {
      position: static;
    }
  }

  &--has-top-margin {
    margin-top: -0.85rem;

    @include below-ipad-breakpoint {
      margin-top: 2rem;
    }
  }

  &--has-extra-top-margin {
    margin-top: 2rem;
  }

  &__order-details-page--is-block {
    @include below-ipad-breakpoint {
      padding: 0.8rem 1.3rem;
    }
    @include phone {
      position: static;
      display: inline-block;
      margin-top: 2rem;
    }
  }
}