.cookie-consent-page {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/static/bundles/img/cc-background.jpg");
    background-size: cover;
    background-position: center;
    padding: 18px;
    width: 100%;
    height: 100%;

    > div {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        padding: 1rem 2rem;
        text-align: center;
    }

    a.link--pop {
        font-weight: normal;
    }

    a.link--pop:first-child {
        margin-right: 10px;
        border-right: 1px solid #009ee2;
        padding-right: 15px;
    }
}

@media only screen and (max-width: 767.5px) {
    .cookie-consent-page {
        .b_button-primary {
            margin-bottom: 5rem;
        }
    }
}

.cookie-consent-box {
    position: relative;
    width: 0px;
    height: 0px;
    z-index: 31;

    > div {
        position: fixed;
        left: 0px;
        right: 0px;
        display: flex;
        background-color: #fff;
        box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.13);
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    .title {
        font-size: px2rem(18);
        line-height: px2rem(23);
        color: #009ee2;
        font-weight: bold;
        margin-bottom: 1rem;
        margin-right: 2rem;
    }

    .title-mobile {
        display: none;
    }

    .link--pop {
        font-weight: normal;
    }

    a.link--pop:first-child {
        margin-right: 10px;
        border-right: 1px solid #009ee2;
        padding-right: 15px;
    }

    .b_button-wide {
        font-size: px2rem(13) !important;
        white-space: nowrap;
    }

    .buttons {
        display: inline-flex;
        gap: 13px;
    }

    .reject-cookies {
        border-color: #f4f4f4 !important;
        color: #8e8e8e !important;
    }

    .reject-cookies:hover {
        border-color: #009ee2 !important;
        color: #ffffff !important;
    }

    .cookie-links-mobile {
        display: none;
    }
}

@media only screen and (min-width: 570.5px) {
    .cookie-consent-box {
        > div {
            animation-name: slideUpCConsent;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-out;
            animation-delay: 1s;
        }
    }
}

@media only screen and (max-width: 570.5px) {
    .cookie-consent-box {
        > div {
            bottom: 0px;
        }
    }
}

@keyframes slideUpCConsent {
    0% {
        bottom: -85px;
    }
    100% {
        bottom: 0;
    }
}

@media only screen and (max-width: 991.5px) {
    .cookie-consent-box {
        .container {
            flex-direction: column;
        }

        > div {
            padding-top: 1.25rem;
            padding-bottom: 1rem;
        }

        .title-mobile,
        .cookie-links-mobile {
            display: block;
        }

        .title-mobile {
            text-align: center;
            margin-right: 0;
        }

        .title-desktop,
        .cookie-links-desktop {
            display: none;
        }

        .reject-cookies {
            margin-right: 0;
        }

        .buttons {
            margin-bottom: 1rem;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
        }
    }
}

.CookieDeclaration {
    display: none !important;
}