/*
	WYSIWYG content.
	Custom type styles applied to wysiwyg content from the CMS.
*/
.wysiwyg {

	p:first-child {
		margin-top: 0;
	}
	p:last-child {
		margin-bottom: 0;
	}

}
