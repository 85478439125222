.floating-input-label {
  position: relative;
}

.floating-label-span {
  color: $c_text-dark;
  display: block;
  position: absolute;
  top: 0;
  left: 0.4rem;
  transition: all 0.2s;
  pointer-events: none;
}

.floating-input-field {
  width: 100%;
  border: 0;
  border-bottom: solid 1px $c_mercury;
  padding-left: 0;
  margin-bottom: 2rem;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  background-color: transparent;
  z-index: 2;

  &:focus {
    outline: none;
    border-bottom: solid 2px $c_pop;
  }
}

.floating-input-field:focus + .floating-label-span {
  top: -1.5rem;
  left: 0.5rem;
  font-size: 0.7rem;
  color: $c_chicago;
}

.floating-input-field:focus + .floating-label-span,
.floating-input-field.full + .floating-label-span,
.floating-input-field:focus + .error-speech-bubble + .floating-label-span,
.floating-input-field.full + .error-speech-bubble + .floating-label-span
{
  top: -1.5rem;
  left: 0.5rem;
  font-size: 0.7rem;
  color: $c_chicago;
}