.returns {

  &__container {
    margin-bottom: 4rem;
  }

  &__row {
    position: relative;

    @include below-ipad-breakpoint {
      padding-bottom: 2rem;
    }

    &--content {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 2rem;

      @include below-ipad-breakpoint {
        margin-bottom: 4rem;
      }
    }

     .f_form {
       margin-top: 0;
     }
  }

  &__row--right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p:first-child {
      margin-right: 4rem;
    }
  }

  &__row--left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
    
    .f_checkbox-container {
      margin-right: 1rem;
      transform: translateY(1.45rem);

      @include below-ipad-breakpoint {
        transform: translateY(0);
      }
     }
  }

  &__form {
    opacity: 0;
    max-height: 0;
    transition: all 0.3s;

    &--is-visible {
      opacity: 1;
      max-height: 450px;
      margin-bottom: 3rem;
    }
  }

  &__thumb {
    img {
      width: 55px;
      padding: 15px;
      margin-right: 2rem;
      border: solid 1px $c_gallery;
    }
  }

  &__title {
    width: 250px;
    a {
      font-size: 1.2rem;
    }
    span {
      display: block;
    }
  }

  &__btn-container {
    margin: 5rem 0 2rem 0;
  }
}

@include below-ipad-breakpoint {
  .returns__row--content {
    display: block;
  }
  .returns__row--left {
    display: block;
  }
  .returns__row--right {
    display: block;

    p {
      margin-left: 0;
    }
  }

  .returns__row--content {
    position: relative;
    padding: 2rem 0 6rem 0;

    .f_checkbox-container {
      position: absolute;
      top: 0;
    }

    .returns__title {
      position: absolute;
      width: auto;
      top: 0;
      left: 40px;
    }

    .returns__thumb {
      position: absolute;
      right: 0;

      top: 4rem;
    }

    .returns__row--right {
      position: absolute;
      top: 3rem;
      left: 40px;
    }

  }

}