/* 
    JS Select
*/

/* Dropdown Button */
.dropbtn,
.dropdown-content > div:first-child {
    background-color: #ffffff;
    color: #009ee2;
    cursor: pointer;
    border: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 9px 35px 9px 15px;
    position: relative;

    .edit {
        font-size: px2rem(12);
        text-decoration: underline;
        margin-right: 0.4rem;
    }

    &::after {
        content: "";
        background-image: url(/static/bundles/img/svg/chevron-down-blue.svg);
        position: absolute;
        right: 15px;
        top: calc(50% - 3px);
        width: 14px;
        height: 7px;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.dropdown-content > div:first-child::after {
    top: 50%;
}

.dropbtn.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    filter: grayscale(100%);
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
    background-color: #fefefe;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    border: 1px solid #eeeeee;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    z-index: 31;
    width: calc(100% + 2px);
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    left: -1px;
    top: -256px;
    box-shadow: 0px -4px 12px -2px rgba(0,0,0,0.2);

    > div:first-child {
        padding-top: 1rem;
        padding-bottom: 0.75rem;
    }

    .dropdown-list {
        height: 230px;
        overflow-x: hidden;
        margin-right: 21px;
        margin-left: 15px;
        margin-bottom: 1rem;
        scrollbar-width: thin;
        scrollbar-color: #059fe3 #d6d6d6;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: #d6d6d6;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #059fe3;
            border-radius: 15px;
            border: 0px none #ffffff;
        }
    }

    .dropdown-item {
        padding: 0.5rem 0;
        color: #8e8e8e;
        cursor: pointer;
    }

    .dropdown-item:not(.dropdown-item-header):hover {
        color: #545454;
    }

    .dropdown-item-header {
        color: #cecece;
        font-style: italic;
        cursor: auto;
        border-top: 1px solid #d6d6d6;
        margin-top: 0.5rem;
        padding-top: 1rem;
        margin-right: 1.3rem;
    }

    .dropdown-item[data-value="0"].d-none + .dropdown-item-header {
        display: none;
    }
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}