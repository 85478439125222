.edit-details {

  &__form {
    margin-top: 3rem;
  }

  &__input-container {
    margin-bottom: 1rem;
  }

  &__extra-info {
    margin-bottom: 2.5rem;
  }

  &__extra-text {
    @include phone {
      line-height: 1.8;
      margin-bottom: 2rem;
    }
  }

  &__btn-container {
    margin-top: 1rem;
  }

}

.edit-details__input:focus + .edit-details__label-span {
  top: -1.5rem;
  left: 0.5rem;
  font-size: 0.7rem;
}

.edit-details__input:focus + .edit-details__label-span, .edit-details__input.full + .edit-details__label-span {
  top: -1.5rem;
  left: 0.5rem;
  font-size: 0.7rem;
}