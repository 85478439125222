.order-summary {
  background-color: $c_white;
  padding: 2rem 1.5rem 1rem 1.5rem;

  @include ipad-column-breakpoint {
    padding: 2rem 1rem 3rem 1rem;
  }

  &--cart {
    @include lg-desktop-breakpoint {
      margin-top: 2rem;
    }
  }

  @include ipad-column-breakpoint {
    margin-top: 0px;
  }

  &__right-block {
    @include ipad-column-breakpoint {
      padding-left: 0;
    }
    @include below-ipad-breakpoint {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &--hidden-on-mobile-checkout-success {
    @include ipad-column-breakpoint {
      display: none;
    }
  }

  &__title {
    font-weight: 600;
    border-bottom: solid 1px $c_mercury;
    padding-bottom: 1.3rem;
    margin-bottom: 1rem;

    &--has-no-bottom-margin {
      border-color: transparent;
      margin-bottom: 0;
      font-size: 1rem;
    }
  }

  &__generic-text {
    line-height: 1.7;
    margin: 0;
    padding: 0;

    &--has-bottom-margin {
      margin-bottom: 2rem;
    }
  }

  &__details {
    display: flex;
    justify-content: space-between;

    &--hidden-on-mobile {
      @include tablet {
        display: none;
      }
    }

    .is-bold {
      padding-left: 3px;
    }

    .generic-text {
      margin: 8px 0px;
    }
  }

  &__details-with-button {
    display: none;
    @include below-ipad-breakpoint {
      display: flex;
      justify-content: space-between;
    }
  }

  &__content-top {
    margin-bottom: 1rem;
  }

  &__content-bottom {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  &__confirmation {
    line-height: 1.5;
    margin-top: 2rem;
    margin-bottom: 2.5rem;

    &--hidden-on-mobile-checkout {
      @include ipad-column-breakpoint {
        display: none;
      }
    }
  }

  &__btn {
    margin-top: 3rem;
    padding-bottom: 4rem;
    text-align: center;
  }

  &__estimated-total {

    @include below-ipad-breakpoint {
      display: none;
    }

    &-border {
      @include below-ipad-breakpoint {
        display: none;
      }
    }
  }


  &__btn-container {
    text-align: center;
    margin-top: 3rem;
    padding-bottom: 2rem;
    @include ipad-column-breakpoint {
      text-align: right;
      margin-top: 0;
      padding-bottom: 0;
    }
  }
  &__btn-responsive-align {
    @include desktop {
      text-align: right !important;
    }
  }

  &__btn-estimated-total {
    display: none;
    text-align: center;

    @include ipad-column-breakpoint {
      display: block;
      margin-top: -0.5rem;
      line-height: 0.5;
      margin-bottom: 3rem;
    }

    @include below-ipad-breakpoint {
      margin-bottom: 2rem;
    }
  }

  &__btn-container--with-text {
    margin-top: 3rem;
    &--hidden-on-desktop-on-checkout {
      display: none;
    }
    @include ipad-column-breakpoint {
      display: block;
      text-align: center;
    }
  }

  &__checkout-btn-container {
    padding-bottom: 3rem;

    @include ipad-column-breakpoint {
      padding-bottom: 1rem;
    }
  }

  &--checkout-success {
    @include below-ipad-breakpoint {
      display: none;
    }
  }

  &__checkout-section {
    display: none;

    @include tablet {
      display: block;
    }
  }
}

@include at(sm) {
  .order-summary {
    position: relative;
    padding: 2rem 1.5rem 3rem 1.5rem;

    &__btn-group {
      position: fixed;
      bottom: 0px;
      z-index: 1010000;
      background-color: white;
      width: 100%;
      left: 0px;
      right: 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: 20px 10px;
      box-shadow: 0px 0 8px 0px #b2b2b2;

      > * {
        padding: 0px 5px;
        margin: 0px;
      }

      .order-summary__btn-estimated-total {
        margin: 0px !important;
        line-height: 23px;

        * {
          margin: 0px !important;
          display: block;
        }
      }
    }

  }
}

@media (max-width: 768px) {
  .js_show-saved-addresses {
    float: initial !important;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
}