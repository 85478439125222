/*
Links.
*/

@function safe-rgba($color, $alpha: 1.0) {
	@return unquote("rgba(#{red($color)}, #{green($color)}, #{blue($color)}, #{$alpha})");
}

@mixin arrow($color) {
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" style="fill:#{safe-rgba($color)};" viewBox="0 0 27.1 8.8"><path d="M22.7 0v3.8H0v1.3h22.7v3.8l4.4-4.4L22.7 0z"/></svg>');
}

.link {
	cursor: pointer;
	@include transition(all);

	&--has-arrow {
		display: flex;
		align-items: center;

		&:after {
			@include arrow($c_text-dark);
			@include transition(all);

			content: '';
			display: inline-block;
			background-repeat: no-repeat;
			background-position: center center;
			width: px2em(30, 13);
			height: 1em;
			margin-left: 1.5em;
			position: relative;
			left: 0;
		}

		&:hover {
			&:after {
				left: px2em(3);
			}
		}
	}

	&--has-back-arrow {
		display: flex;
		align-items: center;

		&:before {
			@include arrow($c_text-dark);
			@include transition(all);

			content: '';
			display: inline-block;
			background-repeat: no-repeat;
			background-position: center center;
			width: px2em(30, 13);
			height: 1em;
			margin-right: 1.5em;
			position: relative;
			right: 0;

			transform: rotate(180deg);
		}

		&:hover {
			&:after {
				left: px2em(3);
			}
		}
	}

	&--pop {
		color: $c_pop;

		&:after,
		&:before {
			@include arrow($c_pop);
		}

		&:hover {
			color: lighten($c_pop,10);
		}
	}

	&--light {
		color: $c_white;

		&:after {
			@include arrow($c_white);
		}

		&:hover {
			color: $c_pop;
		}
	}
}
