.create-account {
  width: 60%;
  @include ipad-column-breakpoint {
    width: 100%;
  }
  &__form {
    margin-top: 3rem;
    width: 90%;
  }

  &__input-container {
    margin-bottom: 1rem;
  }

  &__checkbox {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}