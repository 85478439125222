.product-single {
    background-color: #F7F7F7;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;

    @include below-ipad-breakpoint {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 3.75rem;

        @include below-ipad-breakpoint {
            flex-direction: column;
            align-items: start;
            padding-bottom: 3rem;

            .h1 {
                margin-bottom: 1.875rem;
            }

            .btn--secondary {
                display: none !important;
            }
        }
    }

    &__nav {
        border-bottom: 1px solid #EBEBEB;
        margin-bottom: 3.75rem;
        padding-top: 1.875rem;
        padding-bottom: 0.875rem;

        @include desktop {
            display: none !important;
        }

        &--gap {
            gap: 5rem;
        }

        &--mobile {
            display: none;
            width: 100%;
            background-color: #EFEFEF;
            border: none;
            position: sticky;
            top: 0;
            z-index: 5;
            padding: 0;

            @include desktop {
                display: block !important;
                top: 48px;
            }

            @include ipad-column-breakpoint {
                top: 0;
            }

            &.active {
                
                .product-single__nav-toggle svg {
                    transform: rotate(180deg);
                }

                .product-single__nav-dropdown {
                    display: flex;
                }
            }
        }

        &-link {
            @include transition(color, 2);

            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1.25;
            text-transform: uppercase;

            &:hover {
                color: $c_cerulean;
            }
        }

        &-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #EFEFEF;
            margin: 0 auto;
            padding: 1.375rem px2rem(15);

            @include desktop {
                max-width: 1140px;
            }

            @include lg-desktop-breakpoint {
                max-width: 960px;
            }

            @include ipad-column-breakpoint {
                max-width: 720px;
            }

            @include below-ipad-breakpoint {
                max-width: 100%;
            }
        }

        &-count {
            color: #B9B9B9;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1.25;

            &--active {
                color: $c_cerulean;
            }
        }

        &-dropdown {
            display: none;
            flex-direction: column;
            background-color: $c_white;
            box-shadow: 0px 3px 10px rgba($c_black, 0.05);

            .product-single__nav-link {
                border-bottom: 1px solid #EBEBEB;
                text-align: center;
                padding: 1.375rem;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    &__description {
        font-size: 0.875rem;
        line-height: 1.5;
        
        &-intro {
            font-size: 1.125rem;
            line-height: 1.4;
            margin-top: 1.875rem;
            margin-bottom: 3rem;
        }

        &-title {
            color: $c_cerulean;
            border-bottom: 1px solid #EBEBEB;
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 1.5rem;
            padding-bottom: 0.875rem;
        }

        &-feature {
            margin-bottom: 0.625rem;

            @include below-ipad-breakpoint {
                margin-bottom: 0.25rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .icon--checkmark {
                margin-right: 0.25rem;
            }
        }
    }

    &__carousel-wrapper--mobile {
        margin-bottom: 1.5rem;
        padding-left: px2rem(15);
        padding-right: px2rem(15);
    }

    &__resources {
        overflow: hidden;
        padding-top: 3.875rem;
        padding-bottom: 2.375rem;

        &-overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 200px;
            background-color: rgba($c_black, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;

            &:hover {
                
                & + div + a {
                    color: $c_cerulean;
                }
            }
        }

        &-type {
            color: $c_cerulean;
            font-size: 0.75rem;
            margin-top: 1.375rem;
            margin-bottom: 0.875rem;
        }

        &-title {
            @include transition(color, 2);

            font-size: 0.875rem;

            &:hover {
                color: $c_cerulean;
            }
        }

        .product-single__product-carousel {
        
            .product-single__resources-carousel {

                &--wide {
                    @media (max-width: 576px) {
                        width: calc(100% + 30px);
                        margin-left: -15px;
                        margin-right: -15px;
                    }
                }

                .swiper-slide-active .swiper-content {
                    @media (max-width: 576px) {
                        margin-left: 15px;
                    }
                }

                .swiper-slide:last-child .swiper-content {
                    @media (max-width: 576px) {
                        margin-left: 0;
                        margin-right: 15px;
                    }
                }

                .swiper-content {
                    align-items: flex-start;
                    background-color: transparent;
                    box-shadow: none;
                    padding: 0;
                }

                .swiper-image,
                .swiper-image img {
                    width: 100%;
                    height: 200px;
                    object-fit: cover;
                }
            }
    
            .swiper-button-next,
            .swiper-button-prev {
                top: 96px;

                @include ipad-column-breakpoint {
                    margin-top: 1rem;
                }
            }

            .swiper-pagination-bullets {

                @include ipad-column-breakpoint {
                    bottom: 0.625rem;
                }
            }
        }

        .h2 {
            max-width: 400px;
            margin-bottom: 3.25rem;

            @include below-ipad-breakpoint {
                margin-bottom: 1.875rem;
            }
        }
    }

    &__table {
        display: block;
        table-layout: fixed;
        min-width: 100%;
        border-collapse: collapse;
        position: relative;
        overflow-x: auto;
        z-index: 1;

        @include ipad-column-breakpoint {
            &::-webkit-scrollbar {
                display: unset !important;
                width: 4px;
                height: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #D8D8D8;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background: #F7F7F7;
                border-radius: 10px;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &--vertical {

            td:first-child {
                width: 1%;
                min-width: auto;
                font-weight: 400;
                white-space: nowrap;
            }
        }

        &--scroll {

            th, td {
                width: 180px;
                min-width: 180px;
            }
        }

        &-wrapper {
            position: relative;
            margin-bottom: 2.25rem;
            padding: 3rem 0 2rem 0;

            @include below-ipad-breakpoint {
                padding: 1.5rem 1.25rem;
            }

            &:before {
                content: '';
                width: calc(100% + 7.5rem);
                max-width: 1260px;
                height: 100%;
                background-color: $c_white;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
    
                @include desktop {
                    width: calc(100% + 2.5rem);
                }
    
                @include below-ipad-breakpoint {
                    width: 100%;
                }
            }

            .h2 {  
                margin-bottom: 3rem;

                @include below-ipad-breakpoint {
                    margin-bottom: 1.75rem;
                }
            }
        }

        &-tabs {
            position: relative;
            margin-bottom: 1.75rem;
        }

        &-tabs-nav {
            display: flex;
            border-bottom: 1px solid #EBEBEB;
            overflow-y: hidden;

            @include desktop {
                display: none;
            }

            &--mobile {
                display: none;
                border-bottom: none;
                font-size: 0.875rem;
                font-weight: 700;
                position: relative;

                @include desktop {
                    display: block;
                }

                &.active {
                
                    .product-single__table-tabs-toggle svg {
                        transform: rotate(180deg);
                    }
    
                    .product-single__table-tabs-dropdown {
                        display: flex;
                    }
                }
            }
        }

        &-tabs-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #EFEFEF;
            margin: 0 auto;
            padding: 1.375rem px2rem(15);
        }

        &-tabs-count {
            color: #B9B9B9;
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1.25;

            &--active {
                color: $c_cerulean;
            }
        }

        &-tabs-dropdown {
            display: none;
            flex-direction: column;
            width: 100%;
            background-color: $c_white;
            box-shadow: 0px 3px 10px rgba($c_black, 0.05);
            position: absolute;
            z-index: 2;
        }

        &-tabs-button {
            color: #9F9F9F;
            background-color: #F0F0F0;
            border: 1px solid #F0F0F0;
            border-bottom: none;
            font-size: 0.875rem;
            font-weight: 700;
            white-space: nowrap;
            margin-right: 0.625rem;
            padding: 1rem 1.25rem;
            cursor: pointer;

            @include desktop {
                background-color: $c_white;
                border: none;
                border-bottom: 1px solid #EBEBEB;
                color: #505050;
                text-align: center;
                margin: 0;
            }

            &:first-child {
                margin-left: 0.625rem;
            }

            &:last-child {
                @include desktop {
                    border-bottom: none !important;
                }
            }

            &.active {
                background-color: $c_white;
                border: 1px solid #EBEBEB;
                border-bottom: none;
                color: #505050;
                position: relative;

                @include desktop {
                    background-color: $c_white;
                    border: none;
                    border-bottom: 1px solid #EBEBEB;
                    margin: 0;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: $c_white;
                    position: absolute;
                    bottom: -1px;
                    left: 0;

                    @include desktop {
                        display: none;
                    }
                }
            }
        }

        &-tabs-content {
            display: none;

            &.active {
                display: block;
            }

            .product-single__table {
                @include ipad-column-breakpoint {
                    padding-bottom: 1.75rem;
                }
            }
        }

        &-button-row {
            background-color: $c_white !important;
            border-bottom: none !important;
        }

        &-nav {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: $c_cerulean;
            font-size: 0.75rem;
            line-height: 1;
            position: absolute;
            top: 5.25rem;
            right: 0;
            z-index: 1;

            &-prev,
            &-next {
                display: flex;

                &:disabled {
                    display: none;
                }
            }

            &-prev {
                transform: rotate(180deg);
            }

            @include ipad-column-breakpoint {
                display: none;
            }
        }

        thead {

            tr {
                background-color: $c_white;
                border-bottom: 1px solid #EBEBEB;
            }
        }

        th {
            font-size: 0.875rem;
            font-weight: 400;
            text-align: left;
            padding: 1.375rem 1rem;
        }

        tbody {
            border-top: 1px solid #EBEBEB;

            tr {
                border-bottom: 1px solid #EBEBEB;
    
                &:nth-child(odd) {
                    background-color: #FAFAFA;
                }
            }
        }

        td {
            font-size: 0.875rem;
            font-weight: 700;
            padding: 1.375rem 1rem;
        }

        .btn.btn--small {
            padding-left: 2.25rem;
            padding-right: 2.25rem;
        }
    }

    &__downloads {
        position: relative;
        padding: 3.5rem 0;

        @include below-ipad-breakpoint {
            padding: 1.5rem 0;
        }

        &:before {
            content: '';
            width: calc(100% + 7.5rem);
            max-width: 1260px;
            height: 100%;
            background-color: $c_white;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            @include desktop {
                width: calc(100% + 2.5rem);
            }

            @include below-ipad-breakpoint {
                width: 100%;
            }
        }

        &-container {
            position: relative;
            z-index: 1;
            margin-top: 3rem;

            @include below-ipad-breakpoint {
                margin-top: 1.375rem;
                padding: 0 1.25rem;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #F4F4F4;
            padding-top: 1rem;
            padding-bottom: 1rem;

            @include below-ipad-breakpoint {
                align-items: unset;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            &:first-child {
                border-top: 1px solid #F4F4F4;
            }

            &:hover {
                @include transition((background-color, color), 2);
                
                background-color: $c_cerulean;
                color: $c_white;
    
                .icon--file path {
                    @include transition(fill, 2);
                    
                    fill: $c_white;
                }
    
                .icon--download path {
                    @include transition(stroke, 2);
                    
                    stroke: $c_white;
                }
    
                .btn--secondary,
                .btn--secondary:hover {
                    border-color: $c_white;
                    color: $c_white;
                }
            }
        }

        &-image {
            flex-shrink: 0;
            padding: 0 2rem;

            @include below-ipad-breakpoint {
                padding-left: 0;
                padding-right: 1rem;
            }
            
            picture, img {
                width: 60px;
                height: 85px;
                object-fit: cover;
            }
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @include below-ipad-breakpoint {
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-around;
            }

            span {
                font-weight: 700;

                @include below-ipad-breakpoint {
                    font-size: 0.875rem;
                }
            }
        }

        &-description {
            max-width: 500px;
            font-size: 0.875rem;
            margin-top: 0.5rem;

            @include below-ipad-breakpoint {
                font-size: 0.75rem;
                margin-right: 1.5rem;
            }
        }

        &-size {
            flex-shrink: 0;
            font-size: 0.875rem;
            margin: 0 2rem;

            @include below-ipad-breakpoint {
                font-size: 0.75rem;
                margin: 0;
                margin-top: 0.5rem; 
            }
        }

        .h2 {
            z-index: 1;

            @include below-ipad-breakpoint {
                margin: 0 1.25rem;
            }
        }

        .icon--file {
            flex-shrink: 0;
            margin: 0 1rem;

            @include below-ipad-breakpoint {
                display: none;
            }
        }

        .btn--secondary {
            flex-shrink: 0;
            width: 190px;
            text-align: left;
            position: relative;
            margin: 0 2rem;
            padding-left: 1.75rem !important;
            padding-right: 1.75rem !important;

            @include below-ipad-breakpoint {
                align-self: flex-end;
                width: auto;
                border: none !important;
                background-color: transparent !important;
                margin: 0;
                padding: 0 !important;
            }

            .icon--download {
                position: absolute;
                top: 50%;
                right: 1.75rem;
                transform: translateY(-50%);

                @include below-ipad-breakpoint {
                    position: static;
                    transform: unset;
                }
            }

            span {
                @include below-ipad-breakpoint {
                    display: none;
                }
            }
        }

        .js_trackedResources {
            height: unset;
            margin-top: 0;
        }
    }

    &__accessories {
        overflow: hidden;
        padding-top: 4.5rem;
        padding-bottom: 3.25rem;

        @include below-ipad-breakpoint {
            padding-top: 4rem;
            padding-bottom: 2.875rem;
        }

        &-carousel {
            margin: 0 -0.5rem;
            padding: 0 0.5rem;
            
            @media (max-width: 576px) {
                margin: 0 -1rem;
                padding: 0 1rem;
            }
        }

        .h2 {
            margin-bottom: 3.25rem;

            @include below-ipad-breakpoint {
                margin-bottom: 1.5rem;
            }
        }
    }

    &__product-carousel {
        position: relative;

        .swiper-slide {
            width: auto;
            height: auto;
            margin-top: 0.5rem;
            margin-bottom: 1.25rem;
        }

        .swiper-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            background-color: $c_white;
            box-shadow: 0px 3px 10px rgba($c_black, 0.05);
            box-sizing: border-box;
            position: relative;
            padding: 2.25rem;

            &-overlay {
                @include transition(background-color, 2);

                width: 100%;
                height: 100%;
                background-color: transparent;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
            }

            &:hover {
                .swiper-content-overlay {
                    background-color: rgba($c_black, 0.7);
                    z-index: 0;
                }

                .swiper-title {
                    color: $c_white;
                }

                .btn.btn--secondary {
                    border-color: $c_white;
                    color: $c_white;

                    &:hover {
                        border-color: $c_cerulean;
                    }
                }

                .technical-info span {
                    display: none;
                }

                .btn.btn--primary {
                    display: block;
                }
            }
        }

        .swiper-title {
            @include transition(color, 2);

            min-height: 38px;
            font-weight: 700;
            position: relative;
            z-index: 1;
            margin-bottom: 0.75rem;
        }

        .swiper-image,
        .swiper-image img {
            width: 120px;
            height: 150px;
            object-fit: contain;
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.375rem;
            height: 1.375rem;
            background: none;
            border: 2px solid $c_cerulean;
            border-radius: 100%;

            @include ipad-column-breakpoint {
                position: static;
                margin-top: 1.5rem;
                transform: none !important;
            }
        }

        .swiper-button-prev {
            left: -1.125rem;
            transform: translateX(-100%);

            @include largeTablet {
                left: -0.375rem;
            }

            @include ipad-column-breakpoint {
                margin-right: 0.375rem;
            }
            
            svg {
                transform: rotate(90deg);
            }
        }

        .swiper-button-next {
            right: -1.125rem;
            transform: translateX(100%);

            @include largeTablet {
                right: -0.375rem;
            }
            
            svg {
                transform: rotate(-90deg);
            }
        }

        .swiper-pagination-bullets {
            display: flex;
            gap: 0.625rem;
            width: auto !important;
            position: absolute;
            top: -3.875rem;
            right: 0;

            @include ipad-column-breakpoint {
                top: auto;
                bottom: 0.375rem;
            }

            .swiper-pagination-bullet {
                @include transition(background-color, 2);

                width: 0.5rem;
                height: 0.5rem;
                background-color: #EBEBEB;
                border: none;
                left: 0 !important;
                transform: none;

                &-active {
                    background-color: $c_cerulean;
                }

                &-active-prev,
                &-active-next {
                    background-color: #D1D1D1;
                }
            }
        }

        .technical-info {
            width: 100%;

            span {
                display: block;
                max-width: 180px;
                font-size: 0.875rem;
                text-align: center;
                margin-top: 1.5rem;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .btn.btn--secondary {
            width: 100%;
            min-width: 180px;
            text-align: center;
            position: relative;
            z-index: 1;
            margin-top: 1.5rem;
        }

        .btn.btn--primary {
            display: none;
            width: 100%;
            min-width: 180px;
            text-align: center;
            position: relative;
            z-index: 1;
            margin-top: 0.75rem;
        }
    }

    &__related {
        background-color: #EDEDED;
        position: relative;
        overflow: hidden;
        margin-top: 1rem;
        padding-top: 9rem;
        padding-bottom: 9.75rem;

        @include below-ipad-breakpoint {
            padding-top: 2.75rem;
            padding-bottom: 5.625rem;
        }

        &:before {
            content: '';
            width: 100%;
            height: 1rem;
            background-color: #F7F7F7;
            box-shadow: 0px 3px 10px rgba($c_black, 0.05);
            position: absolute;
            top: -1rem;
            left: 0;
        }

        &-text {
            max-width: 245px;
            font-size: 0.875rem;
            line-height: 1.5;
            margin-bottom: 2.25rem;

            @include below-ipad-breakpoint {
                max-width: 100%;
                margin-bottom: 1.5rem;
            }
        }

        .product-single__product-carousel {
            position: static;

            .swiper-slide {
            
                &:last-child .swiper-content {
                    margin-right: 1rem;
                }

                &-active .swiper-content {
                    margin-left: 15px;
                }
            }

            .swiper-buttons {
                position: absolute;
                margin-top: 2.5rem;
                right: calc((100% - 1140px) / 2 + 15px);

                @include desktop {
                    right: calc((100% - 960px) / 2 + 15px);
                }

                @include ipad-column-breakpoint {
                    right: calc((100% - 720px) / 2 + 15px);
                }

                @include below-ipad-breakpoint {
                    left: 15px;
                    right: auto;
                    margin-top: 0;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                position: static;
                transform: none;
            }

            .swiper-button-prev {
                margin-right: 0.625rem;

                @include below-ipad-breakpoint {
                    margin-right: 0.375rem;
                }
            }
        
            .swiper-pagination-bullets {
                top: 5.25rem;
                right: calc((100% - 1140px) / 2 + 15px);

                @include desktop {
                    right: calc((100% - 960px) / 2 + 15px);
                }

                @include ipad-column-breakpoint {
                    right: calc((100% - 720px) / 2 + 15px);
                }

                @include below-ipad-breakpoint {
                    top: auto;
                    bottom: 3.125rem;
                    right: 15px;
                }
            
                .swiper-pagination-bullet {
                    background-color: #F8F8F8;

                    &-active {
                        background-color: $c_cerulean;
                    }

                    &-active-prev,
                    &-active-next {
                        background-color: #D1D1D1;
                    }
                }
            }
        }

        .container-fluid {
            padding-left: calc((100% - 1140px) / 2);
            padding-right: 0;

            @include desktop {
                padding-left: calc((100% - 960px) / 2);
            }

            @include ipad-column-breakpoint {
                padding-left: calc((100% - 720px) / 2);
            }

            .row {
                margin: 0;

                & > div:last-child {
                    position: static;

                    @include below-ipad-breakpoint {
                        padding-left: 0;
                    }
                }
            }
        }
        
        .h2 {
            max-width: 260px;
            margin-bottom: 2rem;

            @include below-ipad-breakpoint {
                max-width: 100%;
            }
        }

        .link--arrow {
            display: inline-block;
            font-size: 0.875rem;
            line-height: 1.5;

            @include below-ipad-breakpoint {
                margin-bottom: 2.25rem;
            }

            &:after {
                top: 2px !important;
            }
        }
    }

    &__gallery {
        display: flex;

        @include below-ipad-breakpoint {
            flex-direction: column;
        }

        &-nav {
            width: 70px;

            @include below-ipad-breakpoint {
                order: 1;
                width: calc(100% + 15px);
                margin-top: 10px;
            }
        }

        &-thumbs {
            width: 70px;
            height: 448px;

            @include below-ipad-breakpoint {
                width: 100%;
                height: 70px;
            }

            .swiper-wrapper {
                margin-top: -190px;

                @include below-ipad-breakpoint {
                    margin-top: 0;
                }
            }

            .swiper-slide {
                height: 70px !important;
            }

            .swiper-slide-active .product-single__gallery-thumb {
                border: 1px solid $c_cerulean;
            }
        }

        &-thumb {
            width: 68px;
            height: 68px;
            background-color: $c_white;
            border: 1px solid $c_white;
            cursor: pointer;

            picture, img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100% !important;
                height: 100% !important;
                object-fit: contain;
            }
        }

        &-carousel {
            width: 100%;
            max-width: 448px;
            height: 448px;
            margin-left: 12px;

            @include below-ipad-breakpoint {
                max-width: 100%;
                height: 330px;
                margin-left: 0;
            }

            .swiper-slide {
                background-color: $c_white;
            
                &:hover .product-single__gallery-image-overlay {
                    display: flex;
                }
            }
        }

        &-image {
            width: 100%;
            height: 100%;
            
            &-overlay {
                display: none;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.6);
                color: $c_white;
                font-size: 14px;
                font-weight: 700;
                line-height: 1;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;

                svg {
                    margin-bottom: 20px;
                }
            }

            &-full {
                display: none;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                @include below-ipad-breakpoint {
                    height: calc(100vh - 70px - 1.5rem);
                    top: calc(70px + 1.5rem);
                }

                &.active {
                    display: flex;
                }

                picture, img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }

            picture, img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.375rem;
            height: 1.375rem;
            background: none;
            border: 2px solid $c_cerulean;
            border-radius: 100%;
            position: absolute;
            left: 100px;
            transform: none !important;

            @include below-ipad-breakpoint {
                display: none;
            }
        }

        .swiper-button-prev {
            top: 381px;
            transform: rotate(180deg) !important;
        }

        .swiper-button-next {
            top: 411px;
        }
    }

    &__modal-gallery {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 201;
        padding-top: 1.5rem;
        opacity: 0;
        visibility: hidden;

        &.visible {
            z-index: 1999999949;
            opacity: 1;
            visibility: visible;
        }

        .product-single-gallery-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 90%;
            min-height: 70px;
            position: relative;
            z-index: 2;
            margin-bottom: 1.5rem;
            margin-left: auto;
            margin-right: auto;

            @include ipad-column-breakpoint {
                flex-direction: column;
                align-items: flex-start;
                max-width: calc(100% - 30px);
            }
        }
        
        .swiper-button-prev,
        .swiper-button-next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.375rem;
            height: 1.375rem;
            background: none;
            border: 2px solid $c_white;
            border-radius: 100%;
            position: static;
            transform: rotate(-90deg) !important;
        }

        .swiper-button-prev {
            margin-right: 0.5rem;
            transform: rotate(90deg) !important;
        }

        .product-single__gallery-zoom {
            margin-left: 1.5rem;
            cursor: pointer;

            @include ipad-column-breakpoint {
                margin-left: 0.75rem;
            }

            &.active {
                rect:nth-child(2) {
                    display: none;
                }
            }
        }

        .product-single__gallery-nav {
            width: auto;
            max-width: 400px;
            height: 70px;

            @include ipad-column-breakpoint {
                max-width: 100%;
            }

            @include below-ipad-breakpoint {
                order: unset;
            }
        }

        .product-single__gallery-thumbs {
            width: auto;
            height: 70px;

            .swiper-wrapper {
                margin-top: 0;
            }

            .swiper-slide {
                width: 70px;
            }
        }

        .product-single__gallery-close {
            color: $c_white;
            font-size: 3rem;
            font-weight: 100;
            line-height: 0.5;
            cursor: pointer;

            @include ipad-column-breakpoint {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .product-single__gallery-carousel {
            width: 100%;
            max-width: 100%;
            height: 600px;
            margin-left: 0;

            @include ipad-column-breakpoint {
                height: 400px;
            }

            .swiper-slide {
                background-color: transparent;
            }
        }

        .product-single__gallery-image {
            width: auto;
            height: 600px;
            background-color: $c_white;

            @include ipad-column-breakpoint {
                height: 400px;
            }

            picture, img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__video-player {
        width: 60%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 50%;
        z-index: 201;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;

        @include below-ipad-breakpoint {
            width: calc(100% - 30px);
        }

        &.visible {
            z-index: 1999999949;
            opacity: 1;
            visibility: visible;
        }

        &-close {
            color: $c_white;
            font-size: 3rem;
            font-weight: 100;
            line-height: 0.5;
            position: fixed;
            top: 4rem;
            right: -10%;
            z-index: 100000010;
            transform: translateX(100%);
            cursor: pointer;

            @include below-ipad-breakpoint {
                top: 3rem;
                right: 0;
                transform: translateX(0);
            }
        }

        &-text {
            color: $c_white;
            margin-top: 3rem;
        }

        &-overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100000009;
            opacity: 0;
            visibility: hidden;

            &.visible {
                z-index: 1999999948;
                opacity: 1;
                visibility: visible;
            }
        }

        figure {
            width: 100%;
            height: 0;
            position: relative;
            top: 4rem;
            margin: 0;
            padding-top: 66.67%;

            @include below-ipad-breakpoint {
                top: 6rem;
            }
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100000010;
        }
    }

    .h1 {
        color: $c_cerulean;
        font-size: 2.375rem;
        font-weight: 700;
        line-height: 1.25;
        letter-spacing: -0.0125em;

        @include below-ipad-breakpoint {
            font-size: 1.625rem;
            line-height: 1.4;
        }
    }
    
    .h2 {
        font-size: 1.5rem;
        font-family: Arial, sans-serif;
        font-weight: 700;
        line-height: 1.5;
        position: relative;
        padding-bottom: 1.25rem;

        @include below-ipad-breakpoint {
            padding-bottom: 1.25rem;
        }

        &:before {
            content: '';
            width: 4rem;
            height: 2px;
            background-color: $c_cerulean;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .link {
        color: $c_cerulean;

        &--white {
            color: $c_white;

            &.link--arrow:after {
                background-image: url('/static/bundles/img/svg/arrow-right-white.svg');
            }
        }

        &--arrow {

            &:after {
                @include transition(left, 2);
    
                content: "";
                width: 1.7rem;
                height: 1rem;
                display: inline-block;
                background-image: url('/static/bundles/img/svg/arrow-right-blue.svg');
                background-position: center center;
                background-repeat: no-repeat;
                position: relative;
                top: calc(50% - 0.5rem);
                left: 1rem;
            }
    
            &:hover {
                &:after {
                    left: 1.25rem;
                }
            }
        }
    }

    .btn {
        @include transition((background-color, border-color, color), 2);

        display: inline-block;
        border-radius: px2rem(30);
        font-size: 1rem;
        font-weight: 700;
        line-height: 1;
        padding: 1rem 2.25rem;
        cursor: pointer;

        &--primary {
            background-color: $c_cerulean;
            border: 2px solid $c_cerulean;
            color: $c_white;

            &:hover {
                background-color: $c_atlantis;
                border-color: $c_atlantis;
            }
        }

        &--secondary {
            background-color: transparent;
            border: 2px solid $c_cerulean;
            color: $c_cerulean;

            &:hover {
                background-color: $c_cerulean;
                border-color: $c_cerulean;
                color: $c_white;
            }
        }

        &--small {
            font-size: 0.875rem;
            padding: 0.75rem 1.25rem;
        }
    }
}
