/*
Slide Nav.
*/

.slide-nav {
	opacity: 0;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: $c_white;
	z-index: 100;

	&__slide {
		@include transition(transform, 3);

		position: absolute;
		z-index: 1;
		width: 100vw;
		height: 100vh;
		background-color: $c_white;
		padding-top: px2rem(96);

		&[data-level="1"] {
			// transform: translateX(100%);
		}

		&[data-level="2"] {
			transform: translateX(100%);
		}

		&[data-level="3"] {
			transform: translateX(100%);
		}

		&.is-active {
			transform: none;
			z-index: 20;
		}

		&.was-active {
			transition: none;
			transform: none !important;
			z-index: 10;
		}
	}

	&__separate-item {
		padding: 0.4rem 0rem;
	}

	&__item {
		@include transition((background-color, color));

		padding: 0.4rem 1.5rem;
		width: 100%;
		text-align: left;
		color: $c_text-dark;
		border-top: 1px solid transparent;
		font-weight: lighter !important;

		&:last-child {
			padding-bottom: 1rem;
			max-width: px2rem(588);
			margin-left: auto;
			margin-right: auto;
		}

		&-inner {
			@include transition(color);
			font-weight: lighter !important;
			color: $c_text-dark;
			max-width: px2rem(540);
			margin: 0 auto;
			position: relative;
			font-weight: lighter !important;
			font-size: 17px;
			transform: translateY(5px);
		}

		&--has-children {

			&:after {
				position: absolute;
				right: 0;
				left: auto;
			}
		}

		&--back {
			font-weight: 400;
			transform: translateX(-0.45rem) !important;
			color: $c_pop !important;
			padding-top: 0.8rem;
			padding-bottom: 0.8rem;

			&:hover {
				color: $c_pop;
			}
			&:before {
				background-image: url(/static/bundles/img/svg/menu-arrow.svg) !important;
				vertical-align: middle;
				content: '';
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center center;
				width: 1.30769em;
				height: 1em;
				transform: scale(0.9) rotate(-180deg);
			}
		}

		span {
			display: block;
		}
	}

	&[data-currentlevel="2"] {
		.slide-nav {
			&__slide {
				&[data-level="1"] {
					transform: translateX(-100%);
				}
			}
		}
	}

	&[data-currentlevel="3"] {
		.slide-nav {
			&__slide {
				&[data-level="2"],
				&[data-level="1"] {
					transform: translateX(-100%);
				}
			}
		}
	}

}

div.slide-nav__item {
	padding-bottom: 0px !important;
}

.slide-nav__back {

	&-btn {
		background-color: $c_grey-2;
		font-weight: 600 !important;
		margin-top: -1rem;
	}

	&-container {
		padding-top: 1rem;
	}
}

.slide-nav__contact-list {

	margin-top: 0.5rem;

	.slide-nav__item-inner--icon {
		display: inline-block;
		margin-right: 7px;
		transform: translateY(3px);
		height: 18px;
	}

	background-color: $c_grey-2;

	li {
		border-bottom: solid 1px $c_mercury;
		width: 90%;
		padding-left: 0;
		padding-right: 0;
		margin: 0 auto;

		&:last-child {
			border-bottom: none;
		}
	}

	.js_slide-nav-trigger {
		width: 100%;
		height: 37px;
	}
}

.slide-nav__form-container {
	display: flex;
	justify-content: space-between;
}

.form__nav {
	width: 100%;
}

body.is-open,
body.is-single-close-open {
	.customer-portal-top.container-fluid {
		display: none;
	}

	&:not(.slider-open) #megaMenuElement {
		display: none;
	}
}

@include at(md) {
	.slide-nav {
		&.is-open {
			opacity: 1;
			visibility: visible;
			.slide-nav {
				&__slide {
					overflow-y: scroll;
					height: calc(100% - 80px);

					.form__nav {
						margin-bottom: 40px;
					}
					
					.no-quotes {
						text-align: center;
					}
				}
			}
		}

		&__header {
			height: px2rem(80);
			z-index: 100;
			right: 0;

			.nav__button {
				border-left: 0;
			}
		}

		&__secondary {
			.slide-nav__item {
				border-top: none;
			}
		}
	}

	.slide-nav-is-open {
		overflow-y: hidden;
	}
}


@include at(xs) {
	.slide-nav {
		&__item {
			&-inner {
				font-size: 17px;
				transform: translateY(5px);
				font-weight: bold !important;
			}
			&--has-children {
				&:after {
					position: absolute;
				}
			}
		}
	}
}




.right-panel-slider {
	transform: translateX(120%);
	transition: .25s linear;
	background-color: white;
	position: absolute;
	z-index: 21;
	display: block!important;

	&.is-active {
		transform: translateX(0);
	}
}

.slide-nav__login-titles {
	display: flex;
	justify-content: space-between;
}

@include at(md) {
	.right-panel-slider {

		&__inner {
			padding-top: px2rem(90);

			&--user-icon::before {
				content: url(/static/bundles/img/svg/user.svg);
				height: 30px;
				width: 30px;
			}
		}

		.form-container {
			max-width: px2rem(540) !important;
			margin: 0 auto !important;
			padding: 0px !important;
		}

		.close-container {
			background-color: #ffffff;
			position: fixed;
			left: -25px;
			right: 0px;
			top: 0px;
			height: px2rem(84);
			width: 100% !important;
		}
	}
}

.slide-nav__item a{
	float: left;
	// padding-left: 3.5rem;
}

.slide-nav__item button {
	float: right;
}

.slide-nav__item::after {
	content: "";
	clear: both;
	display: table;
}

.slide-nav__item .link--has-arrow:after {
	padding: 20px 0;
	text-align: right;
	top: -4px;
}

.slide-nav__item--no-arrow .link--has-arrow:after {
	opacity: 0;
}

.overflow-nav {
    overflow-x: hidden!important;
    overflow-y: auto!important;
}

@media (max-width: 430px) {
	.form__nav input[type='submit'], .mobile-quote .js_add-to-quote-submit {
		padding: 1.875rem 0.5625rem;
	}
}

