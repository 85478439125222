.terms {

  padding-bottom: 2rem;

  @include below-ipad-breakpoint {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h1 {
    margin-bottom: 3rem;
    line-height: 1.4;
    font-size: 1.75rem;
    letter-spacing: -.0125em;
    font-weight: bold;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.6;
    padding-bottom: 2rem;

    + p {
      margin-top:-1rem;
    };
  }


  h2 {
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
  }

  table {
    padding-bottom: 1rem;
    font-size: 0.9rem;
  }
}