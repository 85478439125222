.popup-container {
  position: fixed;
  z-index: 1999999948;
  overflow: auto;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.41);
  backdrop-filter: blur(2px);
  padding: 2rem;
}

div[id^="rxp-overlay-"] {
  z-index: 1999999948 !important;
}

.popup-model {
  width: 100%;
  min-width: 450px;
  max-width: fit-content;
  z-index: 400;
  background: $c_white;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 1rem;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.4);

  .create-an-account-mobile {
    display: none;
  }

  &__close {
    color: $c_pop;
    font-size: 1.9rem;
    position: absolute;
    top: 12px;
    right: 20px;
    cursor: pointer;
    z-index: 402;
  }

  .field:not(.u_mb-1) {
    margin-bottom: 2.5rem !important;
  }

  &__title-image {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 700;
    color: $c_text-dark;
  
    img {
      width: 40px;
      display: inline-block;
      margin-right: 10px;
    }
  }

  &__model {
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
  }

  &__padded {
    padding: 2rem;
  }

  &__padded-thick {
    padding: 4rem;
  }
  
  &__left-placeholder + .popup-model__right {
    width: 100%;
    max-width: 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  &__left-placeholder.small-model + .popup-model__right {
    width: 500px;
  }

  &__left-placeholder.medium-model + .popup-model__right {
    width: 700px;
  }

  &__left {
    padding-right: 15px;
    padding-left: 15px;
  }

  &__left * {
    color: #ffffff !important;
  }

  &__left {
    a:hover {
      color: #d7f3ff !important
    }
  }

  &__left {
    p, ol, ul, li {
      line-height: 24px;
      margin: 0 0 3px 0;
      font-size: 16px;
    }
    ol, ul {
      padding-left: 12px;
    }
  }

  &__right {
    padding-right: 15px;
    padding-left: 15px;
    overflow-x: hidden;
  }

  &__left > section{
    background-color: $c_cerulean;
    height: 100%;
    padding: 2rem 2rem;
    color: $c_white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div > *:first-child {
      margin-top: 0px;
    }
  }

  &__right > section {
    padding: 2rem 2rem 2rem 1rem;
    min-width: 400px;
  }

  h2, &__title {
    color: $c_cerulean;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    &--left {
      color: $c_white !important;
    }
  }

  &__lead {
    line-height: 1.7;
  }

  &__lead-link {
    color: $c_white;
    font-weight: lighter;
    text-decoration: underline;
  }

  &__btn-container {
    text-align: right;
  }

  &__btn-group-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > * {
      display: block;
      margin-bottom: 1rem;
    }

    > a.link.link--pop {
      margin-right: 1rem;
    }
  }

  .b_button-primary:not(.b_button-narrow):not(.b_button-short),
  .b_button-secondary:not(.b_button-narrow):not(.b_button-short),
  .b_button-invert:not(.b_button-narrow):not(.b_button-short)
  {
    padding: 1rem 3.8rem !important;
    font-size: 0.9rem !important;
    font-weight: 400;
  }

  &__nudge-tooltip .tooltip {
    top: 8px !important;
    z-index: 2 !important;
  }

}

.tooltip-top-zero + .tooltip{
  top: 0px !important;
}

.model-width-fixed {
  .f_checkbox-label, p {
    max-width: initial !important;
  }
}

.popup-model--notification {
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: solid 6px $c_green;

  .t_page-title-secondary {
    font-size: 1.1rem  !important;
  }

  &__title {
    color: $c_text-dark !important;
    margin-bottom: 2rem !important;
    display: flex;
    align-items: center;
  }

  &__lead {
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
    max-width: 320px;
  }

  &__icon {
    width: 30px;
    display: inline-block;
    margin-right: 10px;
  }
}

/* enquiry popup slides */

.popup-enquiry--b {
  display: none;
}

.popup-next-slide {
  animation: slideLeft 0.2s forwards;
}

@keyframes slideLeft {
  0% {
    transform: translateX(400px);
  }
  100% {
    transform: translateX(0);
  }
}

.popup-prev-slide {
  animation: slideRight 0.2s forwards;
}

@keyframes slideRight {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(0);
  }
}

.popup-enquiry-bullets {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.popup-enquiry-bullet {
  height: 10px;
  width: 10px;
  border: solid 1px $c_silver;
  background: transparent;
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.5rem;
  cursor: pointer;

  &--active {
    background-color: $c_silver;
  }
}

.popup-bullets {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.popup-bullet {
  height: 10px;
  width: 10px;
  border: solid 1px $c_silver;
  background: transparent;
  display: inline-block;
  border-radius: 50%;
  margin-right: 0.5rem;

  &--active {
    background-color: $c_silver;
  }
}

@media (max-width: 840px) {

  .popup-model__left:not(.keep-blue-section) {
    display: none;
  }

  .popup-model__left.keep-blue-section {
    width: 100% !important;
    max-width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    order: 1;
  }

  .popup-model__right {
    width: 100% !important;
    max-width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .popup-model {
    .b_button-primary,
    .b_button-secondary,
    .b_button-invert
    {
      display: block;
      width: 100%;
    }

    &__btn-group-container {
      flex-direction: column;
      text-align: center;
  
      > * {
        margin-bottom: 2rem;
        width: 100%;
      }

      .link--has-back-arrow {
        order: 1;
      }

      > a.link.link--pop {
        margin-right: 0;
      }
    }
  }


}

@media (max-width: 500px) {

  .popup-model__right > section {
    padding: 4rem 0.5rem !important;
    min-width: initial;
  }
  
  .popup-container {
    padding: 0.5rem;
  }

}

@media (max-width: 530px) {

  .popup-model {
    min-width: initial;
    max-width: initial;
  }

}

#configurator-quote-slider {
  .field {
    margin-bottom: 3.125rem !important;
  }
}

.item-added-to-cart{

  table {
    border-spacing: 0px;
    border-collapse: collapse;
    width: 100%;
  }

  &__product, &__price {
    font-size: 1.2rem;
    font-weight: 700;
    border-top: 2px solid #ebebeb;
    border-bottom: 2px solid #ebebeb;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__product {
    color: $c_cerulean;
    padding-right: 1rem;
  }

  &__price {
    color: $c_text-dark;
    text-align: right;
  }

}

@media (max-width: 649px) {
  .popup-model {
    &__padded-thick {
      padding: 4rem 2rem;
    }
  }
}

@media (max-width: 400px) {
  .popup-model {
    &__padded {
      padding: 2rem 1rem;
    }
    &__padded-thick {
      padding: 4rem 1rem;
    }
  }
}


@media (max-width: 991px) {

  .popup-mobile-slider {

    &.popup-container {
      background-color: transparent;
      backdrop-filter: initial;
      top: 143px;
      height: calc(100% - 143px);
      padding: 0rem;
    }

    .popup-model {
      box-shadow: none;
      max-width: 36.75rem;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem;
    }

    .popup-model__right {
      width: 100%;
    }

    .popup-model__close {
      display: none;
    }

    .popup-model__left {
      display: none;
    }

    .popup-model__right>section {
      min-width: initial !important;
      padding: 1rem !important;
    }

    .b_button-primary,
    .b_button-invert {
      display: block;
      width: 100%;
    }

    h2 {
      color: #5c5c5b;
      font-size: 1.2rem;
    }

    .popup-model__btn-group-container {
      flex-direction: column-reverse;

      .link--has-back-arrow {
        order: 0;
      }

      > * {
        margin-bottom: 2rem;
      }
    }

    .popup-enquiry-bullets {
      margin-top: 1rem;
    }

    .popup-bullets {
      margin-top: 1rem;
    }

    .reset-password {
      text-align: center;
      margin-top: -2rem;
    }

  }

}

@media (max-width: 768px) {

  .popup-model {
    .create-an-account-mobile {
      display: inline-block;
      float: right;
      color: #009EE2 !important;
      font-weight: lighter;
      text-decoration: underline;
      cursor: pointer;
      font-size: 1rem;
      padding-top: 2px;
      margin-left: 2px;
    }
  }

}

#shipping-option-container {
  width: 460px;
}

@media (max-width: 580px) {
  #shipping-option-container {
    width: auto;
  }
}

.popup-model__right {
  table.table-product-list {
    border: 0;
    margin: 0 0 2rem;
    width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    font-size: 13px;

    thead th, thead td{
      border-bottom-width: 2px;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      white-space: nowrap;
    }

    td, th {
      border-bottom: 1px solid #dee2e6;
      padding: .75rem;
      vertical-align: middle;

      &:first-child {
        padding-left: 0px;
      }

      &:last-child {
        padding-right: 0px;
      }

      &.product-thumbnail img {
        max-width: 50px;
        max-height: 50px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    th {
      text-align: inherit;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    th.text-left {
      text-align: left !important;
    }
    .text-right {
      text-align: right;
    }

    .hide-cell {
      display: none;
    }
  }
}

@media (max-width: 610px) {
  .table-responsive {
    display: block;
    overflow-x: auto;
  }
}

@media (max-width: 840px) {
  .popup-model .scroll-grey {
    height: auto !important;
    overflow-y: visible;
    padding-right: 0px !important;
  }
}

@media (min-width: 841px) {
  .popup-model .scroll-grey {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }

    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c2bdbd;
      border: 0px none #ffffff;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #949090;
    }

    &::-webkit-scrollbar-thumb:active {
      background: #949090;
    }

    &::-webkit-scrollbar-track {
      background: #e7e5e5;
      border: 0px none #ffffff;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-track:hover {
      background: #e7e5e5;
    }

    &::-webkit-scrollbar-track:active {
      background: #e7e5e5;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}


.popup-model__center {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .logo {
    width: 190px;
    margin-bottom: 3rem;
  }

  h1 {
    font-size: px2rem(28);
    line-height: px2rem(38);
    color: #009EE2;
    font-weight: bold;
  }

  p {
    margin-bottom: 2rem;
    font-size: px2rem(18);
  }

  .b_button-primary {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width : 767.5px) {
  .popup-model__center {
      .logo {
          width: 138px;
          margin-bottom: 4rem;
      }

      h1 {
          font-size: px2rem(21);
          line-height: px2rem(31);
      }

      p {
          font-size: px2rem(16);
      }

      .b_button-primary:not(.b_button-narrow):not(.b_button-short),
      .b_button-primary {
          font-size: px2rem(13) !important;
          padding: 1rem 2rem !important;
      }
  }
}

.popup-model__wide {
  min-width: 930px;
}

@media only screen and (max-width : 1016px) {
  .popup-model__wide {
    min-width: 100%;
  }
}

@media only screen and (max-width : 600px) {
  .popup-model__center .b_button-primary:not(.b_button-narrow):not(.b_button-short),
  .popup-model__center .b_button-primary,
  .popup-model__center .b_button-secondary:not(.b_button-narrow):not(.b_button-short),
  .popup-model__center .b_button-wide {
    padding: 1rem 2rem !important;
  }
}