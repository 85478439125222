/*
News.
*/

.news-section {
	&__title-area {
		border-bottom: 1px solid $c_border-light;
	}
}

