.customer-portal-top {
  background: white;
  position: relative;
  white-space: nowrap;
  border-bottom: 1px solid #E8E7E7;
  z-index: 1999999947;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 60;
    background: white;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__logo {
    pointer-events: none;
    width: 118px;
    cursor: pointer;
    display: block;
    margin-right: 10px;
  }

  @media (max-width: 992px) {
    &__logo {
      width: 95px !important;
      height: 28px !important;
    }
  }

  &__user-icon {
    margin-right: 0.5rem;
    transform: translateY(4px);
    height: 18px;
    display: inline-block;
    vertical-align: text-bottom;
  }

  &__cart-icon-menu {
    margin-right: 0.3rem;
    margin-top: -4px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
  }

  &__cart-icon {
    margin-right: 0.3rem;
    height: 38px;
    width: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E8E7E7;
    border-radius: 100%;
  }

  &__left {
    display: flex;
    position: relative;
  }

  &__right {
    display: flex;
    position: relative;

    &--section {
      display: inline-flex;
      align-items: center;
      gap: 4px;
      margin-left: 0.75rem;

      &--dropdown {
        position: relative;
      }
    }
  }

  &__welcome {
    color: $c_pop;
    margin-bottom: 13px;
  }

  &__account, &__history, &__contact, &__cart {
    font-size: px2rem(13);
    color: #A4A4A3;
    font-weight: normal;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  &__separator {
    display: inline-block;
    margin-right: 1rem;
    color: $c_silver;
  }
}

img.customer-portal-top__logo {
  margin-right: 2rem;
}