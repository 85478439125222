/*
Image Zoom, based on https://github.com/Spiderpig86/focus.js
*/

.focus-img {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
    position: relative;
}

.focus-img:hover ~ .hud {
    transition: all .2s;
    opacity: 1;
}

.focus-img.smoother {
    /* Slight delay to lower the stuttering */
    -webkit-transition: all .2s;
    transition: all .2s
}

.focus-img img {
    opacity: 0;
    max-width: 100%;
    max-height: 440px;
}

/* CURSOR TYPES */
.focus-img.cursor-arrow {
    cursor: default;
}

.focus-img.cursor-grab {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.focus-img.cursor-crosshair {
    cursor: crosshair;
}

/* HUD Displays */
.hud {
    bottom: 0;
    position: absolute;
    color: #fff;
    background: rgba(0, 0, 0, 0.75);
    padding: .25rem .75rem;
    opacity: 0;
    transition: all .2s;
}

.hud-bottom-left {
    bottom: 0;
    left: 0;
}

.hud-bottom-right {
    bottom: 0;
    right: 0;
}