#map {
    width: 100%;
    height: 100vh;
    position: static;
    z-index: 10;
    overflow: unset;
}

.map-box {
    position: relative;
    z-index: 20;
}

.map-search__box {
    max-width: 374px;
}

.map-search__section {
    position: relative;
    z-index: 32;
}

.header__map {
    z-index: 1;
}

.map-search__box-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: white;
}

.location-result-item .extra-info-show {
    display: none;
}

.location-result-multiple {
    position: relative;
    margin-bottom: 27px;
}

.location-result-multiple .location-result-item {
    border-bottom: 1px solid #f4f4f4;
    padding: 0 0 23px 0;
    margin-right: 20px;
    margin-bottom: 25px;

    .extra-info {
        display: none;
    }

    .extra-info-show {
        display: block;
    }
}

.location-result-item.focused {
    cursor: initial;
}

.location-result-item:last-of-type {
    border-bottom: none;
}

.location-result-item__title {
    color: #4e4d5a;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;

    span {
        margin-right: 8px;
    }
}

.location-result-item__info {
    color: #8e8e8e;
    margin-bottom: 5px;
    line-height: 24px;
    font-size: 14px;
}

.location-result-item__info-icon {
    display: block;
    float: left;
    width: 20px;
    margin-top: 3px;
}

.location-result-item__mobile-arrow {
    position: absolute;
    right: 32px;
    top: 32px;

    @media only screen and (min-width: 767.5px) {
        display: none;
    }
}

.location-result-item__info-desc,
.location-result-item__info-desc-small {
    display: block;
    margin-left: 30px;
}

.location-result-item__info-desc-small {
    font-size: 13px;
    font-style: italic;
    line-height: 19px;
    margin-bottom: 10px;
}

.location-result-item__link {
    font-size: 13px;
    color: #009ee2 !important;
    padding: 0px !important;
    margin-left: 30px;
    text-decoration: underline !important;
}

.link--pop:after,
.link--pop:before {
    vertical-align: middle;
}

.pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
}

.t_color--blue {
    color: #009ee2;
}

.location-toggle {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
}

.ga-breadcumb-hack .breadcrumb-item {
    color: #009ee2;
}

.ga-breadcumb-hack .breadcrumb-item.active {
    color: black;
}

.location-toggle__item {
    display: inline-block;
    z-index: 20;
    position: relative;
    width: 60px;
    text-align: center;
    line-height: 60px;
    background-color: white;
    height: 60px;
    margin-right: -4px;
    cursor: pointer;
}

.location-toggle__item svg {
    fill: #009ee2;
    transform: translateY(3px);
}

.location-toggle__item.is-active {
    background-color: #009ee2;
}

.location-toggle__item.is-active svg {
    fill: white;
}

.map-banner-box {
    position: absolute;
    top: 0;
    padding-top: 121px;
    height: 100%;
}

.banner-map .header {
    padding-top: 0 !important;
}

@media only screen and (max-width: 767.5px) {
    .map-banner-box {
        padding-top: 93px;
    }
    .map-search__box {
        max-width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .location-toggle__item {
        height: 45px;
        width: 45px;
        line-height: 45px;
    }
}

/* //search on mobile 50px */

.map-search__box-wrap .breadcrumb {
    float: left;
    width: auto;
    z-index: 10;
    position: relative;
}

@media only screen and (min-width: 767.5px) {
    .row-100-tab {
        width: 100%;
    }
}

.search-arrow:after {
    content: "";
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 44%;
}

.search-arrow-more-pad:after {
    right: 40px;
}

.map-search__box-wrap .breadcrumb-item.active {
    color: #000;
}

.search-arrow svg {
    vertical-align: middle;
}

// Loccation Box

.location-box {
    span {
        display: block;
    }
    &__search {
        background-color: white;
        font-family: "Arial";
        font-weight: 400;
        font-size: 16px;
        padding: 0 26px;
        position: relative;
        color: rgba(255, 255, 255, 0.66);
    }

    &__search-area {
        border-right: 2px solid #2aaee7;
        padding: 16px 0;
        display: inline-block;
        cursor: pointer;
        position: relative;
        float: left;
        width: 75px;
    }

    &__search-country {
        display: inline-block;
        cursor: pointer;
    }

    &__search-drop {
        position: relative;
        width: 100%;
        display: table-cell;
    }

    &__search-drop:after {
        content: "^";
        color: white;
        position: absolute;
        right: 0;
    }
    &__card {
        position: absolute;
        z-index: 5;
        top: 0px;
        width: 100%;
        height: auto;
        background-color: white;
        overflow-y: hidden;
        border: 1px solid #ebebeb;
        box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.1);
        list-style: none;
        max-height: calc(100vh - 345px);
        transition: height 0.15s ease-in-out;
        display: flex;
        flex-direction: column;

        ul {
            padding: 50px 20px 0;
            margin: 0;

            h3.location-result-item__title {
                cursor: pointer;
            }
        }
    }

    &__card.active {
        height: 400px;
    }

    &__card a {
        text-decoration: none;
        color: #8e8e8e;
        display: block;
        padding: 9px 0;
    }

    &__list-places h3 {
        color: #4e4d5a;
        font-family: "Arial";
        font-weight: bold;
        margin-bottom: 24px;
    }

    &__list-places p {
        color: #8e8e8e;
        line-height: 26px;
    }

    &__result-icon-wrap {
        display: block;
        margin-right: 24px;
        float: left;
    }

    &__result-info-wrap {
        display: block;
        width: 90%;
        margin-left: 36px;
    }
    &__list-drop {
        position: absolute;
        z-index: 6;
        width: 100%;
        list-style: none;
        background-color: white;
        top: 27px;
        border: 1px solid #ebebeb;
        box-shadow: 0 34px 75px -53px #ebebeb;
        padding: 0 20px;
        height: 0;
        overflow-y: hidden;
        transition: height 0.15s ease-in-out;

        ul {
            padding: 39px 0px;
            margin: 0;
        }
    }
    &__list-drop span {
        padding: 9px 0;
        display: block;
        text-decoration: none;
        color: #8e8e8e;
        cursor: pointer;
    }

    &__list-drop span:hover {
        color: #a5a5a5;
    }

    &__list-drop.active {
        height: 300px;
        overflow-y: hidden;
    }

    &__list-drop li {
        display: block;
    }
}

@media only screen and (max-width: 767.5px) {
    .location-box__search-area {
        padding-top: 11px;
        padding-bottom: 11px;
    }

    .location-box__card {
        max-height: calc(100vh - 47vh);
    }

    .location-toggle__item svg {
        width: 12px;
    }
}

.location-cont {
    &__search {
        background: white;
        padding: 20px;
    }

    &__search-input-pic {
        position: absolute;
        left: 9px;
        top: 50%;
        transform: translate(50%, -50%);
    }

    &__search-input-pic-size {
        height: 19px;
        width: 19px;
    }

    &__search-input-wrap {
        position: relative;
        input {
            border: 1px solid #efefef;
            border-radius: 30px;
            width: 100%;
            height: 44px;
            padding-left: 50px;
            outline: none;
            &::placeholder {
                color: #aaaaaa;
            }
        }
    }
}

.store-filter {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: white;
    width: 100%;

    @media only screen and (min-width: 767.5px) {
        bottom: unset;
        width: 170px;
        right: 0;
        left: unset;
    }

    &__item {
        position: relative;
        &-outer {
            position: relative;
            height: 100%;
        }
        &:after {
            content: "";
            clear: both;
            display: table;
        }
    }

    &__img {
        width: 19px;
        vertical-align: middle;
        display: inline;
        margin-right: 10px;
    }

    label {
        color: #aaaaaa;
        font-size: 13px;
        font-family: "Arial", "Helvetica", "sans-serif";
    }

    input {
        float: right;
    }

    &-checkbox {
        display: inline;
        &::after {
            content: " ";
            display: table;
            clear: both;
        }
        &__label {
        }

        &__input {
        }
    }
}

.custom-checkbox {
    position: relative;
    font-family: sans-serif;
    margin: 8px 0;

    input[type="checkbox"] {
        display: none;

        ~ b {
            display: inline-block;
            position: relative;
            background-color: #ffffff;
            border: 2px solid #007fae;
            font-size: 20px;
            color: #49b35e;
            cursor: pointer;
            line-height: 1;
            outline: 0;
            text-align: center;
            vertical-align: middle;
            height: 18px;
            width: 18px;
        }

        &:checked {
            ~ b:after {
                content: "\2713";
            }
        }

        &:disabled {
            ~ b {
                background-color: #007f9f;
            }

            &:checked ~ b:after {
                content: "\2713";
            }

            ~ b:after {
                content: "";
            }
        }

        &:hover {
            ~ b {
                border: 2px solid #49b35e;
            }

            &:disabled ~ b {
                border-color: #007fae;
                cursor: not-allowed;

                ~ span {
                    cursor: not-allowed;
                }
            }
        }
    }

    span {
        cursor: pointer;
    }
}

.filter-box__checkbox {
    user-select: none;
    cursor: pointer;
    margin-bottom: 0;
}

.filter-box__checkbox input:checked + .filter-box__checkbox svg polyline {
    stroke-dashoffset: 0;
}

.label-cbx:hover .filter-box__checkbox svg path {
    stroke-dashoffset: 0;
}

.filter-box__checkbox {
    position: relative;
    top: 2px;
    float: left;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border: 2px solid #c8ccd4;
    border-radius: 3px;
}

.filter-box__checkbox svg {
    position: absolute;
    top: -2px;
    left: -2px;
}

.filter-box__checkbox svg path {
    fill: none;
    // stroke: #20C2E0;
    // stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 71px;
    stroke-dashoffset: 71px;
    transition: all 0.6s ease;
}

.filter-box__checkbox svg polyline {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 18px;
    stroke-dashoffset: 18px;
    transition: all 0.3s ease;
}

.filter-box__checkbox > span {
    pointer-events: none;
    vertical-align: middle;
}

.cntr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;

    padding: 15px;
    display: block;
    background: white;
}

.invisible {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    opacity: 0;
}

.filter-box {
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    z-index: 30;
    width: calc(100% - 50px);
    box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.1);

    @media only screen and (min-width: 767.5px) {
        bottom: unset;
        width: 170px;
        right: 0;
        left: unset;
    }

    &__item {
        background-color: white;
        display: block;
        width: 100%;
        clear: both;
        &::after {
            content: "";
            display: table;
            clear: both;
        }
    }

    &__item-outer {
        position: relative;
    }

    &__label-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }

    &__item-label {
        padding: 10px 15px;
        clear: both;
        display: block;
        display: table;
        width: 100%;

        input:checked + .checkbox svg polyline {
            stroke-dashoffset: 0;
        }

        &:hover .checkbox svg path {
            stroke-dashoffset: 0;
        }

        .checkbox {
            position: relative;
            top: 2px;
            float: right;
            margin-right: 8px;
            width: 16px;
            height: 16px;
            border: 1px solid #c8ccd4;
            border-radius: 2px;
        }

        .checkbox svg {
            position: absolute;
            top: -2px;
            left: -1px;
        }

        .checkbox svg path {
            fill: none;
            // stroke: #20C2E0;
            // stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 71px;
            stroke-dashoffset: 71px;
            transition: all 0.6s ease;
        }
        .checkbox svg polyline {
            fill: none;
            stroke: #707070;
            stroke-width: 2;
            stroke-dasharray: 20px;
            stroke-dashoffset: 20px;
            transition: all 0.3s ease;
        }
        > span {
            pointer-events: none;
            vertical-align: middle;
        }
    }

    &__label {
        &::after {
            content: "";
            display: table;
            clear: both;
        }
    }

    &__icon {
        width: 19px;
        margin-right: 7px;
        vertical-align: middle;
        display: inline;
    }

    &__list {
    }

    &__item {
        border-bottom: 1px solid #eeeeee;

        &::last-of-type {
            border-bottom: none;
        }
    }

    &__item-outer {
    }

    &__text-wrap {
        float: left;
    }

    &__text {
        color: #aaaaaa;
        font-size: 13px;
        font-family: "Arial", "Helvetica", "sans-serif";
        font-weight: normal;
    }

    &__checkbox {
        float: right;
    }
}

.location-result-item__img {
    width: 13px;
    transform: translateY(3px);
}

.location-result-item__img-arrow {
    width: 16px;
    transform: translateY(5px);
}

.location-result-item__img-main {
    width: 23px;
    display: inline;
    margin-top: -4px;
    vertical-align: top;
}

.pointer {
    cursor: pointer;
}

.point-of-contact {
    position: absolute;
    top: 100%;
    background-color: #ffffff;
    width: 100%;
    padding: 3rem 0;
    box-shadow: 0px 0px 20px 20px rgba(0,0,0,0.04);

    &__result-header {
        padding: 22px 32px 22px 32px;
        border-bottom: 1px solid #eeeeee;
        font-size: px2rem(18);
    }

    &__result-header h3 {
        color: #009ee2;
        margin-bottom: 3px;
    }

    &__result-header div {
        color: #8e8e8e;
        font-style: italic;
        font-size: px2rem(14);
    }
    
    .row {
        gap: 16px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.content-map {
    margin-top: 8rem;
    padding-top: 4.5rem;
}

@media only screen and (max-width: 991.5px) {
    .point-of-contact {
        padding: 2.5rem 0;

        .row {
            display: block;
        }

        .map-location-container {
            margin-bottom: 1rem;
        }

        h2 {
            margin-bottom: 0.75rem;
        }
    }

    .content-map {
        margin-top: 10rem;
    }
}

@media only screen and (max-width: 767.5px) {
    .point-of-contact {
        padding: 1rem 0 1.5rem 0;

        .container {
            max-width: 100%;
        }

        .map-location-container {
            padding-bottom: 0rem;
        }
    }

    .content-map .f_body.u_mb--3 {
        margin-bottom: 1rem !important;
    }
}

#map-address-list {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin: 32px 23px 32px 32px;
    padding-right: 12px;
    scrollbar-width: thin;
    scrollbar-color: #059fe3 #d6d6d6;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #d6d6d6;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #059fe3;
        border-radius: 15px;
        border: 0px none #ffffff;
    }
}

.location-result-item__more {
    font-size: 13px;
    color: #009ee2 !important;
    padding: 0px !important;
    margin-left: 10px;
    text-decoration: underline !important;
    font-weight: bold;
}

.location-result-item__img-more {
    width: 14px;
    transform: translateY(6px);
}

#map-search-results.d-flex + div {
    display: none;
}

.zoom-control {
    display: flex;
    background-color: white;
    width: 35px;
    height: 70px;
    z-index: 31;
    flex-direction: column;
    position: absolute;
    bottom: 37px;
    right: 0px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.2);
    cursor: pointer;

    > div {
        padding: 10px;
    }

    .zoom-control-plus:hover,
    .zoom-control-minus:hover
    {
        opacity: 0.7;
    }

    .zoom-control-plus {
        border-bottom: 1px solid #c6c6c6;
    }

    .zoom-control-plus::before {
        background: url(/static/bundles/img/svg/plus.svg);
    }

    .zoom-control-minus::before {
        background: url(/static/bundles/img/svg/minus.svg);
    }

    .zoom-control-plus::before,
    .zoom-control-minus::before
    {
        content: '';
        background-repeat: no-repeat;
        display: block;
        width: 15px;
        height: 15px;
    }
}