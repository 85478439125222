/*
Related Products
*/

.related-products {
    padding: px2rem(70) 0;

    &.t_background--light {
        .section-title--dark {
            border-bottom-color: $c_border;
        }
    }
}

.related-products-carousel-container {
    overflow: hidden;
}

.related-products-carousel-container {
    .swiper-slide {
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.07) !important;
    }
}