/*
Downloads.
*/

.downloads {

	aside & {
		border: none;
	}
}

.download {
	padding: 1.5rem 0;
	border-bottom: solid 1px $c_gallery;
	line-height: 1.5;

	&__image {
		margin-right: $grid-gutter-width;
		width: px2rem(100);
	}

	&__body {
		width: calc(100% - #{px2rem(100)});
	}

	h3 {
		margin-bottom: px2rem(8);
	}

	.icon {
		width: 30px;
		height: 30px;
		margin-left:  px2rem(20);

		+ .icon {
			margin-left: px2rem(10);
		}
	}

	aside & {
		padding-top: 0;

		~ .download {
			padding: px2rem(40) 0;
		}
	}
}

.track-resources-header {
	height: 26rem !important;

	.header__image .header__body {
		top: 150px !important;
	}

	@media (max-width: 767px) {
		.header__image .header__body {
			top: 100px !important;
		}
	}
}

#tracked-resources-files {
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 18px;
		max-width: 500px;
		margin-top: 3rem;
    	margin-bottom: 6rem;
	}

	a {
		display: block;
    	width: 100%;
	}
}








@include at(xs) {
	.downloads {
		border-top: none;
	}
}
