.accessories-related {
  background-color: $c_white;
  padding: 4rem 0 1rem 0;
  margin-top: 0 !important;

  .swiper-wrapper {
    margin-bottom: 3rem;
  }

  &__transparent {
    background-color: transparent;
  }

  &__section-title {
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    border-bottom: solid 1px $c_gallery;
    padding-bottom: 1rem;
    margin-bottom: 3rem;

    &--no-margin-bottom {
      margin-bottom: 0rem;
    }
  }

  @media (max-width: 495px) {
    &__has-document {
      flex-direction: column;
    }
  }

  @media (min-width: 1200px) {
    &__hide-nav {
      .related-products__arrows,
      .swiper-pagination-bullets {
        display: none;
      }

    }
  }
}

.related-products-carousel__has-document {
  .swiper-pagination {
    display: none;
  }
}

.js_product-accessories-carousel .swiper-slide {
  width: 174px !important;
  height: 280px !important;
  padding: 2rem 1.5rem 1.5rem 1.5rem;
  margin-top: 10px !important;
  margin-left: 15px !important;
  margin-right: 0px !important;

  picture {
      width: 170px;
      height: 123px;
  }

  .price {
      font-size: px2rem(16);
  }

  .key-facts {
      height: 129px;
  }
}

.product-accessories-carousel {
  margin-top: 0;
  padding-bottom: 0;
  background-color: #f9f9f9;

  .product-feed-secondary {
    margin-bottom: 0;
  }
}

.product-accessories-tables {
  &__header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__header .b_button-primary {
    font-weight: bold;
  }
  .tabs__table-container {
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
}

.popup-model[data-model="product-accessories"] {
  max-width: 1140px !important;
}

@media only screen and (max-width: 767.5px) {
  .product-accessories-tables {
    &__header {
      margin-bottom: 2rem;
    }

    .tabs__table-container {
      margin-top: 0 !important;
    }

    .tabs__table-container table {
      margin-top: -47px !important;
    }

    .tabs__table-container::before {
      top: 0px;
    }
  }

  .popup-model[data-model="product-accessories"] .popup-model__padded {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .product-accessories-carousel {
    .product-feed-secondary {
      padding: 1rem 0rem 0rem 0rem !important;
    }

    .product-feed-secondary-intro {
      padding: 0rem !important;
    }

    .product-feed-secondary-intro>div {
      padding: 1rem 0rem !important;
    }

    .t_generic-text--wysiwyg {
      margin-bottom: 0.5rem !important;
    }

    .product-accessories-thumbs {
      padding: 0;
      margin-left: -10px;
    }
  }

  .accessories-related {
    padding: 3.5rem 0 1rem 0;

    .section-title {
      margin-bottom: 1rem;
      padding-bottom: 1rem;

      h3 {
        font-size: 14px;
      }
    }
  }

	.accessories-related .related-products__arrows {
		position: absolute;
		right: 16px;
		bottom: 0px;
    z-index: 2;
	}
}